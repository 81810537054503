import { Link as ChakraLink } from '@chakra-ui/react';
import { ComponentProps, ReactNode } from 'react';
import { Link } from '../shared/Typography';

type ExternalLinkProps = Pick<
  ComponentProps<typeof ChakraLink>,
  'color' | 'textDecoration'
> & {
  to: string;
  children: ReactNode;
};

export const ExternalLink = ({
  to,
  children,
  color,
  textDecoration,
}: ExternalLinkProps) => (
  <ChakraLink isExternal href={to} textDecoration={textDecoration}>
    <Link color={color}>{children}</Link>
  </ChakraLink>
);
