import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  FieldErrorsBody,
  SignupErrorCodes,
  SignupRequest,
  SignupResponse,
} from '@finch-api/developer-dashboard-common';
import { StatusCodes } from 'http-status-codes';

import { baseURL } from '../../../utils/baseUrls';

export const signup = async (
  request: SignupRequest,
): Promise<{
  success?: SignupResponse;
  error?: FieldErrorsBody<SignupErrorCodes>;
}> => {
  try {
    const response = await axios.post<SignupResponse>(
      `${baseURL}/api/v1/account`,
      request,
    );

    return {
      success: response.data,
    };
  } catch (err) {
    if (
      axios.isAxiosError(err) &&
      err.response &&
      err.response.status === StatusCodes.BAD_REQUEST
    ) {
      const errorPayload = err.response
        .data as FieldErrorsBody<SignupErrorCodes>;
      return {
        error: errorPayload,
      };
    }

    // We were unable to gracefully handle the error, so now we ring the alarms:
    Sentry.captureException(err);
    throw err;
  }
};
