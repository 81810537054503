import { Stack, HStack, Text } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { ProviderIcon } from 'components/Iconagraphy';
import { NewSession } from 'connections-v2/hooks/useConnectSession';
import { SwitchInput } from 'components/SwitchInput';
import { useMemo } from 'react';
import { AUTH_TYPE_MAP } from 'constant/auth-methods';
import {
  ImplementationKind,
  isAssistedImplementationKind,
} from '@finch-api/common/dist/internal/connect/authorize';
import { useApplication } from 'applications/hooks';
import { ProviderWithImplementation } from 'integrations/types';

export const OptionalSection = ({
  form,
  automatedProviders,
  allProviders,
  isLoading,
}: {
  form: UseFormReturn<NewSession>;
  allProviders: ProviderWithImplementation[];
  automatedProviders: ProviderWithImplementation[];
  isLoading: boolean;
}) => {
  const { application } = useApplication();

  const { register, formState } = form;

  const selectedProvider = form.watch('providerId');
  const showAssistedProviders = form.watch('showAssisted');

  const providers = showAssistedProviders ? allProviders : automatedProviders;

  const implementationOptions = useMemo(() => {
    return providers
      .find((provider) => provider.id === selectedProvider)
      ?.implementations.filter(
        ({ enabled, kind }) =>
          enabled &&
          // If the user has selected to show assisted providers, then we show all implementations
          // Otherwise, we only show non-assisted implementations
          (showAssistedProviders ? true : !isAssistedImplementationKind(kind)),
      )
      .map(({ id, kind }) => ({
        value: id,
        label: AUTH_TYPE_MAP[kind as ImplementationKind],
      }));
  }, [providers, selectedProvider, showAssistedProviders]);

  const redirectUriOptions = useMemo(() => {
    return application?.redirect_uris.map((uri) => ({
      value: uri,
      label: uri,
    }));
  }, [application]);

  return (
    <Stack spacing={6} fontSize="14px">
      <Stack py={2} spacing={4}>
        <FormInput
          id="externalCompanyEmail"
          label="Customer Email"
          register={register}
          error={formState.errors.externalCompanyEmail?.message}
        />
        <FormSelect
          id="providerId"
          register={register}
          control={form.control}
          placeholder="Choose provider"
          isLoading={isLoading}
          options={providers.map(({ displayName, icon, id }) => ({
            value: id,
            label: (
              <HStack key={id}>
                <ProviderIcon provider={displayName} src={icon} />
                <Text>{displayName}</Text>
              </HStack>
            ),
          }))}
          label="Provider"
        />
        <FormSelect
          id="implementationId"
          register={register}
          control={form.control}
          placeholder="Choose Authentication Method"
          isLoading={isLoading}
          options={implementationOptions ?? []}
          label="Authentication Method"
        />
        <FormSelect
          id="redirectUri"
          register={register}
          control={form.control}
          placeholder="Choose Redirect URL"
          isLoading={isLoading}
          options={redirectUriOptions ?? []}
          label="Redirect URL"
          helpText="Leave blank if you want a live connection created right after the employer completes the connect session."
        />
        <FormInput
          id="expiresAtInSeconds"
          label="Expiration (in minutes)"
          register={register}
          error={formState.errors.expiresAtInSeconds?.message}
          helpText="It's 14 days (20,160 minutes) by default."
          type="number"
        />
        <SwitchInput
          label="Show Assisted Providers"
          control={form.control}
          name="showAssisted"
        />
      </Stack>
    </Stack>
  );
};
