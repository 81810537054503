import {
  Box,
  Button,
  FormErrorMessage,
  HStack,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { RequestOption } from './request-option';
import { JsonBuilder } from './json-builder';
import { RequestPreview } from './request-preview';
import { useSelectedOperation } from '../store/selected-operation';
import { useSelectedJsonMode } from '../store/selected-json-mode';
import { AppTab, AppTabList } from 'components/Tabs';
import { OperationTitle } from './operation-title';
import { ParameterEntry } from './parameter-entry';
import { Z_INDEX } from 'constant';

type RequestProps = {
  fetch: () => void;
  error: Error | null;
  isLoading: boolean;
};

export const EntityTypeLabel = {
  headers: 'Headers',
  paths: 'Path parameters',
  queries: 'Query parameters',
};

export const Request = ({ fetch, isLoading, error }: RequestProps) => {
  const { register } = useFormContext();
  const { selectedOperation } = useSelectedOperation();
  const { selectedJsonMode, setSelectedJsonMode } = useSelectedJsonMode();

  const handleTabsChange = (index: number) => {
    setSelectedJsonMode(index);
  };

  const formPath = `${selectedOperation?.path}.${selectedOperation?.method}`;

  return (
    <Stack gap="2" maxH="full" pos="relative">
      <HStack
        justify="space-between"
        pos="sticky"
        top="0"
        bg="white"
        zIndex={Z_INDEX.TABLE_STICKY_HEADER}
        p="4"
      >
        <Box flexBasis="90%">
          <OperationTitle />
        </Box>
        <Button
          variant="primaryPurple"
          size="sm"
          onClick={fetch}
          isLoading={isLoading}
        >
          Send
        </Button>
      </HStack>
      <Stack p="4" pt="0" gap="6" h="full" overflowY="auto">
        {selectedOperation?.parameters?.headers?.length && (
          <ParameterEntry
            entries={selectedOperation.parameters.headers}
            entityType="headers"
            formPath={formPath}
            register={register}
            disableInputs
          />
        )}
        {selectedOperation?.parameters?.paths?.length && (
          <ParameterEntry
            entries={selectedOperation.parameters.paths}
            entityType="paths"
            formPath={formPath}
            register={register}
          />
        )}
        {selectedOperation?.parameters?.queries?.length && (
          <ParameterEntry
            entries={selectedOperation.parameters.queries}
            entityType="queries"
            formPath={formPath}
            register={register}
          />
        )}
        {(selectedOperation?.requestBody ||
          selectedOperation?.typedRequestBody) && (
          <RequestOption title="Request body" openByDefault>
            <Tabs
              size="sm"
              p="0"
              index={selectedJsonMode}
              onChange={handleTabsChange}
            >
              <AppTabList gap={6}>
                {selectedOperation.typedRequestBody && (
                  <AppTab fontSize="13px" p="0 0 6px 0">
                    JSON builder
                  </AppTab>
                )}
                {selectedOperation?.requestBody && (
                  <AppTab fontSize="13px" p="0 0 6px 0">
                    Raw JSON
                  </AppTab>
                )}
              </AppTabList>
              <TabPanels>
                {selectedOperation.typedRequestBody && (
                  <TabPanel p="0" pt="4">
                    <Stack gap={6}>
                      <Box flex="3">
                        <JsonBuilder
                          key={`${formPath}.typedRequestBody`}
                          formPath={`${formPath}.typedRequestBody`}
                          jsonSchemas={selectedOperation.typedRequestBody}
                        />
                      </Box>
                      <RequestPreview
                        key={`${formPath}.typedRequestBody`}
                        formPath={`${formPath}.typedRequestBody`}
                      />
                    </Stack>
                  </TabPanel>
                )}
                {selectedOperation?.requestBody && (
                  <TabPanel px="0" py="2">
                    <Textarea
                      placeholder="{}"
                      fontFamily="mono"
                      {...register(`${formPath}.requestBody`)}
                      key={`${formPath}.requestBody`}
                      fontSize="xs"
                      defaultValue={JSON.stringify(
                        selectedOperation.requestBody,
                        null,
                        2,
                      )}
                      size="sm"
                      rows={6}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </RequestOption>
        )}
        {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </Stack>
    </Stack>
  );
};
