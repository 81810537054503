import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Caption } from './Typography';

export const useStatusToast = (position: 'top' | 'bottom' = 'top') => {
  const toast = useToast();

  const statusToast = useCallback(
    (
      args:
        | {
            wasSuccessful: true;
            message: React.ReactChild;
          }
        | {
            wasSuccessful: false;
            message?: React.ReactChild;
          },
    ) => {
      const message = args.wasSuccessful
        ? args.message
        : args.message || 'An error has occurred. Please try again.';

      const description =
        typeof message === 'string' ? <Caption>{message} </Caption> : message;

      return toast({
        variant: 'subtle',
        description,
        status: args.wasSuccessful ? 'success' : 'error',
        duration: 3000,
        position: position,
      });
    },
    [position, toast],
  );

  return statusToast;
};
