import {
  Text,
  Stack,
  HStack,
  Icon,
  StackDivider,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import { GreenCheck } from 'shared/icons/GreenCheck';
import { ChevronRightIcon } from 'shared/icons/ChevronRight';
import { ConnectionCard } from '../../components/ConnectionCard';
import { COLORS } from 'constant';
import { PillButton } from 'components/PillButton';
import { CalendarIcon } from 'shared/icons/Calendar';
import { DurationIcon } from 'shared/icons/Duration';
import { CancelCircleIcon } from 'shared/icons/CancelCircle';
import { ConnectSessionEvent } from 'types/connect';
import { IncompleteCircle } from 'shared/icons/IncompeleteCircle';
import { TimelineErrorDrawer } from './TimelineErrorDrawer';
import { InProgressIcon } from 'shared/icons/InProgressCircle';
import { NumberCount } from 'components/NumberCount';
import { generateTimelineSteps } from './utils/generateTimelineSteps';

const StatusToIcon = {
  'Not Started': { icon: IncompleteCircle, color: COLORS.GRAY.GRAY_600 },
  Completed: { icon: GreenCheck, color: COLORS.GREEN },
  Error: { icon: CancelCircleIcon, color: COLORS.FINCH.RED },
  'In Progress': { icon: InProgressIcon, color: COLORS.FINCH.PURPLE },
};

export const ConnectionTimelineCard = ({
  sessionEvents,
}: {
  sessionEvents: ConnectSessionEvent[];
  navigateToSessionEventsTab: () => void;
}) => {
  const { steps, errorEvents } = generateTimelineSteps(sessionEvents);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ConnectionCard>
      <Stack
        divider={<StackDivider borderColor="gray.200" />}
        align="flex-start"
        direction={{ base: 'column', lg: 'row' }}
        spacing="0"
      >
        {steps.map((step) => (
          <Stack
            key={step.step}
            flex={1}
            px={6}
            py={4}
            spacing={6}
            width="100%"
          >
            <Stack spacing={2}>
              <Text fontSize="13px" color={COLORS.GRAY.GRAY_600}>
                Stage {step.step}
              </Text>
              <Text fontWeight="500" fontSize="sm" color={COLORS.FINCH.BLACK}>
                {step.title}
              </Text>
            </Stack>
            <Stack
              spacing={3}
              direction={{ base: 'row', lg: 'column' }}
              wrap="wrap"
              justify="space-between"
            >
              <HStack spacing={1} color={StatusToIcon[step.status].color}>
                <Center boxSize="20px">
                  <Icon as={StatusToIcon[step.status].icon} />
                </Center>
                <Text fontSize="sm">{step.status}</Text>
                {step.errorCount && (
                  <NumberCount>{step.errorCount}</NumberCount>
                )}
              </HStack>
              <HStack spacing={1}>
                <CalendarIcon />
                <Text color={COLORS.GRAY.GRAY_600} fontSize="sm">
                  {step.formattedDate !== '—' ? step.formattedDate : '—'}
                </Text>
              </HStack>
              <HStack spacing={1}>
                <DurationIcon />
                <Text color={COLORS.GRAY.GRAY_600} fontSize="sm">
                  {step.duration}
                </Text>
              </HStack>
            </Stack>
            {step.errorCount && (
              <PillButton
                alignSelf="flex-end"
                variant="secondary"
                py="1"
                px="2"
                onClick={onOpen}
                pr="1"
                rightIcon={<ChevronRightIcon />}
              >
                More info
              </PillButton>
            )}
          </Stack>
        ))}
      </Stack>

      <TimelineErrorDrawer
        errorEvents={errorEvents}
        isOpen={isOpen}
        onClose={onClose}
      />
    </ConnectionCard>
  );
};
