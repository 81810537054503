import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { DateTime, Duration } from 'luxon';

import {
  Input,
  InputGroup,
  Box,
  Flex,
  Button,
  Select,
  InputLeftElement,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import DatePicker from 'react-datepicker';

import { PayrollProvider, statusDisplayFilters } from './tableProperties';
import { getProviders } from './providers';
import { CalendarContainer } from '../shared/DatePickerGlobalStyles';
import { Spacer } from '../shared/Spacer';
import { Caption } from '../shared/Typography';
import { useActivityLogs } from './hooks/useActivityLogs';
import { useActivityStore } from './state/activityStore';

const SIXTY_DAYS_AGO = Duration.fromObject({
  days: 60,
});

const DateFilter = () => {
  const dateMin = DateTime.now().minus(SIXTY_DAYS_AGO).toJSDate();
  const {
    updateDraftFilters,
    draftFilters: { startDate: startDateInt, endDate: endDateInt },
  } = useActivityStore();

  const startDate = startDateInt ? new Date(Number(startDateInt)) : null;
  const endDate = endDateInt ? new Date(Number(endDateInt)) : null;

  return (
    <>
      <Box>
        <DatePicker
          disabled={false}
          placeholderText="Start date: MM/DD/YYYY HH:MM"
          dateFormat="MM/dd/yyyy hh:mm a"
          selected={startDate}
          minDate={dateMin}
          maxDate={endDate || new Date()}
          shouldCloseOnSelect={false}
          showTimeInput={true}
          onChange={(date) => {
            if (date) {
              updateDraftFilters({
                startDate: date.getTime(),
              });
            }
          }}
          customInput={<Input />}
          calendarContainer={CalendarContainer}
        />
      </Box>
      <Box>
        <DatePicker
          disabled={false}
          placeholderText="End date: MM/DD/YYYY HH:MM"
          dateFormat="MM/dd/yyyy hh:mm a"
          selected={endDate}
          minDate={startDate}
          maxDate={new Date()}
          shouldCloseOnSelect={false}
          showTimeInput={true}
          onChange={(date) => {
            if (date) {
              updateDraftFilters({
                endDate: date.getTime(),
              });
            }
          }}
          customInput={<Input />}
          calendarContainer={CalendarContainer}
        />
      </Box>
    </>
  );
};

const DEFAULT_PROVIDER = { id: 'all', name: 'All' };

export const PayrollProviderDropdownFilter = () => {
  const {
    updateDraftFilters,
    draftFilters: { payrollProviderId },
  } = useActivityStore();

  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const { applicationId } = useParams<{ applicationId: string }>();
  if (!applicationId) throw new Error('no application id in url param');

  const [providers, setProviders] = useState<PayrollProvider[]>([
    DEFAULT_PROVIDER,
  ]);

  useEffect(() => {
    const run = async () => {
      const token = await getAccessTokenSilently();
      if (token) {
        const response = await getProviders(token, applicationId);
        response.unshift(DEFAULT_PROVIDER);
        setProviders(response);
      }
    };
    run();
  }, [getAccessTokenSilently, applicationId]);

  return (
    <Box flexGrow={1}>
      <Select
        placeholder="Payroll provider"
        value={payrollProviderId}
        onChange={(event) => {
          const option = event.target.value;

          updateDraftFilters({
            payrollProviderId: option,
          });
        }}
      >
        {providers.map((option) => (
          <option key={option.name} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </Box>
  );
};

const StatusDropdownFilter = () => {
  const {
    updateDraftFilters,
    draftFilters: { statusCode, statusDescription },
  } = useActivityStore();

  const selectVal = `${statusCode}-${statusDescription}`;

  return (
    <Box>
      <Select
        value={selectVal}
        placeholder="HTTP status code"
        onChange={(event) => {
          const [statusCode, statusDescription] = event.target.value.split('-');

          updateDraftFilters({
            statusCode: Number(statusCode),
            statusDescription,
          });
        }}
      >
        {statusDisplayFilters.map((option) => (
          <option
            key={JSON.stringify(option)}
            value={`${option.code}-${option.description}`}
          >
            {option.code
              ? `${option.code} ${option.description}`
              : option.description}
          </option>
        ))}
      </Select>
    </Box>
  );
};

const QuerySearchField = () => {
  const {
    updateDraftFilters,
    draftFilters: { fuzzyIdAndNameSearchQuery },
  } = useActivityStore();

  return (
    <Box flexGrow={2}>
      <InputGroup>
        <InputLeftElement color="gray.500" pointerEvents="none">
          <FiSearch />
        </InputLeftElement>
        <Input
          isDisabled={false}
          placeholder={
            'Search for company name, company id, request id, individual id, or payment id.'
          }
          value={fuzzyIdAndNameSearchQuery}
          onChange={(e) => {
            const newInputVal = e.target.value ?? '';

            updateDraftFilters({
              fuzzyIdAndNameSearchQuery: newInputVal,
            });
          }}
        />
      </InputGroup>
    </Box>
  );
};

const EndpointOminiSearchBar = () => {
  const {
    updateDraftFilters,
    draftFilters: { endpointSearchQuery },
  } = useActivityStore();

  return (
    <Box flexGrow={2}>
      <InputGroup>
        <InputLeftElement color="gray.500" pointerEvents="none">
          <FiSearch />
        </InputLeftElement>
        <Input
          isDisabled={false}
          placeholder={'Search for endpoint path'}
          value={endpointSearchQuery}
          onChange={(e) => {
            const newInputVal = e.target.value
              ? e.target.value.toLowerCase()
              : '';

            updateDraftFilters({
              endpointSearchQuery: newInputVal,
            });
          }}
        />
      </InputGroup>
    </Box>
  );
};

const SearchButton = () => {
  const { submitSearch } = useActivityLogs();

  return (
    <Flex alignItems="flex-end">
      <Button
        width="125px"
        variant="primaryPurple"
        type="submit"
        onClick={() => {
          submitSearch();
        }}
      >
        <Caption>Search</Caption>
      </Button>
    </Flex>
  );
};

export const SearchFilters = () => {
  const { clearFilters, commitFilters } = useActivityStore();
  const { fetchLogs } = useActivityLogs();

  return (
    <Box>
      <form
        onSubmit={(ev) => {
          // Prevents a page refresh on submit
          ev.preventDefault();
        }}
      >
        <Flex flexDirection={{ base: 'column', md: 'row' }} gap="12px">
          <QuerySearchField />
          <EndpointOminiSearchBar />
          <PayrollProviderDropdownFilter />
        </Flex>
        <Spacer p="2" />
        <Flex
          sx={{
            '> div': {
              flexGrow: '1',
            },
          }}
          flexDirection={{ base: 'column', sm: 'row' }}
          gap="12px"
        >
          <DateFilter />
          <StatusDropdownFilter />
        </Flex>
        <Spacer p="4" />
        <Flex justifyContent="flex-end" gap="8px">
          <Button
            onClick={() => {
              clearFilters();
              commitFilters();
              fetchLogs();
            }}
          >
            Reset
          </Button>
          <SearchButton />
        </Flex>
      </form>
    </Box>
  );
};
