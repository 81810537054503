import { PlainImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import isNil from 'lodash/isNil';

import { Endpoints, Group, Provider as _Provider } from './types';
import { flattenObject } from './utils';

type OperationSupport =
  | 'supported'
  | 'not_supported_by_finch'
  | 'is_client_access_only'
  | 'not_supported_by_provider';

export class ProviderImplementation {
  private benefits_support?: null | Record<string, OperationSupport>;

  private supported_fields?: null | Record<string, boolean>;

  constructor(
    private readonly params: Readonly<
      _Provider['authentication_methods'][number]
    >,
  ) {}

  get type() {
    return this.params.type;
  }

  get supportedFields() {
    if (this.supported_fields !== undefined) {
      return this.supported_fields;
    }

    this.supported_fields = flattenObject(
      this.params.supported_fields || Object.create(null),
    ) as Record<string, boolean> | null;

    return this.supported_fields;
  }

  get benefitsSupport() {
    if (this.benefits_support !== undefined) {
      return this.benefits_support;
    }

    this.benefits_support = flattenObject(
      this.params.benefits_support || Object.create(null),
    ) as Record<string, OperationSupport> | null;

    return this.benefits_support;
  }

  isAssisted(): boolean {
    return this.type === 'assisted';
  }

  isApiToken(): boolean {
    return this.type === 'api_token';
  }

  isCredential(): boolean {
    return this.type === 'credential';
  }

  isOAuth(): boolean {
    return this.type === 'oauth';
  }

  supports<G extends Group>(endpoint: Endpoints[G], field: string): boolean {
    if (!this.supportedFields) {
      return false;
    }

    return this.supportedFields[`${endpoint}.${field}`]!;
  }

  coverage(total: number): number {
    return this.availability / total;
  }

  get availability(): number {
    const supportedFields = this.supportedFields;

    if (isNil(supportedFields)) {
      return 0;
    }

    return Object.values(supportedFields).filter(Boolean).length;
  }
}

export class Provider {
  private authentication_methods: ProviderImplementation[] | undefined;

  constructor(private readonly params: Readonly<_Provider>) {}

  get id() {
    return this.params.id;
  }

  get displayName() {
    return this.params.display_name;
  }

  get icon() {
    return this.params.icon;
  }

  get logo() {
    return this.params.logo;
  }

  get type() {
    return this.params.products;
  }

  get authenticationMethods() {
    if (this.authentication_methods) {
      return this.authentication_methods;
    }

    this.authentication_methods = this.params.authentication_methods.map(
      (x) => new ProviderImplementation(x),
    );

    return this.authentication_methods;
  }

  isBeta(): boolean {
    return this.params.beta;
  }

  isManual(): boolean {
    return this.params.manual;
  }

  authenticationMethod(
    method: `${PlainImplementationKind}`,
  ): ProviderImplementation | undefined {
    return this.authenticationMethods.find((a) => a.type === method);
  }

  supports<Selected extends Group>(
    method: `${PlainImplementationKind}`,
    endpoint: Endpoints[Selected],
    field: string,
  ): boolean {
    return !!this.authenticationMethod(method)?.supports(endpoint, field);
  }
}
