import { create } from 'zustand';
import type { ColumnFiltersState } from '@tanstack/react-table';

export const useSelectedFiltersStore = create<{
  authorizationTypes: string[];
  providers: string[];

  setProviders: (selected: string[]) => void;
  setAuthorizationTypes: (selected: string[]) => void;

  organizationFilters: ColumnFiltersState;
  setOrganizationFilters: (filters: ColumnFiltersState) => void;

  deductionFeatureFilters: ColumnFiltersState;
  setDeductionFeatureFilters: (filters: ColumnFiltersState) => void;

  deductionOperationFilters: ColumnFiltersState;
  setDeductionOperationFilters: (filters: ColumnFiltersState) => void;

  payrollFilters: ColumnFiltersState;
  setPayrollFilters: (filters: ColumnFiltersState) => void;
}>((set) => ({
  authorizationTypes: [],
  setAuthorizationTypes: (selected) => set({ authorizationTypes: selected }),

  providers: ['adp_run', 'gusto', 'quickbooks'],
  setProviders: (selected) => set({ providers: selected }),

  organizationFilters: [],
  setOrganizationFilters: (filters) => set({ organizationFilters: filters }),

  payrollFilters: [],
  setPayrollFilters: (filters) => set({ payrollFilters: filters }),

  deductionFeatureFilters: [],
  setDeductionFeatureFilters: (filters) =>
    set({ deductionFeatureFilters: filters }),

  deductionOperationFilters: [],
  setDeductionOperationFilters: (filters) =>
    set({ deductionOperationFilters: filters }),
}));
