import {
  AssistedJob,
  JobStatusEntry,
  JobType,
  JobStatusPayload,
  ConnectionStatus,
  ExternalJobStatus,
  BenefitTask,
} from '@finch-api/common/dist/external/dashboard/job-status';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import { baseURL } from '../utils/baseUrls';
import {
  ConnectionCategory,
  DetailedConnectionStatusInfo,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { JobEntry } from './types';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { NotFoundError } from './errors/NotFoundError';

export type DeprecatedAccountJobDetails = {
  connectionStatus: ConnectionStatus | null;
  createdAt: Date;
  employeeId: string;
  implementationKind: ImplementationKind;
  jobs: DeprecatedJobStatusEntry[];
  lastRetrieved: Date | null;
  tokens: {
    lastUsedAt: Date | null;
    redactedToken: string;
  }[];
  username: string | null;
};

export type DeprecatedJobStatusEntry =
  | {
      connectionCategory?: ConnectionCategory;
      date: Date | null;
      jobId: string;
      status: ExternalJobStatus;
      type: JobType.ORG_PAYROLL_SYNC;
    }
  | {
      connectionCategory?: ConnectionCategory;

      date: Date | null;
      jobId: string;
      status: ExternalJobStatus;
      tasks: BenefitTask[];
      type: Exclude<JobType, JobType.ORG_PAYROLL_SYNC>;
    };

export type DeprecatedJobStatusPayload = {
  jobStatus: Record<string, DeprecatedAccountJobDetails>;
};

export const getCompanyJobs = async (
  token: string,
  applicationId: string,
  companyId: string,
): Promise<DeprecatedJobStatusPayload['jobStatus']> => {
  const requestURL = new URL(`
    ${baseURL}/api/v1/applications/${applicationId}/jobs/${companyId}
  `);

  try {
    const resp = await axios.get<DeprecatedJobStatusPayload>(
      requestURL.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return resp.data.jobStatus;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      // Don't throw an error if the company has no automated jobs
      if (error.response?.status === 404) return {};

      Sentry.captureException(error);

      throw new Error(error.response?.data.message);
    }

    Sentry.captureException(error);
  }

  return {};
};

export const getCompanyAssistedJobs = async (
  token: string,
  applicationId: string,
  companyId: string,
): Promise<JobEntry[]> => {
  const requestURL = new URL(`
    ${baseURL}/api/v1/applications/${applicationId}/assisted-jobs/${companyId}
  `);

  try {
    const resp = await axios.get<{
      assistedJobs: AssistedJob[];
    }>(requestURL.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return resp.data.assistedJobs.map((job) => ({
      jobId: job.jobId,
      date: job.completedAt,
      creationDate: job.createdAt,
      status: job.status as JobStatusEntry['status'],
      type: job.taskType as JobType,
      connectionCategory: ConnectionCategory.Assisted,
      tasks: job.tasks,
    }));
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data.message);
    }

    throw new Error('Failed to get assisted jobs');
  }
};

export const getDetailedConnectionInfo = async (
  token: string,
  applicationId: string,
  companyId: string,
): Promise<DetailedConnectionStatusInfo> => {
  const url = new URL(`
    ${baseURL}/api/v1/applications/${applicationId}/employers/${companyId}
  `);

  try {
    const { data } = await axios.get(url.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.connection;
  } catch (error: unknown) {
    Sentry.captureException(error);

    if (axios.isAxiosError(error)) {
      switch (error.response?.status) {
        case 404:
          throw new NotFoundError(error.response?.data.message, error);

        default:
          throw new Error(error.response?.data.message);
      }
    }

    throw new Error('Failed to get connection details');
  }
};
