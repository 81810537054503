import { useCallback, useMemo } from 'react';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import isArray from 'lodash/isArray';

import { Category, Item, Label } from '../types';
import { PayStatementCategoryGroup } from '../components/pay-statement-category-group';
import { EmptyState } from '../../../../components/EmptyState';
import { ContentBox } from '../../../../components/ContentBox';
import { useLabels } from '../hooks/use-labels';
import { useEligibility } from '../hooks/use-eligibility';
import { PayStatementCategoryEditControls } from '../components/pay-statement-category-edit-controls';
import { useFormControls } from '../hooks/use-form-controls';
import { COLORS } from '../../../../constant';
import { exportCSV } from '../../../../utils/exportCsv';

const EmptyJobHistoryIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M23.4166 47L15.3333 38.9167L23.4445 30.8054L25.4028 32.75L20.6249 37.5279H46.9999V40.3054H20.6249L25.3749 45.0554L23.4166 47ZM40.5833 33.1946L38.6249 31.25L43.3749 26.5H16.9999V23.7221H43.3749L38.597 18.9446L40.5553 17L48.6666 25.1112L40.5833 33.1946Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};

function PayStatementItemDate() {
  return (
    <Text
      fontSize="13px"
      backgroundColor={COLORS.GRAY.GRAY_300}
      paddingY="2px"
      paddingX="8px"
      borderRadius="6px"
    >
      All pay statement items are dated to January 1, 2024
    </Text>
  );
}

export function PayStatementMapping({
  payStatementItem,
  payStatementItemLabels,
}: {
  payStatementItem: Item[];
  payStatementItemLabels: Label[];
}) {
  const { setLabel, labels } = useLabels();
  const { setEligible, eligible } = useEligibility();
  const { loading, editing, onEdit, onCancel, onSave } = useFormControls();
  const onChange = useCallback(
    (item: Item, label: string | string[]) => {
      const selected = isArray(label) ? label[0] : label;
      const option = payStatementItemLabels.find((x) => x.value === selected);
      const value = option?.value;

      if (value) {
        setLabel(item.category, item.name, value);
      }
    },
    [setLabel, payStatementItemLabels],
  );
  const onToggle = useCallback(
    (item: Item, polarity: boolean) => {
      setEligible(item.category, item.name, polarity);
    },
    [setEligible],
  );

  const mergedLabels = useMemo(() => {
    const serverStateLabels = payStatementItem
      .filter((item) => item.attributes.metadata?._PENSION_PRO_COLUMN)
      .reduce((merged, item) => {
        const stagedLabel = labels[item.category]?.[item.name];

        return {
          ...merged,
          [item.category]: {
            ...merged[item.category],
            [item.name]:
              stagedLabel ?? item.attributes.metadata?._PENSION_PRO_COLUMN,
          },
        };
      }, {} as Partial<Record<Category, Record<string, string>>>);

    return { ...labels, ...serverStateLabels };
  }, [payStatementItem, labels]);

  const categories = useMemo(
    () =>
      payStatementItem.reduce((categories, item) => {
        // TEMP: we are cutting scope on taxes for P1
        // https://tryfinch.slack.com/archives/C07PY2U4MAP/p1732903918909129
        if (item.category === 'taxes') {
          return categories;
        }

        if (!categories.has(item.category)) {
          categories.set(item.category, []);
        }

        categories.get(item.category)?.push(item);

        return categories;
      }, new Map<Category, Item[]>()),
    [payStatementItem],
  );
  const download = useCallback(() => {
    const data = Array.from(categories.entries()).reduce(
      (rows, [category, items]) => {
        for (const item of items) {
          rows.push({
            category,
            name: item.name,
            // label: item.attributes.metadata.label,
            label: '', // TODO: update me when backend is returning metadata
          });
        }

        return rows;
      },
      new Array<{ category: Category; name: string; label: string }>(),
    );

    exportCSV({
      data,
      headers: ['category', 'name', 'label'],
      fileName: 'Pay-Statement-Item-Mapping-' + new Date().toISOString(),
    });
  }, [categories]);
  const remapping = useMemo(() => {
    for (const key in labels) {
      const category = key as Category;

      if (categories.has(category)) {
        const mapped = categories.get(category)?.map(({ name }) => name);
        const remapped = Object.keys(labels[category] ?? {});

        for (const name of remapped) {
          if (mapped?.includes(name)) {
            return true;
          }
        }
      }
    }

    return false;
  }, [categories, labels]);

  if (payStatementItem.length === 0) {
    return (
      <ContentBox py="64px">
        <EmptyState
          icon={<EmptyJobHistoryIcon />}
          text="There's nothing here just yet."
          subText="Pay statement items are not yet available"
        />
      </ContentBox>
    );
  }

  return (
    <Stack gap="24px" marginTop="8px">
      <HStack>
        <PayStatementItemDate />
        <Spacer />
        <PayStatementCategoryEditControls
          remapping={remapping}
          download={download}
          disabled={loading}
          editing={editing}
          setEditing={onEdit}
          saveEdits={onSave}
          cancelEdits={onCancel}
        />
      </HStack>
      {Array.from(categories.entries()).map(([header, items], i) => {
        return (
          <PayStatementCategoryGroup
            key={i}
            editing={editing}
            header={header}
            items={items}
            labels={payStatementItemLabels}
            values={mergedLabels}
            checked={eligible}
            disabled={loading}
            onChange={onChange}
            onToggle={onToggle}
          />
        );
      })}
    </Stack>
  );
}
