import {
  Button,
  Stack,
  ModalHeader,
  ModalBody,
  Heading,
  Spacer,
  ModalFooter,
  HStack,
  Text,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubSectionHeading } from '../../../shared/Typography';
import { FormInput } from '../../../components/FormInput';
import {
  NewSession,
  newSessionSchema,
  ReauthSession,
} from './ModalNewConnectSession';
import { FormSelect } from '../../../components/FormSelect';
import { useProviderConfigurations } from '../../../integrations/hooks/useProviderConfigurations';
import { ProviderIcon } from '../../../components/Iconagraphy';
import { UseMutateFunction } from '@tanstack/react-query';
import {
  CreateConnectSessionError,
  CreateConnectSessionResponse,
} from 'connections-v2/hooks/useConnectSession';
import { Link, useParams } from 'react-router-dom';
import startCase from 'lodash/startCase';
import { PRODUCT_LABEL_MAP } from 'constant/scopes';
import { useApplication } from 'applications/hooks';

export const CreateSessionForm = ({
  isPending,
  mutate,
  initialRef,
  error,
}: {
  mutate: UseMutateFunction<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession,
    unknown
  >;
  isPending: boolean;
  initialRef?: React.RefObject<HTMLInputElement>;
  error?: AxiosError<CreateConnectSessionError> | null;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { automatedProviders, isLoading } = useProviderConfigurations();
  const form = useForm<NewSession>({
    resolver: zodResolver(newSessionSchema),
  });
  const { application } = useApplication(applicationId);

  const { register, handleSubmit, formState, setError } = form;

  const doesConnectionAlreadyExist =
    error?.response?.data?.message === 'Connection already exists';

  const existingConnectionId =
    error?.response?.data?.extra?.context?.connectionId;

  const scopes =
    application?.scopes
      .filter(({ name }) => name && PRODUCT_LABEL_MAP.get(name))
      .map(({ name }) => ({
        value: `employer:${name}`,
        label: PRODUCT_LABEL_MAP.get(name) || startCase(name),
      })) || [];

  return (
    <FormProvider {...form}>
      <ModalHeader fontSize="20px" fontWeight="500" pt="32px">
        <Heading as="h1" size="md" fontWeight="medium">
          Create a new Finch Connect link
        </Heading>
        <Spacer p="2" />
        <SubSectionHeading as="h2" fontWeight="normal">
          Enter the company’s information to generate a new Finch Connect URL.
        </SubSectionHeading>
      </ModalHeader>
      <ModalBody zIndex={1}>
        <Stack spacing={6} fontSize="14px">
          <Stack py={2} spacing={4}>
            <FormInput
              id="externalCompanyName"
              label="Company Name"
              inputRef={initialRef}
              register={register}
              error={formState.errors.externalCompanyName?.message}
            />
            <Stack>
              <FormInput
                id="externalCompanyId"
                register={register}
                error={formState.errors.externalCompanyId?.message}
                label="Customer ID (or Plan ID)"
                helpText="This is the ID you use in your system to identify your customer. This could be the Plan ID or another identifier."
              />
              {doesConnectionAlreadyExist && existingConnectionId && (
                <Link
                  to={`/app/applications/${applicationId}/connections/${existingConnectionId}`}
                  style={{ textDecoration: 'underline' }}
                >
                  Click here to navigate to the connection.
                </Link>
              )}
            </Stack>
            <FormSelect
              id="providerId"
              register={register}
              control={form.control}
              placeholder="Choose provider"
              isLoading={isLoading}
              options={automatedProviders
                .filter((provider) => provider.enabled)
                .map(({ displayName, icon, id }) => ({
                  value: id,
                  label: (
                    <HStack key={id}>
                      <ProviderIcon provider={displayName} src={icon} />
                      <Text>{displayName}</Text>
                    </HStack>
                  ),
                }))}
              label="Provider ID (optional)"
              helpText="Choosing a provider will direct your customer straight to that provider rather than allowing them to select one."
            />
            <FormSelect
              id="products"
              register={register}
              control={form.control}
              placeholder="Choose products"
              isLoading={isLoading}
              options={scopes}
              label="Products"
              helpText="Choose which Finch products to be included as part of the Finch Connect flow."
            />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          w="full"
          variant="primary"
          isDisabled={!formState.isValid}
          isLoading={isPending}
          fontSize="16px"
          onClick={handleSubmit((values) =>
            mutate(values, {
              onError: (error) => {
                if (
                  error.response?.data?.message === 'Connection already exists'
                ) {
                  setError('externalCompanyId', {
                    message:
                      'A connection with this customer ID already exists.',
                  });
                }
              },
            }),
          )}
          h="52px"
        >
          Create Finch Connect URL
        </Button>
      </ModalFooter>
    </FormProvider>
  );
};
