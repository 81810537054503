import {
  ROLES,
  SFTP_PERMISSION,
} from '../../../constant/roles-and-permissions';

export const useRoles = () => {
  return ROLES.map((role) => {
    return {
      ...role,
      readablePermissions: role.readablePermissions.filter(
        (permission) => permission !== SFTP_PERMISSION,
      ),
    };
  });
};
