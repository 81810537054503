import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { captureException } from '@sentry/react';

import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import {
  getCompanyAssistedJobs,
  getCompanyJobs,
  getDetailedConnectionInfo,
} from '../companyJobs';
import { AccountDetails } from '../../connections/types';

import {
  AccountStatus,
  ConnectionCategory,
  DetailedConnectionAccountInfo,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { NotFoundError } from '../errors/NotFoundError';
import { useHistory } from 'react-router-dom';

export const DataSyncContext = createContext<{
  isLoading: boolean;
  connectionAccounts: AccountDetails[];
  companyName: string;
  error?: unknown;
}>({
  isLoading: false,
  companyName: '',
  connectionAccounts: [],
});

export const useJobStatusData = () => useContext(DataSyncContext);

const getValidAssistedAccount = (accounts: DetailedConnectionAccountInfo[]) => {
  const assitedAccounts = accounts.filter((account) =>
    isAssistedImplementationKind(account.implementationKind),
  );

  const nonDisconnectedAssistedAccounts = assitedAccounts.find(
    (account) => account.status !== AccountStatus.DISCONNECTED,
  );

  // Return the first non-disconnected assisted account or the first assisted account if all are disconnected
  return nonDisconnectedAssistedAccounts || assitedAccounts[0];
};

export const DataSyncProvider = ({
  applicationId,
  companyId,
  children,
}: {
  applicationId: string;
  companyId: string;
  children: ReactNode;
}) => {
  const history = useHistory();
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [connectionAccounts, setConnectionAccounts] = useState<
    AccountDetails[]
  >([]);
  const [companyName, setCompanyName] = useState<string>('');

  useEffect(() => {
    setIsLoading(true);

    const run = async () => {
      const token = await getAccessTokenSilently();
      if (token) {
        try {
          const automatedJobsMap = await getCompanyJobs(
            token,
            applicationId,
            companyId,
          );

          const assistedJobs = await getCompanyAssistedJobs(
            token,
            applicationId,
            companyId,
          );

          const connectionInfo = await getDetailedConnectionInfo(
            token,
            applicationId,
            companyId,
          );

          setCompanyName(connectionInfo.companyName);

          const accounts: AccountDetails[] = [];

          for (const {
            accountId,
            implementationKind,
            status,
            firstConnectedAt,
            accountName,
            lastRedactedTokensUsed,
            statusMessage,
            connectionAccountId,
            statusTimeline,
          } of connectionInfo.accounts) {
            const accountWithAutomatedJobs = automatedJobsMap[accountId];

            /**
             * Make use of the tokens from the old endpoint first because the new endpoint only shows tokens that have been used.
             *
             * TODO: Remove this once the behavior of the tokens on the new endpoint has been validated/updated
             */
            const tokens =
              accountWithAutomatedJobs?.tokens?.map(
                ({ redactedToken }) => redactedToken,
              ) ||
              lastRedactedTokensUsed ||
              [];

            // Skip assisted accounts since they are handled separately
            if (isAssistedImplementationKind(implementationKind)) {
              continue;
            }

            const automatedJobs = accountWithAutomatedJobs?.jobs
              .filter((job) =>
                // Filter out jobs that are already in the assisted jobs list to avoid duplicated
                connectionInfo.implementationKinds.some((kind) =>
                  isAssistedImplementationKind(kind),
                )
                  ? !assistedJobs.map((job) => job.jobId).includes(job.jobId)
                  : true,
              )
              .map((job) => ({
                ...job,
                connectionCategory: ConnectionCategory.Automated,
                creationDate: null,
              }));

            accounts.push({
              lastRetrieved: accountWithAutomatedJobs?.lastRetrieved || null,
              tokens,
              createdAt: firstConnectedAt,
              accountId,
              username: accountName,
              implementationKind,
              jobs: automatedJobs || [],
              accountStatus: status as AccountStatus,
              statusMessage,
              connectionAccountId,
              statusTimeline,
            });
          }

          // Add the assisted account to the end of the list
          const assistedAccount = getValidAssistedAccount(
            connectionInfo.accounts,
          );
          if (assistedAccount) {
            accounts.push({
              accountId: assistedAccount.accountId,
              username: null, // We don't need to display username for assisted accounts
              implementationKind: assistedAccount.implementationKind,
              jobs: assistedJobs,
              accountStatus: assistedAccount.status as AccountStatus,
              lastRetrieved: null,
              tokens: assistedAccount.lastRedactedTokensUsed || [],
              createdAt: assistedAccount.firstConnectedAt,
              statusMessage: assistedAccount.statusMessage,
              connectionAccountId: assistedAccount.connectionAccountId,
              statusTimeline: assistedAccount.statusTimeline,
            });
          }

          setConnectionAccounts(accounts);
        } catch (err) {
          if (err instanceof NotFoundError) {
            setIsLoading(false);
            history.push(`/app/applications/${applicationId}/connections`);
          } else if (err instanceof Error) {
            setError(err);
          }
        }
      }
    };

    run()
      .finally(() => setIsLoading(false))
      .catch((err) => captureException(err));
  }, [getAccessTokenSilently, applicationId, companyId]);

  return (
    <DataSyncContext.Provider
      value={{
        isLoading,
        connectionAccounts,
        error,
        companyName,
      }}
    >
      {children}
    </DataSyncContext.Provider>
  );
};
