import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import type { ReadonlyDeep } from 'type-fest';

export const AUTH_TYPE_MAP: ReadonlyDeep<Record<ImplementationKind, string>> = {
  api_token: 'API',
  oauth: 'OAuth',
  assisted: 'Assisted',
  credential: 'Credentials',
  finch_sandbox_api_token: 'API',
  finch_sandbox_oauth: 'OAuth',
  finch_sandbox_credential: 'Credentials',
  finch_sandbox_assisted: 'Assisted',
};
