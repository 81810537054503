export const VisibilityIcon = ({ show = false }: { show?: boolean }) =>
  show ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.5C10.9722 13.5 11.7986 13.1597 12.4792 12.4792C13.1597 11.7986 13.5 10.9722 13.5 10C13.5 9.02778 13.1597 8.20139 12.4792 7.52083C11.7986 6.84028 10.9722 6.5 10 6.5C9.02778 6.5 8.20139 6.84028 7.52083 7.52083C6.84028 8.20139 6.5 9.02778 6.5 10C6.5 10.9722 6.84028 11.7986 7.52083 12.4792C8.20139 13.1597 9.02778 13.5 10 13.5ZM10 12C9.44444 12 8.97222 11.8056 8.58333 11.4167C8.19444 11.0278 8 10.5556 8 10C8 9.44444 8.19444 8.97222 8.58333 8.58333C8.97222 8.19444 9.44444 8 10 8C10.5556 8 11.0278 8.19444 11.4167 8.58333C11.8056 8.97222 12 9.44444 12 10C12 10.5556 11.8056 11.0278 11.4167 11.4167C11.0278 11.8056 10.5556 12 10 12ZM10 16C8.0195 16 6.21535 15.4549 4.58754 14.3646C2.95974 13.2743 1.76389 11.8194 1 10C1.76389 8.18056 2.95974 6.72569 4.58754 5.63542C6.21535 4.54514 8.0195 4 10 4C11.9805 4 13.7847 4.54514 15.4125 5.63542C17.0403 6.72569 18.2361 8.18056 19 10C18.2361 11.8194 17.0403 13.2743 15.4125 14.3646C13.7847 15.4549 11.9805 16 10 16Z"
        fill="#737373"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 18.1248L13.2917 15.4165C12.7639 15.5832 12.2257 15.7221 11.6771 15.8332C11.1285 15.9443 10.5694 15.9998 10 15.9998C8.01389 15.9998 6.19792 15.4616 4.55208 14.3853C2.90625 13.3089 1.72222 11.8471 1 9.99984C1.23611 9.27762 1.63194 8.57275 2.1875 7.88525C2.74306 7.19775 3.33333 6.59012 3.95833 6.06234L1.875 3.979L2.9375 2.9165L17.0625 17.0623L16 18.1248ZM10 13.4998C10.1944 13.4998 10.3889 13.479 10.5833 13.4373C10.7778 13.3957 10.9722 13.3471 11.1667 13.2915L6.70833 8.83317C6.66667 9.02762 6.62153 9.22206 6.57292 9.4165C6.52431 9.61095 6.5 9.80539 6.5 9.99984C6.5 10.9721 6.84028 11.7984 7.52083 12.479C8.20139 13.1596 9.02778 13.4998 10 13.4998ZM16.0417 13.9165L13.2708 11.1457C13.3403 10.9651 13.3958 10.7776 13.4375 10.5832C13.4792 10.3887 13.5 10.1943 13.5 9.99984C13.5 9.02762 13.1597 8.20123 12.4792 7.52067C11.7986 6.84012 10.9722 6.49984 10 6.49984C9.80556 6.49984 9.61458 6.5172 9.42708 6.55192C9.23958 6.58664 9.04861 6.63873 8.85417 6.70817L6.70833 4.56234C7.23611 4.354 7.77431 4.20817 8.32292 4.12484C8.87153 4.0415 9.43056 3.99984 10 3.99984C11.9861 3.99984 13.8021 4.53803 15.4479 5.61442C17.0938 6.69081 18.2778 8.15262 19 9.99984C18.6944 10.7915 18.2882 11.5103 17.7812 12.1561C17.2743 12.8019 16.6944 13.3887 16.0417 13.9165ZM11.9792 9.854L10.1458 7.99984C10.4236 7.97206 10.6736 8.00331 10.8958 8.09359C11.1181 8.18387 11.3264 8.32623 11.5208 8.52067C11.6875 8.68734 11.8125 8.8922 11.8958 9.13525C11.9792 9.37831 12.0069 9.61789 11.9792 9.854Z"
        fill="#737373"
      />
    </svg>
  );
