import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { analyticsClient } from '../utils/analytics/client';
import { SignUpEvent } from '@finch-api/common/dist/analytics/developer-dashboard-events';
import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';

import FinchLogo from '../assets/images/logos/finch/logo.svg';
import { useOrganization } from '../shared/OrganizationContext';
import { useStatusToast } from '../shared/StatusToast';
import { legalLinks, finchEmails, finchMailToLinks } from '../shared/links';
import { datadogRum } from '@datadog/browser-rum';
import { useSearchParams } from '../shared/useQuery';
import noop from 'lodash/noop';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import { ExternalLink } from '../shared/ExternalLink';
import { useAuthApi } from './hooks';

const errorQueryParam = 'error';
const errorDescriptionQueryParam = 'error_description';

const CenteredSpinner = () => (
  <Flex justifyContent="center" alignItems="center" h="100vh">
    <Spinner />
  </Flex>
);

const OrganizationAcceptTOS = () => {
  const [isAccepting, setIsAccepting] = useState(false);

  const toast = useStatusToast();
  const { acceptTOS } = useOrganization();

  const accept = useCallback(async () => {
    setIsAccepting(true);
    try {
      await acceptTOS();
    } catch (err) {
      toast({
        wasSuccessful: false,
        message: 'Please try accepting our terms of service again.',
      });
    } finally {
      setIsAccepting(false);
    }
  }, [toast, acceptTOS, setIsAccepting]);

  const isOperationInProgress = isAccepting;

  return (
    <Flex justifyContent="center" alignItems="center" h="100vh">
      <Box maxW="420px">
        <Center>
          <Image src={FinchLogo} my={16} />
        </Center>

        <Heading as="h4" mb={4}>
          <Center>One Last Thing...</Center>
        </Heading>

        <Text align="center" my={4} color="gray.c800">
          Before using the Finch Developer Dashboard, please read through and
          agree to the Finch{' '}
          <Link color="primary.c600" href={legalLinks.tos} target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link color="primary.c600" href={legalLinks.privacy} target="_blank">
            Privacy Policy
          </Link>
          .
        </Text>

        <Box my={8}>
          <Center>
            <Divider w="50%" />
          </Center>
        </Box>

        <HStack justifyContent="center" spacing={2}>
          <Button
            variant="secondary"
            isDisabled={isOperationInProgress}
            as={RouterLink}
            to="/logout"
          >
            Logout
          </Button>
          <Button
            variant="primary"
            onClick={accept}
            isLoading={isAccepting}
            isDisabled={isOperationInProgress}
          >
            I Agree
          </Button>
        </HStack>

        <Text
          my={4}
          align="center"
          fontSize="14px"
          lineHeight="20px"
          color="gray.c600"
        >
          By clicking &quot;I Agree&quot;, you agree to our{' '}
          <Link color="primary.c600" href={legalLinks.tos} target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link color="primary.c600" href={legalLinks.privacy} target="_blank">
            Privacy Policy
          </Link>
          .
        </Text>
      </Box>
    </Flex>
  );
};

const ResendVerificationEmail = ({ userId }: { userId: string }) => {
  const api = useAuthApi();
  const [resending, setResending] = useState(false);
  const [resent, setResent] = useState(false);
  const resend = useCallback(() => {
    if (!api) {
      return;
    }

    setResending(true);

    const post = async () => {
      const sent = await api.resendVerificationEmail(userId);

      return sent;
    };

    post()
      .then((sent: boolean) => setResent(sent))
      .finally(() => setResending(false));
  }, [api, userId]);

  if (resent) {
    return (
      <Text fontSize="14px" textAlign="center">
        Check your inbox and spam folder for your verification email.
      </Text>
    );
  }

  return (
    <Text fontSize="14px" textAlign="center">
      <Link
        as="span"
        onClick={resending ? noop : resend}
        textDecoration="underline"
      >
        Click here to re-send the verification email.
      </Link>
    </Text>
  );
};

const ErrorScreen = ({
  error,
  errorDescription,
}: {
  error: string;
  errorDescription?: string | null;
}) => {
  const fallbackDescription = 'An error was encountered when trying to log in.';
  // HACK: https://tryfinch.atlassian.net/browse/EN-4385
  let description = errorDescription;
  let userId = '';

  if (errorDescription && errorDescription.trim().startsWith('{')) {
    try {
      const { message, user_id } = JSON.parse(errorDescription);

      description = message;
      userId = user_id;
    } catch (caught) {
      description = fallbackDescription;
    }
  }

  const unverifiedEmail =
    'access_denied' === error &&
    'Please verify your email before logging in.' === description &&
    !!userId.length;

  return (
    <Center h="100%">
      <Card p="12" maxW="4xl" shadow="lg">
        <Stack spacing="6">
          <Heading as="h4" fontSize="24px" textAlign="center">
            {startCase(error)}
          </Heading>
          <Text textAlign="center">
            {description ? upperFirst(description) : fallbackDescription}
          </Text>
          <Text textAlign="center">
            If the issue persists, please contact{' '}
            <ExternalLink to={finchMailToLinks.developers}>
              {finchEmails.developers}
            </ExternalLink>
          </Text>
          <HStack>
            <Button
              flex="1"
              as="a"
              href="/auth/callback"
              variant="primary"
              size="md"
            >
              Retry
            </Button>
            <Button
              flex="1"
              as="a"
              href="/logout"
              variant="secondary"
              size="md"
            >
              Log out
            </Button>
          </HStack>
          {unverifiedEmail && <ResendVerificationEmail userId={userId} />}
        </Stack>
      </Card>
    </Center>
  );
};

/**
 * Handles returning from Auth0; fetches the Organization details.
 * @returns JSX React Component
 */
export const AuthCallback = () => {
  const searchParams = useSearchParams();
  const { isLoading, user, isAuthenticated } = useAuth0();
  const { fetchOrganization, organization, isFetchingOrg } = useOrganization();

  const error = searchParams.get(errorQueryParam);
  const errorDescription = searchParams.get(errorDescriptionQueryParam);

  useEffect(() => {
    if (isLoading) return;
    const run = async () => {
      try {
        const org = await fetchOrganization();

        if (user?.email && org) {
          analyticsClient.setUserId(user?.email);

          analyticsClient.track(SignUpEvent.UserLogin, {
            email: user?.email,
            companyName: org?.name,
          });
        }
      } catch (err) {
        datadogRum.addError(err, {
          page: 'AuthCallback',
        });
      }
    };
    run();
  }, [isLoading, fetchOrganization, user?.email]);

  if (isLoading || isFetchingOrg) {
    return <CenteredSpinner />;
  }

  if (error) {
    return <ErrorScreen error={error} errorDescription={errorDescription} />;
  }

  const hasAcceptedTos =
    organization?.tos?.currentVersion === organization?.tos?.acceptedVersion;

  return (
    <>
      {!organization && !isAuthenticated && <Redirect to="/logout" />}
      {hasAcceptedTos && <Redirect to="/app/applications" />}
      {!hasAcceptedTos && <OrganizationAcceptTOS />}
    </>
  );
};
