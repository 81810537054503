import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { CUSTOM_CLAIM_NAMESPACE } from '../constant/token';
import { Role } from '../constant/roles-and-permissions';

export const useAuth0Claims = () => {
  const { getIdTokenClaims } = useAuth0();

  const query = useQuery({
    queryKey: ['auth0-claims'],
    queryFn: () => getIdTokenClaims(),
  });

  const customClaims = Object.fromEntries(
    Object.entries(query.data || {})
      .filter(([key]) => key?.startsWith(CUSTOM_CLAIM_NAMESPACE))
      .map(([key, value]) => {
        return [key.replace(`${CUSTOM_CLAIM_NAMESPACE}/`, ''), value];
      }),
  );

  const isAdmin = customClaims?.orgRoles?.includes(Role.Admin);

  return {
    ...query,
    customClaims,
    isAdmin,
  };
};
