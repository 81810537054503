import { Role, RoleOption } from '../../../constant/roles-and-permissions';

export const getChangeRolePermissions = ({
  currentRole,
  newRole,
  roles,
}: {
  roles: RoleOption[];
  currentRole: Role;
  newRole: Role;
}): { isChangeUpgrade: boolean; permissions: string[] } => {
  const currentRoleIndex = roles.findIndex(
    ({ value }) => value === currentRole,
  );
  const newRoleIndex = roles.findIndex(({ value }) => value === newRole);

  const isChangeUpgrade = newRoleIndex > currentRoleIndex;

  const permissions = [];

  // Push all gained or lost permissions to the permissions array
  if (isChangeUpgrade) {
    for (let i = currentRoleIndex + 1; i <= newRoleIndex; i++) {
      permissions.push(...(roles[i]?.readablePermissions || []));
    }
  } else {
    for (let i = newRoleIndex + 1; i <= currentRoleIndex; i++) {
      permissions.push(...(roles[i]?.readablePermissions || []));
    }
  }

  return { isChangeUpgrade, permissions };
};
