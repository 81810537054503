import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import type { ColumnFiltersState } from '@tanstack/react-table';

import { Group, Subgroup } from '../types';

type FilterDataMap = {
  field?: {
    id: string;
    value: string;
  };

  endpoint?: {
    id: string;
    value: string;
  };

  authorization_type?: {
    id: string;
    value: string;
  };

  provider?: {
    id: string;
    value: string;
  };
};

/**
 * Sync the given data as URL query paramters.
 */
export const useFieldSupportSearchParamsSync = ({
  columnFilters,
  selectedGroup,
  selectedSubgroup,
}: {
  columnFilters: ColumnFiltersState;
  selectedGroup: Group;
  selectedSubgroup: Subgroup;
}) => {
  const history = useHistory();

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    const filterMap: FilterDataMap = columnFilters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.id]: filter,
      };
    }, {});

    newUrlParams.set('group', selectedGroup);
    newUrlParams.set('subgroup', selectedSubgroup);
    newUrlParams.set('field', filterMap.field?.value || '');
    newUrlParams.set('endpoint', filterMap.endpoint?.value || '');
    newUrlParams.set('provider', filterMap.provider?.value || '');
    newUrlParams.set(
      'authorization_type',
      filterMap.authorization_type?.value || '',
    );

    history.replace(`?${newUrlParams}`);
  }, [columnFilters, selectedGroup, selectedSubgroup, history]);
};
