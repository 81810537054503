import { useQuery } from '@tanstack/react-query';

import { useConnectionDetailApi } from './useConnectionDetailApi';
import { AxiosError } from 'axios';

export const useStagedConnectionDetails = ({
  applicationId,
  stagedConnectionId,
}: {
  applicationId: string;
  stagedConnectionId: string;
}) => {
  const api = useConnectionDetailApi();

  const {
    isLoading,
    data = null,
    error,
  } = useQuery({
    queryKey: ['staged-connection-detail', applicationId, stagedConnectionId],
    queryFn: () => api.getConnectionDetail(applicationId, stagedConnectionId),
    refetchOnMount: false,
    retry: 1,
  });

  return { connectionDetail: data, isLoading, error: error as AxiosError };
};
