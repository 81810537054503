import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Box,
  TableContainer,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  ColumnFiltersState,
  SortingState,
  PaginationState,
} from '@tanstack/react-table';

import { Pagination } from '../../components/Pagination';
import {
  CompanyCell,
  ConnectStepCell,
  DateCell,
  ProviderNameCell,
} from './TableCells';
import { EmptyStateMessage } from './EmptyStateConnectionsTable';
import { COLORS, SHADOWS } from '../../constant';
import { ConnectionCategory } from '@finch-api/common/dist/external/dashboard/connection-status';
import { Loading } from '../../components/Loading/Loading';
import { StagedConnection, StagedConnectionTableFilterId } from '../types';
import {
  TableFilters,
  SearchFilterInput,
  filterFns,
  DateRangeFilterInput,
  EmptyFilterStateMessage,
  dateRangeOptions,
  SelectFilterInput,
} from '../../components/TableFilters';
import {
  connectStepOptions,
  DisabledTableStyles,
  getProviderOptions,
  stagedConnectionFilterList,
} from '../constants';
import { useFilters } from '../../components/TableFilters/hooks';
import { useSearchParams } from '../../shared/useQuery';
import {
  getConnectionFilterFromQueryParams,
  useConnectionsQuerySync,
} from '../hooks/useConnectionsQuerySync';
import { TableHeaderContent } from '../../components/TableHeaderContent';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { ConnectionsFetchErrorMessage } from './ConnectionsFetchErrorMessage';
import { useIncompleteTableDataStore } from '../state/tableDataStore';
import { Caption } from 'shared/Typography';
import { ExternalLink } from 'shared/ExternalLink';
import { FINCH_DOCS_BASE_URL } from 'shared/links';

const Style = styled.div`
  table {
    border-spacing: 0;

    th {
      border: 0px;
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};
    }

    tr {
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};

      :last-child {
        border-bottom: 0px;
      }
    }

    td {
      padding: 10px 20px;
      border: 0px;
    }
  }
`;

const TableCellText = styled.div<{ width: string | undefined }>`
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.width && `width: ${props.width};`}
`;

const StagedTableBodyContent = ({
  applicationId,
  rows,
  disabled = false,
}: {
  applicationId: string;
  rows: Row<StagedConnection>[];
  disabled?: boolean;
}) => {
  const history = useHistory();

  return (
    <>
      {rows.map((row) => {
        return (
          <Tr
            cursor="pointer"
            _hover={{
              bgColor: COLORS.GRAY.GRAY_100,
            }}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        history.push(
                          `/app/applications/${applicationId}/incomplete-connections/${row.original.id}`,
                          { navigatedFromConnectionsTable: true },
                        );
                      }
                }
              >
                <TableCellText
                  width={cell.column.columnDef.meta?.style?.minWidth?.toString()}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};

export const implementationKindToCategory: Record<
  ImplementationKind,
  ConnectionCategory
> = {
  api_token: ConnectionCategory.Automated,
  oauth: ConnectionCategory.Automated,
  credential: ConnectionCategory.Automated,
  assisted: ConnectionCategory.Assisted,

  finch_sandbox_api_token: ConnectionCategory.Automated,
  finch_sandbox_oauth: ConnectionCategory.Automated,
  finch_sandbox_credential: ConnectionCategory.Automated,
  finch_sandbox_assisted: ConnectionCategory.Assisted,
};

export const StagedConnectionsTable = ({
  connections,
  isSandbox,
  loading,
  error,
  disabled = false,
}: {
  connections: StagedConnection[];
  isSandbox?: boolean;
  loading: boolean;
  error: Error | null;
  disabled?: boolean;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const queryParams = useSearchParams();
  const setIncompleteTableData = useIncompleteTableDataStore(
    (state) => state.setTableData,
  );
  const pageParam = queryParams.get('page') || '0';

  if (!applicationId) throw new Error('no application id in url param');

  const tableData = useMemo(() => {
    setIncompleteTableData(connections);
    return connections ?? [];
  }, [connections, setIncompleteTableData]);

  const columnHelper = createColumnHelper<StagedConnection>();

  const columns = [
    columnHelper.accessor('externalCompanyId', {
      enableHiding: true,
      meta: {
        filterLabel: 'Customer ID',
        filterInput: SearchFilterInput,
        style: { minWidth: '220px' },
      },
    }),
    columnHelper.accessor('externalCompanyName', {
      header: 'Customer',
      meta: {
        filterLabel: 'Customer Name',
        filterInput: SearchFilterInput,
        style: { minWidth: '220px' },
      },
      cell: (props) => (
        <CompanyCell
          name={props.getValue()}
          id={props.row.original.externalCompanyId}
        />
      ),
    }),
    columnHelper.accessor('latestStepInfo', {
      header: 'Stage Completed',
      filterFn: 'equals',
      meta: {
        style: { minWidth: '200px' },
        filterInput: SelectFilterInput,
        filterInputOptions: connectStepOptions,
      },
      cell: (props) => <ConnectStepCell props={props} />,
    }),
    columnHelper.accessor('firstConnectedAt', {
      header: 'Created',
      filterFn: 'dateRange',
      meta: {
        style: { minWidth: '200px' },
        filterInput: DateRangeFilterInput,
        filterInputOptions: dateRangeOptions,
      },
      cell: (props) => <DateCell value={props.getValue()} />,
    }),
    columnHelper.accessor('latestStepInfo.latestActivity', {
      header: 'Latest Activity',
      filterFn: 'dateRange',
      meta: {
        style: { minWidth: '200px' },
        filterInput: DateRangeFilterInput,
        filterInputOptions: dateRangeOptions,
      },
      cell: (props) => <DateCell value={props.getValue()} />,
    }),
    columnHelper.accessor('providerName', {
      header: 'Provider',
      filterFn: 'arrIncludesSome',
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: getProviderOptions(tableData),
        filterLabel: 'Provider',
        style: { minWidth: '200px' },
      },
      cell: (props) =>
        props.row.original.providerIcon ? (
          <ProviderNameCell
            name={props.getValue()}
            icon={props.row.original.providerIcon}
          />
        ) : (
          <Caption>Not Selected</Caption>
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.providerName > rowB.original.providerName
          ? 1
          : rowA.original.providerName < rowB.original.providerName
          ? -1
          : 0;
      },
    }),
  ];

  const [columnVisibility, setColumnVisibility] = useState<
    Partial<Record<keyof StagedConnection, boolean>>
  >(
    columns.reduce((acc, column) => {
      if ('accessorKey' in column) {
        acc[column.accessorKey as keyof StagedConnection] =
          !column.enableHiding;
      }
      return acc;
    }, {} as Partial<Record<keyof StagedConnection, boolean>>),
  );

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'firstConnectedAt',
      desc: true,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: parseInt(pageParam),
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() =>
    getConnectionFilterFromQueryParams(),
  );

  const [selectedFilters, setSelectedFilters] = useState<
    StagedConnectionTableFilterId[]
  >(
    () =>
      getConnectionFilterFromQueryParams().map(
        ({ id }) => id,
      ) as StagedConnectionTableFilterId[],
  );

  const {
    getHeaderGroups,
    getState,
    getRowModel,
    getColumn,
    getCanPreviousPage,
    getCanNextPage,
    getFilteredRowModel: getCurrentFilteredRowModel,
  } = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    filterFns,
  });

  const state = getState();
  const rows = getRowModel().rows;

  const shouldShowEmptyState = !loading && 0 === connections?.length && !error;

  const shouldShowEmptyRowsState =
    !loading && 0 === rows.length && 0 < state.columnFilters.length && !error;
  const shouldShowPagination = 0 < rows.length && !shouldShowEmptyState;

  const resetPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  };

  const { setFilterValue, removeAllFilters, ...filterProps } = useFilters<
    StagedConnection,
    StagedConnectionTableFilterId
  >({
    filterList: [...stagedConnectionFilterList],
    getColumn,
    selectedFilters,
    setSelectedFilters,
  });

  useConnectionsQuerySync({ columnFilters });

  return (
    <Box sx={disabled ? DisabledTableStyles : undefined}>
      <Stack
        borderRadius="8px"
        boxShadow={SHADOWS.PAGE}
        spacing="0"
        backgroundColor={COLORS.WHITE}
      >
        <TableFilters
          setFilterValue={(...args) => {
            setFilterValue(...args);

            // Reset back to the first page after changing filters from the menu.
            // This is to prevent 0 search results showing if the new filtered list
            // size is less than the last filtered size.
            resetPagination();
          }}
          {...filterProps}
        />

        <TableContainer>
          <Style>
            <Table
              borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
              variant="simple"
            >
              <TableHeaderContent
                headerGroups={getHeaderGroups()}
                thOverrideStyle={{ p: '10px 20px' }}
              />
              <Tbody textColor="#1A202C">
                {!loading && (
                  <StagedTableBodyContent
                    applicationId={applicationId}
                    rows={rows}
                    disabled={disabled}
                  />
                )}
              </Tbody>
            </Table>
          </Style>
          {error && (
            <ConnectionsFetchErrorMessage
              table="connections"
              onRetry={() => window.location.reload()}
            />
          )}
          {shouldShowEmptyState && (
            <EmptyStateMessage
              caption={
                <>
                  You have no staged connections.{' '}
                  {!isSandbox && (
                    <>
                      Read our{' '}
                      <ExternalLink to={FINCH_DOCS_BASE_URL}>
                        Documentation
                      </ExternalLink>{' '}
                      to get started.
                    </>
                  )}
                </>
              }
            />
          )}
          {shouldShowEmptyRowsState && (
            <EmptyFilterStateMessage
              table="connections"
              onClearFilters={removeAllFilters}
            />
          )}
        </TableContainer>
        {loading && (
          <Loading message="We're fetching your application's incomplete employer connections." />
        )}
      </Stack>
      {shouldShowPagination && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1,
              }));
            }}
            canPreviousPage={getCanPreviousPage()}
            nextPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }));
            }}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={getCurrentFilteredRowModel().rows.length}
          />
        </Box>
      )}
    </Box>
  );
};
