import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

export enum ConnectionStage {
  Live = 'live',
  Staged = 'staged',
  Disconnected = 'disconnected',
}

export const getConnectionStageFromQueryParams = (): ConnectionStage => {
  const searchParamsOnLoad = new URLSearchParams(window.location.search);

  const connectionStage = z
    .nativeEnum(ConnectionStage)
    .safeParse(searchParamsOnLoad.get('stage'));

  if (!connectionStage.success) {
    return ConnectionStage.Live;
  }

  return connectionStage.data ?? ConnectionStage.Live;
};

export const useConnectionStageQuerySync = () => {
  const history = useHistory();

  const [connectionStage, setConnectionStage] = useState<ConnectionStage>(
    getConnectionStageFromQueryParams(),
  );

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    newUrlParams.set('stage', connectionStage);

    history.replace(`?${newUrlParams}`);
  }, [connectionStage, history]);

  return [connectionStage, setConnectionStage] as const;
};
