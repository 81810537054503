import { create } from 'zustand';
import { OperationsOptions } from '../open-api';

export const useSelectedOperation = create<{
  selectedOperation: OperationsOptions | null;
  setSelectedOperation: (selectedOperation: OperationsOptions) => void;
}>((set) => ({
  selectedOperation: null,
  setSelectedOperation: (selectedOperation: OperationsOptions) =>
    set({ selectedOperation }),
}));
