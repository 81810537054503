import { COLORS } from 'constant';

export const AltTrashIcon = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 14V2.5H0V1H4V0H8V1H12V2.5H11V14H1ZM2.5 12.5H9.5V2.5H2.5V12.5ZM4 11H5.5V4H4V11ZM6.5 11H8V4H6.5V11Z"
      fill={COLORS.GRAY.GRAY_500}
    />
  </svg>
);
