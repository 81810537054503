import {
  HStack,
  Stack,
  Text,
  StackDivider,
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { ImplementationInfoWithAssitedBenefits } from '../types';
import { kindToTitle } from '../constants';
import { COLORS } from '../../constant';
import {
  ImplementationKind,
  PlainImplementationKind,
} from '@finch-api/common/dist/internal/connect/authorize';
import { Switch } from '../../components/Switch';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';
import { useState } from 'react';
import { useProviderSettings } from '../hooks/useProviderSettings';
import { AuthorizationTypeDisabled } from './AuthorizationTypeDisabled';
import { ScopeTag } from './ScopeTag';
import { AssistedBenefitScopeTag } from './AssistedBenfitScopeTag';

type OAuthCredentialsFormData = {
  clientId: string;
  clientSecret: string;
};

export const AuthorizationType = ({
  implementation,
  defaultImplementationId,
  requireOAuthCredentials,
  oauthClientId,
  showAuthenticationTypeConfig,
}: {
  implementation: ImplementationInfoWithAssitedBenefits;
  defaultImplementationId: string;
  requireOAuthCredentials: boolean;
  oauthClientId: string | null;
  showAuthenticationTypeConfig: boolean;
}) => {
  const {
    selectedProvider,
    stagedOAuthCredentials,
    addOAuthCredentials,
    updatePrimaryImplementation,
    toggleProviderImplementation,
    shouldAllowUpdatesToImplementation,
  } = useProviderConfigurations();

  const { providerSettings } = useProviderSettings();

  const stagedCredentials = selectedProvider
    ? stagedOAuthCredentials.get(selectedProvider?.id)
    : null;

  const [formData, setFormData] = useState<OAuthCredentialsFormData>({
    clientId: stagedCredentials?.clientId || oauthClientId || '',
    clientSecret: stagedCredentials?.clientSecret || '',
  });

  const showOAuthCredentialsInput =
    implementation.kind === ImplementationKind.OAUTH && requireOAuthCredentials;

  const shouldBlockConfigChanges =
    (selectedProvider &&
      !shouldAllowUpdatesToImplementation(selectedProvider, implementation)) ??
    false;

  const isImplementationDisabledInSettings =
    providerSettings?.allowedImplementationKinds.every(
      (kind) => kind !== implementation.kind,
    ) || false;

  const updateStagedCredentials = (formData: OAuthCredentialsFormData) => {
    addOAuthCredentials({
      clientId: formData.clientId,
      clientSecret: formData.clientSecret,
    });
  };

  const onClientIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formData, clientId: e.target.value };
    setFormData(newFormData);
    updateStagedCredentials(newFormData);
  };

  const onClientSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formData, clientSecret: e.target.value };
    setFormData(newFormData);
    updateStagedCredentials(newFormData);
  };

  return (
    <Stack
      borderRadius="8px"
      border={`1px solid ${COLORS.GRAY.GRAY_400}`}
      divider={<StackDivider />}
      spacing="0"
    >
      <HStack p="16px" justify="space-between">
        <HStack>
          <Text fontSize="sm" fontWeight="500">
            {kindToTitle[implementation.kind as PlainImplementationKind]}
          </Text>
          {!isImplementationDisabledInSettings &&
            showAuthenticationTypeConfig && (
              <Box>
                {defaultImplementationId === implementation.id ? (
                  <Box
                    bg="primary.50"
                    borderRadius="6px"
                    color={COLORS.FINCH.PURPLE}
                    p="2px 6px"
                    fontSize="xs"
                    fontWeight="500"
                  >
                    Primary
                  </Box>
                ) : (
                  <Button
                    size="xs"
                    variant="grayText"
                    isDisabled={shouldBlockConfigChanges}
                    color={COLORS.GRAY.GRAY_600}
                    onClick={() =>
                      updatePrimaryImplementation(implementation.id)
                    }
                  >
                    Make Primary -{'>'}
                  </Button>
                )}
              </Box>
            )}
        </HStack>

        {isImplementationDisabledInSettings ? (
          <AuthorizationTypeDisabled
            authorizationType={
              kindToTitle[implementation.kind as PlainImplementationKind]
            }
          />
        ) : (
          showAuthenticationTypeConfig && (
            <Switch
              id={implementation.id}
              isDisabled={shouldBlockConfigChanges}
              enabled={implementation.enabled}
              labels={{ enabled: 'On', disabled: 'Off' }}
              onChange={() =>
                toggleProviderImplementation(
                  implementation.kind,
                  !implementation.enabled,
                )
              }
            />
          )
        )}
      </HStack>
      {showOAuthCredentialsInput && (
        <Stack p="16px" spacing="16px">
          <FormControl isInvalid={!formData.clientId}>
            <FormLabel htmlFor="clientId" fontSize="sm" fontWeight="500">
              Client ID
            </FormLabel>
            <Input
              size={'sm'}
              id="clientId"
              value={formData.clientId}
              placeholder="Client ID needed to enable"
              onChange={onClientIDChange}
            />
          </FormControl>
          <FormControl isInvalid={!oauthClientId && !formData.clientSecret}>
            <FormLabel htmlFor="clientSecret" fontSize="sm" fontWeight="500">
              Client Secret
            </FormLabel>
            <Input
              size={'sm'}
              type="password"
              value={formData.clientSecret}
              id="clientSecret"
              placeholder={
                !oauthClientId ? 'Client Secret needed to enable' : '**********'
              }
              onChange={onClientSecretChange}
            />
          </FormControl>
        </Stack>
      )}
      <Stack p="16px">
        <Text fontSize="sm" fontWeight="500">
          Available Scopes
        </Text>
        <HStack spacing="8px" flexWrap="wrap">
          {implementation.scopes.map((scope) => (
            <ScopeTag key={scope} scope={scope} />
          ))}
          {implementation.hasAssistedBenefits && <AssistedBenefitScopeTag />}
        </HStack>
      </Stack>
    </Stack>
  );
};
