import { Box } from '@chakra-ui/react';
import { Spacer } from '../shared/Spacer';
import { PageHeading } from '../shared/Typography';
import { LogsTable } from './LogsTable';
import { PageContainer } from '../components/PageContainer';

export const LogsPage = () => {
  return (
    <Box>
      <PageContainer>
        <PageHeading>Activity</PageHeading>
        <Spacer p="16px" />
        <LogsTable />
      </PageContainer>
    </Box>
  );
};
