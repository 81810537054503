import { IconButton, Flex } from '@chakra-ui/react';
import {
  FiArrowLeft as ArrowLeft,
  FiArrowRight as ArrowRight,
} from 'react-icons/fi';

export const Pagination = ({
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageIndex,
  pageSize,
  totalSize,
  hidePageCount,
}: {
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  hidePageCount?: boolean;
  pageIndex: number;
  pageSize: number;
  totalSize: number;
}) => {
  const currentIndexOffset = pageIndex * pageSize;
  const currentResultStartingIndex =
    0 === totalSize ? 0 : currentIndexOffset + 1;
  const currentResultEndingIndex =
    totalSize < pageSize ? totalSize : currentIndexOffset + pageSize;

  return (
    <Flex justifyContent={'flex-end'} alignItems="center" gap={2}>
      {!hidePageCount && (
        <span>
          <strong>
            {currentResultStartingIndex}–
            {Math.min(currentResultEndingIndex, totalSize)}
          </strong>{' '}
          of {totalSize}
        </span>
      )}
      <Flex gap={1}>
        <IconButton
          icon={<ArrowLeft />}
          variant="icon"
          aria-label="Previous page"
          onClick={() => previousPage()}
          isDisabled={!canPreviousPage}
        />
        <IconButton
          variant="icon"
          icon={<ArrowRight />}
          aria-label="Next page"
          onClick={() => nextPage()}
          isDisabled={!canNextPage}
        />
      </Flex>
    </Flex>
  );
};
