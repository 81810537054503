import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrganizationClient } from './organization-client';
import { useToast } from '@chakra-ui/react';

export const useUpdateUserRole = () => {
  const auth0 = useAuth0();
  const toast = useToast({ duration: 3000, isClosable: true });
  const organizationClient = new OrganizationClient(auth0);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: organizationClient.updateUserRole,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['organization', 'members'] });

      toast({
        description: `The role for ${variables.email} has been successfully updated`,
        status: 'success',
      });
    },
  });
};
