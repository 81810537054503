import { ContentBox } from 'components/ContentBox';
import { EmptyState } from 'components/EmptyState';

const EmptyOverviewPanelIcon = () => {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.646973" width="64" height="64" rx="32" fill="#EFF1FF" />
      <path
        d="M34.1345 28.096V16H49V28.096H34.1345ZM15 33.6345V16H29.8655V33.6345H15ZM34.1345 50V32.3655H49V50H34.1345ZM15 50V37.904H29.8655V50H15ZM17.2695 31.3655H27.596V18.2695H17.2695V31.3655ZM36.404 47.7305H46.7305V34.6345H36.404V47.7305ZM36.404 25.827H46.7305V18.2695H36.404V25.827ZM17.2695 47.7305H27.596V40.173H17.2695V47.7305Z"
        fill="#3E4AE7"
      />
    </svg>
  );
};

export const ConnectionOverviewPanel = () => {
  return (
    <ContentBox py="64px">
      <EmptyState
        icon={<EmptyOverviewPanelIcon />}
        text="There's nothing here just yet"
        subText="Your connection overview will show up here when the connection is live"
      />
    </ContentBox>
  );
};
