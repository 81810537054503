import styles from './ProviderSwitch.module.css';

/**
 * This is a stripped down version of the Chakra Switch component to improve its performance
 * in a big list. Chakra has a runtime trade-off documented here
 * @see {@link https://chakra-ui.com/getting-started/comparison#the-runtime-trade-off-%EF%B8%8F}
 */
export const ProviderSwitch = ({
  checked,
  onChange,
  labels = { checked: 'Enabled', unchecked: 'Disabled' },
  id,
  disabled = false,
}: {
  checked: boolean;
  id: string;
  labels?: { checked: string; unchecked: string };
  onChange: (enabled: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="css-0">
      <div
        role="group"
        className={styles.checkboxGroup}
        style={{ opacity: disabled ? '0.5' : 'inherit' }}
      >
        <label
          htmlFor={`is-${id}-enabled`}
          id="field-:ral5:-label"
          className={styles.checkboxLabel}
          {...(checked && { 'data-checked': '' })}
          {...(disabled && { 'data-disabled': '' })}
        >
          {checked ? labels.checked : labels.unchecked}
        </label>
        <label
          {...(checked && { 'data-checked': '' })}
          className={styles.outerContainer}
        >
          <input
            className={styles.input}
            type="checkbox"
            id={`is-${id}-enabled`}
            aria-invalid="false"
            aria-disabled={disabled}
            disabled={disabled}
            value=""
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            style={{
              border: '0px',
              clip: 'rect(0px, 0px, 0px, 0px)',
              height: '1px',
              width: '1px',
              margin: '-1px',
              padding: '0px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              position: 'absolute',
            }}
          />
          <span
            {...(checked && { 'data-checked': '' })}
            {...(disabled && { 'data-disabled': '' })}
            aria-hidden="true"
            className={styles.switchTrack}
          >
            <span
              className={styles.switchThumb}
              {...(checked && { 'data-checked': '' })}
              {...(disabled && { 'data-disabled': '' })}
            ></span>
          </span>
        </label>
      </div>
    </div>
  );
};
