import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { DataSyncProvider } from '../components/DataSyncProvider';

import { DataSyncScreen } from '../components/DataSyncScreen';
import { PageContainer } from '../../components/PageContainer';

export const DataSyncPage = () => {
  const { applicationId, companyId } = useParams<{
    companyId: string;
    applicationId: string;
  }>();

  return (
    <DataSyncProvider applicationId={applicationId} companyId={companyId}>
      <Box>
        <PageContainer>
          <DataSyncScreen companyId={companyId} />
        </PageContainer>
      </Box>
    </DataSyncProvider>
  );
};
