import {
  ApplicationWithFeatures,
  ApplicationStatus,
} from '@finch-api/developer-dashboard-common';

export class Application {
  constructor(private readonly params: Readonly<ApplicationWithFeatures>) {}

  get id() {
    return this.params.id;
  }

  get scopes() {
    return this.params.scopes;
  }

  get redirect_uris() {
    return this.params.redirect_uris;
  }

  get name() {
    return this.params.name;
  }

  get status() {
    return this.params.status;
  }

  get is_blocked() {
    return this.params.is_blocked;
  }

  get scopeNames() {
    return this.params.scopes.map((scope) => scope.name).filter(Boolean);
  }

  get features() {
    return this.params.features;
  }

  isSandbox(): boolean {
    return this.params.status === ApplicationStatus.SANDBOX;
  }
}
