import { useColorModePreference } from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';

import { useSeachParamsOnLoad } from '../../shared/useQuery';
import { ControlsSchema, FilterSchema } from '../types';
import { controlsSchema } from '../schema';

/**
 * Parses the non-filter query parameters from the URI and validates them. These
 * parameters control things like controls, theme, and menu states.
 */
export function useControlsFromURI(): ControlsSchema {
  const query = useSeachParamsOnLoad();
  const mode = useColorModePreference() ?? 'light';
  const parsed = (
    Object.keys(controlsSchema.shape) as Array<keyof FilterSchema>
  ).reduce<FilterSchema>(
    (parsed, parameter) => set(parsed, parameter, query.get(parameter)),
    Object.create(null),
  );

  controlsSchema.shape.mode.default(mode);

  const validation = controlsSchema.safeParse(omitBy(parsed, isNil));

  if (!validation.success) {
    return { group: 'organization', mode, subgroup: 'operations' };
  }

  return validation.data;
}
