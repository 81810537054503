import { HStack, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { COLORS } from '../../constant';
import back_arrow from '../../assets/images/icons/back_arrow.svg';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { useHistory } from 'react-router-dom';
import { ConnectionDetailActionMenu } from './ConnectionDetailActionMenu';
import { Loading } from '../../components/Loading/Loading';
import { useEffect } from 'react';
import { StagedConnectionDetailContent } from './StagedConnectionDetailContent';
import { useStagedConnectionDetails } from '../hooks/useStagedConnectionDetail';
import { ConnectionDetailHeader } from 'connection-detail-v2/components/ConnectionDetailHeader';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GoBackButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.FINCH.PURPLE};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const wouldAccountRequireReAuth = (accountStatus: AccountStatus) => {
  return accountStatus === AccountStatus.REAUTH;
};

export const StagedConnectionDetailScreen = ({
  applicationId,
  stagedConnectionId,
}: {
  applicationId: string;
  stagedConnectionId: string;
}) => {
  const {
    connectionDetail,
    isLoading: isConnectionDetailLoading,
    error: connectionDetailError,
  } = useStagedConnectionDetails({
    applicationId,
    stagedConnectionId,
  });

  const isLoading = isConnectionDetailLoading;

  const history = useHistory();

  useEffect(() => {
    if (connectionDetailError?.response?.data?.name === 'not_found_error') {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  }, [connectionDetailError, applicationId, history]);

  const onClickGoBack = () => {
    if (history.location.state?.navigatedFromConnectionsTable) {
      history.goBack();
    } else {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  };

  return (
    <>
      <HStack
        gap={6}
        justify="space-between"
        align="center"
        wrap="wrap"
        mb="24px"
        minH="40px"
      >
        <GoBackButton onClick={onClickGoBack}>
          <img src={back_arrow} alt="back button" />
          Back to Staged Connections
        </GoBackButton>
        {!isLoading && (
          <ConnectionDetailActionMenu connectionDetail={connectionDetail} />
        )}
      </HStack>
      <Stack spacing="12">
        <ConnectionDetailHeader
          isLoading={isLoading}
          customerName={connectionDetail?.stagedConnection.externalCompanyName}
          customerId={connectionDetail?.stagedConnection.externalCompanyId}
          isStaged
        />
        {isLoading && (
          <Wrapper>
            <Loading message="We’re getting your connection details ready." />
          </Wrapper>
        )}
        {!isLoading && connectionDetail && (
          <StagedConnectionDetailContent connectionDetail={connectionDetail} />
        )}
      </Stack>
    </>
  );
};
