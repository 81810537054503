import { ReactNode } from 'react';
import { usePermissions } from '../hooks/use-permissions';
import { Permission } from '../constant/roles-and-permissions';

export const PermissionRequired = ({
  children,
  match,
}: {
  children: ReactNode;
  match: Array<Permission>;
}) => {
  const enable = usePermissions(match);

  if (!enable) {
    return null;
  }

  return <>{children}</>;
};
