import { Box, Center, Text, Link } from '@chakra-ui/react';
import { Spacer } from '../../shared/Spacer';
import { finchEmails, finchMailToLinks } from '../../shared/links';
import { Caption } from '../../shared/Typography';
import { WebhooksIcon } from './WebhookIcon';
import { COLORS } from '../../constant';
import { ContentBox } from '../../components/ContentBox';

export const WebhooksNotEnabled = () => {
  return (
    <ContentBox>
      <Center paddingY="64px" paddingX="16px">
        <Caption>
          <Center>
            <WebhooksIcon />
          </Center>
          <Spacer p="3" />
          <Box>
            <Text
              fontSize="14px"
              lineHeight="24px"
              align="center"
              fontWeight="400"
            >
              Webhooks are available on our Scale plan.
            </Text>
            <Text
              fontSize="14px"
              lineHeight="24px"
              align="center"
              fontWeight="400"
            >
              To learn more, please reach out to us at{' '}
              <Link
                href={finchMailToLinks.developers}
                isExternal
                color={COLORS.FINCH.PURPLE}
              >
                {finchEmails.developers}
              </Link>{' '}
            </Text>
          </Box>
        </Caption>
      </Center>
    </ContentBox>
  );
};
