export const getStatusCodeColor = (statusCode?: number) => {
  if (statusCode) {
    if (statusCode >= 200 && statusCode < 300) {
      return 'green';
    } else if (statusCode >= 300 && statusCode < 400) {
      return 'yellow';
    } else if (statusCode >= 400 && statusCode < 500) {
      return 'orange';
    } else if (statusCode >= 500) {
      return 'red';
    }
  }

  return 'grey';
};
