export const TrashIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 13.2292L10 11.5625L11.6667 13.2292L12.7292 12.1667L11.0625 10.5L12.7292 8.83333L11.6667 7.77083L10 9.4375L8.33333 7.77083L7.27083 8.83333L8.9375 10.5L7.27083 12.1667L8.33333 13.2292ZM5 17V5.5H4V4H8V3H12V4H16V5.5H15V17H5Z"
      fill="currentColor"
    />
  </svg>
);
