import { ConnectionsTable } from './ConnectionsTable';
import { COLORS } from '../../constant';
import {
  Box,
  Divider,
  HStack,
  Link,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { SandboxMode } from '../types';
import { useConnections, useSandboxConnections } from '../hooks/useConnections';
import { SandboxInfoBar } from './SandboxInfoBar';
import { PageHeading } from '../../shared/Typography';
import { NewConnectSessionButton } from './ConnectSession';
import { Banner } from '../../components/Banner/Banner';
import warningInfoIcon from '../../assets/images/icons/warning_info.svg';
import { useRef, useState } from 'react';
import { Connection } from '../model';
import { AggregatedConnectionStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { usePermissions } from '../../hooks/use-permissions';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { useApplication } from '../../applications/hooks';
import { useParams } from 'react-router-dom';
import { AppTab, AppTabList } from 'components/Tabs';

const tabIndexToSandboxMode: Array<SandboxMode> = ['finch', 'provider'];

const SandboxConnections = ({
  loading,
  filterToNeedsAttention,
  setFilterToNeedsAttention,
}: {
  loading: boolean;
  filterToNeedsAttention?: boolean;
  setFilterToNeedsAttention?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { limit, used, connections, selectedSandbox, setSelectedSandbox } =
    useSandboxConnections();

  return (
    <Tabs
      size="sm"
      isLazy
      index={tabIndexToSandboxMode.indexOf(selectedSandbox)}
      onChange={(index) =>
        setSelectedSandbox(tabIndexToSandboxMode[index] || 'finch')
      }
    >
      <AppTabList gap={6}>
        <AppTab>Finch Sandbox</AppTab>
        <AppTab>Provider Sandbox</AppTab>
      </AppTabList>
      <SandboxInfoBar
        limit={limit}
        used={used}
        loading={loading}
        mode={selectedSandbox}
      >
        <TabPanels>
          <TabPanel paddingLeft="0" paddingRight="0">
            <ConnectionsTable
              connections={connections}
              loading={loading}
              isSandbox
              filterToNeedsAttention={filterToNeedsAttention}
              setFilterToNeedsAttention={setFilterToNeedsAttention}
            />
          </TabPanel>
          <TabPanel paddingLeft="0" paddingRight="0">
            <ConnectionsTable
              connections={connections}
              loading={loading}
              isSandbox
              filterToNeedsAttention={filterToNeedsAttention}
              setFilterToNeedsAttention={setFilterToNeedsAttention}
            />
          </TabPanel>
        </TabPanels>
      </SandboxInfoBar>
    </Tabs>
  );
};

type ConnectionAttentionBannerProps = {
  connectionCount: number;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  fullWidth?: boolean;
};

const ConnectionAttentionBanner = (
  props: ConnectionAttentionBannerProps,
): JSX.Element | null => {
  const { connectionCount, onClick } = props;
  if (!connectionCount) return null;
  return (
    <Banner
      icon={warningInfoIcon}
      fullWidth={props.fullWidth}
      content={
        <>
          <Box as="span" color={COLORS.FINCH.BLACK} pr="2">
            {`${connectionCount} connections need attention `}
          </Box>
          <Link
            onClick={onClick}
            color={COLORS.ORANGE}
            textDecoration="underline"
            _hover={{
              color: COLORS.FINCH.BLACK,
            }}
          >
            View Now
          </Link>
        </>
      }
    />
  );
};

const connectionsNeedAttention = (connections: Connection[]): number => {
  const needsAttention = [
    AggregatedConnectionStatus.NEEDS_ATTENTION,
    AggregatedConnectionStatus.INSUFFICIENT_PERMISSIONS,
    AggregatedConnectionStatus.NO_ACCOUNT_SET_UP,
    AggregatedConnectionStatus.REAUTHORIZATION_NEEDED,
  ];
  return connections.filter((conn) => needsAttention.includes(conn.status))
    .length;
};

export const ConnectionsScreen = ({ isSandbox }: { isSandbox: boolean }) => {
  const { isLoading, connections } = useConnections();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application } = useApplication(applicationId);
  const isFlatfileEnabled =
    application !== undefined && application.features.flatFileEnabled;

  const connectionCount = connectionsNeedAttention(connections);
  const [filterToNeedsAttention, setFilterToNeedsAttention] =
    useState<boolean>(false);

  const connectionsTableRef = useRef<HTMLDivElement>(null);

  const scrollToConnectionsTable = () => {
    connectionsTableRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const filterAndScroll = () => {
    setFilterToNeedsAttention(true);
    scrollToConnectionsTable();
  };

  const roleCanCreateConnectSession = usePermissions([
    PERMISSION.ConnectSession.Create,
  ]);

  return (
    <Stack spacing="6">
      <HStack justify="space-between">
        <PageHeading color={COLORS.FINCH.BLACK} fontSize="24px" flexGrow={1}>
          {!isSandbox ? 'Connections' : 'Sandbox Connections'}
        </PageHeading>
        <Stack>
          {roleCanCreateConnectSession && isFlatfileEnabled && (
            <NewConnectSessionButton />
          )}
          {!(roleCanCreateConnectSession && isFlatfileEnabled) && (
            <ConnectionAttentionBanner
              connectionCount={connectionCount}
              onClick={filterAndScroll}
            />
          )}
        </Stack>
      </HStack>
      {roleCanCreateConnectSession && isFlatfileEnabled && (
        <>
          <Divider />
          <ConnectionAttentionBanner
            connectionCount={connectionCount}
            onClick={filterAndScroll}
            fullWidth={true}
          />
        </>
      )}

      <div ref={connectionsTableRef}>
        {!isSandbox ? (
          <ConnectionsTable
            connections={connections}
            loading={isLoading}
            filterToNeedsAttention={filterToNeedsAttention}
            setFilterToNeedsAttention={setFilterToNeedsAttention}
          />
        ) : (
          <SandboxConnections
            loading={isLoading}
            filterToNeedsAttention={filterToNeedsAttention}
            setFilterToNeedsAttention={setFilterToNeedsAttention}
          />
        )}
      </div>
    </Stack>
  );
};
