import * as Sentry from '@sentry/react';
import axios from 'axios';

import { baseURL } from '../utils/baseUrls';
import {
  LogResponse,
  SearchQuery,
} from '@finch-api/developer-dashboard-common';

export const getLogs = async ({
  token,
  applicationId,
  searchFilter,
}: {
  token: string;
  applicationId: string;
  searchFilter: SearchQuery;
}): Promise<LogResponse> => {
  const statusCode = searchFilter.statusCode;
  const search = Object.entries(searchFilter)
    .filter(([, value]) => value !== undefined)
    .map(([name, value]) => [name, `${value}`]);

  const query = new URLSearchParams(search);
  if (statusCode === 500) {
    query.delete('statusDescription');
  }

  const requestURL = new URL(
    `${baseURL}/api/v1/applications/${applicationId}/activity/logs?${query.toString()}`,
  );

  try {
    const response = await axios.get(requestURL.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return { total: 0, response: [] };
  }
};
