import { useParams } from 'react-router-dom';

import { Box, Center, Spinner } from '@chakra-ui/react';
import { ConnectionsScreen } from '../components/ConnectionsScreen';
import { useApplication } from '../../applications/hooks';
import { NotFoundPage } from '../../NotFoundPage';
import { PageContainer } from '../../components/PageContainer';

export const ConnectionsPageV2 = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  if (!applicationId) throw new Error('no application id in url param');

  const { application, isLoading } = useApplication(applicationId);

  if (isLoading) {
    return (
      <PageContainer>
        <Center>
          <Spinner />
        </Center>
      </PageContainer>
    );
  }

  if (!application) {
    return <NotFoundPage />;
  }

  return (
    <Box>
      <PageContainer>
        <ConnectionsScreen isSandbox={application.isSandbox()} />
      </PageContainer>
    </Box>
  );
};
