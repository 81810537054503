import { StagedConnectionDetail } from '../types';
import { SessionDetailsDrawer } from 'connection-detail-v2/components/SessionDetailsDrawer';
import { ConnectSessionsTable } from 'connection-detail-v2/components/ConnectSessionsTable';
import { ConnectSession } from 'types/connect';
import { SessionHistoryTab } from 'connection-detail-v2/constants';

export const ConnectSessions = ({
  connectionDetail,
  selectedSession,
  setSelectedSession,
  activeSessionEventsTab,
  setActiveSessionEventsTab,
}: {
  connectionDetail: StagedConnectionDetail;
  selectedSession: ConnectSession | null;
  setSelectedSession: (connectSession: ConnectSession | null) => void;
  activeSessionEventsTab: SessionHistoryTab;
  setActiveSessionEventsTab: (tab: SessionHistoryTab) => void;
}) => {
  return (
    <>
      <ConnectSessionsTable
        data={[connectionDetail.connectSession]}
        setSelectedSession={setSelectedSession}
      />
      {connectionDetail.connectSession && (
        <SessionDetailsDrawer
          onClose={() => {
            setSelectedSession(null);
          }}
          connectSession={selectedSession}
          sessionEvents={connectionDetail.connectSessionEvents}
          activeSessionEventsTab={activeSessionEventsTab}
          setActiveSessionEventsTab={setActiveSessionEventsTab}
        />
      )}
    </>
  );
};
