import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useConnectionDetailApi } from './useConnectionDetailApi';

export const useConnectionDisconnect = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const api = useConnectionDetailApi();
  const queryClient = useQueryClient();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });
  const mutation = useMutation({
    mutationFn: () => api.disconnect(applicationId, connectionId),
    onError: () => {
      toast({
        description: 'Error disconnecting connection',
        status: 'error',
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['connections-detail', applicationId, connectionId],
      });
      toast({
        description: 'This company has been successfully disconnected',
        status: 'success',
      });
    },
  });

  return mutation;
};
