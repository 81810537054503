import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Image, useDisclosure, useToast } from '@chakra-ui/react';
import {
  Category,
  Sandbox,
} from '@finch-api/common/dist/internal/connect/authorize';
import { useFinchConnect } from '@tryfinch/react-connect';
import { useQueryClient } from '@tanstack/react-query';

import openLinkSvg from '../../assets/images/icons/open_link.svg';
import { TokenExchangeModal } from './TokenExchangeModal';
import { useApplication } from '../../applications/hooks';
import { COLORS } from '../../constant';
import { Application } from '../../applications/model';

type Props = {
  mode?: Sandbox;
};

const DEFAULT_SCOPES = [
  'company',
  'directory',
  'individual',
  'employment',
  'payment',
  'pay-statement',
];

const FINCH_SANDBOX_SCOPES = ['ssn'];

const setScopes = (mode?: Sandbox, application?: Application): string[] => {
  const initalScopes = application?.scopeNames || DEFAULT_SCOPES;

  if (mode === 'finch') {
    return Array.from(new Set([...initalScopes, ...FINCH_SANDBOX_SCOPES]));
  }
  return initalScopes;
};

export const ConnectSandbox = ({ mode }: Props) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const toast = useToast();
  const [code, setCode] = useState<string | null>(null);
  const { isOpen, onOpen, onClose: onCompleted } = useDisclosure();
  const { application, isLoading } = useApplication(applicationId);
  const client = useQueryClient();

  const onSuccess = ({ code }: { code: string }) => {
    toast({
      status: 'success',
      description: 'Successfully connected to the selected provider',
    });
    setCode(code);
    onOpen();
  };
  const onError = ({ errorMessage }: { errorMessage: string }) =>
    toast({ status: 'error', description: errorMessage });
  const onClose = () =>
    toast({ status: 'info', description: 'Finch Connect closed' });

  const { open } = useFinchConnect({
    clientId: applicationId,
    products: setScopes(mode, application),
    sandbox: mode,
    manual: false,
    category: Category.HRIS,
    onSuccess,
    onError,
    onClose,
    apiConfig: {
      connectUrl: process.env.REACT_APP_CONNECT_URI || '',
      redirectUrl: process.env.REACT_APP_REDIRECT_URL || '',
    },
  });

  return (
    <>
      <Button
        size="sm"
        color={COLORS.FINCH.BLACK}
        isLoading={isLoading}
        fontWeight="normal"
        border={`1px solid ${COLORS.GRAY.GRAY_400}`}
        background={COLORS.WHITE}
        _hover={{ background: COLORS.GRAY.GRAY_200 }}
        rightIcon={<Image src={openLinkSvg} />}
        onClick={() => {
          setCode(null);
          open();
        }}
        p="8px 10px"
        minW="fit-content"
      >
        Connect Test Account
      </Button>
      <TokenExchangeModal
        isOpen={isOpen}
        onClose={() => {
          onCompleted();
          client.invalidateQueries({
            queryKey: ['connections'],
          });
        }}
        applicationId={applicationId}
        code={code}
      />
    </>
  );
};
