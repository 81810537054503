import { FormLabel, HStack } from '@chakra-ui/react';
import { Control, Controller, Path } from 'react-hook-form';
import { Switch } from './Switch';

export const SwitchInput = <FormData extends Record<string, unknown>>({
  control,
  name,
  label,
}: {
  control: Control<FormData>;
  name: Path<FormData>;
  label?: string;
}) => {
  return (
    <HStack justify="space-between">
      {label && (
        <FormLabel fontSize="sm" m="0">
          {label}
        </FormLabel>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Switch
            id="showAssisted"
            enabled={(field.value as boolean) ?? false}
            {...field}
          />
        )}
      />
    </HStack>
  );
};
