import { Button, Image } from '@chakra-ui/react';
import openLinkSvg from '../../assets/images/icons/open_link.svg';

import { COLORS } from '../../constant';
import { SandboxMode } from '../types';

export const SandboxSetup = ({ mode }: { mode: SandboxMode }) => (
  <Button
    as="a"
    href={
      mode === 'finch'
        ? 'https://developer.tryfinch.com/docs/documentation/3x7je96vjp2xq-finch-sandbox'
        : 'https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-test-environments'
    }
    target="_blank"
    size="sm"
    color={COLORS.FINCH.BLACK}
    fontWeight="normal"
    p="8px 10px"
    minW="fit-content"
    border={`1px solid ${COLORS.GRAY.GRAY_400}`}
    background={COLORS.WHITE}
    _hover={{ background: COLORS.GRAY.GRAY_200 }}
    rightIcon={<Image src={openLinkSvg} />}
  >
    View Sandbox Instructions
  </Button>
);
