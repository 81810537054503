import { ConnectionTimelineCard } from './ConnectionTimelineCard';
import { Stack } from '@chakra-ui/react';
import { ConnectSessionEvent } from 'types/connect';

export const AuthenticationProgressPanel = ({
  connectSessionEvents,
  navigateToSessionEventsTab,
}: {
  connectSessionEvents: ConnectSessionEvent[];
  navigateToSessionEventsTab: () => void;
}) => {
  return (
    <Stack spacing="6">
      <ConnectionTimelineCard
        sessionEvents={connectSessionEvents}
        navigateToSessionEventsTab={navigateToSessionEventsTab}
      />
    </Stack>
  );
};
