import { Badge, BadgeTheme } from '../Badge/Badge';
import check_circle from '../../assets/images/icons/check_circle.svg';
import check_circle_blue from '../../assets/images/icons/check_circle_blue.svg';
import in_progress from '../../assets/images/icons/in_progress.svg';
import error_warning_red from '../../assets/images/icons/error_warning_red.svg';

const membersStatusVariants = {
  you: { icon: check_circle_blue, theme: BadgeTheme.BLUE, text: 'You' },
  active: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Active' },
  pending: { icon: in_progress, theme: BadgeTheme.BLUE, text: 'Pending' },
  expired: { icon: error_warning_red, theme: BadgeTheme.RED, text: 'Expired' },
};

type MembersStatusVariant = keyof typeof membersStatusVariants;

/**
 * Tag used for displaying the status of an assisted account of an employer
 * connection.
 *
 * @see https://www.figma.com/file/gldI3ap3tphioNftkEHThx/Employers?type=design&node-id=2235-1475&mode=design&t=Np9Bs86KCQ1W7jGc-4
 */
export const MemberStatusTag = ({
  variant,
}: {
  variant: MembersStatusVariant;
}) => {
  const { icon, theme, text } = membersStatusVariants[variant];

  return <Badge theme={theme} icon={icon} text={text} />;
};
