import type { ColumnFiltersState } from '@tanstack/react-table';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DateRange,
  getTimeValueForSelectedDateRange,
} from '../../components/TableFilters';

type FilterDataMap = {
  jobId?: {
    id: string;
    value: string;
  };

  connectionCategory?: {
    id: string;
    value: string;
  };

  type?: {
    id: string;
    value: string[];
  };

  status?: {
    id: string;
    value: string[];
  };

  date?: {
    id: string;
    value: {
      id: string;
      range: number[];
    };
  };
};

export const getConnectionDetailsFilterFromQueryParams = () => {
  const searchParamsOnLoad = new URLSearchParams(window.location.search);
  const columnFilters = [];

  const jobId = searchParamsOnLoad.get('jobId');

  if (jobId) {
    columnFilters.push({
      id: 'jobId',
      value: jobId,
    });
  }

  const connectionCategory = searchParamsOnLoad.get('connectionCategory');

  if (connectionCategory) {
    columnFilters.push({
      id: 'connectionCategory',
      value: connectionCategory,
    });
  }

  const jobType = searchParamsOnLoad.get('jobType');

  if (jobType) {
    columnFilters.push({
      id: 'type',
      value: jobType.split(','),
    });
  }

  const status = searchParamsOnLoad.get('status');

  if (status) {
    columnFilters.push({
      id: 'status',
      value: status.split(','),
    });
  }

  const dateFilter = searchParamsOnLoad.get('dateFilter') as DateRange;

  if (dateFilter) {
    const dateStart = searchParamsOnLoad.get('dateStart');
    const dateEnd = searchParamsOnLoad.get('dateEnd');

    const isCustomRange = dateFilter === 'custom_range' && dateStart && dateEnd;

    const dateRange: [number, number] = isCustomRange
      ? [Number(dateStart), Number(dateEnd)]
      : getTimeValueForSelectedDateRange(dateFilter);

    columnFilters.push({
      id: 'date',
      value: {
        id: dateFilter,
        range: dateRange,
      },
    });
  }

  return columnFilters;
};

export const useConnectionDetailsQuerySync = ({
  columnFilters,
}: {
  columnFilters: ColumnFiltersState;
}) => {
  const history = useHistory();

  // Sync the URL query params with the active filters
  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    const filterMap: FilterDataMap = columnFilters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.id]: filter,
      };
    }, {});

    newUrlParams.set('jobId', filterMap.jobId?.value || '');
    newUrlParams.set(
      'connectionCategory',
      filterMap.connectionCategory?.value || '',
    );

    newUrlParams.set('jobType', filterMap.type?.value.join(',') || '');
    newUrlParams.set('status', filterMap.status?.value.join(',') || '');

    const completionDate = filterMap.date?.value;
    const dateRange = completionDate?.range || [];

    newUrlParams.set('dateFilter', completionDate?.id || '');
    newUrlParams.set('dateStart', String(dateRange[0] || ''));
    newUrlParams.set('dateEnd', String(dateRange[1] || ''));

    history.replace(`?${newUrlParams}`, {
      ...history.location.state,
    });
  }, [columnFilters, history]);
};
