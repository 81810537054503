import { WebhookFormData, WebhookFormError } from './types';

export const validateFormData = (
  formData: WebhookFormData,
): WebhookFormError | null => {
  const errors: WebhookFormError = {};
  if (formData.endpointURL.length === 0) {
    errors.endpointURL = 'Endpoint URL is required';
  } else {
    try {
      // eslint-disable-next-line no-new
      new URL(formData.endpointURL);
    } catch (error) {
      errors.endpointURL = 'Endpoint URL is invalid';
    }
    if (!formData.endpointURL.startsWith('https://')) {
      errors.endpointURL = 'Endpoint URL must start with https://';
    }
  }

  if (formData.events.length === 0) {
    errors.events = 'At least 1 event is required';
  }
  return Object.keys(errors).length > 0 ? errors : null;
};
