import axios from 'axios';
import * as Sentry from '@sentry/react';
import { baseURL } from '../utils/baseUrls';

export const checkApiIsInMaintenance = async (): Promise<{
  maintenance?: boolean;
}> => {
  try {
    const response = await axios.get<{ maintenance: boolean }>(
      new URL('/maintenance', baseURL).toString(),
    );

    return {
      maintenance: response.data.maintenance ?? false,
    };
  } catch (err) {
    Sentry.captureException(err);

    return {
      maintenance: false,
    };
  }
};
