import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Box,
  TableContainer,
  Stack,
} from '@chakra-ui/react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import type {
  Row,
  ColumnFiltersState,
  SortingState,
  PaginationState,
  FilterFn,
} from '@tanstack/react-table';

import { Pagination } from '../../components/Pagination';
import {
  CompanyCell,
  ConnectedStatusCell,
  ConnectionTypeCell,
  DateCell,
  IdCell,
  isDisconnected,
  ProviderNameCell,
} from './TableCells';
import { EmptyStateMessage } from './EmptyStateConnectionsTable';
import { COLORS, SHADOWS } from '../../constant';
import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { Loading } from '../../components/Loading/Loading';
import { ConnectionsTableColumn, ConnectionTableFilterId } from '../types';
import {
  TableFilters,
  SelectFilterInput,
  RadioFilterInputForArrayId,
  SearchFilterInput,
  filterFns,
  DateRangeFilterInput,
  EmptyFilterStateMessage,
  dateRangeOptions,
  RelativeDateFilterInput,
  relativeDateRangeOptions,
} from '../../components/TableFilters';
import {
  connectionsFilterList,
  connectionStatusOptions,
  connectionTypeOptions,
  DisabledTableStyles,
  getProviderOptions,
} from '../constants';
import { useFilters } from '../../components/TableFilters/hooks';
import { useSearchParams } from '../../shared/useQuery';
import {
  getConnectionFilterFromQueryParams,
  useConnectionsQuerySync,
} from '../hooks/useConnectionsQuerySync';
import { TableHeaderContent } from '../../components/TableHeaderContent';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { Connection } from '../model';
import { ConnectionsFetchErrorMessage } from './ConnectionsFetchErrorMessage';
import { useTableDataStore } from '../state/tableDataStore';
import { ConnectionStage } from '../hooks/useConnectionStageSync';
import { ExternalLink } from 'shared/ExternalLink';
import { FINCH_DOCS_BASE_URL } from 'shared/links';

const Style = styled.div`
  table {
    border-spacing: 0;

    th {
      border: 0px;
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};
    }

    tr {
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};

      :last-child {
        border-bottom: 0px;
      }
    }

    td {
      padding: 10px 20px;
      border: 0px;
    }
  }
`;

const TableCellText = styled.div<{ width: string | undefined }>`
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.width && `width: ${props.width};`}
`;

const TableBodyContent = ({
  applicationId,
  rows,
  disabled = false,
}: {
  applicationId: string;
  rows: Row<ConnectionsTableColumn>[];
  disabled?: boolean;
}) => {
  const history = useHistory();
  const isRowDisconnected = (row: Row<ConnectionsTableColumn>) =>
    isDisconnected(row.original.status);

  return (
    <>
      {rows.map((row) => {
        return (
          <Tr
            cursor={disabled ? 'default' : 'pointer'}
            _hover={{
              bgColor: disabled ? undefined : COLORS.GRAY.GRAY_100,
            }}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        history.push(
                          `/app/applications/${applicationId}/connections/${row.original.connectionId}`,
                          {
                            navigatedFromConnectionsTable: true,
                            isDisconnected: isRowDisconnected(row),
                          },
                        );
                      }
                }
              >
                <TableCellText
                  width={cell.column.columnDef.meta?.style?.minWidth?.toString()}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};

export const implementationKindToCategory: Record<
  ImplementationKind,
  ConnectionCategory
> = {
  api_token: ConnectionCategory.Automated,
  oauth: ConnectionCategory.Automated,
  credential: ConnectionCategory.Automated,
  assisted: ConnectionCategory.Assisted,

  finch_sandbox_api_token: ConnectionCategory.Automated,
  finch_sandbox_oauth: ConnectionCategory.Automated,
  finch_sandbox_credential: ConnectionCategory.Automated,
  finch_sandbox_assisted: ConnectionCategory.Assisted,
};

const getConnectionsData = ({
  relations,
}: {
  relations: Connection[] | undefined;
}) => {
  const toCategory = (kind: ImplementationKind) =>
    implementationKindToCategory[kind];

  return (
    relations?.map((relation) => {
      return {
        connectionId: relation.id,
        companyName: relation.companyName,
        companyId: relation.companyId,
        company: {
          id: relation.companyId,
          name: relation.companyName,
        },
        customer: {
          id: relation.customerId,
          name: relation.customerName,
        },
        connectedAt: new Date(relation.firstConnectedAt).getTime(),
        connectedAtIso: relation.firstConnectedAt?.toISOString(),
        lastSyncAt: relation.lastSyncAt?.getTime(),
        lastSyncAtIso: relation.lastSyncAt?.toISOString(),
        providerName: relation.providerName,
        provider: {
          id: relation.providerId,
          name: relation.providerName,
          icon: relation.providerIcon,
        },
        status: relation.connectionStatus,
        kinds: Array.from(
          new Set<ConnectionCategory>(relation.kinds.map(toCategory)),
        ),
      };
    }) ?? []
  );
};

const statusFilter: FilterFn<ConnectionsTableColumn> = (
  row,
  _columnId,
  value,
) => {
  if (value.length === 0) {
    return true;
  }

  const status: AccountStatus = row.getValue('status');
  return value.includes(status);
};

export const ConnectionsTable = ({
  connections,
  isSandbox,
  loading,
  filterToNeedsAttention,
  setFilterToNeedsAttention,
  connectionStage,
  error,
  disabled = false,
}: {
  connections: Connection[];
  isSandbox?: boolean;
  loading: boolean;
  error: Error | null;
  filterToNeedsAttention?: boolean;
  setFilterToNeedsAttention?: React.Dispatch<React.SetStateAction<boolean>>;
  connectionStage?: ConnectionStage;
  disabled?: boolean;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const queryParams = useSearchParams();
  const setTableData = useTableDataStore((state) => state.setTableData);
  const pageParam = queryParams.get('page') || '0';

  if (!applicationId) throw new Error('no application id in url param');

  const tableData = useMemo(() => {
    const connectionsData = getConnectionsData({
      relations: connections,
    });
    setTableData(connectionsData);
    return connectionsData;
  }, [connections, setTableData]);

  const columnHelper = createColumnHelper<ConnectionsTableColumn>();

  const columns = [
    columnHelper.accessor('company', {
      header: 'Company',
      meta: {
        style: { minWidth: '220px' },
      },
      cell: (props) => (
        <CompanyCell
          disconnected={isDisconnected(props.row.original.status)}
          name={props.getValue().name}
          id={props.getValue().id}
        />
      ),
    }),
    columnHelper.accessor('connectionId', {
      header: 'Connection ID',
      meta: {
        filterLabel: 'Connection ID',
        filterInput: SearchFilterInput,
      },
      cell: (props) => (
        <IdCell
          disconnected={isDisconnected(props.row.original.status)}
          id={props.getValue()}
        />
      ),
    }),
    columnHelper.accessor('customer', {
      header: 'Customer',
      meta: {
        style: { minWidth: '220px' },
      },
      cell: (props) => (
        <CompanyCell
          disconnected={isDisconnected(props.row.original.status)}
          name={props.getValue().name ?? ''}
          id={props.getValue().id ?? ''}
          idProps={{ fontFamily: 'Inter, sans-serif' }}
        />
      ),
    }),
    columnHelper.accessor('status', {
      header: 'Connection Status',
      filterFn: statusFilter,
      meta: {
        style: { minWidth: '200px' },
        filterInput: SelectFilterInput,
        filterInputOptions: connectionStatusOptions,
      },
      cell: (props) => <ConnectedStatusCell props={props} />,
    }),
    columnHelper.accessor('kinds', {
      header: 'Connection Type',
      filterFn: 'arrayEquals',
      meta: {
        style: { minWidth: '216px' },
        filterInput: RadioFilterInputForArrayId,
        filterInputOptions: connectionTypeOptions,
      },
      cell: (props) => <ConnectionTypeCell props={props} />,
    }),
    columnHelper.accessor('connectedAt', {
      header: 'Connected',
      filterFn: 'dateRange',
      meta: {
        style: { minWidth: '160px' },
        filterInput: DateRangeFilterInput,
        filterInputOptions: dateRangeOptions,
      },
      cell: (props) => (
        <DateCell
          disconnected={isDisconnected(props.row.original.status)}
          value={props.getValue()}
        />
      ),
    }),
    columnHelper.accessor('lastSyncAt', {
      header: 'Last Synced',
      filterFn: 'relativeDateRange',
      meta: {
        style: { minWidth: '160px' },
        filterInput: RelativeDateFilterInput,
        filterInputOptions: relativeDateRangeOptions,
      },
      cell: (props) => (
        <DateCell
          disconnected={isDisconnected(props.row.original.status)}
          value={props.getValue()}
        />
      ),
    }),
    columnHelper.accessor('provider', {
      header: 'Provider',
      filterFn: 'arrIncludesSome',
      meta: {
        style: { minWidth: '200px' },
      },
      cell: (props) => (
        <ProviderNameCell
          name={props.getValue().name}
          icon={props.getValue().icon}
          disconnected={isDisconnected(props.row.original.status)}
        />
      ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.provider.name > rowB.original.provider.name
          ? 1
          : rowA.original.provider.name < rowB.original.provider.name
          ? -1
          : 0;
      },
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      enableHiding: true,
      meta: {
        filterLabel: 'Company Name',
        filterInput: SearchFilterInput,
      },
    }),
    columnHelper.accessor('companyId', {
      id: 'companyId',
      enableHiding: true,
      meta: {
        filterLabel: 'Company ID',
        filterInput: SearchFilterInput,
      },
    }),

    columnHelper.accessor('providerName', {
      id: 'providerName',
      filterFn: 'arrIncludesSome',
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: getProviderOptions(tableData),
        filterLabel: 'Provider',
      },
      enableHiding: true,
    }),
  ];

  const [columnVisibility, setColumnVisibility] = useState<
    Partial<Record<keyof ConnectionsTableColumn, boolean>>
  >(
    columns.reduce((acc, column) => {
      if ('accessorKey' in column) {
        acc[column.accessorKey as keyof ConnectionsTableColumn] =
          !column.enableHiding;
      }
      return acc;
    }, {} as Partial<Record<keyof ConnectionsTableColumn, boolean>>),
  );
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'connectedAt',
      desc: true,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: parseInt(pageParam),
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() =>
    getConnectionFilterFromQueryParams(),
  );

  const [selectedFilters, setSelectedFilters] = useState<
    ConnectionTableFilterId[]
  >(
    () =>
      getConnectionFilterFromQueryParams().map(
        ({ id }) => id,
      ) as ConnectionTableFilterId[],
  );

  const {
    getHeaderGroups,
    getState,
    getRowModel,
    getColumn,

    getCanPreviousPage,
    getCanNextPage,
    getPageCount,

    getFilteredRowModel: getCurrentFilteredRowModel,
  } = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    filterFns,
  });

  useEffect(() => {
    if (!loading && connections) {
      const maxPageIndex = Math.max(getPageCount() - 1, 0);
      const pageIndexParam = parseInt(pageParam);

      if (pageIndexParam > maxPageIndex) {
        setPagination({ pageIndex: maxPageIndex, pageSize });
      }
    }
  }, [connections, getPageCount, loading, pageParam, pageSize]);

  const history = useHistory();

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    newUrlParams.set('page', pageIndex.toString());

    history.replace(`?${newUrlParams}`);
  }, [history, applicationId, pageIndex]);

  const state = getState();
  const rows = getRowModel().rows;

  const shouldShowEmptyState = !loading && 0 === connections?.length && !error;

  const shouldShowEmptyRowsState =
    !loading && 0 === rows.length && 0 < state.columnFilters.length && !error;
  const shouldShowPagination = 0 < rows.length && !shouldShowEmptyState;

  const resetPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  };

  const {
    filterConfigs,
    getFilterValue,
    setFilterValue,
    addFilter,
    deleteFilter,
    toggleOpen,
    activeFilterId,
    removeAllFilters,
    closeFilters,
  } = useFilters<ConnectionsTableColumn, ConnectionTableFilterId>({
    filterList: [...connectionsFilterList],
    getColumn,
    selectedFilters,
    setSelectedFilters,
  });

  useEffect(() => {
    if (!filterToNeedsAttention || !setFilterToNeedsAttention) return;
    setColumnFilters([
      {
        id: 'status',
        value: [
          AccountStatus.REAUTH,
          AccountStatus.ERROR_NO_ACCOUNT_SETUP,
          AccountStatus.ERROR_PERMISSIONS,
        ],
      },
    ]);
    setSelectedFilters(['status']);
    closeFilters();
    setFilterToNeedsAttention(false);
  }, [
    filterToNeedsAttention,
    setColumnFilters,
    setFilterToNeedsAttention,
    closeFilters,
  ]);

  useConnectionsQuerySync({ columnFilters });

  return (
    <Box sx={disabled ? DisabledTableStyles : undefined}>
      <Stack
        borderRadius="8px"
        boxShadow={SHADOWS.PAGE}
        spacing="0"
        backgroundColor={COLORS.WHITE}
        key={connectionStage}
      >
        <TableFilters
          setFilterValue={(...args) => {
            setFilterValue(...args);

            // Reset back to the first page after changing filters from the menu.
            // This is to prevent 0 search results showing if the new filtered list
            // size is less than the last filtered size.
            resetPagination();
          }}
          getFilterValue={getFilterValue}
          filterConfigs={filterConfigs}
          addFilter={addFilter}
          deleteFilter={deleteFilter}
          selectedFilters={selectedFilters}
          toggleOpen={toggleOpen}
          activeFilterId={activeFilterId}
        />

        <TableContainer>
          <Style>
            <Table
              borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
              variant="simple"
            >
              <TableHeaderContent
                headerGroups={getHeaderGroups()}
                thOverrideStyle={{ p: '10px 20px' }}
              />
              <Tbody textColor="#1A202C">
                {!loading && (
                  <TableBodyContent
                    applicationId={applicationId}
                    rows={rows}
                    disabled={disabled}
                  />
                )}
              </Tbody>
            </Table>
          </Style>
          {error && (
            <ConnectionsFetchErrorMessage
              table="connections"
              onRetry={() => window.location.reload()}
            />
          )}
          {shouldShowEmptyState && (
            <EmptyStateMessage
              caption={(() => {
                switch (connectionStage) {
                  case ConnectionStage.Live:
                    return (
                      <>
                        You have no connected companies.{' '}
                        {!isSandbox && (
                          <>
                            Read our{' '}
                            <ExternalLink to={FINCH_DOCS_BASE_URL}>
                              Documentation
                            </ExternalLink>{' '}
                            to get started with Finch.
                          </>
                        )}
                      </>
                    );
                  case ConnectionStage.Disconnected:
                    return 'You have no disconnected connections.';
                  default:
                    return 'You have no connected companies.';
                }
              })()}
            />
          )}
          {shouldShowEmptyRowsState && (
            <EmptyFilterStateMessage
              table="connections"
              onClearFilters={removeAllFilters}
            />
          )}
        </TableContainer>
        {loading && (
          <Loading message="We're fetching your application's employer connections." />
        )}
      </Stack>
      {shouldShowPagination && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1,
              }));
            }}
            canPreviousPage={getCanPreviousPage()}
            nextPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }));
            }}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={getCurrentFilteredRowModel().rows.length}
          />
        </Box>
      )}
    </Box>
  );
};
