import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  NewSession,
  newSessionSchema,
  useConnectSession,
} from '../../hooks/useConnectSession';
import { ConnectUrlCopy } from './ConnectUrlCopy';
import { CreateSessionForm } from './CreateSessionForm';
import { ConnectSessionError } from './SessionError';

export const ModalNewConnectSession = ({
  isOpen,
  onClose,
  connectUrl,
  helperText,
}: {
  isOpen: boolean;
  onClose: () => void;
  connectUrl?: string;
  helperText?: string;
}) => {
  const {
    create: { mutate, isPending, data, error },
  } = useConnectSession();
  const form = useForm<NewSession>({
    resolver: zodResolver(newSessionSchema),
  });
  const firstInputRef = useRef<HTMLInputElement>(null);
  const url = connectUrl || data?.connectUrl;
  const expiryMin = form.watch('expiresAtInSeconds') || 14 * 24 * 60; // 14 days;

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={firstInputRef}>
      <ModalOverlay />
      <ModalContent maxWidth="400px" minHeight="700px" borderRadius="20px">
        <ModalCloseButton />

        {url ? (
          <ConnectUrlCopy
            url={url}
            onClose={onClose}
            helperText={helperText}
            expiryMin={expiryMin}
          />
        ) : error ? (
          <ConnectSessionError onClose={onClose} error={error} />
        ) : (
          <CreateSessionForm
            isPending={isPending}
            mutate={mutate}
            initialRef={firstInputRef}
            form={form}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
