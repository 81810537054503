import { PrimitiveSchemaType } from './types';

export const PRIMITIVE_SCHEMA_TYPE: PrimitiveSchemaType[] = [
  'integer',
  'number',
  'string',
  'boolean',
];

export const REQUEST_METHODS = [
  'get',
  'put',
  'post',
  'delete',
  'options',
  'head',
  'patch',
  'trace',
] as const;
