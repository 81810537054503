import { useState, useCallback, useMemo, ReactNode, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useField,
  yupToFormErrors,
} from 'formik';
import type { FormikHelpers, FormikErrors } from 'formik';
import * as yup from 'yup';
import {
  FieldErrorsBody,
  SignupErrorCodes,
  SignupForm,
  SignupRequest,
  SignupResponse,
} from '@finch-api/developer-dashboard-common';
import { Link as RRLink } from 'react-router-dom';
import _ from 'lodash';

import { signup } from './signup-api';
import { panelHeadingProps } from './utils';
import { verificationUrl } from '../verification/utils';
import {
  finchEmails,
  finchMailToLinks,
  legalLinks,
} from '../../../shared/links';
import { useStatusToast } from '../../../shared/StatusToast';
import { signupErrorMessages } from './error-messages';
import { passwordValidation } from './password-validation';
import { useSearchParams } from '../../../shared/useQuery';
import { analyticsClient } from '../../../utils/analytics/client';
import { getOauthCompanyName } from '../../../utils/getOauthCompanyName';
import { SignUpEvent } from '@finch-api/common/dist/analytics/developer-dashboard-events';

const queryParamKeyEmail = 'email';

const labelProps = {
  fontSize: '14px',
  lineHeight: '20px',
  mb: '6px',
};

type FormPanelProps = {
  onCreated: (arg0: SignupResponse) => void | Promise<void>;
};

const signupSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
  password: yup
    .string()
    .min(8, 'Please enter a password longer than 8 characters')
    .test(
      'auth0PasswordComplexity',
      'error msg here',
      (value, { createError }) => {
        const message = passwordValidation(value);
        if (message) {
          return createError({
            message,
          });
        }
        return true;
      },
    )
    .required('Please enter a password'),
  companyName: yup.string().required('Please enter your company name'),
});

const ConnectedFormControl = ({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) => {
  const [, meta] = useField(name);
  // TODO: we can add a context provider for field name to remove repetition
  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      {children}
    </FormControl>
  );
};

const getUtmParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const utmParameters = {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_term: urlParams.get('utm_term'),
    utm_content: urlParams.get('utm_content'),
    hutk: urlParams.get('hubspotutk') || urlParams.get('hutk'),
  };

  if (Object.values(utmParameters).filter(Boolean).length === 0) {
    const utmCookies = JSON.parse(Cookies.get('utm_cookie') || '{}');
    const hutk = Cookies.get('hubspotutk');
    if (hutk && !utmCookies.hutk) {
      utmCookies.hutk = hutk;
      utmCookies.referrer = document.referrer;
    }
    return utmCookies;
  }

  if (!utmParameters.hutk) {
    utmParameters.hutk = Cookies.get('hubspotutk') || null;
  }

  return Object.fromEntries(
    Object.entries({ ...utmParameters, referrer: document.referrer }).filter(
      ([, value]) => value,
    ),
  );
};

export const FormPanel = ({ onCreated }: FormPanelProps) => {
  const searchParams = useSearchParams();

  useEffect(() => {
    analyticsClient.track(SignUpEvent.SignUpPageVisit);
  }, []);

  const toast = useStatusToast();
  const [serverErrorTuple, setServerErrorTuple] = useState<{
    values: SignupForm;
    error: FieldErrorsBody<SignupErrorCodes>;
  }>();

  const createAccount = useCallback(
    async (
      values: SignupForm,
      { setFieldError, setSubmitting }: FormikHelpers<SignupForm>,
    ) => {
      setServerErrorTuple(undefined);
      setSubmitting(true);

      const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        gclid,
        hutk,
        referrer,
      } = getUtmParameters();

      const request: SignupRequest = {
        ...values,
        verificationUrl: verificationUrl(),
        utmParameters: {
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
          gclid,
          hutk,
          referrer,
        },
      };

      try {
        const signupResult = await signup(request);
        if (signupResult.success) {
          await onCreated(signupResult.success);

          analyticsClient.setUserId(values.email);

          analyticsClient.track(SignUpEvent.AccountCreated, {
            email: values.email,
            companyName: getOauthCompanyName(values.companyName),
          });

          // TODO: success message
        }
        if (signupResult.error) {
          setServerErrorTuple({
            values,
            error: signupResult.error,
          });
          signupResult.error.errors.forEach((e) => {
            const msg = signupErrorMessages[e.code];
            setFieldError(e.field, msg);
          });
        }
      } catch (error) {
        toast({
          wasSuccessful: false,
          message: (
            <Text>
              Failure during Sign Up. Please try again or contact{' '}
              <Link display="inline-block" href={finchMailToLinks.developers}>
                {finchEmails.developers}
              </Link>{' '}
              for assistance.
            </Text>
          ),
        });
      } finally {
        setSubmitting(false);
      }
    },
    [onCreated, toast, setServerErrorTuple],
  );

  const initialValues: SignupForm = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email: searchParams.get(queryParamKeyEmail) || '',
      password: '',
      companyName: '',
    }),
    [searchParams],
  );

  // This function only exists to combine the server error with the yup
  // validation errors. Without this the server error (obtained via async) will
  // be cleared once yup ran again (on blur/change).
  const customValidation = useCallback(
    async (values: SignupForm) => {
      let result: FormikErrors<SignupForm> = {};

      try {
        await signupSchema.validate(values, { abortEarly: false });
      } catch (err) {
        result = {
          ...result,
          ...yupToFormErrors(err),
        };
      }

      if (!serverErrorTuple) return result;

      // For each field where we had a server error, we see if it's value had
      // changed. If it had we clear the error. If it still had the same value,
      // the server error will remain.
      _.each(serverErrorTuple.error.errors, (e) => {
        const newValue = _.get(values, e.field);
        const oldValue = _.get(serverErrorTuple.values, e.field);
        if (_.isEqual(newValue, oldValue)) {
          // the form values were unchanged, the error messages need to stay!
          const msg = signupErrorMessages[e.code];
          result = {
            ...result,
            [e.field]: msg,
          };
        }
      });

      return result;
    },
    [serverErrorTuple],
  );

  return (
    <>
      <Heading {...panelHeadingProps} mb="32px">
        Sign up for free
      </Heading>
      <Formik
        initialValues={initialValues}
        validate={customValidation}
        onSubmit={createAccount}
      >
        {({ isSubmitting, isValid }) => (
          <Flex as={Form} gap="20px" flexDirection="column">
            <Flex rowGap="20px" columnGap="14px" flexWrap="wrap">
              <Box flexGrow={1} flexBasis="200px">
                <ConnectedFormControl name="firstName">
                  <FormLabel htmlFor="firstName" {...labelProps}>
                    First name
                  </FormLabel>
                  <Field
                    as={Input}
                    id="firstName"
                    name="firstName"
                    type="text"
                  />
                  <ErrorMessage component={FormErrorMessage} name="firstName" />
                </ConnectedFormControl>
              </Box>
              <Box flexGrow={1} flexBasis="200px">
                <ConnectedFormControl name="lastName">
                  <FormLabel htmlFor="lastName" {...labelProps}>
                    Last name
                  </FormLabel>
                  <Field as={Input} id="lastName" name="lastName" type="text" />
                  <ErrorMessage component={FormErrorMessage} name="lastName" />
                </ConnectedFormControl>
              </Box>
            </Flex>
            <ConnectedFormControl name="email">
              <FormLabel htmlFor="email" {...labelProps}>
                Company email
              </FormLabel>
              <Field as={Input} id="email" name="email" type="text" />
              <ErrorMessage component={FormErrorMessage} name="email" />
            </ConnectedFormControl>
            <ConnectedFormControl name="password">
              <FormLabel htmlFor="password" {...labelProps}>
                Password
              </FormLabel>
              <Field as={Input} id="password" name="password" type="password" />
              <ErrorMessage component={FormErrorMessage} name="password" />
            </ConnectedFormControl>
            <ConnectedFormControl name="companyName">
              <FormLabel htmlFor="companyName" {...labelProps}>
                Company name
              </FormLabel>
              <Field
                as={Input}
                id="companyName"
                name="companyName"
                type="text"
              />
              <ErrorMessage component={FormErrorMessage} name="companyName" />
            </ConnectedFormControl>
            <Button
              variant="primary"
              w="100%"
              isLoading={isSubmitting}
              isDisabled={!isValid}
              type="submit"
              mb="10px"
            >
              Create account
            </Button>
          </Flex>
        )}
      </Formik>

      <Box>
        <Text
          fontSize="14px"
          lineHeight="20px"
          color="gray.c600"
          textAlign="center"
        >
          By clicking Create account, you agree to our{' '}
          <Link color="brand.purple" href={legalLinks.tos} target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link color="brand.purple" href={legalLinks.privacy} target="_blank">
            Privacy Policy
          </Link>
          .
        </Text>
      </Box>

      <Center>
        <Divider my="32px" w="50%" />
      </Center>

      <Center>
        <Text fontSize="14px" lineHeight="20px" color="gray.c600">
          Already have an account?{' '}
          <Link as={RRLink} to="/login" color="brand.purple">
            Log in
          </Link>
        </Text>
      </Center>
    </>
  );
};
