import { Badge, BadgeTheme } from '../Badge/Badge';
import check_circle from '../../assets/images/icons/check_circle.svg';
import in_progress from '../../assets/images/icons/in_progress.svg';
import error_warning_red from '../../assets/images/icons/error_warning_red.svg';

const connectSessionStatusVariants = {
  pending: { icon: in_progress, theme: BadgeTheme.BLUE, text: 'Pending' },
  expired: { icon: error_warning_red, theme: BadgeTheme.RED, text: 'Expired' },
  complete: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Complete' },
};

type ConnectSessionStatusVariant = keyof typeof connectSessionStatusVariants;

export const ConnectSessionStatusTag = ({
  variant,
}: {
  variant: ConnectSessionStatusVariant;
}) => {
  const { icon, theme, text } = connectSessionStatusVariants[variant];

  return <Badge theme={theme} icon={icon} text={text} />;
};
