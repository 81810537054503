/**
 * Function to get the dirty values (the values that have changed from what's coming from the server) from a form.
 * Modified from @see {@link https://github.com/orgs/react-hook-form/discussions/1991#discussioncomment-6318535}
 */
export function getDirtyValues<
  Values extends Record<keyof DirtyFields, unknown>,
  DirtyFields extends Record<string, unknown> = { [key: string]: unknown },
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce<Partial<typeof values>>(
    (collectedDirtyValues, key) => {
      // Unsure when RFH sets this to `false`, but omit the field if so.
      if (!dirtyFields[key]) return collectedDirtyValues;

      return {
        ...collectedDirtyValues,
        [key]:
          typeof dirtyFields[key] === 'object'
            ? getDirtyValues(
                dirtyFields[key] as DirtyFields,
                values[key] as Values,
              )
            : values[key],
      };
    },
    {},
  );

  return dirtyValues;
}
