import { Center, Spacer } from '@chakra-ui/react';
import { Body } from '../../shared/Typography';
import { ExternalLink } from '../../shared/ExternalLink';
import { finchEmails, finchMailToLinks } from '../../shared/links';

export const EmptyApplicationPage = () => {
  return (
    <>
      <Spacer p="100" />
      <Center textAlign="center">
        <Body>
          You have no applications. Please contact{' '}
          <ExternalLink to={finchMailToLinks.developers}>
            {finchEmails.developers}
          </ExternalLink>{' '}
          to create one.
        </Body>
      </Center>
    </>
  );
};
