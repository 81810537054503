export const ApiDocIcon = ({ color }: { color?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16.6667C9.34726 16.1389 8.63546 15.7292 7.86462 15.4375C7.09379 15.1458 6.29171 15 5.45837 15C4.65282 15 3.81254 15.1632 2.93754 15.4896C2.06254 15.816 1.36115 16.1736 0.833374 16.5625V4.68749C1.48615 4.21527 2.23615 3.87152 3.08337 3.65624C3.9306 3.44097 4.72226 3.33333 5.45837 3.33333C6.25004 3.33333 7.03129 3.43402 7.80212 3.63541C8.57296 3.8368 9.3056 4.13888 10 4.54166C10.6945 4.13888 11.4237 3.8368 12.1875 3.63541C12.9514 3.43402 13.7292 3.33333 14.5209 3.33333C15.257 3.33333 16.0487 3.44444 16.8959 3.66666C17.7431 3.88888 18.5 4.22916 19.1667 4.68749V16.5625C18.6389 16.1597 17.9341 15.7986 17.0521 15.4792C16.1702 15.1597 15.3264 15 14.5209 15C13.6875 15 12.8889 15.1458 12.125 15.4375C11.3612 15.7292 10.6528 16.1389 10 16.6667Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
