// This file is copied from the /employers folder with minor changes.
// We're duplicating pagination as this feature's behavior is slightly different.
// We may be able to abstract it away once we have a more centralized design around tables.

import { IconButton, Flex } from '@chakra-ui/react';
import {
  FiArrowLeft as ArrowLeft,
  FiArrowRight as ArrowRight,
} from 'react-icons/fi';

export const Pagination = ({
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageIndex,
  pageSize,
  totalSize,
}: {
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  pageIndex: number;
  pageSize: number;
  totalSize: number;
}) => {
  // For design purposes, we're removing pagination when the table doesn't need it.
  if (totalSize < pageSize) {
    return null;
  }
  const currentIndexOffset = pageIndex * pageSize;
  const currentResultStartingIndex =
    0 === totalSize ? 0 : currentIndexOffset + 1;
  const currentResultEndingIndex = Math.min(
    currentIndexOffset + pageSize,
    totalSize,
  );

  return (
    <Flex justifyContent={'flex-end'} alignItems="center" gap={2}>
      <span>
        {currentResultStartingIndex} - {currentResultEndingIndex} of {totalSize}
      </span>
      <Flex gap={1}>
        <IconButton
          icon={<ArrowLeft />}
          variant="icon"
          aria-label="Previous page"
          onClick={() => previousPage()}
          isDisabled={!canPreviousPage}
        />
        <IconButton
          variant="icon"
          icon={<ArrowRight />}
          aria-label="Next page"
          onClick={() => nextPage()}
          isDisabled={!canNextPage}
        />
      </Flex>
    </Flex>
  );
};
