import { create } from 'zustand';
import { Tab } from '../types';

export const useSelectedTabStore = create<{
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;
}>((set) => ({
  selectedTab: 'All',
  setSelectedTab: (selectedTab) => set({ selectedTab }),
}));
