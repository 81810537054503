import type { ReadonlyDeep } from 'type-fest';
import {
  GustoIndividualEncryptedSSNFootnote,
  TrinetCreateBenefitsFootnote,
  WorkdayDepartmentNameFootnote,
  WorkdayDepartmentSourceIDFootnote,
} from './components/TableCells';

import {
  authorizationTypeSchema,
  deductionSupportSchema,
  filterSchema,
  groupSchema,
  organizationScopeSchema,
  payrollScopeSchema,
} from './schema';
import type {
  AuthenticationMethod,
  Endpoint,
  FieldSupportTableFilterId,
  Group,
  Subgroup,
} from './types';

export const groups: ReadonlyDeep<Group[]> = Object.values(groupSchema.enum);

export const subgroups: ReadonlyDeep<Record<Group, Subgroup[]>> = {
  deductions: Object.values(deductionSupportSchema.enum),
  organization: [],
  payroll: [],
};

export const endpoints: ReadonlyDeep<Record<Group, Endpoint[]>> = {
  deductions: [],
  organization: Object.values(organizationScopeSchema.enum),
  payroll: Object.values(payrollScopeSchema.enum),
};

export const authenticationMethods: ReadonlyDeep<AuthenticationMethod[]> =
  Object.values(authorizationTypeSchema.enum);

export const fieldSupportFilterList: ReadonlyDeep<FieldSupportTableFilterId[]> =
  filterSchema.keyof().options;

export const authorizationType: ReadonlyDeep<
  Record<AuthenticationMethod, string>
> = {
  api_token: 'API',
  oauth: 'OAuth',
  assisted: 'Assisted',
  credential: 'Credentials',
};

export const footnotes: Record<string, () => JSX.Element> = {
  'gusto.oauth.individual.encrypted_ssn': GustoIndividualEncryptedSSNFootnote,
  'workday.api_token.company.departments.name': WorkdayDepartmentNameFootnote,
  'workday.api_token.company.departments.source_id':
    WorkdayDepartmentSourceIDFootnote,
  'workday.api_token.directory.department.name': WorkdayDepartmentNameFootnote,
  'workday.api_token.directory.department.source_id':
    WorkdayDepartmentSourceIDFootnote,
  'workday.api_token.employment.department.name': WorkdayDepartmentNameFootnote,
  'trinet.assisted.create_company_benefits.*': TrinetCreateBenefitsFootnote,
  'trinet.assisted.create_individual_benefits.*': TrinetCreateBenefitsFootnote,
};
