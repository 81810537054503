import { useEffect } from 'react';
import type { ColumnFiltersState } from '@tanstack/react-table';
import unionBy from 'lodash/unionBy';

import { useSelectedFiltersStore } from '../state/selectedFilters';

function authorizationType({ id }: ColumnFiltersState[number]) {
  return id === 'authorization_type';
}

function provider({ id }: ColumnFiltersState[number]) {
  return id === 'provider';
}

/**
 * Use the complete set of global filters that apply to all views of the
 * table filters.
 */
export function useGlobalFilters(initialFilters: ColumnFiltersState) {
  const { authorizationTypes, providers, setAuthorizationTypes, setProviders } =
    useSelectedFiltersStore();
  const globalFilters: ColumnFiltersState = [];
  const setGlobalFilters = (selected: ColumnFiltersState) => {
    setAuthorizationTypes(
      (selected.find(authorizationType)?.value as string[]) || [],
    );
    setProviders((selected.find(provider)?.value as string[]) || []);
  };

  if (providers.length) {
    globalFilters.push({ id: 'provider', value: providers });
  }

  if (authorizationTypes.length) {
    globalFilters.push({ id: 'authorization_type', value: authorizationTypes });
  }

  useEffect(() => {
    setGlobalFilters(unionBy(initialFilters, globalFilters, 'id'));
  }, []);

  return { globalFilters, setGlobalFilters };
}
