import { Spinner, Stack, StackDivider } from '@chakra-ui/react';
import { TwoFactorAuthentication } from '../components/TwoFactorAuthentication';
import { ContentBox } from '../../components/ContentBox';
import { SubSectionHeading } from '../../shared/Typography';
import { COLORS } from '../../constant';
import { Switch } from '../../components/Switch';
import { useOrganization } from '../../shared/hooks/use-organization';
import { SettingEntry } from '../components/SettingEntry';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { PermissionRequired } from '../../components/PermissionRequired';

export const SecuritySettingsPage = () => {
  const { organization, updateMfaSettings } = useOrganization();

  return (
    <ContentBox>
      <Stack
        divider={<StackDivider borderColor={COLORS.GRAY.GRAY_400} />}
        spacing={0}
      >
        <SubSectionHeading px="6" py="4">
          Multi-Factor Authentication
        </SubSectionHeading>
        <PermissionRequired match={[PERMISSION.OrgMfaSettings.Update]}>
          <SettingEntry
            title="Enforce Multi-Factor Authentication For All Organization Members"
            details="When turned on, all members on your team will be required to set
              up Multi-Factor Authentication for their account."
            action={
              organization.isPending || updateMfaSettings.isPending ? (
                <Spinner />
              ) : (
                <Switch
                  enabled={organization.data?.mfa?.required ?? false}
                  id="organization-mfa"
                  onChange={() => {
                    updateMfaSettings.mutate({
                      required: !organization.data?.mfa?.required,
                    });
                  }}
                  labels={{ enabled: 'On', disabled: 'Off' }}
                />
              )
            }
          />
        </PermissionRequired>
        <TwoFactorAuthentication />
      </Stack>
    </ContentBox>
  );
};
