import { useColorModeValue } from '@chakra-ui/react';
import { COLORS } from '../../constant';

export const useColours = () => {
  return {
    background: useColorModeValue(COLORS.WHITE, '#0E0E10'),
    color: useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f'),
    border: useColorModeValue(COLORS.GRAY.GRAY_400, '#f3f3f30f'),
  };
};
