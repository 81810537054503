import { COLORS } from 'constant';
import { Code, Heading, Stack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export const RequestPreview = ({ formPath }: { formPath: string }) => {
  const { watch } = useFormContext();

  const jsonString = JSON.stringify(watch(formPath), null, 4);

  return (
    <Stack flex="2" pos="relative">
      <Heading as="h4" fontSize="13px">
        Preview
      </Heading>
      <Code p="3" fontSize="xs" bg={COLORS.GRAY.GRAY_200} borderRadius="8">
        {jsonString?.split('\n').map((line, i) => (
          <Text as="pre" key={i} wordBreak="break-word" whiteSpace="pre-wrap">
            {line}
          </Text>
        )) || ''}
      </Code>
    </Stack>
  );
};
