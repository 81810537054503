import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { baseURL } from '../../utils/baseUrls';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '@chakra-ui/react';
import { useSettingsStore } from '../state/settingsState';

export const useSaveProviderSettings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { stagedProviderSettings, clearStagedSettings } = useSettingsStore();

  const clearStagedProviderSettings = () => {
    clearStagedSettings();
  };

  const mutation = useMutation({
    mutationFn: async () => {
      return axios
        .put(
          `${baseURL}/api/v1/applications/${applicationId}/provider-settings`,
          {
            ...stagedProviderSettings,
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['providerConfigurations', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['providerSettings', applicationId],
        }),
      ]);
      toast({
        description: 'Settings successfully updated.',
        status: 'success',
        duration: 3000,
      });
    },
    onError: (error) => {
      toast({
        description: error.message,
        status: 'error',
        duration: 3000,
      });
    },
  });

  return {
    clearStagedProviderSettings,
    ...mutation,
  };
};
