import { useQuery } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';

import { FeatureFlag } from '../../constant/feature-flags';
import { useConnectionDetailApi } from './useConnectionDetailApi';

export const useConnectionPayStatementItem = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const api = useConnectionDetailApi();
  const enabled = useFlag(FeatureFlag.PayStatementMapping);

  const { isLoading, data = null } = useQuery({
    queryKey: ['connection-pay-statement-item', applicationId, connectionId],
    queryFn: () => api.payStatementItem(applicationId, connectionId),
    enabled,
  });

  return { payStatementItem: data || [], isLoading };
};
