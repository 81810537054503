import { ReactNode } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { COLORS, SHADOWS, Z_INDEX } from '../../constant';
import { PlusIcon } from '../../shared/icons/PlusIcon';
import { CheckIcon } from '../../shared/icons/CheckIcon';
import { FilterComposer } from './FilterComposer';
import { FilterConfig } from './types';

const FilterMenuButton = ({
  children,
  hover,
  borderColor,
  color,
}: {
  children: ReactNode;
  hover?: { bg: string };
  borderColor?: string;
  color?: string;
}) => (
  <MenuButton
    as={Button}
    borderColor={borderColor}
    _hover={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
    _active={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
    _selected={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
    size="sm"
    p="4px 8px"
    color={color}
    fontWeight="normal"
    variant="outline"
    rightIcon={<PlusIcon fill={color} />}
  >
    {children}
  </MenuButton>
);

export const TableFilters = <FilterId extends string, FilterValue>({
  getFilterValue,
  setFilterValue,
  filterConfigs,
  selectedFilters,
  toggleOpen,
  deleteFilter,
  addFilter,
  activeFilterId,
  hover,
  borderColor = COLORS.GRAY.GRAY_400,
  color,
  menu,
  shadows,
  scrollbar,
}: {
  activeFilterId: FilterId | null;
  setFilterValue: (columnId: FilterId, filterValue?: FilterValue) => void;
  getFilterValue: (id: FilterId) => FilterValue | undefined;
  filterConfigs: FilterConfig<FilterId>[];
  selectedFilters: FilterId[];
  toggleOpen: (id: FilterId) => void;
  deleteFilter: (id: FilterId) => void;
  addFilter: (id: FilterId) => void;
  hover?: { bg: string };
  borderColor?: string;
  color?: string;
  menu?: { _hover: { bg: string }; backgroundColor: string };
  shadows?: string;
  scrollbar?: string;
}) => {
  return (
    <HStack m="12px">
      {selectedFilters.map((selectedFilterId) => {
        const filterConfig = filterConfigs.find(
          ({ id }) => id === selectedFilterId,
        );

        if (!filterConfig) return null;

        return (
          <FilterComposer<FilterValue, FilterId>
            key={selectedFilterId}
            filterConfig={filterConfig}
            label={filterConfig.label}
            isOpen={activeFilterId === selectedFilterId}
            filterValue={getFilterValue(selectedFilterId)}
            options={filterConfig.inputOptions}
            setFilterValue={setFilterValue}
            toggleOpen={toggleOpen}
            deleteFilter={deleteFilter}
            borderColor={borderColor}
            hover={hover}
            color={color}
            menu={menu}
            shadows={shadows}
            scrollbar={scrollbar}
          >
            {filterConfig.filterInput || (() => null)}
          </FilterComposer>
        );
      })}
      <Menu>
        <FilterMenuButton hover={hover} borderColor={borderColor} color={color}>
          Add Filter
        </FilterMenuButton>
        <MenuList
          p="4px"
          boxShadow={SHADOWS.PAGE}
          zIndex={Z_INDEX.MENU}
          backgroundColor={menu?.backgroundColor}
          style={{
            scrollbarColor: scrollbar,
          }}
        >
          {filterConfigs.map((config) => (
            <MenuItem
              key={config.id}
              isDisabled={!!selectedFilters.find((id) => config.id === id)}
              borderRadius="4px"
              color={color}
              backgroundColor={menu?.backgroundColor}
              _hover={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
              _focus={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
              onClick={() => addFilter(config.id)}
              justifyContent="space-between"
            >
              {config.label}
              {!!selectedFilters.find((id) => config.id === id) && (
                <CheckIcon />
              )}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
};
