import { useMemo } from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { useFlag } from '@unleash/proxy-client-react';

import { useProviders } from './useProviders';
import { Group, ProviderSupport, Subgroup } from '../types';
import { isEndpoint } from '../utils';
import { endpoints } from '../constants';
import { ProviderImplementation } from '../model';
import { FeatureFlag } from '../../constant/feature-flags';

export function useSupportedFields(filters: [Group, Subgroup]) {
  const clearAcfStatus = useFlag(FeatureFlag.ClearAcfStatus);
  const [group, subgroup] = filters;
  const { loading, error, providers } = useProviders();
  const implementations = useMemo(
    () =>
      providers.reduce<ProviderSupport[]>((columns, provider) => {
        if (
          !clearAcfStatus &&
          provider.isManual() &&
          !provider.authenticationMethods.some(
            (am) =>
              am.benefitsSupport !== null &&
              Object.keys(am.benefitsSupport).length > 0,
          )
        ) {
          return columns;
        }

        let hasSupport: (implementation: ProviderImplementation) => boolean;

        if (clearAcfStatus) {
          hasSupport = (implementation) =>
            group === 'deductions'
              ? !isEmpty(implementation.benefitsSupport)
              : !isEmpty(implementation.supportedFields);
        } else {
          hasSupport = (implementation) =>
            provider.isManual() ||
            !implementation.isAssisted() ||
            !isEmpty(implementation.benefitsSupport);
        }

        columns.push(
          ...provider.authenticationMethods
            .filter(hasSupport)
            .reduce((methods, method) => {
              let supportedFields: any = null;

              if (group === 'deductions') {
                supportedFields = method.benefitsSupport
                  ? Object.entries(method.benefitsSupport).reduce(
                      (fields, [field, operationSupport]) => {
                        const supportedSubgroup = `.supported_${subgroup}.`;

                        if (field.includes(supportedSubgroup)) {
                          if (subgroup === 'features') {
                            const key = field.split(supportedSubgroup);

                            // Handle fields like 401k.employee_deduction = ["fixed", "percentage"] to be frequencies:every_paycheck = true and frequencies:every_month = true
                            if (isArray(operationSupport)) {
                              operationSupport.map((support) => {
                                if (isString(support)) {
                                  fields[[key.join('.'), support].join(':')] =
                                    true;
                                } else {
                                  fields[key.join('.')] = true;
                                }
                              });
                            } else {
                              fields[key.join('.')] = operationSupport;
                            }

                            return fields;
                          }

                          const key = field
                            .replace(supportedSubgroup, '.')
                            .split('.')
                            .reverse()
                            .join('.')
                            .replace('.', '_');

                          fields[key] =
                            subgroup === 'operations'
                              ? operationSupport === 'supported'
                                ? true
                                : false
                              : operationSupport;
                        }

                        return fields;
                      },
                      Object.create(null),
                    )
                  : null;
              } else {
                if (!clearAcfStatus && method.type === 'assisted') {
                  return methods;
                }

                supportedFields = method.supportedFields
                  ? Object.entries(method.supportedFields).reduce(
                      (fields, [field, supported]) => {
                        if (
                          field
                            .slice(1 + field.indexOf('.'))
                            .startsWith('paging')
                        ) {
                          return fields;
                        }

                        const [endpoint] = field.split('.');

                        if (
                          isEndpoint(endpoint) &&
                          endpoints[group].includes(endpoint)
                        ) {
                          if (endpoint === 'pay_statement') {
                            fields[field.replace('.pay_statements.', '.')] =
                              supported;
                          } else {
                            fields[field] = supported;
                          }
                        }

                        return fields;
                      },
                      Object.create(null),
                    )
                  : null;
              }

              // NOTE: this compensates for an issue with the backend (exp-437)
              const isempty =
                Object.values(supportedFields).filter(Boolean).length === 0;

              if (isempty) {
                return methods;
              }

              methods.push({
                id: provider.id,
                name: provider.displayName,
                type: method.type,
                icon: provider.icon,
                beta: provider.isBeta(),
                supportedFields,
              });

              return methods;
            }, new Array<ProviderSupport>()),
        );

        return columns;
      }, []),
    [group, subgroup, loading],
  );

  return {
    loading,
    error,
    implementations,
  };
}
