import { useCallback } from 'react';
import {
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Button,
  Spacer,
  Tabs,
  TabList,
  Tab,
  MenuItemOption,
  MenuOptionGroup,
  Flex,
  Box,
  DarkMode,
  LightMode,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react';
import isArray from 'lodash/isArray';
import startCase from 'lodash/startCase';

import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { FieldSupportTable } from '../components/FieldSupportTable';
import { useSupportedFields } from '../hooks/useSupportedFields';
import { COLORS, Z_INDEX } from '../../constant';
import { groups, subgroups } from '../constants';
import { isGroup } from '../utils';
import { CheckIcon } from '../../shared/icons/CheckIcon';
import { ProviderSupport } from '../types';
import { useColours } from '../hooks/useColours';
import { useSelectedGroup } from '../hooks/useSelectedGroup';
import { useControlsFromURI } from '../hooks/useControlsFromURI';
import { useSelectedSubgroup } from '../hooks/useSelectedSubgroup';
import { AppTab, AppTabList } from 'components/Tabs';

const GroupMenu = ({ loading }: { loading: boolean }) => {
  const { selectedGroup, setSelectedGroup } = useSelectedGroup();
  const changeView = useCallback(
    (group: string | string[]) => {
      const navigateTo = isArray(group) ? group[0] : group;

      if (!isGroup(navigateTo)) {
        return;
      }

      setSelectedGroup(navigateTo);
    },
    [setSelectedGroup],
  );
  const theme = useColours();
  const border = useColorModeValue(COLORS.GRAY.GRAY_400, '#ffffff1a');
  const background = useColorModeValue(undefined, '#1a1a1a');
  const selected = useColorModeValue(COLORS.GRAY.GRAY_600, '#dfdfdf');
  const hovered = useColorModeValue(COLORS.GRAY.GRAY_200, '#212121');

  if (loading) {
    return <Skeleton width="320px" height="40px" borderRadius="lg" />;
  }

  return (
    <Menu variant="secondary">
      <MenuButton
        as={Button}
        borderRadius="lg"
        border="1px solid"
        borderColor={border}
        background={theme.background}
        padding="10px"
        rightIcon={<ChevronDownIcon />}
        width="320px"
        _active={{ background: 'none' }}
        _hover={{ textDecoration: 'unset' }}
        color={theme.color}
        fontSize="14px"
        textAlign="left"
      >
        Field Support for {startCase(selectedGroup)}
      </MenuButton>
      <MenuList
        padding="4px"
        minWidth="332px"
        zIndex={Z_INDEX.MENU}
        background={background}
      >
        <MenuOptionGroup
          defaultValue="organization"
          type="radio"
          onChange={changeView}
        >
          {groups.map((group) => (
            <MenuItemOption
              key={group}
              value={group}
              icon={null}
              borderRadius="sm"
              background={background}
              _focus={{ background: hovered }}
              _selected={{ background: hovered }}
              _hover={{ background: hovered }}
            >
              <HStack>
                <Text color={group === selectedGroup ? selected : theme.color}>
                  Field Support for {startCase(group)}
                </Text>
                <Spacer />
                {group === selectedGroup ? <CheckIcon /> : null}
              </HStack>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

const SubgroupTabs = ({
  onChange,
  selected,
  tabs,
}: {
  selected: number;
  tabs: Readonly<string[]> | string[];
  onChange: (index: number) => void;
}) => {
  const { selectedGroup } = useSelectedGroup();
  const theme = useColours();

  if (tabs.length === 0) {
    return null;
  }

  return (
    <Tabs
      size="sm"
      isLazy
      index={selected}
      onChange={onChange}
      backgroundColor={theme.background}
    >
      <AppTabList gap="24px" borderColor={theme.border}>
        {tabs.map(
          (label, index) => (
            <AppTab
              color={theme.color}
              key={index}
              fontSize="14px"
              fontWeight={600}
            >
              Supported {startCase(label)} for {startCase(selectedGroup)}
            </AppTab>
          ),
          [],
        )}
      </AppTabList>
    </Tabs>
  );
};

const Theme = ({ children }: { children: JSX.Element }) => {
  const { mode } = useControlsFromURI();

  if (mode === 'dark') {
    return <DarkMode>{children}</DarkMode>;
  }

  return <LightMode>{children}</LightMode>;
};

const Page = ({
  implementations,
  loading,
}: {
  implementations: ProviderSupport[];
  loading: boolean;
}) => {
  const { selectedGroup } = useSelectedGroup();
  const { selectedSubgroup, setSelectedSubgroup } = useSelectedSubgroup();
  // NOTE: hiding percentage-based coverage, for now as per a product request
  //       https://tryfinch.atlassian.net/browse/EN-6113
  const coverage = false; // selectedGroup !== 'deductions';
  const availability = selectedGroup !== 'deductions';
  const theme = useColours();

  return (
    <Flex
      flexDir="column"
      height="100%"
      maxHeight="100vh"
      padding="4px"
      backgroundColor={theme.background}
    >
      <Box backgroundColor={theme.background} flex="0 1 auto">
        <HStack
          alignItems="start"
          paddingBottom={`${selectedGroup !== 'deductions' ? 40 : 25}px`}
        >
          <Spacer />
          <GroupMenu loading={loading} />
        </HStack>
        <SubgroupTabs
          selected={subgroups[selectedGroup].indexOf(selectedSubgroup)}
          tabs={subgroups[selectedGroup]}
          onChange={(index) =>
            subgroups[selectedGroup][index] &&
            selectedSubgroup !== subgroups[selectedGroup][index] &&
            setSelectedSubgroup(subgroups[selectedGroup][index]!)
          }
        />
      </Box>
      <Box flex="1" backgroundColor={theme.background}>
        {!loading && (
          <FieldSupportTable
            implementations={implementations}
            coverage={coverage}
            availability={availability}
          />
        )}
      </Box>
    </Flex>
  );
};

export const FieldSupportPage = () => {
  const { selectedGroup } = useSelectedGroup();
  const { selectedSubgroup } = useSelectedSubgroup();
  const { loading, implementations } = useSupportedFields([
    selectedGroup,
    selectedSubgroup,
  ]);

  return (
    <Theme>
      <Page implementations={implementations} loading={loading} />
    </Theme>
  );
};
