import { HStack, Stack, StackDivider, TabPanel } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { ScopeTag } from 'components/ScopeTag';
import { format } from '../../shared/utils/dateFormatter';
import { ConnectSession } from 'types/connect';
import { ConnectSessionStatusTag } from 'components/Tag/ConnectSessionTag';
import { getConnectSessionStatus } from '../utils';
import { TableDetailItem } from './TableDetailItem';
import { RedirectUriMap } from '../constants';

export const SessionDetailsTabPanel = ({
  connectSession,
}: {
  connectSession: ConnectSession;
}) => {
  const redirectUri =
    RedirectUriMap[connectSession.redirectUri as keyof typeof RedirectUriMap] ??
    (connectSession.redirectUri || '—');

  return (
    <TabPanel p="0">
      <Stack
        spacing="0"
        divider={<StackDivider borderColor={COLORS.GRAY.GRAY_400} />}
      >
        <TableDetailItem
          title="Products"
          data={
            <HStack wrap="wrap">
              {connectSession.scopes.map((type) => (
                <ScopeTag key={type} scope={type} />
              ))}
            </HStack>
          }
        />

        <TableDetailItem
          title="Connect URL"
          data={connectSession.url}
          withClipboard
        />

        <TableDetailItem title="Session ID" data={connectSession.id} />

        <TableDetailItem
          title="Date Created"
          data={format(new Date(connectSession.createdAt))}
        />

        <TableDetailItem
          title="Expiration Date"
          data={format(new Date(connectSession.expiresAt))}
        />

        <TableDetailItem
          title="Status"
          data={
            <ConnectSessionStatusTag
              variant={getConnectSessionStatus(connectSession)}
            />
          }
        />

        <TableDetailItem
          title="Customer Email Address"
          data={connectSession.externalCompanyEmail ?? '—'}
        />

        <TableDetailItem title="Redirect URL" data={redirectUri} />

        {/* Uncomment once providerName is added to the response */}
        {/* <SessionDetailItem
                              title="Provider"
                              data={connectSession.providerName ?? '—'}
                            /> */}

        {/* Uncomment once authMethod is added to the response */}
        {/* <SessionDetailItem
                              title="Authentication Method"
                              data={connectSession.authMethod ?? '—'}
                            /> */}

        <TableDetailItem
          title="Sandbox Access"
          data={connectSession.sandbox ?? 'Disabled'}
        />

        <TableDetailItem
          title="Show Assisted Providers"
          data={connectSession.showAssisted ? 'Enabled' : 'Disabled'}
        />
      </Stack>
    </TabPanel>
  );
};
