import { Stack } from '@chakra-ui/react';
import { NavItem } from './NavItem';
import { NavOption } from './types';
import { useLocation } from 'react-router-dom';

export const NavList = ({ options }: { options: NavOption[] }) => {
  const { pathname } = useLocation();

  return (
    <Stack p="16px">
      {options.map((option) => (
        <NavItem
          key={option.href}
          selected={pathname === option.href}
          {...option}
        />
      ))}
    </Stack>
  );
};
