import { HStack } from '@chakra-ui/react';
import { SubSectionHeading } from '../../../shared/Typography';
import { EditIcon } from '../../../shared/icons/EditIcon';
import { PillButton } from '../../../components/PillButton';

export const Header = ({
  editMode,
  setEditMode,
  isLoading,
  clearStagedChanges,
}: {
  editMode: boolean;
  isLoading: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  clearStagedChanges: () => void;
}) => {
  const cancel = () => {
    clearStagedChanges();
    setEditMode(false);
  };

  return (
    <HStack justify="space-between">
      <SubSectionHeading id="form-title">SFTP Credentials</SubSectionHeading>
      {editMode ? (
        <HStack>
          {!isLoading && (
            <PillButton variant="secondary" onClick={cancel}>
              Cancel
            </PillButton>
          )}
          <PillButton
            variant="primaryPurple"
            type="submit"
            isLoading={isLoading}
          >
            Save Changes
          </PillButton>
        </HStack>
      ) : (
        <PillButton
          leftIcon={<EditIcon hollow />}
          onClick={() => setEditMode(true)}
          isLoading={isLoading}
        >
          Edit
        </PillButton>
      )}
    </HStack>
  );
};
