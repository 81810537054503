import { create } from 'zustand';
import { SandboxMode } from '../types';

export const useSandboxStore = create<{
  selectedSandbox: SandboxMode;
  setSelectedSandbox: (sandbox: SandboxMode) => void;
}>((set) => ({
  selectedSandbox: 'finch',
  setSelectedSandbox: (selectedSandbox) => set({ selectedSandbox }),
}));
