import { useAuth0, User } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { StagedConnectionDetailApi } from '../api';
import { baseURL } from '../../utils/baseUrls';

export const useConnectionDetailApi = () => {
  const auth: Auth0ContextInterface<User> = useAuth0();

  return new StagedConnectionDetailApi(baseURL, auth);
};
