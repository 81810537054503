import type { Column } from '@tanstack/react-table';
import { useState } from 'react';
import { FilterConfig, FilterValue } from './types';

export const useFilters = <ColumnRecord, FilterId extends keyof ColumnRecord>({
  filterList,
  getColumn,
  selectedFilters,
  setSelectedFilters,
}: {
  filterList: FilterId[];
  getColumn: (id: FilterId) => Column<ColumnRecord, unknown> | undefined;
  selectedFilters: FilterId[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<FilterId[]>>;
}) => {
  const [activeFilterId, setActiveFilterId] = useState<FilterId | null>(null);

  const setFilterValue = (id?: FilterId, newValue?: FilterValue) => {
    if (!id) return;

    getColumn?.(id)?.setFilterValue(newValue);
  };

  const removeAllFilters = () => {
    selectedFilters.forEach((id) => {
      setFilterValue(id, undefined);
    });

    setSelectedFilters([]);
  };

  const closeFilters = () => {
    setActiveFilterId(null);
  };

  const toggleOpen = (id: FilterId) => {
    if (activeFilterId !== id) {
      setActiveFilterId(id);
    }

    if (activeFilterId === id) {
      setActiveFilterId(null);
    }
  };

  const deleteFilter = (id: FilterId) => {
    setSelectedFilters((selected) =>
      selected.filter((selectedId) => id !== selectedId),
    );

    setFilterValue(id, undefined);
    setActiveFilterId(null);
  };

  const addFilter = (id: FilterId) => {
    setSelectedFilters((oldIds) => [...oldIds, id]);

    getColumn?.(id)?.setFilterValue(undefined);

    setActiveFilterId(id);
  };

  const getFilterValue = (id: FilterId) => {
    return getColumn?.(id)?.getFilterValue() as FilterValue;
  };

  const filterConfigs: FilterConfig<FilterId>[] = filterList.map((filterId) => {
    const columnDef = getColumn?.(filterId)?.columnDef;

    return {
      id: filterId,
      label: columnDef?.meta?.filterLabel || columnDef?.header?.toString(),
      filterInput: columnDef?.meta?.filterInput,
      inputOptions: columnDef?.meta?.filterInputOptions,
    };
  });

  return {
    selectedFilters,
    activeFilterId,
    toggleOpen,
    deleteFilter,
    addFilter,
    filterConfigs,
    setFilterValue,
    getFilterValue,
    removeAllFilters,
    closeFilters,
  };
};
