import type { FilterFn } from '@tanstack/react-table';
import dayjs from 'dayjs';
import gte from 'lodash/gte';
import lte from 'lodash/lte';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import { DateRange, RelativeDateFilterValue } from './types';

export const arrayEquals: FilterFn<string[]> = (row, columnId, filterValue) => {
  const rowValue = row.getValue(columnId) as string[];

  return (
    filterValue &&
    rowValue.every((value) => filterValue.includes(value)) &&
    rowValue.length === filterValue.length
  );
};

export const getTimeValueForSelectedDateRange = (
  dateRange: DateRange,
): [number, number] => {
  switch (dateRange) {
    case 'today':
      return [dayjs().startOf('day').valueOf(), dayjs().endOf('day').valueOf()];
    case 'last_7_days':
      return [
        dayjs().startOf('day').subtract(7, 'day').valueOf(),
        dayjs().endOf('day').valueOf(),
      ];
    case 'last_30_days':
      return [
        dayjs().startOf('day').subtract(30, 'day').valueOf(),
        dayjs().endOf('day').valueOf(),
      ];
    case 'last_90_days':
      return [
        dayjs().startOf('day').subtract(90, 'day').valueOf(),
        dayjs().endOf('day').valueOf(),
      ];
    case 'last_12_months':
      return [
        dayjs().startOf('day').subtract(12, 'month').valueOf(),
        dayjs().endOf('day').valueOf(),
      ];
    case 'custom_range':
      return [
        dayjs().subtract(1, 'day').startOf('day').valueOf(),
        dayjs().endOf('day').valueOf(),
      ];
  }
};

export const dateRange: FilterFn<{
  id: DateRange;
  range: [number, number];
}> = (row, columnId, filterValue) => {
  const rowValue = new Date(row.getValue(columnId) ?? 0).valueOf() as number;

  return (
    filterValue &&
    rowValue >= filterValue?.range[0] &&
    rowValue <= filterValue?.range[1]
  );
};

export const relativeDateRange: FilterFn<RelativeDateFilterValue> = (
  row,
  columnId,
  filterValue: RelativeDateFilterValue,
) => {
  const rowValue = row.getValue(columnId);

  if (filterValue.id === 'never') {
    return !rowValue;
  } else if (!rowValue) {
    return false;
  }

  const moment = new Date(+rowValue).valueOf();
  const now = new Date();
  const predicate = filterValue.id === 'less' ? lte : gte;

  switch (filterValue.units) {
    case 'hours':
      return predicate(subHours(now, filterValue.amount).getTime(), moment);

    case 'days':
      return predicate(subDays(now, filterValue.amount).getTime(), moment);

    case 'weeks':
      return predicate(subWeeks(now, filterValue.amount).getTime(), moment);

    case 'months':
      return predicate(subMonths(now, filterValue.amount).getTime(), moment);

    default:
      return true;
  }
};

export const filterFns: Record<string, FilterFn<any>> = {
  arrayEquals,
  dateRange,
  relativeDateRange,
};
