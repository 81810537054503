import { RefObject } from 'react';
import { type IconButtonProps, useClipboard } from '@chakra-ui/react';
import { FiCheckCircle } from 'react-icons/fi';
import { ClipboardIcon } from '../shared/icons/Clipboard';
import { TransparentIconButton } from '../shared/TransparentIconButton';

export const ClipboardButton = ({
  buttonRef,
  value,
  iconSize,
  ...props
}: Omit<IconButtonProps, 'aria-label'> & {
  buttonRef?: RefObject<HTMLButtonElement>;
  value: string;
  iconSize?: string;
}) => {
  const { hasCopied, onCopy } = useClipboard(value);
  return hasCopied ? (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copied"
      icon={<FiCheckCircle height={iconSize} width={iconSize} />}
      onClick={onCopy}
      variant="icon"
      {...props}
    />
  ) : (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copy"
      icon={<ClipboardIcon iconSize={iconSize} />}
      onClick={onCopy}
      variant="icon"
      {...props}
    />
  );
};
