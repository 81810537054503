import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { PageContainer } from '../../components/PageContainer';
import { StagedConnectionDetailScreen } from '../components/StagedConnectionDetailScreen';

export const StagedConnectionDetail = () => {
  const { applicationId, stagedConnectionId } = useParams<{
    stagedConnectionId: string;
    applicationId: string;
  }>();

  return (
    <Box>
      <PageContainer>
        <StagedConnectionDetailScreen
          applicationId={applicationId}
          stagedConnectionId={stagedConnectionId}
        />
      </PageContainer>
    </Box>
  );
};
