import { SignupErrorCodes } from '@finch-api/developer-dashboard-common';

/**
 * Rather than hard code these english messages in the server, we instead
 * receive errors with the keys of this map. The values are the English
 * localization. If we add more languages, we will need to i18n this map!
 */
export const signupErrorMessages = {
  [SignupErrorCodes.COMPANY_NAME_EXISTS]:
    'A company with this name already exists',
  [SignupErrorCodes.EMAIL_EXISTS]: 'A user with this email already exists.',
};
