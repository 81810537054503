export const ChevronRightIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 10L10 16L8.9375 14.9375L13.125 10.75L4 10.75L4 9.25L13.125 9.25L8.9375 5.0625L10 4L16 10Z"
      fill="#161616"
    />
  </svg>
);
