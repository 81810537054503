import { Props, Status, variants } from './Tag';
import { Badge } from '../Badge/Badge';

export type AssistedAccountStatusVariant = Status<
  | 'connected'
  | 'pending'
  | 'processing'
  | 'no_account_set_up'
  | 'improper_permissions'
  | 'disconnected'
  | 'unknown'
>;

/**
 * Tag used for displaying the status of an assisted account of an employer
 * connection.
 *
 * @see https://www.figma.com/file/gldI3ap3tphioNftkEHThx/Employers?type=design&node-id=2235-1475&mode=design&t=Np9Bs86KCQ1W7jGc-4
 */
export const AssitedAccountStatusTag = ({
  variant,
}: Props<AssistedAccountStatusVariant>) => {
  const { icon, theme, text } = variants[variant];

  return <Badge theme={theme} icon={icon} text={text} />;
};
