import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import {
  addApplicationRedirectURIs,
  removeApplicationRedirectURIs,
} from '../utils/applicationRedirects';
import { Scope } from '@finch-api/developer-dashboard-common';

const RedirectURIsContext = createContext<RedirectURIsContextValue | undefined>(
  undefined,
);

type RedirectURIsContextValue = {
  urls: string[];
  isAdding: boolean;
  handleAddingStarted: () => void;
  handleAddingCancelled: () => void;
  handleAddRedirect: (redirectUrl: string) => Promise<void>;
  handleRemoveRedirect: (redirectUrl: string) => Promise<void>;
};

type RedirectURIsProps = {
  children: JSX.Element;
  applicationId: string;
  urls: string[];
  scopes: Scope[];
};

export const RedirectURIs = (props: RedirectURIsProps) => {
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();

  const [urls, setUrls] = useState(props.urls);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => setUrls(props.urls), [props.urls]);

  const handleAddRedirect = useCallback(
    async (redirectUrl: string) => {
      const token = await getAccessTokenSilently();

      const newUrls = await addApplicationRedirectURIs(
        token,
        props.applicationId,
        [redirectUrl],
      );

      setUrls(newUrls);
      setIsAdding(false);

      return;
    },
    [props.applicationId, getAccessTokenSilently],
  );

  const handleRemoveRedirect = useCallback(
    async (redirectUrl: string) => {
      const token = await getAccessTokenSilently();

      const remainingUrls = await removeApplicationRedirectURIs(
        token,
        props.applicationId,
        [redirectUrl],
      );

      setUrls(remainingUrls);

      return;
    },
    [props.applicationId, getAccessTokenSilently],
  );

  const handleAddingStarted = () => {
    setIsAdding(true);
  };

  const handleAddingCancelled = () => {
    setIsAdding(false);
  };

  return (
    <RedirectURIsContext.Provider
      value={{
        urls,
        isAdding,
        handleAddingStarted,
        handleAddingCancelled,
        handleAddRedirect,
        handleRemoveRedirect,
      }}
    >
      {props.children}
    </RedirectURIsContext.Provider>
  );
};

export const useRedirectURIs = () => {
  const context = useContext(RedirectURIsContext);
  if (context === undefined) {
    throw new Error(
      'useRedirects must be used within a RedirectsContextProvider',
    );
  }
  return context;
};
