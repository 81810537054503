import { Log, SearchQuery } from '@finch-api/developer-dashboard-common';
import { create } from 'zustand';

const FILTER_DEFAULTS = {
  fuzzyIdAndNameSearchQuery: '',
  endpointSearchQuery: '',
  startDate: undefined,
  endDate: undefined,
  payrollProviderId: '',
  statusCode: undefined,
  statusDescription: '',
  pageIndex: 0,
};

type ActivityStoreState = {
  loading: boolean;
  setLoading: (loading: boolean) => void;

  initialFetchComplete: boolean;
  setInitialFetchComplete: (initialFetchComplete: boolean) => void;

  logs: Log[];
  setLogs: (logs: Log[]) => void;

  totalLogSize: number;
  setTotalLogSize: (logSize: number) => void;

  draftFilters: SearchQuery;
  updateDraftFilters: (filterUpdate: Partial<SearchQuery>) => void;

  activeFilters: SearchQuery;
  commitFilters: () => void;

  clearFilters: () => void;

  updateActivePageIndex: (pageIndex: number) => void;

  selectedLog: Log | null;
  setSelectedLog: (selectedLog: Log | null) => void;
};

export const useActivityStore = create<ActivityStoreState>((set) => ({
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),

  initialFetchComplete: false,
  setInitialFetchComplete: (initialFetchComplete: boolean) =>
    set({ initialFetchComplete }),

  logs: [],
  setLogs: (logs: Log[]) => set({ logs }),

  totalLogSize: 0,
  setTotalLogSize: (totalLogSize: number) => set({ totalLogSize }),

  draftFilters: { ...FILTER_DEFAULTS },
  updateDraftFilters: (filterUpdate: Partial<SearchQuery>) =>
    set((state) => ({
      draftFilters: {
        ...state.draftFilters,
        ...filterUpdate,
      },
    })),

  activeFilters: { ...FILTER_DEFAULTS },

  updateActivePageIndex: (pageIndex: number) =>
    set((state) => ({
      activeFilters: {
        ...state.activeFilters,
        pageIndex,
      },
    })),

  commitFilters: () =>
    set((state) => ({
      activeFilters: {
        ...state.draftFilters,
      },
    })),

  clearFilters: () =>
    set(() => ({
      draftFilters: {
        ...FILTER_DEFAULTS,
      },
    })),

  selectedLog: null,
  setSelectedLog: (selectedLog: Log | null) => set({ selectedLog }),
}));
