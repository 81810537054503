import type { ReadonlyDeep } from 'type-fest';
import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import {
  ExternalJobStatus,
  JobType,
} from '@finch-api/common/dist/external/dashboard/job-status';

export const jobTypeOptions = [
  {
    id: JobType.ORG_PAYROLL_SYNC,
    label: 'Data Sync',
  },
  {
    id: JobType.BENEFIT_CREATE,
    label: 'Create Benefit',
  },
  {
    id: JobType.BENEFIT_ENROLL,
    label: 'Enroll Benefits',
  },
  {
    id: JobType.BENEFIT_UNENROLL,
    label: 'Unenroll Benefits',
  },
  {
    id: JobType.BENEFIT_UPDATE,
    label: 'Update Benefits',
  },
];

export const connectionCategoryOptions = [
  {
    id: ConnectionCategory.Automated,
    label: 'Automated only',
  },
  {
    id: ConnectionCategory.Assisted,
    label: 'Assisted only',
  },
];

export const jobStatusOptions = [
  {
    id: ExternalJobStatus.COMPLETE,
    label: 'Success',
  },
  {
    id: ExternalJobStatus.PENDING,
    label: 'Pending',
  },
  {
    id: ExternalJobStatus.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    id: ExternalJobStatus.REAUTH_ERROR,
    label: 'Reauth Error',
  },
  {
    id: ExternalJobStatus.PERMISSION_ERROR,
    label: 'Permission Error',
  },
  {
    id: ExternalJobStatus.PARTIAL_ERROR,
    label: 'Partial Error',
  },
];

export const PERMISSIONS_VERIFIED = 3;

export const ACCOUNTANT_INVITATION_ACCEPTED = 2;

export const TOKEN_CREATED = 1;

export const NO_PROGRESS = 0;

export type Progress =
  | typeof PERMISSIONS_VERIFIED
  | typeof ACCOUNTANT_INVITATION_ACCEPTED
  | typeof TOKEN_CREATED
  | typeof NO_PROGRESS;

export const assistedStatusProgress: ReadonlyDeep<
  Record<AccountStatus, Progress>
> = {
  [AccountStatus.CONNECTED]: PERMISSIONS_VERIFIED,
  [AccountStatus.REAUTH]: PERMISSIONS_VERIFIED,
  [AccountStatus.ERROR_PERMISSIONS]: ACCOUNTANT_INVITATION_ACCEPTED,
  [AccountStatus.ERROR_NO_ACCOUNT_SETUP]: TOKEN_CREATED,
  [AccountStatus.PENDING]: TOKEN_CREATED,
  [AccountStatus.DISCONNECTED]: NO_PROGRESS,
  [AccountStatus.PROCESSING]: NO_PROGRESS,
  [AccountStatus.UNKNOWN]: NO_PROGRESS,
};

export const JOB_HISTORY_TAB = 0;

export const ASSISTED_SETUP_STATUS_TAB = 1;

export const accountDetailTabs = [
  'Job History',
  'Assisted Setup Status',
] as const;
