import startCase from 'lodash/startCase';
import { getStatusLabelByValue } from '../../activity/tableProperties';
import { Badge, BadgeTheme } from '../Badge/Badge';

export const getColorScheme = (statusCode: number) => {
  if (statusCode >= 200 && statusCode < 300) {
    return BadgeTheme.GREEN;
  } else {
    return BadgeTheme.RED;
  }
};

/**
 * Tag used for displaying the http status of a request
 *
 * @see https://www.figma.com/file/gldI3ap3tphioNftkEHThx/Employers?type=design&node-id=2235-1475&mode=design&t=Np9Bs86KCQ1W7jGc-4
 */
export const HttpStatusTag = ({
  status,
}: {
  status: {
    code: number;
    description: string;
  };
}) => {
  const theme = getColorScheme(status.code);

  return (
    <Badge
      theme={theme}
      text={`${status.code} ${startCase(getStatusLabelByValue(status))}`}
    />
  );
};
