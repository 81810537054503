import { ConnectionAccount } from './types';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';

export class Connection {
  constructor(private readonly params: Readonly<ConnectionAccount>) {}

  get id() {
    return this.params.connectionAccountId;
  }

  get clientId() {
    return this.params.clientId;
  }

  get companyId() {
    return this.params.companyId;
  }

  get providerId() {
    return this.params.providerId;
  }

  get clientName() {
    return this.params.clientName;
  }

  get companyName() {
    return this.params.companyName;
  }

  get customerName() {
    return this.params.externalCompanyName;
  }

  get customerId() {
    return this.params.externalCompanyId;
  }

  get providerName() {
    return this.params.providerName;
  }

  get connectionStatus() {
    return this.params.connectionStatus;
  }

  get implementationKind() {
    return this.params.implementationKind;
  }

  get automatedStatus() {
    return this.params.automatedStatus;
  }

  get assistedStatus() {
    return this.params.assistedStatus;
  }

  get providerLogo() {
    return this.params.providerLogo;
  }

  get providerIcon() {
    return this.params.providerIcon;
  }

  get firstConnectedAt() {
    return new Date(this.params.firstConnectedAt);
  }

  get lastSyncAt() {
    const lastSyncAt = this.params.lastSyncAt;

    if (lastSyncAt) {
      return new Date(lastSyncAt);
    }
  }

  // For backwards compatibility
  get kinds() {
    const kinds = [];
    if (this.params.automatedStatus) {
      kinds.push(this.params.implementationKind);
    }

    if (this.params.assistedStatus) {
      kinds.push(ImplementationKind.ASSISTED);
    }

    return kinds;
  }

  isConnected(): boolean {
    return this.params.connectionStatus === AccountStatus.CONNECTED;
  }

  isDisconnected(): boolean {
    return this.params.connectionStatus === AccountStatus.DISCONNECTED;
  }

  isPending(): boolean {
    return this.params.connectionStatus === AccountStatus.PENDING;
  }

  // Check if this is required when there is only 1 connection
  needsAttention(): boolean {
    return [
      AccountStatus.ERROR_NO_ACCOUNT_SETUP,
      AccountStatus.ERROR_PERMISSIONS,
      AccountStatus.REAUTH,
    ].includes(this.params.connectionStatus);
  }

  insufficientPermissions(): boolean {
    return this.params.connectionStatus === AccountStatus.ERROR_PERMISSIONS;
  }

  noAccountSetUp(): boolean {
    return (
      this.params.connectionStatus === AccountStatus.ERROR_NO_ACCOUNT_SETUP
    );
  }

  reauthNeeded(): boolean {
    return this.params.connectionStatus === AccountStatus.REAUTH;
  }

  isLegacySandboxProvider(): boolean {
    return this.params.providerId === 'finch';
  }

  hasFinchSandboxKinds(): boolean {
    return this.params.implementationKind.startsWith('finch_sandbox_');
  }

  isImplementation(...kinds: `${ImplementationKind}`[]): boolean {
    return Array.from(new Set(kinds)).every(
      (kind) => this.params.implementationKind === kind,
    );
  }

  isAssisted(): boolean {
    return [
      ImplementationKind.ASSISTED,
      ImplementationKind.FINCH_SANDBOX_ASSISTED,
    ].includes(this.params.implementationKind);
  }

  isAutomated(): boolean {
    return this.isCredential() || this.isOAuth() || this.isApi();
  }

  isCredential(): boolean {
    return [
      ImplementationKind.CREDENTIAL,
      ImplementationKind.FINCH_SANDBOX_CREDENTIAL,
    ].includes(this.params.implementationKind);
  }

  isOAuth(): boolean {
    return [
      ImplementationKind.OAUTH,
      ImplementationKind.FINCH_SANDBOX_OAUTH,
    ].includes(this.params.implementationKind);
  }

  isApi(...kind: ('token' | 'credential')[]): boolean {
    const isApiToken = [
      ImplementationKind.API_TOKEN,
      ImplementationKind.FINCH_SANDBOX_API_TOKEN,
    ].includes(this.params.implementationKind);

    const kinds = new Set(kind);

    if (kinds.size === 2) {
      return isApiToken;
    }

    if (kinds.size === 1) {
      switch (kind[0]) {
        case 'token':
          return isApiToken;
      }
    }

    return isApiToken;
  }
}
