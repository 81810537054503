import {
  Heading,
  type HeadingProps,
  Text,
  type TextProps,
} from '@chakra-ui/react';
import { COLORS } from '../constant';
import { ComponentProps } from 'react';

const headerColor = '#101828';

export const PageHeading = ({
  children,
  ...rest
}: HeadingProps & { children: React.ReactNode }) => {
  return (
    <Heading fontWeight="medium" fontSize="24px" {...rest}>
      {children}
    </Heading>
  );
};

export const SectionHeading = ({ children }: { children: React.ReactNode }) => {
  return (
    <Heading fontWeight="600" fontSize="18px">
      {children}
    </Heading>
  );
};

export const SubSectionHeading = ({
  children,
  ...rest
}: HeadingProps & { children: React.ReactNode }) => {
  return (
    <Heading fontWeight="medium" fontSize="16px" {...rest}>
      {children}
    </Heading>
  );
};

export const Caption = ({
  children,
  ...props
}: TextProps & { children: React.ReactNode }) => {
  return (
    // Chakra Text defaults to `p`, which is a block element, instead of `span`, which is inline
    // https://github.com/chakra-ui/chakra-ui/issues/389
    // inline elements are more suitable for typography primitives as they're more composable,
    // and allows for propeties like text overflow to be configured on the containing block element,
    // instead of overloading the API of typography primitives.
    <Text as="span" fontSize="14px" {...props}>
      {children}
    </Text>
  );
};

export const BoldCaption = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text as="span" fontSize="14px" fontWeight="600">
      {children}
    </Text>
  );
};

export const SelectedCaption = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Text as="span" fontSize="14px" fontWeight="600" textColor="brand.purple">
      {children}
    </Text>
  );
};

export const Body = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text as="span" fontWeight="normal" fontSize="16px">
      {children}
    </Text>
  );
};

export const Link = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color?: ComponentProps<typeof Text>['color'];
}) => {
  return (
    <Text as="span" fontWeight="600" color={color || 'brand.purple'}>
      {children}
    </Text>
  );
};

export const AlertHeadingText = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Text fontSize="18px" fontWeight={500} color={headerColor}>
    {children}
  </Text>
);

export const AlertBodyText = ({ children }: { children: React.ReactNode }) => (
  <Text
    fontSize="14px"
    fontWeight={400}
    color={COLORS.FINCH.BLACK}
    lineHeight="20px"
  >
    {children}
  </Text>
);
