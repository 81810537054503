import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  ImplementationKind,
  isAssistedImplementationKind,
} from '@finch-api/common/dist/internal/connect/authorize';
import first from 'lodash/first';

import { JobStatusTable } from './JobsTable';
import { Loading } from '../../components/Loading/Loading';
import { AccountInfo } from './AccountInfo';
import { COLORS } from '../../constant';
import { NoJobsScreen } from './NoJobsScreen';
import { AccountSelectionPanel } from './AccountSelectionPanel';
import { AccountDetails } from '../../connections/types';
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { Disconnected } from './Disconnected';
import { JobEntry } from '../types';
import { AssistedSetupStatus } from './AssistedSetupStatus';
import {
  ASSISTED_SETUP_STATUS_TAB,
  JOB_HISTORY_TAB,
  accountDetailTabs,
} from '../constants';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DataSyncTabs = ({
  account,
  isLoading,
  allJobs,
  focusAssistedTab,
}: {
  account?: AccountDetails;
  isLoading: boolean;
  allJobs: JobEntry[];
  focusAssistedTab: boolean;
}) => {
  const jobs = account ? account.jobs : allJobs;
  const statusLog = first(account?.statusTimeline);
  const [selectedTab, setSelectedTab] = useState<number>(JOB_HISTORY_TAB);
  const [assistedTab, setAssistedTab] = useState<number>(
    focusAssistedTab ? ASSISTED_SETUP_STATUS_TAB : JOB_HISTORY_TAB,
  );
  const visibleTabs = (_: string, index: number) => {
    switch (index) {
      case ASSISTED_SETUP_STATUS_TAB:
        return (
          account && isAssistedImplementationKind(account.implementationKind)
        );

      default:
        return true;
    }
  };
  const onTabChange = (index: number) => {
    switch (account?.implementationKind) {
      case ImplementationKind.ASSISTED:
        setAssistedTab(index);

        break;

      default:
        setSelectedTab(index);
    }
  };

  useEffect(() => {
    switch (account?.implementationKind) {
      case ImplementationKind.FINCH_SANDBOX_ASSISTED:
      case ImplementationKind.ASSISTED:
        setSelectedTab(assistedTab);

        break;

      default:
        setSelectedTab(JOB_HISTORY_TAB);
    }
  }, [account?.implementationKind, assistedTab]);

  if (!account && allJobs.length === 0) {
    return (
      <Wrapper>
        <NoJobsScreen />
      </Wrapper>
    );
  }

  return (
    <Tabs
      flexGrow={1}
      index={selectedTab}
      onChange={onTabChange}
      // marginTop="-43px" // NOTE: some design things that need to be thought out for long company names
    >
      <TabList gap={6}>
        {accountDetailTabs.filter(visibleTabs).map((label, index) => (
          <Tab
            key={index}
            fontSize="14px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            padding="0 0 12px 0"
          >
            {label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel paddingLeft="0" paddingRight="0">
          {isLoading && (
            <Wrapper>
              <Loading message="We’re getting your job history ready." />
            </Wrapper>
          )}
          {!isLoading && jobs.length ? (
            <JobStatusTable isLoading={isLoading} jobs={jobs} />
          ) : (
            <Wrapper>
              <NoJobsScreen />
            </Wrapper>
          )}
        </TabPanel>
        <TabPanel paddingLeft="0" paddingRight="0">
          <AssistedSetupStatus
            status={(statusLog?.newStatus as AccountStatus) ?? undefined}
            message={account?.statusMessage}
            lastUpdatedAt={
              statusLog ? new Date(statusLog?.createdAt) : undefined
            }
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const DataSyncContent = ({
  isLoading,
  connectionAccounts,
  error,
  account,
  setAccount,
}: {
  isLoading: boolean;
  connectionAccounts: AccountDetails[];
  error?: unknown;
  account?: AccountDetails;
  setAccount: Dispatch<SetStateAction<AccountDetails | undefined>>;
}) => {
  const fullyDisconnected = connectionAccounts.every(
    ({ accountStatus }) => accountStatus === AccountStatus.DISCONNECTED,
  );
  const showDisconnected =
    (account && account.accountStatus === AccountStatus.DISCONNECTED) ||
    (!account && fullyDisconnected);

  // if there's only 1 account, just show the first one.
  useEffect(() => {
    if (!account && connectionAccounts.length === 1) {
      setAccount(connectionAccounts[0]);
    }
  }, [account, setAccount, connectionAccounts]);

  const allJobs = useMemo(() => {
    const all = connectionAccounts.flatMap((accountJobDetails) => {
      return Object.values(accountJobDetails.jobs);
    });

    // All jobs with no date are moved to the top of the list because they haven't been run yet.
    return all.sort((a, b): number => {
      if (!a.date) return -1;
      if (!b.date) return 1;
      return new Date(b?.date).valueOf() - new Date(a?.date).valueOf();
    });
  }, [connectionAccounts]);

  if (isLoading) {
    return (
      <Wrapper>
        <Loading message="We’re getting your job history ready." />
      </Wrapper>
    );
  }

  if (!connectionAccounts.length) {
    return (
      <Wrapper>
        <NoJobsScreen
          message={error instanceof Error ? error.message : undefined}
        />
      </Wrapper>
    );
  }

  return (
    <Flex
      gap="24px"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{ base: 'stretch', lg: 'flex-start' }}
    >
      <Flex direction="column" gap="24px">
        <AccountSelectionPanel
          connectionAccounts={connectionAccounts}
          selectedAccount={
            account?.accountId
              ? `${account?.accountId}-${account?.implementationKind}`
              : undefined
          }
          setAccount={setAccount}
        />
        {account && <AccountInfo account={account} />}
      </Flex>
      {showDisconnected && <Disconnected />}
      {!showDisconnected && (
        <DataSyncTabs
          focusAssistedTab={connectionAccounts.some(
            ({ implementationKind, accountStatus }) =>
              isAssistedImplementationKind(implementationKind) &&
              accountStatus !== AccountStatus.CONNECTED,
          )}
          isLoading={isLoading}
          allJobs={allJobs}
          account={account}
        />
      )}
    </Flex>
  );
};
