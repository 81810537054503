import { Stack } from '@chakra-ui/react';

import { useConnectSession } from '../../connections-v2/hooks/useConnectSession';
import { ConnectSession } from 'types/connect';
import { SessionDetailsDrawer } from './SessionDetailsDrawer';
import { useConnectSessionEvents } from '../hooks/useConnectSessionEvents';
import { ConnectSessionsTable } from './ConnectSessionsTable';
import { SessionHistoryTab } from '../constants';

export const SessionDetailsDrawerWithApi = ({
  connectSession,
  onClose,
  activeSessionEventsTab,
  setActiveSessionEventsTab,
}: {
  connectSession: ConnectSession | null;
  onClose: () => void;
  activeSessionEventsTab: SessionHistoryTab;
  setActiveSessionEventsTab: (tab: SessionHistoryTab) => void;
}) => {
  const { list } = useConnectSessionEvents({ sessionId: connectSession?.id });
  const sessionEvents = list.data ?? [];

  return (
    <SessionDetailsDrawer
      connectSession={connectSession}
      onClose={onClose}
      sessionEvents={sessionEvents}
      isLoading={list.isLoading}
      activeSessionEventsTab={activeSessionEventsTab}
      setActiveSessionEventsTab={setActiveSessionEventsTab}
    />
  );
};

export const ConnectSessions = ({
  selectedSession,
  setSelectedSession,
  activeSessionEventsTab,
  setActiveSessionEventsTab,
}: {
  selectedSession: ConnectSession | null;
  setSelectedSession: (connectSession: ConnectSession | null) => void;
  activeSessionEventsTab: SessionHistoryTab;
  setActiveSessionEventsTab: (tab: SessionHistoryTab) => void;
}) => {
  const { list } = useConnectSession();

  return (
    <Stack>
      <ConnectSessionsTable
        data={list.data}
        setSelectedSession={setSelectedSession}
      />

      {selectedSession && (
        <SessionDetailsDrawerWithApi
          connectSession={selectedSession}
          onClose={() => setSelectedSession(null)}
          activeSessionEventsTab={activeSessionEventsTab}
          setActiveSessionEventsTab={setActiveSessionEventsTab}
        />
      )}
    </Stack>
  );
};
