import { CSSProperties } from 'react';
import type { IconType } from 'react-icons';
import type { ThemeTypings } from '@chakra-ui/react';

type DoubleCircleIconProps = {
  IconType: IconType;
  colorScheme: ThemeTypings['colorSchemes'];
};

export const DoubleCircleIcon = ({
  IconType,
  colorScheme,
}: DoubleCircleIconProps) => {
  const outerStyle = {
    width: '48px',
    height: '48px',
    borderRadius: '28px',
    background: `var(--chakra-colors-${colorScheme}-100)`,
    border: `8px solid var(--chakra-colors-${colorScheme}-50)`,
    color: `var(--chakra-colors-${colorScheme}-600)`,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  };

  const innerStyle = {
    margin: 'auto',
    width: '20px',
    height: '20px',
  } as CSSProperties;

  return (
    <div style={outerStyle}>
      <IconType style={innerStyle} />
    </div>
  );
};
