import { Button } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';

export const WebhookActions = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      leftIcon={<FiPlus />}
      variant="primaryPurple"
      size="md"
      onClick={onClick}
    >
      Create Webhook
    </Button>
  );
};
