import { useQuery } from '@tanstack/react-query';

import { useConnectionDetailApi } from './useConnectionDetailApi';

export const useConnectionJobs = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const api = useConnectionDetailApi();

  const { isLoading, data = null } = useQuery({
    queryKey: ['connection-jobs', applicationId, connectionId],
    queryFn: () => api.jobs(applicationId, connectionId),
  });

  return { jobs: data || [], isLoading };
};
