import { ConnectionCardRow } from 'components/ConnectionCard';
import { Box, HStack, Text } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { ReactNode } from 'react';
import { ClipboardButton } from 'components/ClipboardButton';

export const TableDetailItem = ({
  data,
  withClipboard,
  title,
}: {
  data: ReactNode;
  title: string;
  withClipboard?: boolean;
}) => {
  return (
    <ConnectionCardRow px="0" py="4">
      <Text
        fontWeight={500}
        flex="2"
        fontSize="14px"
        color={COLORS.FINCH.BLACK}
        width="200px"
      >
        {title}
      </Text>

      <Box flex="5">
        {typeof data === 'string' ? (
          <HStack justify="space-between" align="flex-start">
            <Text
              flexBasis="80%"
              fontWeight={400}
              fontSize="14px"
              color={COLORS.FINCH.BLACK}
            >
              {data}
            </Text>
            {withClipboard && <ClipboardButton boxSize="20px" value={data} />}
          </HStack>
        ) : (
          data
        )}
      </Box>
    </ConnectionCardRow>
  );
};
