import type { OpenAPIV3 } from 'openapi-types';
import { Authorization } from './types';

export const parseAuthorization = (
  security?: OpenAPIV3.OperationObject['security'],
): Authorization | null => {
  if (!security) {
    return null;
  }

  const securityRequirements = security[0];

  if (!securityRequirements) {
    return 'none';
  }

  const [securityRequirement] = Object.keys(securityRequirements);

  if (securityRequirement === 'bearerAuth') {
    return 'bearerAuth';
  }

  if (securityRequirement === 'basicAuth') {
    return 'basicAuth';
  }

  return 'none';
};
