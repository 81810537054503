import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Center,
  Spinner,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import type {
  SortingState,
  CellContext,
  PaginationState,
} from '@tanstack/react-table';
import { COLORS } from '../../constant';
import { WebhookEndpoint } from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { Caption } from '../../shared/Typography';
import { format } from '../../shared/utils/dateFormatter';

import { ContentBox } from '../../components/ContentBox';
import { WebhookEndpointStatusTag } from '../../components/Tag/WebhookEndpointStatusTag';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Pagination } from '../../components/Pagination';
import { filterFns } from '../../components/TableFilters';
import { TableHeaderContent } from '../../components/TableHeaderContent';

export const WebhooksTable = ({
  webhookEndpoints,
  onWebhookViewRequested,
  fetchMore,
  isFetching,
}: {
  webhookEndpoints: WebhookEndpoint[];
  onWebhookViewRequested: (endpoint: WebhookEndpoint) => void;
  fetchMore: () => void;
  isFetching: boolean;
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = [
    {
      accessorKey: 'url',
      cell: (props: CellContext<WebhookEndpoint, unknown>) => {
        return props.getValue();
      },
      header: 'Webhook',
    },
    {
      accessorKey: 'enabled',
      cell: (props: CellContext<WebhookEndpoint, boolean>) => {
        return (
          <div style={{ minWidth: '100px' }}>
            <WebhookEndpointStatusTag
              variant={props.getValue() ? 'enabled' : 'disabled'}
            />
          </div>
        );
      },
      header: 'Status',
    },
    {
      accessorKey: 'createdAt',
      header: 'Date Created',
      cell: (props: CellContext<WebhookEndpoint, string>) => {
        return (
          <Box isTruncated>
            <Caption>{format(new Date(props.getValue()))}</Caption>
          </Box>
        );
      },
    },
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: webhookEndpoints,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),

    filterFns: filterFns,

    autoResetPageIndex: false,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),

    state: {
      pagination,
      sorting,
    },
  });

  const pageCount = table.getPageCount();

  const fetchMoreRef = useRef(fetchMore);

  useEffect(() => {
    fetchMoreRef.current = fetchMore;
  }, [fetchMore]);

  useEffect(() => {
    if (webhookEndpoints.length && pageIndex + 1 === pageCount) {
      // We store this in a ref so that this hook does not re-run
      // when the original fetchMore function changes
      fetchMoreRef.current();
    }
  }, [pageCount, pageIndex, webhookEndpoints.length]);

  return (
    <>
      <ContentBox>
        <TableContainer border="none">
          <Table
            variant="unstyled"
            sx={{
              'td:first-of-type': {
                width: '100%',
              },
            }}
          >
            <TableHeaderContent headerGroups={table.getHeaderGroups()} />
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => onWebhookViewRequested(row.original)}
                  borderTop={'1px solid #D9D9D9'}
                  fontSize={'14px'}
                  style={{ cursor: 'pointer' }}
                  _hover={{
                    backgroundColor: COLORS.GRAY.GRAY_100,
                  }}
                  cursor={'pointer'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </ContentBox>
      <Center></Center>
      <Box
        paddingTop={2}
        display="flex"
        justifyContent={'flex-end'}
        alignItems={'center'}
        gap={16}
      >
        {isFetching && <Spinner />}
        <Pagination
          hidePageCount
          canPreviousPage={table.getCanPreviousPage()}
          previousPage={table.previousPage}
          canNextPage={table.getCanNextPage()}
          nextPage={table.nextPage}
          pageIndex={0}
          pageSize={0}
          totalSize={0}
        />
      </Box>
    </>
  );
};
