// feature flagging - remove post-release
import { Tooltip } from '@chakra-ui/react';

import { AggregatedConnectionStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import {
  DisconnectedIcon,
  NeedsAttentionIcon,
  PendingIcon,
} from '../../components/Iconagraphy';

type StatusDetails = {
  icon?: () => JSX.Element;
  tooltipText?: string;
};

const connectionStatusMap = (
  status: AggregatedConnectionStatus,
): StatusDetails => {
  switch (status) {
    case AggregatedConnectionStatus.DISCONNECTED:
      return {
        icon: DisconnectedIcon,
        tooltipText: 'Disconnected',
      };
    case AggregatedConnectionStatus.PENDING:
      return {
        icon: PendingIcon,
        tooltipText: 'Pending',
      };
    case AggregatedConnectionStatus.INSUFFICIENT_PERMISSIONS:
    case AggregatedConnectionStatus.NO_ACCOUNT_SET_UP:
    case AggregatedConnectionStatus.REAUTHORIZATION_NEEDED:
    case AggregatedConnectionStatus.NEEDS_ATTENTION:
      return {
        icon: NeedsAttentionIcon,
        tooltipText: 'This company has multiple accounts that need attention',
      };
    case AggregatedConnectionStatus.CONNECTED:
    default:
      return {};
  }
};

type ConnectionStatusIconProps = {
  status: AggregatedConnectionStatus;
};

export const ConnectionStatusIcon = ({
  status,
}: ConnectionStatusIconProps): JSX.Element => {
  const { icon, tooltipText } = connectionStatusMap(status);

  if (icon) {
    return (
      <Tooltip placement="top" label={tooltipText}>
        <span>{icon()}</span>
      </Tooltip>
    );
  }
  return <></>;
};
// end
