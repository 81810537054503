export class NotFoundError extends Error {
  public readonly code = 404;

  constructor(
    public readonly message: string = 'Not Found',
    public readonly previous?: Error,
  ) {
    super(message);
  }
}
