import { ReactNode } from 'react';
import { type BoxProps } from '@chakra-ui/react';
import { ContentBox } from '../../components/ContentBox';

export type SideNavigationProps = BoxProps & {
  children: ReactNode;
};

export const SideNavigation = ({ children, ...props }: SideNavigationProps) => {
  return (
    <ContentBox w={{ base: 'full', md: '275px' }} {...props}>
      {children}
    </ContentBox>
  );
};
