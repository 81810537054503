import { ApplicationStatus } from '@finch-api/developer-dashboard-common';
import { useApplication } from '../applications/hooks';
import { useUserRole } from '../auth/use-user-role';
import { Permission, ROLES } from '../constant/roles-and-permissions';

export const usePermissions = (match: Array<Permission>) => {
  const { application } = useApplication();
  const { role } = useUserRole();

  const permissions = ROLES.find((r) => r.value === role)?.permissions;

  if (!permissions) {
    return false;
  }

  const requiredPermissions = match.map((permission) => {
    if (application?.status === ApplicationStatus.LIVE) {
      return permissions.prod.includes(permission);
    }

    return permissions.nonProd.includes(permission);
  });

  return requiredPermissions.every(Boolean);
};
