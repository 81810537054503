import { COLORS, SHADOWS } from '../constant';

import { Box, BoxProps, Flex, Stack, StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const ConnectionCard = ({
  children,
  ...props
}: BoxProps & { children: ReactNode }) => {
  return (
    <Box
      borderRadius="8px"
      bg={COLORS.WHITE}
      boxShadow={SHADOWS.CARD}
      {...props}
    >
      {children}
    </Box>
  );
};

export const ConnectionCardHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      borderBottom="1px solid"
      borderColor={COLORS.GRAY.GRAY_400}
      height="48px"
      width="100%"
      borderTopRadius="8px"
    >
      <Flex
        justify="space-between"
        width="100%"
        height="100%"
        align="center"
        paddingLeft="24px"
        paddingRight="20px"
      >
        {children}
      </Flex>
    </Box>
  );
};

export const ConnectionCardBody = ({
  children,
  ...props
}: StackProps & {
  children: ReactNode;
}) => {
  return (
    <Stack
      alignItems="center"
      paddingTop="16px"
      paddingBottom="24px"
      {...props}
    >
      {children}
    </Stack>
  );
};

export const ConnectionCardRow = ({
  children,
  ...props
}: StackProps & { children: ReactNode }) => {
  return (
    <Stack
      width="100%"
      paddingLeft="24px"
      paddingRight="24px"
      align="flex-start"
      direction={{ base: 'column', sm: 'row' }}
      {...props}
    >
      {children}
    </Stack>
  );
};
