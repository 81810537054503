import { create } from 'zustand';

import { Subgroup } from '../types';

export const useSelectedSubgroupStore = create<{
  selectedSubgroup: Subgroup;
  setSelectedSubgroup: (group: Subgroup) => void;
}>((set) => ({
  selectedSubgroup: 'operations',
  setSelectedSubgroup: (selectedTab) => set({ selectedSubgroup: selectedTab }),
}));
