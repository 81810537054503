import { Box, Stack } from '@chakra-ui/react';
import { ConnectionAccountDetail, JobEntry } from '../types';

import { ConnectionStatusCard } from './ConnectionStatusCard';
import { ConnectionInformationCard } from './ConnectionInformationCard';
import { DataSyncInformationCard } from './DataSyncInformationCard';
import { ProviderInformationCard } from './ProviderInformationCard';
import { LastUsedTokensCard } from './LastUsedTokensCard';

export const ConnectionOverviewPanel = ({
  connectionDetail,
  jobs,
}: {
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
}) => {
  return (
    <Box height="100%" width="100%">
      <Stack spacing="24px" direction={{ base: 'column', lg: 'row' }}>
        <Stack direction="column" spacing="24px" flex={1}>
          <ConnectionStatusCard connectionDetail={connectionDetail} />
          <DataSyncInformationCard
            connectionDetail={connectionDetail}
            jobs={jobs}
          />
        </Stack>
        <Stack direction="column" spacing="24px" flex={1}>
          <ConnectionInformationCard connectionDetail={connectionDetail} />
          {connectionDetail &&
            connectionDetail.lastRedactedTokensUsed?.length > 0 && (
              <LastUsedTokensCard connectionDetail={connectionDetail} />
            )}
          <ProviderInformationCard connectionDetail={connectionDetail} />
        </Stack>
      </Stack>
    </Box>
  );
};
