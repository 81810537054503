import {
  Button,
  ModalHeader,
  ModalBody,
  Heading,
  ModalFooter,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FormProvider, UseFormReturn } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { UseMutateFunction } from '@tanstack/react-query';
import {
  CreateConnectSessionError,
  CreateConnectSessionResponse,
  NewSession,
  ReauthSession,
} from 'connections-v2/hooks/useConnectSession';
import { useState } from 'react';
import { RequiredSection } from './RequiredSection';
import { OptionalSection } from './OptionalSection';
import { useProviderConfigurations } from 'integrations/hooks/useProviderConfigurations';

export const CreateSessionForm = ({
  isPending,
  mutate,
  initialRef,
  form,
}: {
  mutate: UseMutateFunction<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession,
    unknown
  >;
  isPending: boolean;
  initialRef?: React.RefObject<HTMLInputElement>;
  form: UseFormReturn<NewSession>;
}) => {
  const [formSection, setFormSection] = useState<'required' | 'optional'>(
    'required',
  );

  const { automatedProviders, isLoading, allProviders } =
    useProviderConfigurations();

  const { handleSubmit, formState } = form;

  return (
    <FormProvider {...form}>
      <ModalHeader fontSize="20px" fontWeight="500" pt="32px">
        <Heading as="h1" size="md" fontWeight="medium">
          Create a new Finch Connect Session{' '}
          {formSection === 'optional' && '(optional parameters)'}
        </Heading>
      </ModalHeader>
      <ModalBody zIndex={1}>
        {formSection === 'required' && (
          <RequiredSection initialRef={initialRef} form={form} />
        )}
        {formSection === 'optional' && (
          <OptionalSection
            allProviders={allProviders}
            automatedProviders={automatedProviders}
            isLoading={isLoading}
            form={form}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          w="full"
          variant="primary"
          isDisabled={!formState.isValid}
          isLoading={isPending}
          fontSize="16px"
          onClick={
            formSection === 'required'
              ? () => setFormSection('optional')
              : handleSubmit((values) => mutate(values))
          }
          h="52px"
        >
          {formSection === 'required' ? 'Next Step ->' : 'Generate URL ->'}
        </Button>
      </ModalFooter>
      {/* <DevTool control={form.control} /> */}
    </FormProvider>
  );
};
