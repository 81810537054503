import { useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FiAlertTriangle } from 'react-icons/fi';

import { DoubleCircleIcon } from '../../shared/DoubleCircleIcon';
import { AlertBodyText, AlertHeadingText } from '../../shared/Typography';
import { bodyFont } from '../../shared/theme';

const confirmationColor = '#344054';

type InputConfirmationOpts = {
  instructions?: React.ReactNode;
  text: string;
};

type DestructiveButtonOpts = {
  text?: string;
  action: () => Promise<void> | void;
};

const InputConfirmation = ({
  inputConfirmation,
  onConfirmationChanged,
}: {
  inputConfirmation?: InputConfirmationOpts;
  onConfirmationChanged: (isConfirmed: boolean) => void;
}) => {
  if (!inputConfirmation) return <></>;

  const textProps = {
    fontFamily: bodyFont,
    fontSize: '14px',
    color: confirmationColor,
    mt: 2,
    fontWeight: 500,
  };

  const instructions = inputConfirmation.instructions ? (
    <Text {...textProps}>{inputConfirmation.instructions}</Text>
  ) : (
    <Text {...textProps}>
      Please type{' '}
      <Text as="span" fontWeight="bold">
        {inputConfirmation.text}
      </Text>{' '}
      to confirm:
    </Text>
  );

  const handleInputConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => onConfirmationChanged(event.target.value === inputConfirmation?.text);

  return (
    <Box mt="24px">
      {instructions}
      <Input mt="6px" onChange={handleInputConfirmationChange} />
    </Box>
  );
};

export type InputConfirmationDialogProps = {
  header?: {
    showIcon?: boolean; // TODO: consider making this a color/icon tuple
    text?: React.ReactNode;
  };
  bodyText: React.ReactNode;
  cancelButtonText?: string;
  isOpen: boolean;
  onClose: () => void;
  destructiveButton: JSX.Element | DestructiveButtonOpts;
  inputConfirmation?: InputConfirmationOpts;
};

export const InputConfirmationDialog = (
  props: InputConfirmationDialogProps,
) => {
  const [destructiveButtonDisabled, setDestructiveButtonDisabled] =
    useState<boolean>(!!props.inputConfirmation);
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  const handleConfirmationChanged = (isConfirmed: boolean) =>
    setDestructiveButtonDisabled(!isConfirmed);

  let destructiveButton: JSX.Element;
  const isJSX = !('action' in props.destructiveButton);
  if (isJSX) {
    destructiveButton = props.destructiveButton as JSX.Element;
  } else {
    const opts = props.destructiveButton as DestructiveButtonOpts;
    destructiveButton = (
      <Button
        variant="danger"
        onClick={opts.action}
        isDisabled={destructiveButtonDisabled}
      >
        {opts.text ?? "I'm Sure"}
      </Button>
    );
  }

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={props.onClose}
      size="lg"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <Flex alignItems="center">
              {props.header?.showIcon && (
                <Box mr={2}>
                  <DoubleCircleIcon
                    colorScheme="orange"
                    IconType={FiAlertTriangle}
                  />
                </Box>
              )}
              <AlertHeadingText>
                {props.header?.text ?? 'Are you sure you want to continue?'}
              </AlertHeadingText>
            </Flex>
          </AlertDialogHeader>
          <AlertDialogBody py={0}>
            <AlertBodyText>{props.bodyText}</AlertBodyText>
            <InputConfirmation
              inputConfirmation={props.inputConfirmation}
              onConfirmationChanged={handleConfirmationChanged}
            />
          </AlertDialogBody>
          <AlertDialogFooter p="24px">
            <VStack align="stretch" w="full">
              {destructiveButton}
              <Button
                ref={leastDestructiveRef}
                onClick={props.onClose}
                variant="secondary"
              >
                {props.cancelButtonText ?? 'Cancel'}
              </Button>
            </VStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
