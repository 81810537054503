const lowerCase = /[a-z]/;
const upperCase = /[A-Z]/;
const number = /[0-9]/;
const specialChars = /[`~!@#$%^&*()\-_=+,.<>/?;:'"[\]{}\\|]/;

const totalCharTypes = 4;
const numMinRequiredCharTypes = 3;

/**
 * Attempts to validate the password against the Auth0 instance configured
 * rules. Does not handle length validation - just character composition types.
 * @param password the password value being validated
 * @returns
 */
export const passwordValidation = (password?: string): string | undefined => {
  if (password === undefined) return;

  const missing = [];
  if (!lowerCase.test(password)) {
    missing.push('lower case (a-z)');
  }
  if (!upperCase.test(password)) {
    missing.push('upper case (A-Z)');
  }
  if (!number.test(password)) {
    missing.push('numbers (0-9)');
  }
  if (!specialChars.test(password)) {
    missing.push('special characters (e.g. !@#$%^&*)');
  }

  const numPresentCharValidations = totalCharTypes - missing.length;

  if (numPresentCharValidations >= numMinRequiredCharTypes) {
    return; // we had 3/4 of 4 types of characters
  }

  const numAdditionalCharTypesNeeded =
    numMinRequiredCharTypes - numPresentCharValidations;

  return `Password should contain at least ${numAdditionalCharTypesNeeded} of the following ${
    missing.length
  } types of characters: ${missing.join(', ')}`;
};
