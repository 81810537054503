import styled from '@emotion/styled';

import { InfoBox } from '../../components/InfoBox/InfoBox';
import retry from '../../assets/images/icons/retry.png';

const Icon = styled.img`
  width: 60px;
`;

export const NoJobsScreen = ({
  message = 'Your job history will eventually show up here.',
}) => {
  return (
    <InfoBox header="There’s nothing here just yet." message={message}>
      <Icon src={retry} alt="" />
    </InfoBox>
  );
};
