export const DisconnectedIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.25" stroke="currentColor" strokeWidth="1.5" />
    <rect x="4" y="7.25" width="8" height="1.5" fill="currentColor" />
  </svg>
);
