import { Center } from '@chakra-ui/react';
import { Caption } from '../../../shared/Typography';

export const EmptyStateMessage = () => {
  return (
    <Center height="300px" border="1px" borderColor="border.gray">
      <Caption>You have no team members.</Caption>
    </Center>
  );
};
