import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { InfoBox } from '../InfoBox/InfoBox';

import loading from '../../assets/images/icons/loading.svg';

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const LoadingIcon = styled.img`
  width: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const Loading = ({
  message,
  omitBorder,
}: {
  message: string;
  omitBorder?: boolean;
}) => {
  return (
    <InfoBox header="Hang tight!" message={message} omitBorder={omitBorder}>
      <LoadingIcon src={loading} alt="" />
    </InfoBox>
  );
};
