import { Center, Spinner } from '@chakra-ui/react';
import { Caption } from '../shared/Typography';

export const EmptyTableRowStateMessage = () => {
  return (
    <Center height="400px" border="1px" borderColor="border.gray">
      <Caption>No results found.</Caption>
    </Center>
  );
};

export const LoadingState = () => {
  return (
    <Center height="400px" border="1px" borderColor="border.gray">
      <Spinner />
    </Center>
  );
};
