import { Box, Center, Heading, Image, Stack } from '@chakra-ui/react';
import { Caption } from '../../shared/Typography';
import pinSvg from '../../assets/images/icons/pinned.svg';
import newSvg from '../../assets/images/icons/new.svg';
import { ContentBox } from '../../components/ContentBox';
import { useSelectedTabStore } from '../state/selectedTab';
import { Tab } from '../types';

const emptyStateContent: Partial<
  Record<Tab, { heading: string; icon: string; caption: string }>
> = {
  New: {
    heading: 'No New Integrations',
    icon: newSvg,
    caption: 'New integrations in the last 30 days will show up here',
  },
  Pinned: {
    heading: 'No Pinned Integrations',
    icon: pinSvg,
    caption: 'Pinning integrations makes them show up first in Finch Connect',
  },
  Settings: {
    heading: 'No Settings',
    icon: newSvg,
    caption: 'No settings found',
  },
};

export const EmptyState = () => {
  const selectedTab = useSelectedTabStore((store) => store.selectedTab);
  const { heading, icon, caption } = emptyStateContent[selectedTab] || {
    icon: newSvg,
    heading: 'No Integrations',
    caption: 'No integrations found',
  };

  return (
    <ContentBox borderTopRadius="0">
      <Center py="64px">
        <Stack align="center" spacing="24px">
          <Box p="8px" bg="primary.50" borderRadius="50%">
            <Image src={icon} />
          </Box>
          <Stack align="center">
            <Heading size="sm">{heading}</Heading>
            <Caption>{caption}</Caption>
          </Stack>
        </Stack>
      </Center>
    </ContentBox>
  );
};
