import {
  Box,
  HStack,
  Input,
  SimpleGrid,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { RequestOption } from './request-option';
import { COLORS } from 'constant';
import { EntityTypeLabel } from './request';
import { Parameter } from '../open-api';
import { AltInfoIcon } from 'shared/icons/AltInfoIcon';

export const ParameterEntry = ({
  entries,
  entityType,
  formPath,
  register,
  disableInputs,
}: {
  entries: Parameter[];
  entityType: 'headers' | 'paths' | 'queries';
  formPath: string;
  register: UseFormRegister<FieldValues>;
  disableInputs?: boolean;
}) => {
  return (
    <RequestOption title={EntityTypeLabel[entityType]}>
      <SimpleGrid
        columns={2}
        alignItems="center"
        gap="1"
        rowGap="16px"
        templateColumns="2fr 3fr"
      >
        {entries.map((entry) => {
          const [[key, entryValue] = []] = Object.entries(entry);

          return (
            <Fragment key={key}>
              <HStack>
                <Text fontSize="13px" fontWeight="500">
                  {key}
                </Text>
                {entryValue?.description && (
                  <Tooltip label={entryValue.description}>
                    <Box>
                      <AltInfoIcon />
                    </Box>
                  </Tooltip>
                )}
              </HStack>
              <Input
                fontSize="14px"
                size="sm"
                borderRadius="8px"
                disabled={disableInputs}
                _disabled={{ bg: COLORS.GRAY.GRAY_200, cursor: 'not-allowed' }}
                {...register(`${formPath}.${entityType}.${key}`)}
                defaultValue={entryValue?.value}
                key={`${formPath}.${entityType}.${key}`}
              />
            </Fragment>
          );
        })}
      </SimpleGrid>
    </RequestOption>
  );
};
