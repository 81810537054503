import { useEffect, useMemo, useState } from 'react';
import { Box, HStack, Skeleton } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { useJobStatusData } from './DataSyncProvider';
import { DataSyncContent } from './DataSyncContent';
import { Code } from '../../components/Code/Code';
import { Banner } from '../../components/Banner/Banner';
import { COLORS } from '../../constant';
import warning_triangle from '../../assets/images/icons/warning_triangle.svg';
import back_arrow from '../../assets/images/icons/back_arrow.svg';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { useHistory, useParams } from 'react-router-dom';
import { AccountDetails } from '../../connections/types';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

const TextBlock = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: ${COLORS.FINCH.BLACK};
`;

const CodeBlock = styled(Code)`
  color: ${COLORS.GRAY.GRAY_600};
`;

const GoBackButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.FINCH.PURPLE};
  margin-bottom: 40px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const wouldAccountRequireReAuth = (accountStatus: AccountStatus) => {
  return accountStatus === AccountStatus.REAUTH;
};

export const DataSyncScreen = ({ companyId }: { companyId: string }) => {
  const { isLoading, connectionAccounts, error, companyName } =
    useJobStatusData();

  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const [requiresReAuth, setRequiresReAuth] = useState(false);

  useMemo(() => {
    const atLeastOneAccountNeedsReauth =
      !!connectionAccounts.length &&
      connectionAccounts.some(({ accountStatus }) =>
        wouldAccountRequireReAuth(accountStatus),
      );

    setRequiresReAuth(atLeastOneAccountNeedsReauth);
  }, [connectionAccounts]);

  const history = useHistory();

  const onClickGoBack = () => {
    if (history.location.state?.navigatedFromConnectionsTable) {
      history.goBack();
    } else {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  };

  const [account, setAccount] = useState<AccountDetails>();

  // if there's only 1 account, just show the first one.
  useEffect(() => {
    if (!account && connectionAccounts.length === 1) {
      setAccount(connectionAccounts[0]);
    }
  }, [account, setAccount, connectionAccounts]);

  return (
    <>
      {requiresReAuth && (
        <Banner
          type="warning"
          icon={warning_triangle}
          content="One or more jobs require reauth"
          fullWidth
        />
      )}
      <GoBackButton onClick={onClickGoBack}>
        <img src={back_arrow} alt="back button" />
        Back to Connections
      </GoBackButton>
      <Box>
        <HStack gap={6}>
          <Header>
            {isLoading ? (
              <Skeleton height="29px" width="256px" rounded="lg" />
            ) : (
              <TextBlock>{companyName}</TextBlock>
            )}
            <CodeBlock>{companyId}</CodeBlock>
          </Header>
        </HStack>
        <DataSyncContent
          account={account}
          setAccount={setAccount}
          isLoading={isLoading}
          connectionAccounts={connectionAccounts}
          error={error}
        />
      </Box>
    </>
  );
};
