import { useCallback, useState } from 'react';
import { Stack } from '@chakra-ui/react';

import { ContentBox } from '../../../components/ContentBox';
import { FormInput } from '../../../components/FormInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSftpCredentials } from './use-sftp-credentials';
import { FORM_ENTRIES } from './constants';
import { Header } from './Header';
import { SftpCredentialsUpdate, sftpCredentialsUpdateSchema } from './types';
import { getDirtyValues } from './utils';

export const SftpCredentialsForm = ({
  formRef,
}: {
  formRef?: React.RefObject<HTMLFormElement>;
}) => {
  const { get, save } = useSftpCredentials();

  const { register, handleSubmit, formState, reset, setError } =
    useForm<SftpCredentialsUpdate>({
      resolver: zodResolver(sftpCredentialsUpdateSchema),
      ...(get.data
        ? { values: get.data }
        : {
            defaultValues: {
              host: '',
              notificationEmail: '',
              rootPath: '',
              username: '',
            },
          }),
    });

  const [editMode, setEditMode] = useState(false);

  const onSubmit = useCallback(
    (values: SftpCredentialsUpdate) => {
      const payload = getDirtyValues<SftpCredentialsUpdate>(
        formState.dirtyFields,
        values,
      );

      // If we don't have a credential ID, we need to ensure a password is provided.
      if (!get.data?.id && !payload.password) {
        setError('password', { message: 'SFTP server password is required' });
        return;
      }

      save.mutate(
        { payload, credentialId: get.data?.id },
        { onSuccess: () => setEditMode(false) },
      );
    },
    [formState.dirtyFields, get.data, save, setError],
  );

  return (
    <ContentBox>
      <Stack
        as="form"
        aria-labelledby="form-title"
        onSubmit={handleSubmit(onSubmit)}
        p="6"
        spacing="8"
        ref={formRef}
      >
        <Header
          editMode={editMode}
          setEditMode={setEditMode}
          isLoading={save.isPending || get.isPending}
          clearStagedChanges={reset}
        />
        {FORM_ENTRIES.map((options) => (
          <FormInput
            {...options}
            key={options.id}
            register={register}
            readOnly={!editMode}
            error={formState.errors[options.id]?.message}
          />
        ))}
      </Stack>
    </ContentBox>
  );
};
