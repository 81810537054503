import { IconButton, Flex } from '@chakra-ui/react';
import {
  FiArrowLeft as ArrowLeft,
  FiArrowRight as ArrowRight,
} from 'react-icons/fi';

export const WebhookPagination = ({
  prevCursor,
  nextCursor,
  onCursorChange,
}: {
  prevCursor: string | null;
  nextCursor: string | null;
  onCursorChange: (cursor: string | undefined) => void;
}) => {
  const onPrevPage = () => {
    onCursorChange(prevCursor ?? undefined);
  };

  const onNextPage = () => {
    onCursorChange(nextCursor ?? undefined);
  };

  return (
    <Flex justifyContent={'flex-end'} alignItems="center" gap={2}>
      <Flex gap={1}>
        <IconButton
          icon={<ArrowLeft />}
          variant="icon"
          aria-label="Previous page"
          onClick={onPrevPage}
          isDisabled={!prevCursor}
        />
        <IconButton
          variant="icon"
          icon={<ArrowRight />}
          aria-label="Next page"
          onClick={onNextPage}
          isDisabled={!nextCursor}
        />
      </Flex>
    </Flex>
  );
};
