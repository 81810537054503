import { Box } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';
import { SHADOWS } from '../constant';
import { LegacyRef } from 'react';

export const ContentBox: React.FC<
  BoxProps & { passedRef?: LegacyRef<HTMLDivElement> }
> = ({ children, passedRef, ...props }) => {
  return (
    <Box
      border="1px"
      borderColor="border.gray"
      ref={passedRef}
      boxShadow={SHADOWS.PAGE}
      borderRadius="8px"
      backgroundColor={'white'}
      {...props}
    >
      {children}
    </Box>
  );
};
