import {
  useToast,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  Category,
  ImplementationKind,
} from '@finch-api/common/dist/internal/connect/authorize';
import { useFinchConnect } from '@tryfinch/react-connect';
import { useParams } from 'react-router-dom';
import { useApplication } from '../../applications/hooks';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';
import { formatProvidersForConnect } from '../utils';
import { config } from '../../utils/baseUrls';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { COLORS } from '../../constant';
import { AutomatedIcon } from '../../shared/icons/Automated';
import { AssistedIcon } from '../../shared/icons/Assisted';
import { ProviderWithImplementation } from '../types';

const DEFAULT_SCOPES = [
  'company',
  'directory',
  'individual',
  'employment',
  'payment',
  'pay-statement',
];

export const PreviewConnect = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const toast = useToast();
  const { application } = useApplication(applicationId);
  const { unfilteredProviders, unfilteredPinnedProviders } =
    useProviderConfigurations();

  const onSuccess = () => {
    toast({
      status: 'success',
      description: 'Successfully connected to the selected provider',
    });
  };
  const onError = ({ errorMessage }: { errorMessage: string }) =>
    toast({ status: 'error', description: errorMessage });
  const onClose = () => {};

  const { open } = useFinchConnect({
    clientId: applicationId,
    products: application?.scopeNames || DEFAULT_SCOPES,
    manual: false,
    category: Category.HRIS,
    onSuccess,
    onError,
    onClose,
    apiConfig: {
      connectUrl: process.env.REACT_APP_CONNECT_URI || '',
      redirectUrl: process.env.REACT_APP_REDIRECT_URL || '',
    },
  });

  const openAndPostMessage = (
    providersToPreview: ProviderWithImplementation[],
  ) => {
    const connectProviderConfigs = formatProvidersForConnect(
      providersToPreview,
      unfilteredPinnedProviders,
    );

    open();

    const iframeElement = window?.document?.querySelector(
      '#finch-connect-iframe',
    ) as Element & {
      contentWindow: Window;
    };

    iframeElement?.addEventListener(
      'load',
      () => {
        iframeElement.contentWindow.postMessage(
          {
            type: 'stagedConfig',
            content: connectProviderConfigs,
          },
          config.connectBaseUrl,
        );
      },
      { once: true },
    );
  };

  const openConnectForAutomatedProviders = () => {
    const automated = unfilteredProviders.filter((provider) =>
      provider.implementations.some(
        (implementation) => implementation.kind !== ImplementationKind.ASSISTED,
      ),
    );
    openAndPostMessage(automated);
  };

  const openConnectForAllProviders = () => {
    openAndPostMessage(unfilteredProviders);
  };

  return (
    <Menu autoSelect={false}>
      <MenuButton
        isDisabled={isDisabled}
        as={Button}
        aria-label="Preview in Connect"
        rightIcon={<ChevronDownIcon />}
        variant="blackText"
        size="md"
      >
        Preview in Connect
      </MenuButton>
      <MenuList borderRadius={'8px'} padding="1px 1px 1px 1px">
        <MenuItem
          _hover={{
            backgroundColor: COLORS.GRAY.GRAY_200,
          }}
          onClick={openConnectForAutomatedProviders}
        >
          <Flex gap={2} align="center">
            <AutomatedIcon color={COLORS.GRAY.GRAY_600} />
            <Text fontSize={'14px'} color={COLORS.GRAY.GRAY_600}>
              Automated Integrations only
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem
          _hover={{
            backgroundColor: COLORS.GRAY.GRAY_200,
          }}
          onClick={openConnectForAllProviders}
        >
          <Flex gap={2} align="center">
            <AssistedIcon color={COLORS.FINCH.BLACK} />
            <Text fontSize={'14px'} color={COLORS.GRAY.GRAY_600}>
              Automated and Assisted Integrations
            </Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
