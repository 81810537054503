export const OptionsIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.49558 11.5C5.08186 11.5 4.72917 11.3527 4.4375 11.0581C4.14583 10.7635 4 10.4093 4 9.99558C4 9.58186 4.14731 9.22917 4.44192 8.9375C4.73654 8.64583 5.09071 8.5 5.50442 8.5C5.91814 8.5 6.27083 8.64731 6.5625 8.94192C6.85417 9.23654 7 9.59071 7 10.0044C7 10.4181 6.85269 10.7708 6.55808 11.0625C6.26346 11.3542 5.90929 11.5 5.49558 11.5ZM9.99558 11.5C9.58186 11.5 9.22917 11.3527 8.9375 11.0581C8.64583 10.7635 8.5 10.4093 8.5 9.99558C8.5 9.58186 8.64731 9.22917 8.94192 8.9375C9.23654 8.64583 9.59071 8.5 10.0044 8.5C10.4181 8.5 10.7708 8.64731 11.0625 8.94192C11.3542 9.23654 11.5 9.59071 11.5 10.0044C11.5 10.4181 11.3527 10.7708 11.0581 11.0625C10.7635 11.3542 10.4093 11.5 9.99558 11.5ZM14.4956 11.5C14.0819 11.5 13.7292 11.3527 13.4375 11.0581C13.1458 10.7635 13 10.4093 13 9.99558C13 9.58186 13.1473 9.22917 13.4419 8.9375C13.7365 8.64583 14.0907 8.5 14.5044 8.5C14.9181 8.5 15.2708 8.64731 15.5625 8.94192C15.8542 9.23654 16 9.59071 16 10.0044C16 10.4181 15.8527 10.7708 15.5581 11.0625C15.2635 11.3542 14.9093 11.5 14.4956 11.5Z"
      fill="#737373"
    />
  </svg>
);
