import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Stack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ConnectionDetailContent } from './ConnectionDetailContent';
import { COLORS } from 'constant';
import back_arrow from 'assets/images/icons/back_arrow.svg';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { useHistory } from 'react-router-dom';
import { useConnectionDetails } from '../hooks/useConnectionDetail';
import { useConnectionJobs } from '../hooks/useConnectionJobs';
import { ConnectionDetailActionMenu } from './ConnectionDetailActionMenu';
import { Loading } from 'components/Loading/Loading';
import { useEffect, useState } from 'react';
import { ConnectionDetailHeader } from './ConnectionDetailHeader';
import { ConnectionDetailTab, connectionDetailTabs } from '../constants';
import { hasSeenTab, isTabNew, markTabAsViewed, tabStorageKey } from '../utils';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlag } from '../../constant/feature-flags';
import { useConnectionPayStatementItem } from '../hooks/useConnectionPayStatementItem';
import { useConnectionPayStatementItemLabels } from '../hooks/useConnectionPayStatementItemLabels';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GoBackButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.FINCH.PURPLE};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const wouldAccountRequireReAuth = (accountStatus: AccountStatus) => {
  return accountStatus === AccountStatus.REAUTH;
};

export const ConnectionDetailScreen = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const {
    connectionDetail,
    isLoading: isConnectionDetailLoading,
    error: connectionDetailError,
  } = useConnectionDetails({
    applicationId,
    connectionId,
  });

  const { jobs, isLoading: isJobsLoading } = useConnectionJobs({
    applicationId,
    connectionId,
  });

  const { payStatementItem, isLoading: isPayStatementItemLoading } =
    useConnectionPayStatementItem({
      applicationId,
      connectionId,
    });

  const { payStatementItemLabels, isLoading: isPayStatementItemLabelsLoading } =
    useConnectionPayStatementItemLabels({
      applicationId,
      connectionId,
    });

  const isLoading =
    isConnectionDetailLoading ||
    isJobsLoading ||
    isPayStatementItemLoading ||
    isPayStatementItemLabelsLoading;

  const history = useHistory();

  useEffect(() => {
    if (connectionDetailError?.response?.data?.name === 'not_found_error') {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  }, [connectionDetailError, applicationId, history]);

  const onClickGoBack = () => {
    if (history.location.state?.navigatedFromConnectionsTable) {
      history.goBack();
    } else {
      history.push(`/app/applications/${applicationId}/connections`);
    }
  };
  const [selectedTab, _setSelectedTab] = useState(ConnectionDetailTab.OVERVIEW);
  const setSelectedTab = (index: ConnectionDetailTab) => {
    _setSelectedTab(index);

    if (isTabNew(index)) {
      markTabAsViewed(index);
    }
  };
  const isPayStatementMappingEnabled = useFlag(FeatureFlag.PayStatementMapping);
  const showPayStatementMappingBanner =
    isPayStatementMappingEnabled &&
    selectedTab !== ConnectionDetailTab.PAY_STATEMENT_MAPPING &&
    !hasSeenTab(ConnectionDetailTab.PAY_STATEMENT_MAPPING);

  return (
    <>
      <HStack
        gap={6}
        justify="space-between"
        align="center"
        wrap="wrap"
        mb="24px"
        minH="40px"
      >
        <GoBackButton onClick={onClickGoBack}>
          <img src={back_arrow} alt="back button" />
          Back to{' '}
          {history.location.state?.isDisconnected
            ? 'Disconnected'
            : 'Live'}{' '}
          Connections
        </GoBackButton>
        {!isLoading &&
          connectionDetail &&
          connectionDetail.connectionStatus !== AccountStatus.DISCONNECTED && (
            <ConnectionDetailActionMenu connectionDetail={connectionDetail} />
          )}
      </HStack>
      {showPayStatementMappingBanner ? (
        <Box marginBottom="24px">
          <Alert
            variant="finch"
            borderRadius="lg"
            fontSize="14px"
            padding="14px 12px"
          >
            <Flex alignItems="start">
              <AlertIcon />
              <AlertDescription>
                Pay statement items are ready to be mapped for this employer{' '}
                <Link
                  textDecoration="underline"
                  as="button"
                  onClick={() =>
                    setSelectedTab(ConnectionDetailTab.PAY_STATEMENT_MAPPING)
                  }
                >
                  <strong>View now</strong>
                </Link>
              </AlertDescription>
            </Flex>
          </Alert>
        </Box>
      ) : null}
      <Stack spacing="12">
        <ConnectionDetailHeader
          isLoading={isLoading}
          companyName={connectionDetail?.companyName}
          companyId={connectionDetail?.companyId}
          customerName={connectionDetail?.externalCompanyName}
          customerId={connectionDetail?.externalCompanyId}
        />

        {isLoading && (
          <Wrapper>
            <Loading message="We’re getting your connection details ready." />
          </Wrapper>
        )}
        {!isLoading && connectionDetail && (
          <ConnectionDetailContent
            connectionDetail={connectionDetail}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            jobs={jobs}
            payStatementItem={payStatementItem}
            payStatementItemLabels={payStatementItemLabels}
          />
        )}
      </Stack>
    </>
  );
};
