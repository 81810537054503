import { useState } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { SignupResponse } from '@finch-api/developer-dashboard-common';
import { Box, Flex, Image } from '@chakra-ui/react';

import { FormPanel } from './FormPanel';
import { CallToActionPanel } from './CallToActionPanel';
import BgDots from '../../../assets/images/bg-dots.png';
import { EmailVerificationPanel } from './EmailVerificationPanel';
import FinchLogo from '../../../assets/images/logos/finch/logo.svg';
import { RegisterLoginEventAndRedirectToApp } from '../../../components/RegisterLoginEventAndRedirectToApp';

export const Signup = () => {
  const { isAuthenticated }: Auth0ContextInterface<User> = useAuth0();

  const [signupResponse, setSignupResponse] = useState<
    SignupResponse | undefined
  >();

  const accountCreatedHandler = (response: SignupResponse) =>
    setSignupResponse(response);

  const rightPanel = signupResponse ? (
    <EmailVerificationPanel signupResponse={signupResponse} />
  ) : (
    <FormPanel onCreated={accountCreatedHandler} />
  );

  return (
    <>
      {isAuthenticated ? (
        <RegisterLoginEventAndRedirectToApp />
      ) : (
        <Flex
          minHeight="100%"
          flexDirection="column"
          backgroundColor="gray.c50"
        >
          <Flex
            flexGrow={1}
            flexShrink={0}
            justifyContent="center"
            backgroundImage={BgDots}
          >
            <Box maxW="1242px" mx="auto" px={4} minH="764px">
              <Flex direction="column" align="center">
                <Box>
                  <Image src={FinchLogo} my="48px" />
                </Box>

                <Box
                  borderRadius="8px"
                  boxShadow="md"
                  background="white"
                  mb={8}
                >
                  <Flex flexWrap="wrap-reverse">
                    <Box
                      flexBasis={400}
                      flexGrow={1}
                      py="56px"
                      px="clamp(32px, 5vw, 64px)"
                      borderRightColor="gray.c200"
                      borderRightWidth="1px"
                    >
                      <CallToActionPanel />
                    </Box>
                    <Box
                      flexBasis={400}
                      flexGrow={1}
                      py="56px"
                      px="clamp(32px, 5vw, 64px)"
                    >
                      {rightPanel}
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};
