import { useQuery } from '@tanstack/react-query';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import axios from 'axios';
import { baseURL } from 'utils/baseUrls';

import { ApiServerProxyResponse, ProxyOptions } from '../open-api';
import { JsonMode, useSelectedJsonMode } from '../store/selected-json-mode';
import { useSelectedOperation } from '../store/selected-operation';
import { filterOutEmptyValues, parseRequestBody } from '../utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

export const useProxyToApiServer = ({
  formContext,
}: {
  formContext: UseFormReturn<FieldValues>;
}) => {
  const auth = useAuth0();
  const { getValues } = formContext;
  const { selectedJsonMode } = useSelectedJsonMode();
  const { selectedOperation } = useSelectedOperation();
  const { applicationId, connectionId } = useParams<{
    connectionId: string;
    applicationId: string;
  }>();

  const formPath = `${selectedOperation?.path}.${selectedOperation?.method}`;

  const { refetch, data, isFetching, error } = useQuery({
    queryKey: [
      'api-explorer-responses',
      connectionId,
      selectedOperation?.method,
      selectedOperation?.path,
    ],
    queryFn: async () => {
      if (!selectedOperation) {
        return;
      }

      const apiServerResponse = await axios.post<ApiServerProxyResponse>(
        `${baseURL}/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/proxy/open-api`,
        {
          method: selectedOperation.method,
          path: selectedOperation.path,
          authType: selectedOperation?.auth || 'none',
          data:
            selectedJsonMode === JsonMode.Builder
              ? getValues(`${formPath}.typedRequestBody`)
              : parseRequestBody(getValues(`${formPath}.requestBody`)),
          parameters: {
            paths: getValues(`${formPath}.paths`),
            headers: filterOutEmptyValues(getValues(`${formPath}.headers`)),
            queries: filterOutEmptyValues(getValues(`${formPath}.queries`)),
          },
        } as ProxyOptions,
        {
          headers: {
            Authorization: `Bearer ${await auth.getAccessTokenSilently()}`,
          },
        },
      );

      return apiServerResponse.data?.apiServerResponse;
    },
    enabled: false,
    retry: false,
  });

  const makeRequest = () => {
    refetch();
  };

  const requestId = data?.headers?.['finch-request-id'] || '';

  return {
    requestId,
    makeRequest,
    data,
    isFetching,
    error,
    serverResponse: data,
  };
};
