import { useDisclosure } from '@chakra-ui/react';
import { LinkIcon } from 'shared/icons/Link';
import { ModalNewConnectSession } from 'connections-v2/components/ConnectSession/ModalNewConnectSession';

import { StagedConnectionDetail } from '../types';
import { useStagedConnectSession } from '../hooks/useStagedConnectSession';
import { ActionMenu } from 'components/ActionMenu';

export const ConnectionDetailActionMenu = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail | null;
}) => {
  const {
    isOpen: isNewConnectSessionOpen,
    onOpen: onNewConnectSessionOpen,
    onClose: onNewConnectSessionClose,
  } = useDisclosure();

  const {
    create: { mutate, data, isPending },
  } = useStagedConnectSession();

  const onCreateNewConnectSession = () => {
    if (connectionDetail) {
      mutate(connectionDetail.connectSession, {
        onSuccess: onNewConnectSessionOpen,
      });
    }
  };

  return (
    <>
      <ActionMenu
        isLoading={isPending}
        menuItems={[
          {
            icon: <LinkIcon />,
            onClick: onCreateNewConnectSession,
            label: 'Refresh Connect link',
          },
        ]}
      />

      {data?.connectUrl && (
        <ModalNewConnectSession
          isOpen={isNewConnectSessionOpen}
          onClose={onNewConnectSessionClose}
          connectUrl={data.connectUrl}
        />
      )}
    </>
  );
};
