import { Props, Status, variants } from './Tag';
import { Badge } from '../Badge/Badge';

export type JobStatusVariant = Status<
  | 'complete'
  | 'success'
  | 'pending'
  | 'in_progress'
  | 'reauth'
  | 'permission'
  | 'error'
  | 'partial_error'
>;

/**
 * Tag used for displaying the status of an async job.
 *
 * @see https://www.figma.com/file/gldI3ap3tphioNftkEHThx/Employers?type=design&node-id=2235-1475&mode=design&t=Np9Bs86KCQ1W7jGc-4
 */
export const JobStatusTag = ({ variant }: Props<JobStatusVariant>) => {
  const { icon, theme, text } = variants[variant];

  return <Badge theme={theme} icon={icon} text={text} />;
};
