import { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Form, Formik, useField } from 'formik';
import { FiTrash2, FiPlus } from 'react-icons/fi';

import { PageHeading } from '../../shared/Typography';
import { useRedirectURIs } from './RedirectURIsContext';
import { redirectURIsFormValidationSchema } from '../utils/utils';
import { useStatusToast } from '../../shared/StatusToast';
import { Confirmation } from '../../shared/Confirmation';
import { FINCH_BASE_URL } from '../../shared/links';
import { ContentBox } from '../../components/ContentBox';

const NoRedirects = () => {
  return (
    <Center paddingX="24px" paddingY="18px">
      <Text fontSize="14px">
        This application does not yet have any Redirect URIs!
      </Text>
    </Center>
  );
};

const RedirectRow = (props: {
  redirectUrl: string;
  shouldShowTopBorder: boolean;
}) => {
  const { handleRemoveRedirect } = useRedirectURIs();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const handleRemoval = async () => {
    await handleRemoveRedirect(props.redirectUrl);
  };

  const borderProps = props.shouldShowTopBorder
    ? {}
    : {
        borderTop: '1px',
        borderTopColor: 'gray.200',
      };

  const confirmationBodyText = (
    <>
      Are you sure you want to remove{' '}
      <Text fontWeight={'bold'} display="inline" overflowWrap="anywhere">
        {props.redirectUrl}
      </Text>
      ?
    </>
  );

  return (
    <Box key={props.redirectUrl} {...borderProps}>
      <Flex
        flex="1"
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        p="18px 24px"
      >
        <Text as="span" fontSize="16px" wordBreak="break-all">
          {props.redirectUrl}
        </Text>
        <Spacer />
        <HStack ml={4}>
          <IconButton
            aria-label="Remove Redirect URI"
            icon={<FiTrash2 />}
            variant="icon"
            onClick={onOpen}
          />
          <Confirmation
            body={confirmationBodyText}
            cancel={{
              action: onClose,
            }}
            confirm={{
              action: handleRemoval,
            }}
            isOpen={isOpen}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

const URLField = (props: { urlRef: React.RefObject<HTMLInputElement> }) => {
  const [field, meta] = useField({ name: 'url' });

  return (
    <FormControl isInvalid={(meta.error && meta.touched) || false}>
      <Input
        {...field}
        ref={props.urlRef}
        placeholder={`URL (e.g., ${FINCH_BASE_URL})`}
        isInvalid={!!meta.error}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export type AddRedirectURIFormValues = {
  url: string;
};

const AddRedirectURIForm = () => {
  const toast = useStatusToast();
  const { handleAddRedirect, handleAddingCancelled, urls } = useRedirectURIs();

  const validationSchema = useMemo(
    () =>
      redirectURIsFormValidationSchema(
        new Set(urls.map((u) => u.toLocaleLowerCase())),
      ),
    [urls],
  );

  // Focuses the URL field when the component is first rendered
  const urlField = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const node = urlField.current;
    if (node) node.focus();
  }, [urlField]);

  const initialValues: AddRedirectURIFormValues = {
    url: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          await handleAddRedirect(values.url);
        } catch (err) {
          toast({
            wasSuccessful: false,
          });
          return;
        }

        toast({
          wasSuccessful: true,
          message: `Redirect URI was successfully added`,
        });
      }}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, handleReset }) => {
        const finished = () => {
          handleAddingCancelled();
          handleReset();
        };

        return (
          <Form>
            <Flex p="18px">
              <URLField urlRef={urlField} />
              <Spacer px="7px" />
              <ButtonGroup>
                <Button variant="secondary" onClick={finished}>
                  Cancel
                </Button>
                <Button
                  variant="primaryPurple"
                  type="submit"
                  isDisabled={!isValid || !dirty}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export const RedirectURIsForm = () => {
  const { urls, isAdding, handleAddingStarted } = useRedirectURIs();

  return (
    <Flex gap="24px" flexDirection={'column'}>
      <Flex align="center" justifyContent="space-between">
        <Box>
          <PageHeading>Redirect URIs</PageHeading>
        </Box>
        <Spacer />
        <Box>
          <Tooltip
            isDisabled={!isAdding}
            placement="bottom"
            label="A redirect URI is already being added below."
          >
            {/* span => https://github.com/chakra-ui/chakra-ui/issues/500 */}
            <span>
              <Button
                leftIcon={<FiPlus />}
                variant="text"
                onClick={handleAddingStarted}
                isDisabled={isAdding}
              >
                Add Redirect URI
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Flex>
      <ContentBox>
        {!urls.length && !isAdding && <NoRedirects />}
        {/* List of existing Redirect URLs */}
        {!!urls.length &&
          urls.map((redirectUrl, idx) => (
            <RedirectRow
              key={redirectUrl}
              shouldShowTopBorder={idx === 0}
              redirectUrl={redirectUrl}
            />
          ))}
        {/* Add Redirect Form */}
        {isAdding && (
          <Box
            borderTop={urls.length ? '1px' : 'none '}
            borderTopColor="gray.200"
          >
            <AddRedirectURIForm />
          </Box>
        )}
      </ContentBox>
    </Flex>
  );
};
