import { Flex, Link, Tag } from '@chakra-ui/react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import { SubSectionHeading } from './Typography';
import { useApplication } from '../applications/hooks';
import { useOrganizationApplications } from './OrganizationApplicationsContext';
import { findHighestPriorityApplication } from '../credentials/utils';
import { usePermissions } from '../hooks/use-permissions';
import { PERMISSION } from '../constant/roles-and-permissions';
import kebabCase from 'lodash/kebabCase';

interface Tab {
  key: string;
  path: string;
  text: string;
  isBeta?: boolean;
}

export const ApplicationTabs = ({
  direction = 'row',
  onClick = () => {},
}: {
  direction?: 'column' | 'row';
  onClick?: () => void;
}) => {
  const applications = useOrganizationApplications();
  const highestPriorityApp = findHighestPriorityApplication(applications);

  const location = useLocation();
  const match = matchPath<{ applicationId: string }>(location.pathname, {
    path: '/app/applications/:applicationId',
  });

  const applicationId = match?.params.applicationId || highestPriorityApp?.id;
  const { application } = useApplication(applicationId);
  const shouldShowIntegrationsPage = application && !application.isSandbox();
  const roleCanViewWebhooks = usePermissions([PERMISSION.Webhooks.Read]);

  const colorFor = (path: string) =>
    location.pathname === path ? 'brand.purple' : undefined;

  const tabPath = (title: string, applicationId?: string) => {
    if (applicationId) {
      return `/app/applications/${applicationId}/${kebabCase(
        title,
      ).toLowerCase()}`;
    }
    return '#';
  };

  const createTab = (title: string, applicationId?: string) => {
    return {
      key: title.toLowerCase(),
      path: tabPath(title, applicationId),
      text: title,
    };
  };

  const tabs: Tab[] = [
    'Credentials',
    'Connections',
    'Integrations',
    'Webhooks',
    'Activity',
  ]
    .filter((tab) => {
      if (tab === 'Integrations') {
        return shouldShowIntegrationsPage;
      }

      if (tab === 'Webhooks') {
        return roleCanViewWebhooks;
      }

      return true;
    })
    .map((title) => createTab(title, applicationId));

  return (
    <Flex
      as="nav"
      alignItems={direction === 'row' ? 'center' : 'flex-start'}
      gap={{ base: '16px', lg: '32px' }}
      rowGap={{ base: '6', md: '1', lg: '2' }}
      direction={direction}
      flexWrap={{ base: 'wrap', lg: 'nowrap' }}
      justify={direction === 'row' ? 'center' : 'flex-start'}
    >
      {tabs.map((tab) => {
        return (
          <Flex key={tab.key} title={`${tab.text} nav`}>
            <Link
              as={NavLink}
              to={tab.path}
              _hover={{
                textDecoration: 'none',
              }}
              onClick={onClick}
              color={colorFor(tab.path)}
            >
              <SubSectionHeading>{tab.text}</SubSectionHeading>
            </Link>
            {tab.isBeta && (
              <Tag size={'sm'} colorScheme="blue">
                beta
              </Tag>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
