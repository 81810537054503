import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { ConnectionDetailScreen } from '../components/ConnectionDetailScreen';
import { PageContainer } from '../../components/PageContainer';

export const ConnectionDetail = () => {
  const { applicationId, connectionId } = useParams<{
    connectionId: string;
    applicationId: string;
  }>();

  return (
    <Box>
      <PageContainer>
        <ConnectionDetailScreen
          applicationId={applicationId}
          connectionId={connectionId}
        />
      </PageContainer>
    </Box>
  );
};
