import { useEffect } from 'react';
import type { ColumnFiltersState } from '@tanstack/react-table';
import unionBy from 'lodash/unionBy';

import { useSelectedFiltersStore } from '../state/selectedFilters';
import { DeductionSupport, Group, Subgroup } from '../types';

function isLocal(f: ColumnFiltersState[number]) {
  return f.id !== 'provider' && f.id !== 'authorization_type';
}

/**
 * Compose the filters that are set for the current table view, using values
 * parsed from the URI as defaults.
 */
export function useLocalFilters(
  initialFilters: ColumnFiltersState,
  selectedGroup: Group,
  selectedSubgroup: Subgroup,
) {
  const {
    organizationFilters,
    payrollFilters,
    deductionFeatureFilters,
    setDeductionFeatureFilters,
    deductionOperationFilters,
    setDeductionOperationFilters,
    setOrganizationFilters,
    setPayrollFilters,
  } = useSelectedFiltersStore();
  const deductionFilters: Record<DeductionSupport, ColumnFiltersState> = {
    features: deductionFeatureFilters,
    operations: deductionOperationFilters,
  };
  const filterSet: Record<Group, ColumnFiltersState> = {
    organization: organizationFilters,
    deductions: deductionFilters[selectedSubgroup],
    payroll: payrollFilters,
  };
  const setLocalFilters = (selected: ColumnFiltersState) => {
    selected = selected.filter(isLocal);

    switch (selectedGroup) {
      case 'organization':
        setOrganizationFilters(selected);

        break;
      case 'payroll':
        setPayrollFilters(selected);

        break;
      case 'deductions':
        switch (selectedSubgroup) {
          case 'operations':
            setDeductionOperationFilters(selected);

            break;
          case 'features':
            setDeductionFeatureFilters(selected);

            break;
        }

        break;
    }
  };

  useEffect(() => {
    setLocalFilters(unionBy(initialFilters, filterSet[selectedGroup], 'id'));
  }, []);

  return { localFilters: filterSet[selectedGroup], setLocalFilters };
}
