import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAuth0Claims } from '../../auth/use-auth0-claims';

export const useSetupUnleashContext = () => {
  const { user }: Auth0ContextInterface<User> = useAuth0();
  const { data } = useAuth0Claims();

  const updateUnleashContext = useUnleashContext();

  const applicationsPathMatch = useRouteMatch<{ applicationId: string }>(
    '/app/applications/:applicationId',
  );

  const applicationId = applicationsPathMatch?.params?.applicationId;

  useEffect(() => {
    updateUnleashContext({
      userId: user?.sub,
      properties: {
        clientId: applicationId || '',
        organizationId: data?.org_id || '',
      },
    });
  }, [applicationId, updateUnleashContext, user, data]);
};
