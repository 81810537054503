export const WarningIcon = ({
  iconColor = 'white',
  width = 20,
  height = 20,
}: {
  iconColor?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17L10 2L19 17H1ZM9.9956 14.5C10.2069 14.5 10.3854 14.4285 10.5312 14.2856C10.6771 14.1427 10.75 13.9656 10.75 13.7544C10.75 13.5431 10.6785 13.3646 10.5356 13.2188C10.3927 13.0729 10.2156 13 10.0044 13C9.79313 13 9.61458 13.0715 9.46875 13.2144C9.32292 13.3573 9.25 13.5344 9.25 13.7456C9.25 13.9569 9.32145 14.1354 9.46435 14.2812C9.60727 14.4271 9.78435 14.5 9.9956 14.5ZM9.25 12H10.75V8H9.25V12Z"
        fill={iconColor}
      />
    </svg>
  );
};
