export const DurationIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7917 13.5417L13.8542 12.4792L10.75 9.375V5H9.25V10L12.7917 13.5417ZM10 18C8.8975 18 7.86146 17.7917 6.89188 17.375C5.92229 16.9583 5.07292 16.3854 4.34375 15.6562C3.61458 14.9271 3.04167 14.0767 2.625 13.105C2.20833 12.1333 2 11.0951 2 9.99042C2 8.88569 2.20833 7.84722 2.625 6.875C3.04167 5.90278 3.61458 5.05556 4.34375 4.33333C5.07292 3.61111 5.92333 3.04167 6.895 2.625C7.86667 2.20833 8.90486 2 10.0096 2C11.1143 2 12.1528 2.21 13.1252 2.63C14.0974 3.05 14.9432 3.62 15.6625 4.34C16.3817 5.06 16.951 5.90667 17.3706 6.88C17.7902 7.85333 18 8.89333 18 10C18 11.1025 17.7917 12.1385 17.375 13.1081C16.9583 14.0777 16.3889 14.9271 15.6667 15.6562C14.9444 16.3854 14.0963 16.9583 13.1223 17.375C12.1483 17.7917 11.1075 18 10 18ZM10.01 16.5C11.8082 16.5 13.3394 15.8646 14.6038 14.5938C15.8679 13.3229 16.5 11.7883 16.5 9.99C16.5 8.19181 15.8679 6.66056 14.6038 5.39625C13.3394 4.13208 11.8082 3.5 10.01 3.5C8.21167 3.5 6.67708 4.13208 5.40625 5.39625C4.13542 6.66056 3.5 8.19181 3.5 9.99C3.5 11.7883 4.13542 13.3229 5.40625 14.5938C6.67708 15.8646 8.21167 16.5 10.01 16.5Z"
      fill="#737373"
    />
  </svg>
);
