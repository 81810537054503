import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { WebhookEndpoint } from '@finch-api/developer-dashboard-common/dist/api/webhook';

import { Spacer } from '../../shared/Spacer';
import type { IconType } from 'react-icons';
import { EditIcon } from '../../shared/icons/EditIcon';
import { PaperAirplaneIcon } from '../../shared/icons/PaperAirplaneIcon';
import { RotateIcon } from '../../shared/icons/RotateIcon';
import { TrashIcon } from '../../shared/icons/TrashIcon';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';

const EndpointMenuItem = ({
  icon,
  text,
  color = COLORS.FINCH.GRAY,
  hoverColor = COLORS.FINCH.BLACK,
  onClick,
}: {
  icon: IconType;
  text: string;
  color?: string;
  hoverColor?: string;
  onClick?: () => void;
}) => {
  return (
    <MenuItem
      _hover={{
        backgroundColor: COLORS.GRAY.GRAY_200,
        borderRadius: '6px',
        color: hoverColor,
      }}
      onClick={onClick}
    >
      <Flex
        borderRadius={'6px'}
        _hover={{ color: hoverColor }}
        gap={1}
        color={color}
      >
        <Icon as={icon} color={color} />
        <Spacer />
        <Text fontSize={'sm'}>{text}</Text>
      </Flex>
    </MenuItem>
  );
};

export const WebhookEndpointActionMenu = ({
  endpoint,
  onSendTestEventRequested,
  onWebhookEditRequested,
  onWebhookSecretRotateRequested,
  onWebhookDeleteRequested,
}: {
  endpoint: WebhookEndpoint;
  onSendTestEventRequested: (endpoint: WebhookEndpoint) => void;
  onWebhookEditRequested: (endpoint: WebhookEndpoint) => void;
  onWebhookSecretRotateRequested: (endpoint: WebhookEndpoint) => void;
  onWebhookDeleteRequested: (endpoint: WebhookEndpoint) => void;
}) => (
  <Menu>
    <MenuButton
      as={Button}
      aria-label="Actions"
      rightIcon={<Icon as={ChevronDownIcon} color="white"></Icon>}
      size="md"
      variant="primaryPurple"
    >
      Actions
    </MenuButton>
    <MenuList minWidth="170px" borderRadius={'8px'}>
      <EndpointMenuItem
        icon={EditIcon}
        text="Edit Webhook"
        color={COLORS.GRAY.GRAY_600}
        onClick={() => onWebhookEditRequested(endpoint)}
      />
      <EndpointMenuItem
        icon={PaperAirplaneIcon}
        text="Send Test Event"
        color={COLORS.GRAY.GRAY_600}
        onClick={() => onSendTestEventRequested(endpoint)}
      />
      <EndpointMenuItem
        icon={RotateIcon}
        text="Rotate Secret"
        color={COLORS.GRAY.GRAY_600}
        onClick={() => onWebhookSecretRotateRequested(endpoint)}
      />
      <EndpointMenuItem
        icon={TrashIcon}
        text="Delete Webhook"
        color={COLORS.FINCH.RED}
        hoverColor={COLORS.FINCH.RED}
        onClick={() => onWebhookDeleteRequested(endpoint)}
      />
    </MenuList>
  </Menu>
);
