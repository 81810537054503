import styled from '@emotion/styled';

import { Code } from '../../components/Code/Code';
import { COLORS, SIZES } from '../../constant';
import { format } from '../../shared/utils/dateFormatter';
import {
  AutomatedAccountStatusTag,
  AutomatedAccountStatusVariant,
} from '../../components/Tag/AutomatedAccountStatusTag';
import {
  AssistedAccountStatusVariant,
  AssitedAccountStatusTag,
} from '../../components/Tag/AssistedAccountStatusTag';
import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { AccountDetails } from '../../connections/types';
import { implementationKindToCategory } from '../../connections/components/ConnectionsTable';
import { InfoIcon } from '../../shared/InfoIcon';
import { HStack, Tooltip } from '@chakra-ui/react';
import { variants } from '../../components/Tag/Tag';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';

const AccountInfoSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.GRAY.GRAY_400};
  border-radius: 8px;
  gap: 1px;
  overflow: hidden;
  filter: drop-shadow(0px 0px 1px rgba(22, 22, 22, 0.12))
    drop-shadow(0px 4px 8px rgba(22, 22, 22, 0.06));

  @media (max-width: ${SIZES.small}) {
    flex: 1;
    flex-grow: 1;
  }
`;

const AccountInfoItem = styled.div`
  width: 100%;
  min-height: 72px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${COLORS.WHITE};

  @media (max-width: ${SIZES.drawer}) {
    :last-child {
      flex-grow: 1;
    }
  }
`;

const AccountInfoLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  color: ${COLORS.GRAY.GRAY_600};
`;

const AccountInfoText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.FINCH.BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CodeBlock = styled(Code)`
  color: ${COLORS.GRAY.GRAY_600};
`;

const connectionStatusBadgeConversion: {
  [k in AccountStatus]:
    | AutomatedAccountStatusVariant
    | AssistedAccountStatusVariant;
} = {
  [AccountStatus.CONNECTED]: 'connected',
  [AccountStatus.PENDING]: 'pending',
  [AccountStatus.DISCONNECTED]: 'disconnected',
  [AccountStatus.ERROR_NO_ACCOUNT_SETUP]: 'no_account_set_up',
  [AccountStatus.ERROR_PERMISSIONS]: 'improper_permissions',
  [AccountStatus.PROCESSING]: 'processing',
  [AccountStatus.REAUTH]: 'reauth_required',
  [AccountStatus.UNKNOWN]: 'unknown',
};

export const getAccountStatusVariant = (accountStatus: AccountStatus) => {
  const variant = connectionStatusBadgeConversion[accountStatus];

  return variants[variant];
};

const connectionStatusBadge = (
  connectionStatus: AccountStatus,
  kind: ConnectionCategory,
): JSX.Element => {
  const variant = connectionStatusBadgeConversion[connectionStatus];

  switch (kind) {
    case ConnectionCategory.Assisted:
      return (
        <AssitedAccountStatusTag
          variant={variant as AssistedAccountStatusVariant}
        />
      );
    case ConnectionCategory.Automated:
      return (
        <AutomatedAccountStatusTag
          variant={variant as AutomatedAccountStatusVariant}
        />
      );
    default:
      return <></>; // NOTE: technically not possible, but enums require defeault
  }
};

const accessTokenTitle = (tokens: string[]): string => {
  switch (tokens.length) {
    case 1:
      return 'Access Token';
    case 5:
      return 'Access Tokens (last 5 used)';
    default:
      return 'Access Tokens';
  }
};

export const AccountInfo = ({
  account,
}: {
  account: AccountDetails | undefined;
}) => {
  if (!account) return null;

  const {
    createdAt,
    lastRetrieved,
    accountStatus,
    tokens,
    implementationKind,
    statusMessage,
  } = account;

  return (
    <AccountInfoSection>
      <AccountInfoItem>
        <AccountInfoLabel>Created Date</AccountInfoLabel>
        <AccountInfoText>{format(new Date(createdAt))}</AccountInfoText>
      </AccountInfoItem>
      <AccountInfoItem>
        <AccountInfoLabel>{accessTokenTitle(tokens)}</AccountInfoLabel>
        {tokens.map((token) => (
          <AccountInfoText key={token}>
            <CodeBlock>{token}</CodeBlock>
          </AccountInfoText>
        ))}
      </AccountInfoItem>
      <AccountInfoItem>
        <AccountInfoLabel>Account Status</AccountInfoLabel>
        <HStack>
          <AccountInfoText>
            {connectionStatusBadge(
              accountStatus,
              implementationKindToCategory[implementationKind],
            )}
          </AccountInfoText>
          <Tooltip placement="top-start" hasArrow label={statusMessage}>
            <div>
              <InfoIcon />
            </div>
          </Tooltip>
        </HStack>
      </AccountInfoItem>
      {![
        ImplementationKind.ASSISTED,
        ImplementationKind.FINCH_SANDBOX_ASSISTED,
      ].includes(implementationKind) ? (
        <AccountInfoItem>
          <AccountInfoLabel>Last Synced</AccountInfoLabel>
          <AccountInfoText>
            {lastRetrieved
              ? format(new Date(lastRetrieved))
              : 'No successful data pull'}
          </AccountInfoText>
        </AccountInfoItem>
      ) : null}
    </AccountInfoSection>
  );
};
