import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Text,
  Stack,
  Box,
  Image,
  HStack,
  Link,
  type LinkProps,
  OrderedList,
  ListItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { SubSectionHeading } from '../../shared/Typography';
import { ReactNode, useState } from 'react';
import { COLORS } from '../../constant';
import { UseMutationResult } from '@tanstack/react-query';
import { VerifyOtpError, VerifyOtpResponse } from '../../auth/auth0-client';

const PurpleLink = ({ children, ...rest }: LinkProps) => {
  return (
    <Link color={COLORS.FINCH.PURPLE} textDecor="underline" {...rest}>
      {children}
    </Link>
  );
};

const Instruction = ({ children }: { children: ReactNode }) => {
  return (
    <ListItem sx={{ '&::marker': { fontWeight: 'medium' } }}>
      {children}
    </ListItem>
  );
};

export const ModalEnrollMfa = ({
  isOpen,
  onClose,
  qrCodeSvg,
  secret,
  verifyOtp,
}: {
  isOpen: boolean;
  onClose: () => void;
  qrCodeSvg: string;
  secret: string;
  verifyOtp: UseMutationResult<
    VerifyOtpResponse,
    VerifyOtpError,
    string,
    unknown
  >;
}) => {
  const [otp, setOtp] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalHeader fontSize="18px" fontWeight="600" textAlign="center" pb="1">
          Set up Multi-Factor Authentication
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SubSectionHeading as="h2" py="6">
            Download Google Authenticator and scan this QR code
          </SubSectionHeading>
          <Stack spacing={6} fontSize="14px">
            <OrderedList spacing="6">
              <Instruction>
                Download Google Authenticator for{' '}
                <PurpleLink href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                  iOS
                </PurpleLink>{' '}
                or{' '}
                <PurpleLink href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA&gl=US">
                  Android
                </PurpleLink>
                .
              </Instruction>
              <Instruction>Open the app and tap Begin Setup.</Instruction>
              <Instruction>
                <Text>Tap Scan Code and point your camera at the screen.</Text>
                <Image
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    qrCodeSvg,
                  )}`}
                  alt="QR code"
                  width="150px"
                  pl="2"
                  ml={-4}
                />

                <Box>
                  <Text>or enter this code into your authentication app:</Text>
                  <Text fontWeight="500">
                    {secret.match(/.{1,4}/g)?.join(' ')}
                  </Text>
                </Box>
              </Instruction>
              <Instruction>
                After you scan or enter the code into your app, you&apos;ll see
                a verification code. Enter it below.
              </Instruction>
            </OrderedList>
            <FormControl isInvalid={!!verifyOtp.error?.response?.data.error}>
              <FormLabel fontWeight="500" fontSize="sm">
                Verification code
              </FormLabel>
              <Input
                placeholder="Enter 6-digit code"
                value={otp}
                fontSize="14px"
                onChange={(e) => setOtp(e.target.value)}
              />
              {verifyOtp.error?.response?.data.error === 'invalid_grant' && (
                <FormErrorMessage fontSize="xs" color={COLORS.FINCH.RED}>
                  Invalid OTP code. Please try again.
                </FormErrorMessage>
              )}
              {verifyOtp.error?.response?.data.error === 'expired_token' && (
                <FormErrorMessage fontSize="xs" color={COLORS.FINCH.RED}>
                  Please restart the enrollment process.
                </FormErrorMessage>
              )}
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter pt="32px">
          <HStack spacing="4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primaryPurple"
              onClick={() => verifyOtp.mutate(otp)}
              isLoading={verifyOtp.isPending}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
