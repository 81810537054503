import { create } from 'zustand';
import {
  ProviderWithImplementation,
  StagedConfiguration,
  StagedOAuthCredentials,
} from '../types';
import { RequiredFields } from '../../utils/type-utils';
import { orderList, sortByPinnedIndex } from '../utils';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { arrayMove } from '@dnd-kit/sortable';

export const useStagedProviderConfigurationsStore = create<{
  /** This saves all configurations that we're updated before a publish */
  originalConfigurations: ProviderWithImplementation[];
  stagedConfigurations: Map<string, StagedConfiguration>;
  stagedOAuthCredentials: Map<string, StagedOAuthCredentials>;
  setOriginalConfigurations: (
    providerConfigurations: ProviderWithImplementation[],
  ) => void;
  setStagedConfiguration: (
    providerId: string,
    updatedConfiguration: RequiredFields<
      Partial<ProviderWithImplementation>,
      'id'
    >,
  ) => void;
  setStagedOAuthCredentials: (
    providerId: string,
    updatedCredentials: StagedOAuthCredentials,
  ) => void;
  resetAllStagedConfigurations: () => void;
  hasStagedConfiguration: (providerId: string) => boolean;
  hasChanges: () => boolean;
  selectedProviderId: string | null;
  setSelectedProviderId: (provider: string | null) => void;
  pinnedProviderIds: string[];
  pinProvider: (providerId: string) => void;
  unpinProvider: (providerId: string) => void;
  setProviderPinnedIndex: (providerId: string, index: number | null) => void;
  sortPinnedProvidersAlphabetically: () => void;
  isPinnedProvidersAlphabetical: () => boolean;
}>((set, get) => ({
  selectedProviderId: null,
  pinnedProviderIds: [],
  originalConfigurations: [],
  stagedConfigurations: new Map<string, StagedConfiguration>(),
  stagedOAuthCredentials: new Map<string, StagedOAuthCredentials>(),
  setStagedConfiguration: (providerId, updatedConfig) => {
    return set((state) => {
      const stagedConfigurations = new Map(state.stagedConfigurations);
      stagedConfigurations.set(providerId, updatedConfig);
      return { stagedConfigurations };
    });
  },
  setStagedOAuthCredentials: (providerId, updatedCredentials) => {
    return set((state) => {
      const stagedOAuthCredentials = new Map(state.stagedOAuthCredentials);
      stagedOAuthCredentials.set(providerId, updatedCredentials);
      return { stagedOAuthCredentials };
    });
  },

  pinProvider: (providerId) => {
    const { pinnedProviderIds, isPinnedProvidersAlphabetical } = get();
    if (!pinnedProviderIds.includes(providerId)) {
      const orderPinnedProviders = orderList(
        [...pinnedProviderIds, providerId],
        isPinnedProvidersAlphabetical(),
      );
      const indexOfProvider = orderPinnedProviders.indexOf(providerId);
      get().setProviderPinnedIndex(providerId, indexOfProvider);
    }
  },

  unpinProvider: (providerId) => {
    get().setProviderPinnedIndex(providerId, null);
  },

  setProviderPinnedIndex: (providerId, index) => {
    return set((state) => {
      const config =
        state.stagedConfigurations.get(providerId) ||
        state.originalConfigurations.find((c) => c.id === providerId);
      const stagedConfigurations = new Map(state.stagedConfigurations);
      if (!config) {
        return state;
      }

      let pinnedProviderIds = [...state.pinnedProviderIds];

      if (index === null) {
        pinnedProviderIds = state.pinnedProviderIds.filter(
          (id) => id !== providerId,
        );
      } else {
        const currentIndex = state.pinnedProviderIds.indexOf(providerId);
        if (currentIndex !== -1) {
          pinnedProviderIds = arrayMove(
            state.pinnedProviderIds,
            currentIndex,
            index,
          );
        } else {
          pinnedProviderIds = [
            ...pinnedProviderIds.slice(0, index),
            providerId,
            ...pinnedProviderIds.slice(index),
          ];
        }
      }

      stagedConfigurations.set(providerId, { ...config, pinnedIndex: index });
      return { pinnedProviderIds, stagedConfigurations };
    });
  },

  setOriginalConfigurations: (
    providerConfigurations: ProviderWithImplementation[],
  ) => {
    const pinnedProvidersIds = providerConfigurations
      .filter((provider) => !isNil(provider.pinnedIndex))
      .sort(sortByPinnedIndex)
      .map((provider) => provider.id);

    return set({
      stagedConfigurations: new Map(),
      stagedOAuthCredentials: new Map(),
      originalConfigurations: providerConfigurations,
      pinnedProviderIds: pinnedProvidersIds,
    });
  },

  resetAllStagedConfigurations: () => {
    return set({
      stagedConfigurations: new Map(),
      stagedOAuthCredentials: new Map(),
    });
  },

  hasStagedConfiguration: (providerId: string) => {
    const {
      stagedConfigurations,
      stagedOAuthCredentials,
      originalConfigurations,
    } = get();
    const stagedConfig = stagedConfigurations.get(providerId);
    const stagedCredentials = stagedOAuthCredentials.get(providerId);
    const originalConfig = originalConfigurations.find(
      (provider) => provider.id === providerId,
    );

    if (!originalConfig) {
      return false;
    }

    if (stagedCredentials) {
      return true;
    }

    // If there is a staged configuration, check if it is different from the current configuration
    if (stagedConfig && !isEqual(stagedConfig, originalConfig)) {
      return true;
    }

    return false;
  },

  hasChanges: () => {
    const { stagedConfigurations, stagedOAuthCredentials } = get();
    if (stagedOAuthCredentials.size > 0) {
      return true;
    }

    for (const providerId of Array.from(stagedConfigurations.keys())) {
      if (get().hasStagedConfiguration(providerId)) {
        return true;
      }
    }
    return false;
  },

  setSelectedProviderId: (providerId) =>
    set({ selectedProviderId: providerId }),

  sortPinnedProvidersAlphabetically: () => {
    const { pinnedProviderIds } = get();
    const sortedOrder = [...pinnedProviderIds].sort((a, b) =>
      a.localeCompare(b),
    );

    for (let i = 0; i < sortedOrder.length; i++) {
      const providerId = sortedOrder[i];
      get().setProviderPinnedIndex(providerId as string, i);
    }
  },

  isPinnedProvidersAlphabetical: () => {
    const { pinnedProviderIds } = get();

    const sortedPinnedProviders = [...pinnedProviderIds].sort((a, b) =>
      a.localeCompare(b),
    );

    return (
      JSON.stringify(pinnedProviderIds) ===
      JSON.stringify(sortedPinnedProviders)
    );
  },
}));
