import { Button, Stack, Text, Tooltip } from '@chakra-ui/react';
import { COLORS } from 'constant';

import { RequestBadge } from './request-badge';
import style from './style.module.css';
import { DATA_REQUEST_SECTIONS, Section } from '../constants';
import { useConnectionsApiSchema } from '../hooks/use-api-schema';
import { Collapsible } from './collapsible';
import { useSelectedOperation } from '../store/selected-operation';

export const OperationsList = () => {
  const { operationsBySection } = useConnectionsApiSchema();
  const { selectedOperation, setSelectedOperation } = useSelectedOperation();

  return (
    <Stack
      overflowY="auto"
      flexBasis="40%"
      h="100%"
      maxW={{ base: '100%', lg: '310px' }}
      className={style.scrollShadow}
    >
      {Object.entries(operationsBySection)
        .sort(([a], [b]) => {
          const indexA = DATA_REQUEST_SECTIONS.indexOf(a as Section);
          const indexB = DATA_REQUEST_SECTIONS.indexOf(b as Section);

          if (indexA === -1) return 1; // a is not in list, sort it to the end
          if (indexB === -1) return -1; // b is not in list, sort it to the end

          return indexA - indexB; // both a and b are in list, sort them by their order in the list
        })
        .map(([section, operations]) => (
          <Collapsible
            spaceBetweenIcon
            key={section}
            heading={section}
            fontSize="14px"
            _hover={{ borderRadius: '8px' }}
            fontWeight="600"
            openByDefault={section === Section.Organization}
          >
            <Stack py="2" spacing={1}>
              {operations.map((operation) => {
                const selected =
                  selectedOperation?.path === operation.path &&
                  selectedOperation?.method === operation.method;

                const color = selected
                  ? COLORS.FINCH.BLACK
                  : COLORS.GRAY.GRAY_600;

                return (
                  <Tooltip
                    label="This connection does not have the required scope to use this operation"
                    key={operation.path + operation.method}
                    isDisabled={operation.hasRequiredScope}
                    placement="right"
                  >
                    <Button
                      size="xs"
                      fontWeight="400"
                      variant="ghost"
                      justifyContent="flex-start"
                      onClick={() => setSelectedOperation(operation)}
                      bg={selected ? COLORS.GRAY.GRAY_200 : 'transparent'}
                      _hover={{ bg: COLORS.GRAY.GRAY_100 }}
                      px="8px"
                      isDisabled={!operation.hasRequiredScope}
                      py="5px"
                      h="auto"
                      gap="2"
                    >
                      {<RequestBadge method={operation.method} />}
                      <Text
                        flexBasis="74%"
                        sx={{ textWrap: 'wrap' }}
                        textAlign="left"
                        lineHeight="1.5"
                        color={color}
                        fontWeight={selected ? '500' : '400'}
                        fontSize="14px"
                      >
                        {operation.title}
                      </Text>
                    </Button>
                  </Tooltip>
                );
              })}
            </Stack>
          </Collapsible>
        ))}
    </Stack>
  );
};
