export const SettingsIcon = ({ color }: { color: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="settings_FILL1_wght400_GRAD0_opsz20 (1) 1">
      <path
        id="Vector"
        d="M7.64583 18.3346L7.33333 15.7096C7.19444 15.6263 7.04167 15.536 6.875 15.4388C6.70833 15.3416 6.55556 15.2513 6.41667 15.168L3.97917 16.1888L1.625 12.1263L3.70833 10.543V9.45964L1.625 7.8763L3.97917 3.8138L6.41667 4.83464C6.55556 4.7513 6.70833 4.66102 6.875 4.5638C7.04167 4.46658 7.19444 4.3763 7.33333 4.29297L7.64583 1.66797H12.3542L12.6667 4.29297C12.8056 4.3763 12.9583 4.46658 13.125 4.5638C13.2917 4.66102 13.4444 4.7513 13.5833 4.83464L16.0208 3.8138L18.375 7.8763L16.3125 9.45964V10.543L18.375 12.1263L16.0208 16.1888L13.5833 15.168C13.4444 15.2513 13.2917 15.3416 13.125 15.4388C12.9583 15.536 12.8056 15.6263 12.6667 15.7096L12.3542 18.3346H7.64583ZM10 12.9805C10.8194 12.9805 11.5208 12.6888 12.1042 12.1055C12.6875 11.5221 12.9792 10.8207 12.9792 10.0013C12.9792 9.18186 12.6875 8.48047 12.1042 7.89714C11.5208 7.3138 10.8194 7.02214 10 7.02214C9.18056 7.02214 8.47917 7.3138 7.89583 7.89714C7.3125 8.48047 7.02083 9.18186 7.02083 10.0013C7.02083 10.8207 7.3125 11.5221 7.89583 12.1055C8.47917 12.6888 9.18056 12.9805 10 12.9805Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);
