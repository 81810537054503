import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import type { OpenAPIV3 } from 'openapi-types';
import { parseOperations } from '../open-api/parse-operations';
import { ApiServerProxyResponse } from '../open-api';
import { baseURL } from '../../../../utils/baseUrls';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useApplication } from 'applications/hooks';
import { useMemo } from 'react';
import { useSelectedOperation } from '../store/selected-operation';
import { useConnectionDetails } from 'connection-detail-v2/hooks/useConnectionDetail';
import { Products } from 'constant/scopes';

export const useConnectionsApiSchema = () => {
  const { application } = useApplication();

  const setSelectedOperation = useSelectedOperation(
    (state) => state.setSelectedOperation,
  );

  const auth = useAuth0();

  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();

  const { connectionDetail } = useConnectionDetails({
    applicationId,
    connectionId,
  });

  const query = useQuery({
    queryKey: ['api-schema'],
    queryFn: async () => {
      const data = await axios
        .post<ApiServerProxyResponse<OpenAPIV3.Document>>(
          `${baseURL}/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/proxy/open-api`,
          {
            method: 'get',
            path: '/openapi.json',
          },
          {
            headers: {
              Authorization: `Bearer ${await auth.getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);

      return data.apiServerResponse.data;
    },
    refetchOnMount: false,
  });

  const parsedSchema = useMemo(() => {
    const connectionScopes = connectionDetail?.products.flatMap(
      (product) => product.scopes,
    ) as Products[];

    const operations = parseOperations({
      spec: query.data,
      filter: {
        operationType: 'connection',
        omitOperations: ['post /disconnect'],
        isSandbox: application?.isSandbox(),
      },
      connectionScopes,
    });

    const firstEnabledOperation = operations.operations.find(
      (operation) =>
        operation.requiredScope === null ||
        connectionScopes?.includes(operation.requiredScope),
    );

    if (firstEnabledOperation) setSelectedOperation(firstEnabledOperation);

    return operations;
  }, [
    query.data,
    application,
    connectionDetail?.products,
    setSelectedOperation,
  ]);

  return { ...query, ...parsedSchema };
};
