import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { captureException } from '@sentry/react';

import { Provider } from '../model';
import { config } from '../../utils/baseUrls';
import { Provider as _Provider } from '../types';

export const useProviders = () => {
  const {
    isLoading,
    data = [],
    error,
  } = useQuery({
    queryKey: ['providers'],
    queryFn: async () => {
      const uri = new URL('/providers', config.publicAPIUrl);

      try {
        const { data } = await axios.get<_Provider[]>(uri.toString(), {
          headers: {
            'Finch-API-Version': '2020-09-17',
          },
        });

        return data;
      } catch (caught) {
        captureException(caught);

        throw new Error('Could not receive provider implementations');
      }
    },
  });

  const providers = data.map((provider) => new Provider(provider));

  return { loading: isLoading, providers, error };
};
