import { z } from 'zod';

export const sftpCredentialsUpdateSchema = z.object({
  host: z.string().min(1, {
    message: 'Please enter a host URL',
  }),
  port: z.coerce.number().optional(),
  notificationEmail: z.string().email({
    message: 'Please enter a valid email address',
  }),
  password: z.string().optional(),
  rootPath: z.string().optional(),
  username: z.string().min(1, {
    message: 'Please enter a username',
  }),
});

export type SftpCredentialsUpdate = z.infer<typeof sftpCredentialsUpdateSchema>;

export type SftpCredentials = Omit<SftpCredentialsUpdate, 'password'> & {
  id: string;
  isConnected: boolean;
  createdAt: string;
  clientId: string;
};
