import {
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  HStack,
  Text,
} from '@chakra-ui/react';
import { InputSearchIcon } from '../../shared/icons/InputSearch';
import { useProviderSearchStore } from '../state/providerSearch';
import { useSelectedTabStore } from '../state/selectedTab';
import { useStagedProviderConfigurationsStore } from '../state/stagedConfigurations';
import { AtoZIcon } from '../../shared/icons/A-Z';

export const ProviderSearch = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { searchTerm, setSearchTerm } = useProviderSearchStore();
  const selectedTab = useSelectedTabStore((store) => store.selectedTab);
  const { sortPinnedProvidersAlphabetically, isPinnedProvidersAlphabetical } =
    useStagedProviderConfigurationsStore();

  return (
    <HStack>
      <InputGroup w="fit-content" flex="2">
        <InputLeftElement color="gray.500" pointerEvents="none">
          <InputSearchIcon />
        </InputLeftElement>
        <Input
          placeholder="Search integrations"
          fontSize="14px"
          value={searchTerm || ''}
          isDisabled={isDisabled}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <InputRightElement w="fit-content">
            <Button
              variant="text"
              size="sm"
              fontWeight="400"
              mx="1"
              onClick={() => setSearchTerm(null)}
            >
              Clear
            </Button>
          </InputRightElement>
        )}
      </InputGroup>

      {selectedTab === 'Pinned' && (
        <Button
          variant="secondary"
          leftIcon={<AtoZIcon />}
          isDisabled={isPinnedProvidersAlphabetical()}
          onClick={sortPinnedProvidersAlphabetically}
        >
          Sort Alphabetically
        </Button>
      )}
    </HStack>
  );
};
