import type { TupleToUnion } from 'type-fest';

export enum Role {
  Admin = 'Admin',
  Member = 'Member',
  Editor = 'Editor',
}

export type RoleOption = {
  value: Role;
  label: string;
  readablePermissions: string[];
  permissions: {
    prod: Array<Permission>;
    nonProd: Array<Permission>;
  };
  extendsRole?: Role;
};

export type Permission = TupleToUnion<typeof ALL_PERMISSIONS>;

export const SFTP_PERMISSION = 'View and edit SFTP credentials';

export const PERMISSION = {
  Connections: {
    Read: 'connections:read',
  },
  Activity: {
    Read: 'activity:read',
  },
  ClientSecret: {
    Read: 'client_secret:read',
    Create: 'client_secret:create',
    Update: 'client_secret:update',
  },
  RedirectUris: {
    Read: 'redirect_uris:read',
    Create: 'redirect_uris:create',
    Update: 'redirect_uris:update',
    Delete: 'redirect_uris:delete',
  },
  ConnectSession: {
    Create: 'connect-session:create',
  },
  Members: {
    Read: 'members:read',
    Create: 'members:create',
    Update: 'members:update',
    Delete: 'members:delete',
  },
  MemberRoles: {
    Read: 'member_roles:read',
    Create: 'member_roles:create',
    Update: 'member_roles:update',
    Delete: 'member_roles:delete',
  },
  SFTP: {
    Read: 'sftp:read',
    Create: 'sftp:create',
    Update: 'sftp:update',
  },
  IntegrationConfigurations: {
    Read: 'integrations:read',
    Update: 'integrations:update',
  },
  Webhooks: {
    Read: 'webhooks:read',
    Create: 'webhooks:create',
    Update: 'webhooks:update',
    Delete: 'webhooks:delete',
  },
  OrgMfaSettings: {
    Update: 'org-mfa-settings:update',
  },
} as const satisfies {
  [key: string]: {
    Read?: `${string}:read`;
    Create?: `${string}:create`;
    Update?: `${string}:update`;
    Delete?: `${string}:delete`;
  };
};

const ALL_PERMISSIONS = Object.values(PERMISSION).flatMap((permission) =>
  Object.values(permission),
);

const MEMBER_READABLE_PERMISSIONS = [
  'View Connections and Activity',
  'View Integrations in read-only',
  'Generate new Connect links',
];

const MEMBER_PERMISSIONS = [
  PERMISSION.Connections.Read,
  PERMISSION.Activity.Read,
  PERMISSION.ClientSecret.Read,
  PERMISSION.ConnectSession.Create,
  PERMISSION.IntegrationConfigurations.Read,
];

const EDITOR_READABLE_PERMISSIONS = [
  'View and edit application webhooks, and integrations',
  'View and edit non-production application credentials',
  SFTP_PERMISSION,
  'View and edit Redirect URIs',
];

const EDITOR_PROD_PERMISSIONS = [
  ...MEMBER_PERMISSIONS,
  ...Object.values(PERMISSION.RedirectUris),
  PERMISSION.Webhooks.Read,
  PERMISSION.Webhooks.Update,
  PERMISSION.IntegrationConfigurations.Update,
];

const EDITOR_NON_PROD_PERMISSIONS = [
  ...EDITOR_PROD_PERMISSIONS,
  PERMISSION.SFTP.Read,
  PERMISSION.SFTP.Create,
  PERMISSION.SFTP.Update,
  PERMISSION.ClientSecret.Update,
];

const ADMIN_READABLE_PERMISSIONS = [
  'Add new members',
  'Update roles and remove existing members',
  'Enforce MFA for the entire organization',
  'Rotate a production application secret',
];

/**
 * The order of these roles is important, as it determines the privilege hierarchy
 * i.e. the first role in the list is the least privileged, and the last role is the most privileged
 */
export const ROLES: Array<RoleOption> = [
  {
    value: Role.Member,
    label: 'Member',
    readablePermissions: MEMBER_READABLE_PERMISSIONS,
    permissions: {
      nonProd: MEMBER_PERMISSIONS,
      prod: MEMBER_PERMISSIONS,
    },
  },
  {
    value: Role.Editor,
    label: 'Editor',
    extendsRole: Role.Member,
    readablePermissions: EDITOR_READABLE_PERMISSIONS,
    permissions: {
      nonProd: EDITOR_NON_PROD_PERMISSIONS,
      prod: EDITOR_PROD_PERMISSIONS,
    },
  },
  {
    value: Role.Admin,
    label: 'Admin',
    extendsRole: Role.Editor,
    readablePermissions: ADMIN_READABLE_PERMISSIONS,
    permissions: {
      nonProd: ALL_PERMISSIONS,
      prod: ALL_PERMISSIONS,
    },
  },
];
