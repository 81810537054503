import { Box, Text } from '@chakra-ui/react';

import { Log } from '@finch-api/developer-dashboard-common';
import type { Row } from '@tanstack/react-table';
import { COLORS } from '../constant';
import { OverflowTooltip } from '../shared/OverflowTooltip';
import { Spacer } from '../shared/Spacer';
import { Caption } from '../shared/Typography';
import { format } from '../shared/utils/dateFormatter';

export const DateCell = ({ date }: { date: number }) => {
  return (
    <Box color={COLORS.GRAY.GRAY_600} width="200px" fontSize="14px">
      {format(new Date(date))}
    </Box>
  );
};

export const PathCell = ({ row }: { row: Row<Log> }) => {
  const url = new URL(row.getValue('fullUrl'));
  const path = url.pathname;
  const method = row.original.method;
  return (
    <Box width="192px">
      <OverflowTooltip label={path}>
        <Caption> {path}</Caption>
      </OverflowTooltip>
      <Spacer p="1" />
      <Text fontSize={'14px'} color={COLORS.GRAY.GRAY_600}>
        {method}
      </Text>
    </Box>
  );
};

export const CompanyCell = ({
  company,
}: {
  company: { id: string; name: string };
}) => {
  return (
    <Box width="305px">
      <Caption> {company.name}</Caption>
      <Spacer p="1" />
      <OverflowTooltip label={company.id}>
        <Text fontSize={'14px'} color={COLORS.GRAY.GRAY_600}>
          {company.id}
        </Text>
      </OverflowTooltip>
    </Box>
  );
};
