import { Badge } from '@chakra-ui/react';
import { ApplicationStatus } from '@finch-api/developer-dashboard-common';

export enum ApplicationStatusBadgeSize {
  REGULAR,
  SMALL,
}

const badgeColorAndCopyFor = (applicationType?: ApplicationStatus) => {
  switch (applicationType) {
    case ApplicationStatus.SANDBOX:
      return { color: 'primary', copy: 'Sandbox' };
    case ApplicationStatus.TEST:
      return { color: 'warning', copy: 'Development' };
    case ApplicationStatus.LIVE:
      return { color: 'success', copy: 'Production' };
    default:
      return;
  }
};

const badgeOptionsFor = (size: ApplicationStatusBadgeSize) => {
  const commonBadgeOptions = {
    fontFamily: 'Inter',
    fontWeight: '600',
  };

  switch (size) {
    case ApplicationStatusBadgeSize.SMALL:
      return {
        ...commonBadgeOptions,
        fontSize: 9,
      };
    case ApplicationStatusBadgeSize.REGULAR:
    default:
      return {
        ...commonBadgeOptions,
        px: 1.5,
        py: 1.5,
        borderRadius: 4,
        sx: {
          letterSpacing: '5%',
        },
      };
  }
};

export const ApplicationStatusBadge = ({
  applicationType,
  size = ApplicationStatusBadgeSize.REGULAR,
}: {
  applicationType?: ApplicationStatus;
  size?: ApplicationStatusBadgeSize;
}) => {
  const colorAndCopy = badgeColorAndCopyFor(applicationType);
  if (!colorAndCopy) return <></>;

  const badgeOptions = badgeOptionsFor(size);

  return (
    <Badge {...badgeOptions} colorScheme={colorAndCopy.color}>
      {colorAndCopy.copy}
    </Badge>
  );
};
