import styled from '@emotion/styled';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';

import { StatusCell, IdCell, TextCell } from './TableCells';
import { JobsTable } from './JobsTableComponents';

import { COLORS, SHADOWS, SIZES } from '../../constant';
import { format } from '../../shared/utils/dateFormatter';
import {
  ExternalJobStatus,
  JobType,
} from '@finch-api/common/dist/external/dashboard/job-status';
import { JobEntry } from '../types';
import { HStack, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '../../shared/InfoIcon';
import {
  dateRangeOptions,
  DateRangeFilterInput,
  RadioFilterInput,
  SearchFilterInput,
  SelectFilterInput,
} from '../../components/TableFilters';
import {
  connectionCategoryOptions,
  jobStatusOptions,
  jobTypeOptions,
} from '../constants';
import startCase from 'lodash/startCase';

const TableWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: auto;
  border-radius: 8px;
  background-color: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.PAGE};

  @media (max-width: ${SIZES.drawer}) {
    padding-right: 0;
    flex-direction: column;
  }
`;

export const jobTypeLabelMap: Record<JobType, string> = {
  [JobType.ORG_PAYROLL_SYNC]: 'Data Sync',
  [JobType.BENEFIT_CREATE]: 'Create Benefit',
  [JobType.BENEFIT_UPDATE]: 'Update Benefit',
  [JobType.BENEFIT_ENROLL]: 'Enroll Benefits',
  [JobType.BENEFIT_UNENROLL]: 'Unenroll Benefits',
};

export const getJobTypeLabel = (jobType: JobType) =>
  jobTypeLabelMap[jobType] || startCase(jobType);

const dataSyncStatusMessage: Record<ExternalJobStatus, string> = {
  [ExternalJobStatus.COMPLETE]: 'Data was successfully synced.',
  [ExternalJobStatus.PENDING]: 'This job has been scheduled for execution.',
  [ExternalJobStatus.IN_PROGRESS]:
    'This job is currently executing. Check back for results in a bit!',
  [ExternalJobStatus.PARTIAL_ERROR]:
    'Some jobs in this batch were unable to complete. Click into this row for more job details.',
  [ExternalJobStatus.PERMISSION_ERROR]:
    'This job was unable to be completed due to a change in the permissions on the account. Please have the user re-authenticate through Finch Connect.',
  [ExternalJobStatus.ERROR]:
    'Finch was unable to fetch data during this job run. The connection is still healthy and no action is required by you or the user.',
  [ExternalJobStatus.REAUTH_ERROR]:
    'This job was unable to be completed due to a session expiry. Please have the user re-authenticate through Finch Connect',
};

const assistedBenefitsStatusMessage: Partial<
  Record<ExternalJobStatus, string>
> = {
  [ExternalJobStatus.COMPLETE]: 'This job was successfully completed.',
  [ExternalJobStatus.PENDING]: 'This job has been scheduled for execution.',
  [ExternalJobStatus.IN_PROGRESS]:
    'This job is currently executing. Check back for results in a bit!',
  [ExternalJobStatus.PARTIAL_ERROR]:
    'Some jobs in this batch were unable to complete. Click into this row for more job details.',
  [ExternalJobStatus.ERROR]:
    'All jobs in this batch failed. Click into this row for more job details.',
};

const getStatusLabel = (
  statusContext: CellContext<JobEntry, ExternalJobStatus>,
) => {
  if (statusContext.row.original.type === JobType.ORG_PAYROLL_SYNC) {
    return dataSyncStatusMessage[statusContext.getValue()];
  }

  const jobTasks = statusContext.row.original?.tasks;

  if (
    statusContext.getValue() === ExternalJobStatus.ERROR &&
    jobTasks?.length === 1
  ) {
    return (
      (jobTasks?.[0]?.response as { message: string })?.message ||
      'This job failed. Please reach out to your Finch rep for more details on this error.'
    );
  }

  return assistedBenefitsStatusMessage[statusContext.getValue()];
};

export const JobStatusTable = ({
  jobs,
  isLoading,
}: {
  jobs: JobEntry[];
  isLoading: boolean;
}) => {
  const columnHelper = createColumnHelper<JobEntry>();

  const columns = [
    columnHelper.accessor('jobId', {
      header: 'Job ID',
      cell: (props) => (
        <IdCell
          id={props.getValue()}
          batchedIndividuals={
            props.row.original.type !== JobType.ORG_PAYROLL_SYNC
              ? props.row.original?.tasks?.filter(
                  (task) => task.employeeId !== null,
                ).length
              : undefined
          }
          connectionCategory={props.row.original?.connectionCategory}
        />
      ),
      meta: {
        style: { minWidth: '400px' },
        filterInput: SearchFilterInput,
      },
    }),
    columnHelper.accessor('type', {
      header: 'Job Type',
      cell: (props) => <TextCell text={getJobTypeLabel(props.getValue())} />,
      filterFn: 'arrIncludesSome',
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: jobTypeOptions,
      },
    }),
    columnHelper.accessor('date', {
      header: 'Completion Date',
      cell: (props) => {
        const value = props.getValue();
        return <TextCell text={value ? format(new Date(value)) : '-'} />;
      },
      filterFn: 'dateRange',
      meta: {
        filterInput: DateRangeFilterInput,
        filterInputOptions: dateRangeOptions,
        style: { minWidth: '190px' },
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (props) => (
        <HStack justify="space-between">
          <StatusCell status={props.getValue()} />
          <Tooltip placement="top-end" label={getStatusLabel(props)}>
            <div>
              <InfoIcon />
            </div>
          </Tooltip>
        </HStack>
      ),
      filterFn: 'arrIncludesSome',
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: jobStatusOptions,
        style: { maxWidth: '200px' },
      },
    }),
    columnHelper.accessor('connectionCategory', {
      enableHiding: true,
      meta: {
        filterLabel: 'Connection Category',
        filterInput: RadioFilterInput,
        filterInputOptions: connectionCategoryOptions,
      },
    }),
  ];

  return (
    <>
      <TableWrapper>
        <JobsTable
          isLoading={isLoading}
          data={jobs}
          columns={columns}
          textColor={COLORS.GRAY.GRAY_600}
        />
      </TableWrapper>
    </>
  );
};
