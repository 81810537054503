import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  Box,
  HStack,
  ListItem,
  Radio,
  List,
  Center,
  RadioGroup,
} from '@chakra-ui/react';

import { ReactNode, useState } from 'react';
import { COLORS } from '../../../constant';
import { CheckIcon } from '../../../shared/icons/CheckIcon';
import { Role } from '../../../constant/roles-and-permissions';
import { useUpdateUserRole } from '../../../auth/use-update-user-role';
import { CloseIcon } from '../../../shared/icons/CloseIcon';
import { getChangeRolePermissions } from './utils';
import { useRoles } from './use-roles';

const Permission = ({
  children,
  isDeny,
}: {
  children: ReactNode;
  isDeny?: boolean;
}) => {
  const icon = isDeny ? (
    <CloseIcon color={COLORS.FINCH.RED} />
  ) : (
    <CheckIcon color={COLORS.GREEN} />
  );
  return (
    <HStack as={ListItem} align="start" spacing="6px">
      <Center mt="6px">{icon}</Center>
      <Text fontSize="14px" color={COLORS.FINCH.BLACK} flexWrap="wrap">
        {children}
      </Text>
    </HStack>
  );
};

const RoleDescription = ({
  permissions,
  label,
  value,
  selected,
  extendsRole,
  currentRole,
  setRole,
}: {
  value: Role;
  label: string;
  permissions: string[];
  extendsRole?: Role;
  selected?: Role;
  currentRole?: string;
  setRole: (role: Role) => void;
}) => {
  const ROLES = useRoles();
  const extendedRoleOption = ROLES.find((role) => role.value === extendsRole);
  return (
    <Stack
      as="button"
      textAlign="left"
      onClick={() => setRole(value)}
      p="4"
      borderRadius="8px"
      border={`1px solid ${
        selected === value ? COLORS.FINCH.PURPLE : COLORS.GRAY.GRAY_400
      }`}
    >
      <HStack justify="space-between" pb="2" w="full">
        <Radio variant="outline" value={value}>
          {label}
        </Radio>
        {currentRole === value && (
          <Box
            borderRadius="6px"
            border={`1px solid ${COLORS.GRAY.GRAY_400}`}
            p="2px 6px"
            fontSize="12px"
            fontWeight="500"
            color={COLORS.GRAY.GRAY_600}
          >
            Current Role
          </Box>
        )}
      </HStack>
      {extendsRole && (
        <Text pl="4" pb="1" fontSize="14px" fontWeight="500">
          All {extendedRoleOption?.label} permissions plus:
        </Text>
      )}
      <List pl="4" spacing="2" variant="custom">
        {permissions.map((permission, index) => (
          <Permission key={index}>{permission}</Permission>
        ))}
      </List>
    </Stack>
  );
};

const ConfirmRoleChange = ({
  currentRole,
  email,
  memberId,
  onClose,
  newRole,
  setConfirmSelection,
}: {
  newRole: Role;
  currentRole: Role;
  email: string;
  memberId: string;
  onClose: () => void;
  setConfirmSelection: (value: boolean) => void;
}) => {
  const ROLES = useRoles();
  const { mutate, isPending } = useUpdateUserRole();
  const currentRoleOption = ROLES.find(({ value }) => value === currentRole);
  const newRoleOption = ROLES.find(({ value }) => value === newRole);

  const { isChangeUpgrade, permissions } = getChangeRolePermissions({
    currentRole,
    newRole,
    roles: ROLES,
  });

  const onConfirm = () => {
    mutate({ memberId, role: newRole, email }, { onSuccess: onClose });
  };
  return (
    <>
      <ModalHeader fontSize="18px" fontWeight="600" pb="1" textAlign="center">
        Confirm Role Change
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack pt="36px" spacing="4" fontSize="14px">
          <Text>
            Changing <strong>{email}</strong> from {currentRoleOption?.label} to{' '}
            {newRoleOption?.label} will {isChangeUpgrade ? ' give ' : ' deny '}
            them the following permissions:
          </Text>
          <List pl="4" spacing="2" variant="custom">
            {permissions.map((permission, index) => (
              <Permission isDeny={!isChangeUpgrade} key={index}>
                {permission}
              </Permission>
            ))}
          </List>
          <Text>Are you sure you want to do this?</Text>
        </Stack>
      </ModalBody>
      <ModalFooter pt="32px">
        <HStack spacing="4">
          <Button
            variant="secondary"
            onClick={() => setConfirmSelection(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primaryPurple"
            onClick={onConfirm}
            isLoading={isPending}
          >
            Confirm
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
};

const RoleSelection = ({
  onClose,
  email,
  currentRole,
  setConfirmSelection,
  role,
  setRole,
}: {
  onClose: () => void;
  email: string;
  currentRole: Role;
  setConfirmSelection: (value: boolean) => void;
  role: Role;
  setRole: (value: Role) => void;
}) => {
  const ROLES = useRoles();
  return (
    <>
      <ModalHeader fontSize="18px" fontWeight="600" pb="1">
        Edit {email}&apos;s role
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <RadioGroup value={role} onChange={(value) => setRole(value as Role)}>
          <Stack spacing="6">
            {ROLES.map(({ readablePermissions, label, value, extendsRole }) => (
              <RoleDescription
                key={value}
                value={value}
                permissions={readablePermissions}
                label={label}
                extendsRole={extendsRole}
                currentRole={currentRole}
                setRole={setRole}
                selected={role}
              />
            ))}
          </Stack>
        </RadioGroup>
      </ModalBody>
      <ModalFooter pt="32px">
        <HStack spacing="4">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primaryPurple"
            isDisabled={role === currentRole}
            onClick={() => setConfirmSelection(true)}
          >
            Update Role
          </Button>
        </HStack>
      </ModalFooter>
    </>
  );
};

export const ModalEditRole = ({
  isOpen,
  onClose,
  email,
  currentRole,
  memberId,
}: {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  currentRole: Role;
  memberId: string;
}) => {
  const [role, setRole] = useState<Role>(currentRole);
  const [confirmSelection, setConfirmSelection] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        {confirmSelection ? (
          <ConfirmRoleChange
            currentRole={currentRole}
            email={email}
            memberId={memberId}
            onClose={onClose}
            newRole={role}
            setConfirmSelection={setConfirmSelection}
          />
        ) : (
          <RoleSelection
            onClose={onClose}
            email={email}
            currentRole={currentRole}
            setConfirmSelection={setConfirmSelection}
            role={role}
            setRole={setRole}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
