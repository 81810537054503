import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { OrganizationClient } from '../../auth/organization-client';

export const useOrganization = () => {
  const auth0 = useAuth0();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  const organizationClient = new OrganizationClient(auth0);

  const members = useQuery({
    queryKey: ['organization', 'members'],
    queryFn: organizationClient.getOrganizationMembers,
    retry: 3,
  });

  const organization = useQuery({
    queryKey: ['organization'],
    queryFn: organizationClient.getOrganization,
  });

  const removeMember = useMutation({
    mutationFn: organizationClient.removeOrganizationMember,
    onSuccess: () => members.refetch(),
  });

  const inviteMember = useMutation({
    mutationFn: organizationClient.inviteOrganizationMember,
    onSuccess: () => members.refetch(),
  });

  const updateMfaSettings = useMutation({
    mutationFn: organizationClient.updateMfaSettings,
    onSuccess: () => {
      organization.refetch();
    },
    onError: () => {
      toast({
        description:
          'Error enforcing Multi-Factor Authentication for organization',
        status: 'error',
      });
    },
  });

  return {
    members,
    removeMember,
    inviteMember,
    organization,
    updateMfaSettings,
  };
};
