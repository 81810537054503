import { Box } from '@chakra-ui/react';
import { EmptyState } from '../components/EmptyState';
import { ProviderList } from '../components/ProviderList';
import { SortableProviderList } from '../components/SortableProviderList';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';
import { useSelectedTabStore } from '../state/selectedTab';
import { ProviderWithImplementation } from '../types';
import { EditProviderDrawer } from './EditProviderDrawer';
import { tabDetails } from '../constants';
import { useProviderSettings } from '../hooks/useProviderSettings';

export const ProvidersListPage = ({
  providers,
}: {
  providers: ProviderWithImplementation[];
}) => {
  const selectedTab = useSelectedTabStore((store) => store.selectedTab);

  const {
    updateIsPinned,
    updateEnabled,
    selectedProvider,
    clearSelectedProvider,
    handlePinnedProviderDrag,
    hasStagedConfiguration,
    setSelectedProviderId,
    pinnedProviders,
    shouldAllowUpdatesToProvider,
  } = useProviderConfigurations();

  const { providerSettings } = useProviderSettings();

  const selectedTabInfo = tabDetails[selectedTab];

  return (
    <Box overflow="auto">
      {providers.length === 0 && <EmptyState />}

      {selectedTabInfo.isIntegrationsList &&
        providerSettings &&
        (selectedTab === 'Pinned' ? (
          <SortableProviderList
            providers={pinnedProviders}
            providerSettings={providerSettings}
            onDragEnd={handlePinnedProviderDrag}
            updateIsPinned={updateIsPinned}
            updateEnabled={updateEnabled}
            shouldAllowUpdatesToProvider={shouldAllowUpdatesToProvider}
            setSelectedProviderId={setSelectedProviderId}
            hasStagedConfiguration={hasStagedConfiguration}
          />
        ) : (
          <ProviderList
            providers={providers}
            providerSettings={providerSettings}
            updateIsPinned={updateIsPinned}
            updateEnabled={updateEnabled}
            shouldAllowUpdatesToProvider={shouldAllowUpdatesToProvider}
            setSelectedProviderId={setSelectedProviderId}
            hasStagedConfiguration={hasStagedConfiguration}
          />
        ))}

      <EditProviderDrawer
        selectedProvider={selectedProvider}
        shouldAllowUpdatesToProvider={shouldAllowUpdatesToProvider}
        clearSelectedProvider={clearSelectedProvider}
        updateEnabled={updateEnabled}
        updateIsPinned={updateIsPinned}
      />
    </Box>
  );
};
