import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';

import { ContentBox } from '../../../../components/ContentBox';
import { categoryLabel } from '../constants';
import { Category, Item, Label } from '../types';
import { PayStatementCategoryRow } from './pay-statement-category-row';

function PayStatementCategoryGroupColumns({ header }: { header: Category }) {
  return (
    <HStack paddingX="24px" paddingY="12px" gap="32px">
      <Box width="100%" maxWidth="580px">
        <Flex gap="8px">
          <Text fontSize="sm" fontWeight={500}>
            Pay Statement Item Name
          </Text>
        </Flex>
      </Box>
      <Box width="100%">
        <Flex gap="8px">
          <Text fontSize="sm" fontWeight={500}>
            Label
          </Text>
        </Flex>
      </Box>
      {/*header === 'earnings' ? (
        <Flex gap="8px" minWidth="73px">
          <Text fontSize="sm" fontWeight={500}>
            Eligible
          </Text>
        </Flex>
      ) : null*/}
    </HStack>
  );
}

export function PayStatementCategoryGroup({
  header,
  items,
  labels,
  editing,
  disabled,
  onChange,
  onToggle,
  checked,
  values,
}: {
  editing: boolean;
  header: Category;
  items: Item[];
  labels: Label[];
  values: Partial<Record<Category, Record<string, string>>>;
  checked: Partial<Record<Category, Record<string, boolean>>>;
  onChange: (item: Item, label: string | string[]) => void;
  onToggle: (item: Item, polarity: boolean) => void;
  disabled: boolean;
}) {
  return (
    <Stack gap="12px" marginBottom="16px">
      <Text fontSize="md" fontWeight={500}>
        {categoryLabel[header]}
      </Text>
      <ContentBox>
        <PayStatementCategoryGroupColumns header={header} />
        {items.map((item, i) => (
          <PayStatementCategoryRow
            key={i}
            options={labels}
            editing={editing}
            disabled={disabled}
            category={item.category}
            name={item.name}
            checked={checked?.[item.category]?.[item.name]}
            value={values?.[item.category]?.[item.name]}
            onChange={(value) => onChange(item, value)}
            onToggle={
              item.category === 'earnings'
                ? (polarity) => onToggle(item, polarity)
                : undefined
            }
          />
        ))}
      </ContentBox>
    </Stack>
  );
}
