import { useEffect } from 'react';

import { useSelectedGroupStore } from '../state/selectedGroup';
import { isGroup } from '../utils';
import { useControlsFromURI } from './useControlsFromURI';

/**
 * Use the selected product (group), defaulting to a value found in the URL
 * query paramters if one is found.
 */
export function useSelectedGroup() {
  const { group } = useControlsFromURI();
  const { selectedGroup, setSelectedGroup } = useSelectedGroupStore();

  useEffect(() => {
    if (isGroup(group)) {
      setSelectedGroup(group);
    }
  }, []);

  return { selectedGroup, setSelectedGroup };
}
