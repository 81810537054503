import { MenuItem, HStack, Text, Center } from '@chakra-ui/react';
import { COLORS } from '../../../constant';

export const MenuOption = ({
  icon,
  children,
  danger,
  onClick,
}: {
  icon: JSX.Element;
  children: string;
  danger?: boolean;
  onClick: () => void;
}) => {
  const color = danger ? COLORS.FINCH.RED : COLORS.GRAY.GRAY_600;
  const hoverColor = danger ? COLORS.FINCH.RED : COLORS.FINCH.BLACK;
  const bg = danger ? COLORS.LIGHT_RED : COLORS.GRAY.GRAY_200;

  return (
    <MenuItem
      _hover={{ bg, color: hoverColor }}
      _focus={{ bg, color: hoverColor }}
      borderRadius="4px"
      color={color}
      fontSize="14px"
      onClick={onClick}
    >
      <HStack align="center">
        <Center boxSize="20px">{icon}</Center>
        <Text>{children}</Text>
      </HStack>
    </MenuItem>
  );
};
