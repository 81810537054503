import * as Sentry from '@sentry/react';
import axios from 'axios';

import { baseURL } from '../../utils/baseUrls';
import { OrganizationMember } from '@finch-api/developer-dashboard-common';

const organizationMembersUrl = new URL(
  `${baseURL}/api/v1/organization/members`,
);

export const getOrganizationMembers = async (
  token: string,
): Promise<OrganizationMember[]> => {
  try {
    return (
      await axios.get<OrganizationMember[]>(organizationMembersUrl.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;
  } catch (error) {
    Sentry.captureException(error);
  }
  return [];
};

export const inviteOrganizationMember = async (
  token: string,
  email: string,
): Promise<boolean> => {
  const requestURL = new URL(organizationMembersUrl.toString());

  try {
    await axios.patch(
      requestURL.toString(),
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};

export const removeOrganizationMember = async (
  token: string,
  id: string,
): Promise<boolean> => {
  const requestURL = new URL(organizationMembersUrl.toString());

  try {
    await axios.delete(requestURL.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id_to_remove: id,
      },
    });
    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
};
