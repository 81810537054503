export const parseRequestBody = (requestString: string) => {
  if (requestString && typeof requestString === 'string') {
    try {
      return JSON.parse(requestString);
    } catch (e) {
      console.error('Error parsing request body', e);
      return null;
    }
  }
};
