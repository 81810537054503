import axios from 'axios';
import * as Sentry from '@sentry/react';

import { ConnectionInfo } from './types';
import type { Auth0ContextInterface, User } from '@auth0/auth0-react';

export class ConnectionApi {
  constructor(
    private baseUrl: string,
    private auth: Auth0ContextInterface<User>,
  ) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth;
    return getAccessTokenSilently();
  };

  async getConnections(applicationId: string): Promise<ConnectionInfo[]> {
    const uri = new URL(
      `/api/v2/applications/${applicationId}/employers`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get<ConnectionInfo[]>(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
    }

    return [];
  }
}
