export enum Section {
  Organization = 'Organization',
  Payroll = 'Payroll',
  Management = 'Management',
  Deductions = 'Deductions',
  Sandbox = 'Sandbox',
}

export enum EndpointTitle {
  Company = 'Company',
  Directory = 'Directory',
  Individual = 'Individual',
  Employment = 'Employment',
  Payment = 'Payment',
  PayStatement = 'Pay Statement',
}

export const DATA_REQUEST_SECTIONS = [
  Section.Organization,
  Section.Payroll,
  Section.Deductions,
  Section.Sandbox,
  Section.Management,
];
