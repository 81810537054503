import { CreateConnectSessionError } from 'types/connect';
import {
  Spacer,
  Flex,
  Heading,
  Text,
  Image,
  ModalBody,
  ModalFooter,
  Button,
  Stack,
} from '@chakra-ui/react';
import invalidCircleIcon from 'assets/images/icons/invalidCircle.svg';
import { AxiosError } from 'axios';
import { Link, useParams } from 'react-router-dom';

export const ConnectSessionError = ({
  onClose,
  error,
}: {
  onClose: () => void;
  error: AxiosError<CreateConnectSessionError>;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();

  const doesConnectionAlreadyExist =
    error?.response?.data?.message === 'Connection already exists';

  const existingConnectionId =
    error?.response?.data?.extra?.context?.connectionId;

  return (
    <>
      <ModalBody>
        <Spacer height="36px" />
        <Flex>
          <Spacer />
          <Image src={invalidCircleIcon} width="64px" height="64px" />
          <Spacer />
        </Flex>
        <Spacer height="24px" />
        <Heading
          fontWeight="medium"
          fontSize="20px"
          lineHeight="26px"
          textAlign="center"
          marginBottom="8px"
        >
          Failed to create session
        </Heading>
        {doesConnectionAlreadyExist && existingConnectionId && (
          <Stack>
            <Text textAlign="center" marginBottom="30px">
              A connection with this customer ID already exists.{' '}
              <Link
                to={`/app/applications/${applicationId}/connections/${existingConnectionId}`}
                style={{ textDecoration: 'underline' }}
              >
                Click here
              </Link>{' '}
              to navigate to the connection.
            </Text>
          </Stack>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          w="full"
          variant="primary"
          onClick={onClose}
          h="52px"
          fontSize="16px"
        >
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
