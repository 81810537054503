import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Flex,
  ListItem,
  UnorderedList,
  Text,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import image from './images/connect_help_center_pop_out.png';

export const hasSeenReleaseModalName = 'hasSeenConnectPopOutHelpCenterFeature';

const setSeenModalInLocalStorage = () => {
  localStorage.setItem(hasSeenReleaseModalName, 'true');
};

export const FeatureReleaseModal = () => {
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  useEffect(() => {
    const hasSeenModalInLocalStorage = localStorage.getItem(
      hasSeenReleaseModalName,
    );
    if (!hasSeenModalInLocalStorage) {
      setShouldOpenModal(true);
    }
  }, []);

  return (
    <Modal
      onClose={() => {
        setSeenModalInLocalStorage();
        setShouldOpenModal(false);
      }}
      isOpen={shouldOpenModal}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {' '}
          <span role="img" aria-label="party popper">
            🎉
          </span>{' '}
          Authentication Guides Now in Finch Connect
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="3">
            <Flex justifyContent="center">
              {/* NOTE: ideal dimensions are 930 × 600 */}
              <Image src={image} />
            </Flex>
            <Text>
              Starting today, all our Authentication Guides are embedded into
              Finch Connect. Rather than navigating to our Help Center,
              employers can find detailed authentication instructions right
              where they need it—no extra clicks required.
            </Text>
            <Text>
              We&apos;ve also made it harder to close out Finch Connect by
              accident, so no one risks losing their progress. Happy connecting!
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => {
              setSeenModalInLocalStorage();
              setShouldOpenModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
