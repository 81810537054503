import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  FormLabel,
  Stack,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { type Control, useController, type FormState } from 'react-hook-form';

import { CreatableSelect } from 'chakra-react-select';

import { Role } from '../../../constant/roles-and-permissions';
import { ChevronDownIcon } from '../../../shared/icons/ChevronDownIcon';
import { InviteFormValues } from './Modal';
import { COLORS } from '../../../constant';
import { addNewEmails, getFormError } from './utils';

export const EmailInputForm = ({
  inputValue,
  setInputValue,
  control,
  formState,
}: {
  control: Control<InviteFormValues>;
  formState: FormState<InviteFormValues>;
  inputValue: string;
  setInputValue: (value: string) => void;
}) => {
  const { field: emailsField } = useController({
    name: 'emails',
    control,
    defaultValue: [],
  });

  const { field: roleField } = useController({
    name: 'role',
    control,
    defaultValue: Role.Member,
  });

  const formError = getFormError(formState.errors, emailsField.value);

  const faultyEmailErrors = Array.isArray(formState?.errors?.emails)
    ? formState?.errors?.emails
    : [];

  return (
    <>
      <HStack align="start" minH="150px">
        <FormControl isInvalid={!!formState.errors.emails}>
          <FormLabel fontSize="sm">Email</FormLabel>
          <CreatableSelect
            isMulti
            name="emails"
            placeholder=""
            inputValue={inputValue}
            onInputChange={setInputValue}
            onBlur={() => {
              if (!inputValue.trim()) return;
              emailsField.onChange(
                addNewEmails({
                  existing: emailsField.value,
                  new: inputValue,
                }),
              );
            }}
            chakraStyles={{
              dropdownIndicator: () => ({ display: 'none' }),
              menuList: () => ({ display: 'none' }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                bg: COLORS.GRAY.GRAY_200,
              }),
              input: (baseStyles) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
            value={emailsField.value.map((email, index) => ({
              value: email,
              label: email,
              variant: faultyEmailErrors[index] ? 'danger' : 'info',
            }))}
            onChange={(value) =>
              emailsField.onChange(value.map((v) => v.value))
            }
            onKeyDown={(e) => {
              if (e.key === ',' || e.key === 'Enter') {
                e.preventDefault();
                if (!inputValue.trim()) return;
                emailsField.onChange(
                  addNewEmails({
                    existing: emailsField.value,
                    new: inputValue,
                  }),
                );
                setInputValue('');
              }
            }}
          />

          <FormHelperText color={COLORS.GRAY.GRAY_600}>
            Use a comma between email addresses to invite multiple members at
            one time.
          </FormHelperText>

          <FormErrorMessage>{formError}</FormErrorMessage>
        </FormControl>

        <Stack spacing="0">
          <FormLabel fontSize="sm">Role</FormLabel>
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              minW="140px"
              px="12px"
              fontWeight="400"
              textAlign="left"
              variant="secondary"
              rightIcon={<ChevronDownIcon />}
            >
              {roleField.value || 'Role'}
            </MenuButton>
            <MenuList minW="150px">
              <MenuOptionGroup
                defaultValue={Role.Member}
                onChange={(value) => roleField.onChange(value)}
              >
                <MenuItemOption value={Role.Member}>Member</MenuItemOption>
                <MenuItemOption value={Role.Editor}>Editor</MenuItemOption>
                <MenuItemOption value={Role.Admin}>Admin</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Stack>
      </HStack>
    </>
  );
};
