import { Stack, HStack, Text } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { SubSectionHeading } from '../../shared/Typography';
import { ReactNode } from 'react';

type SettingEntryProps = {
  title: ReactNode;
  details: ReactNode;
  action?: ReactNode;
};

export const SettingEntry = ({ title, details, action }: SettingEntryProps) => {
  return (
    <Stack px="6" py="4" fontSize="14px">
      <SubSectionHeading as="h3" fontSize="14px">
        {title}
      </SubSectionHeading>
      <HStack justify="space-between" align="start" flexWrap="wrap">
        <Text
          color={COLORS.GRAY.GRAY_600}
          flexBasis={{ base: '100%', md: '40%' }}
        >
          {details}
        </Text>
        {action}
      </HStack>
    </Stack>
  );
};
