import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { WebhookEvent } from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { COLORS } from '../../constant';
import startCase from 'lodash/startCase';
import { useCallback, useEffect, useState } from 'react';
import { MinusIcon } from '../../shared/icons/MinusIcon';
import { WebhookFormError } from '../types';

const CategoryCheckbox = ({ isChecked }: { isChecked?: boolean }) => {
  return isChecked ? <MinusIcon /> : null;
};

export const WebhookEventSelection = ({
  events,
  formEvents,
  error,
  onEventSelected,
  onBulkUpdateEvents,
}: {
  events: WebhookEvent[];
  formEvents: string[];
  error?: WebhookFormError;
  onEventSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBulkUpdateEvents: (eventNames: string[]) => void;
}) => {
  const [eventCategoryMap, setEventCategoryMap] = useState<{
    [key: string]: WebhookEvent[];
  }>({});

  useEffect(() => {
    const newEventCategoryMap = events.reduce((acc, event) => {
      const category = event.name.split('.')[0];

      if (typeof category === 'string') {
        const currentCatItems = acc[category] || [];

        currentCatItems.push(event);
        acc[category] = currentCatItems;
      }

      return acc;
    }, {} as { [key: string]: WebhookEvent[] });

    setEventCategoryMap(newEventCategoryMap);
  }, [events]);

  const onCategorySelected = useCallback(
    (categoryName: string) => {
      const allEventsInSelectedCategory = eventCategoryMap[categoryName];
      const categoryIsSelected = allEventsInSelectedCategory?.every((event) =>
        formEvents.includes(event.name),
      );

      const eventNamesInCategory = allEventsInSelectedCategory?.map(
        (event) => event.name,
      );

      if (categoryIsSelected) {
        const updatedEventList = formEvents.filter(
          (event) => !eventNamesInCategory?.includes(event),
        );

        onBulkUpdateEvents(Array.from(updatedEventList));
      } else {
        if (eventNamesInCategory) {
          const updatedEventList = new Set([
            ...formEvents,
            ...eventNamesInCategory,
          ]);

          onBulkUpdateEvents(Array.from(updatedEventList));
        }
      }
    },
    [eventCategoryMap, formEvents, onBulkUpdateEvents],
  );

  const onClearAll = () => {
    onBulkUpdateEvents([]);
  };

  const areAllEventsInBeta = (category: string) => {
    return eventCategoryMap[category]?.every((evt) => evt.isBeta) ?? false;
  };

  return (
    <Box padding="24px">
      <FormControl isInvalid={!!error?.events}>
        <FormLabel htmlFor="events" fontSize="14px">
          Events
        </FormLabel>

        <Box border="1px" borderColor="gray.c400" borderRadius="8px">
          <Box padding="12px" height={'344px'} overflow="auto">
            {Object.keys(eventCategoryMap).map((category) => (
              <Box key={category} paddingBottom={'32px'}>
                <Stack paddingBottom={'16px'}>
                  <Checkbox
                    value={category}
                    alignItems="center"
                    icon={<CategoryCheckbox />}
                    onChange={() => {
                      onCategorySelected(category);
                    }}
                    isChecked={eventCategoryMap[category]?.every((event) =>
                      formEvents.includes(event.name),
                    )}
                  >
                    <HStack>
                      <Text
                        fontSize={'14px'}
                        fontWeight="medium"
                        casing={'capitalize'}
                        key={category}
                      >
                        {`${startCase(category)} Events`}
                      </Text>
                      {areAllEventsInBeta(category) && (
                        <Tag
                          size={'sm'}
                          backgroundColor="#EFF1FF"
                          color={COLORS.FINCH.PURPLE}
                        >
                          Beta
                        </Tag>
                      )}
                    </HStack>
                  </Checkbox>
                </Stack>
                <Stack paddingLeft="24px" gap={'16px'}>
                  {eventCategoryMap[category]?.map((event) => (
                    <Checkbox
                      key={event.name}
                      value={event.name}
                      alignItems="center"
                      onChange={onEventSelected}
                      isChecked={formEvents.includes(event.name)}
                    >
                      <Text fontSize={'14px'}>{event.name}</Text>
                    </Checkbox>
                  ))}
                </Stack>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          display="flex"
          paddingTop="16px"
          alignItems={'center'}
          justifyContent={'space-between'}
          minHeight={'40px'}
        >
          {formEvents.length > 0 && (
            <>
              <Text fontSize={'14px'} fontWeight="medium">{`${
                formEvents.length ?? 0
              } events selected`}</Text>
              <Button variant="link" onClick={onClearAll}>
                Clear all
              </Button>
            </>
          )}
        </Box>

        <FormErrorMessage>{error?.events}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
