import { useCallback } from 'react';

import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { getLogs } from '../logs';
import { useParams } from 'react-router-dom';
import { SearchQuery } from '@finch-api/developer-dashboard-common';
import { useActivityStore } from '../state/activityStore';

const adjustFilterFields = (filter: SearchQuery) => {
  if ('success' === filter.statusDescription) {
    filter.statusDescription = '';
  } else if (filter.statusDescription === 'All') {
    filter.statusCode = undefined;
    filter.statusDescription = undefined;
  } else if ('all' === filter.payrollProviderId) {
    filter.payrollProviderId = undefined;
  }
  return filter;
};

export const useActivityLogs = () => {
  const {
    loading,
    setLoading,
    logs,
    setLogs,
    totalLogSize,
    setTotalLogSize,
    draftFilters,
    commitFilters,
    updateDraftFilters,
  } = useActivityStore();

  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const { applicationId } = useParams<{ applicationId: string }>();

  const fetchLogs = useCallback(async () => {
    if (loading) {
      console.warn('Skipping logs fetch. A fetch is already in progress');
      return;
    }

    if (!applicationId) {
      console.error('no applicationId provided');
      return;
    }

    setLoading(true);

    const token = await getAccessTokenSilently();

    const { activeFilters } = useActivityStore.getState();

    if (token) {
      const response = await getLogs({
        token,
        applicationId,
        searchFilter: activeFilters,
      });

      setTotalLogSize(response.total);
      setLogs(response.response);
    }

    setLoading(false);
  }, [
    applicationId,
    getAccessTokenSilently,
    loading,
    setLoading,
    setTotalLogSize,
    setLogs,
  ]);

  const submitSearch = useCallback(() => {
    const updatedFilters = adjustFilterFields({
      ...draftFilters,
      pageIndex: 0,
    });

    updateDraftFilters(updatedFilters);

    commitFilters();

    fetchLogs();
  }, [fetchLogs, draftFilters, commitFilters, updateDraftFilters]);

  return {
    fetchLogs,
    logs,
    totalLogSize,
    submitSearch,
  };
};
