import { create } from 'zustand';

export enum JsonMode {
  Builder = 0,
  Raw = 1,
}

export const useSelectedJsonMode = create<{
  selectedJsonMode: JsonMode;
  setSelectedJsonMode: (mode: JsonMode) => void;
}>((set) => ({
  selectedJsonMode: JsonMode.Builder,
  setSelectedJsonMode: (mode) => set({ selectedJsonMode: mode }),
}));
