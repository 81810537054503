import { type FieldErrors } from 'react-hook-form';
import { InviteFormValues } from './Modal';
import { type ZodIssue } from 'zod';

export const getFormError = (
  errors: FieldErrors<InviteFormValues>,
  formEmails: string[],
) => {
  if (!errors.emails) return;

  const faultyEmailErrors: ZodIssue[] = Array.isArray(errors.emails)
    ? errors.emails
    : [];

  const emailIsInvalid = faultyEmailErrors.some(
    (error) => error.message === 'Invalid email',
  );

  const emailExists = faultyEmailErrors.some(
    (error) => error.message === 'Email already exists',
  );

  if (emailExists) {
    return formEmails.length > 1
      ? 'Some emails above already have existing members.'
      : 'A member with this email address already exists.';
  }

  if (emailIsInvalid) {
    return formEmails.length > 1
      ? 'Some emails above are invalid'
      : 'This email address is invalid.';
  }

  // @ts-expect-error This is a valid type. Not sure why Zod is not recognizing it
  return (errors.emails as ZodIssue)?.message;
};

export const addNewEmails = (opts: { existing: string[]; new: string }) => {
  const { existing: existingEmails, new: newEmail } = opts;

  if (!newEmail.trim()) return existingEmails;

  return Array.from(
    new Set([...existingEmails, newEmail.toLowerCase().trim()]),
  );
};
