import { create } from 'zustand';
import { Tab } from '../types';

export const useUnsavedSettingsModalStore = create<{
  isOpen: boolean;
  nextTabToNavigate: Tab | null;
  setIsOpen: (isOpen: boolean) => void;
  setNextTabToNavigate: (nextTabToNavigate: Tab | null) => void;
}>((set) => ({
  isOpen: false,
  nextTabToNavigate: null,
  setIsOpen: (isOpen) => set({ isOpen }),
  setNextTabToNavigate: (nextTabToNavigate) => set({ nextTabToNavigate }),
}));
