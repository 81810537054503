import { AssistedIcon } from '../../shared/AssistedIcon';
import { AutomatedIcon } from '../../shared/AutomatedIcon';
import { ConnectionCategory } from '@finch-api/common/dist/external/dashboard/connection-status';
import { HStack } from '@chakra-ui/react';
import { Caption } from '../../shared/Typography';

export const ConnectionType = ({
  kind,
  disconnected,
}: {
  kind: ConnectionCategory;
  disconnected: boolean;
}) => {
  const opacity = disconnected ? 0.5 : 1.0;

  switch (kind) {
    case ConnectionCategory.Assisted:
      return (
        <HStack opacity={opacity}>
          <AssistedIcon />
          <Caption>Assisted</Caption>
        </HStack>
      );
    case ConnectionCategory.Automated:
      return (
        <HStack opacity={opacity}>
          <AutomatedIcon />
          <Caption>Automated</Caption>
        </HStack>
      );
  }
};
