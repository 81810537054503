import { Button, type ButtonProps } from '@chakra-ui/react';

export const PillButton = ({
  children,
  ...props
}: ButtonProps & { children: React.ReactNode }) => {
  return (
    <Button
      p="2"
      variant="secondary"
      fontWeight="400"
      h="6"
      fontSize="13px"
      sx={{
        '.chakra-button__icon': {
          mr: props.leftIcon ? '1' : '0',
          ml: '0',
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
