import { Tooltip, Box } from '@chakra-ui/react';
import { ReactNode, useRef, useState, useEffect } from 'react';

export const OverflowTooltip = ({
  label,
  children,
}: {
  label?: ReactNode;
  children: ReactNode;
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current;
    setIsOverflown((element?.scrollWidth ?? 0) > (element?.clientWidth ?? 0));
  }, []);

  return (
    <Tooltip
      label={label ?? children}
      isDisabled={!isOverflown}
      hasArrow
      placement="bottom-start"
    >
      <Box
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        ref={ref}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
