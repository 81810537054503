import noop from 'lodash/noop';

import { PillButton } from '../../../../components/PillButton';
import { EditIcon } from '../../../../shared/icons/EditIcon';
import { DownloadIcon } from '../../../../shared/icons/Download';
import { useDisclosure } from '@chakra-ui/react';

import { PayStatementEditConfirmationModal } from './pay-statement-edit-confirmation-modal';
import { useCallback } from 'react';

export function PayStatementCategoryEditControls({
  remapping,
  disabled,
  editing,
  setEditing,
  saveEdits,
  cancelEdits,
  download,
}: {
  remapping: boolean;
  disabled: boolean;
  editing: boolean;
  setEditing: () => void;
  saveEdits: () => void;
  cancelEdits: () => void;
  download: () => void;
}) {
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose,
  } = useDisclosure();

  const onSave = useCallback(() => {
    if (remapping) {
      return onConfirmationModalOpen();
    }

    return saveEdits();
  }, [remapping, saveEdits, onConfirmationModalOpen]);

  if (editing) {
    return (
      <>
        <PillButton
          variant="secondary"
          onClick={disabled ? noop : cancelEdits}
          disabled={disabled}
        >
          Cancel
        </PillButton>
        <PillButton
          variant="primaryPurple"
          type="submit"
          onClick={disabled ? noop : onSave}
          disabled={disabled}
        >
          Save Changes
        </PillButton>

        {isConfirmationModalOpen && (
          <PayStatementEditConfirmationModal
            isOpen={isConfirmationModalOpen}
            onCancel={onConfirmationModalClose}
            onClose={onConfirmationModalClose}
            onSave={disabled ? noop : saveEdits}
          />
        )}
      </>
    );
  }

  return (
    <>
      <PillButton
        leftIcon={<DownloadIcon />}
        onClick={disabled ? noop : download}
        disabled={disabled}
      >
        Download CSV
      </PillButton>
      <PillButton
        leftIcon={<EditIcon hollow />}
        onClick={disabled ? noop : setEditing}
        disabled={disabled}
      >
        Edit mappings
      </PillButton>
    </>
  );
}
