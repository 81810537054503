import { PropsWithChildren } from 'react';
import { Box, Flex, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { type CellContext, type Row } from '@tanstack/react-table';

import { Caption } from '../../../shared/Typography';
import { OverflowTooltip } from '../../../shared/OverflowTooltip';
import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { inviteOrganizationMember } from '../organizationMembers';
import { useStatusToast } from '../../../shared/StatusToast';
import { Confirmation } from '../../../shared/Confirmation';
import { useOrganization } from '../../../shared/hooks/use-organization';
import { MemberStatusTag } from '../../../components/Tag/MembersStatusTag';
import { StatusTag } from '../../../shared/StatusTag';
import { TrashIcon } from '../../../shared/icons/TrashIcon';
import { OrgMember } from '../../../auth/organization-client';
import { MenuOption } from './MenuOption';

type Member = {
  email: string;
  id: string;
};

export type OrganizationMemberColumn = {
  member: Member;
  roles: string[];
  invitation: OrgMember['invitation'];
  hasAccepted: boolean;
  mfaEnabled: boolean;
};

export const DeleteOption = ({
  row,
}: {
  row: Row<OrganizationMemberColumn>;
}) => {
  const toast = useToast({ duration: 3000, isClosable: true });
  const member = row.original.member;
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { removeMember } = useOrganization();

  const onRemove = async () => {
    removeMember.mutate(member.id, {
      onSuccess: () => {
        toast({
          description: `${member.email} has been successfully removed`,
          status: 'success',
        });
        onClose();
      },
    });
  };

  return (
    <Flex justifyContent="flex-end">
      <MenuOption danger icon={<TrashIcon />} onClick={onOpen}>
        Remove
      </MenuOption>
      <Confirmation
        isOpen={isOpen}
        heading="Confirm Member Removal"
        isLoading={removeMember.isPending}
        body={
          <>
            You’re about to remove <strong>{member.email}</strong> from your
            organization. They will no longer be able to log in or access their
            account.
            <br />
            <br />
            Are you sure you want to do this?{' '}
          </>
        }
        cancel={{ action: onClose }}
        confirm={{ action: onRemove, text: 'Remove Member' }}
      />
    </Flex>
  );
};

const PendingInvitationButton = ({
  email,
  isExpired,
}: {
  email: string;
  isExpired?: boolean;
}) => {
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const toast = useStatusToast();

  return (
    <Tooltip label="Click to resend invitation." placement="bottom-start">
      <Box
        as="button"
        onClick={async () => {
          const token = await getAccessTokenSilently();
          if (token) {
            const inviteSuccess = await inviteOrganizationMember(token, email);
            toast({
              message: `We've successfully resent an invite to ${email}`,
              wasSuccessful: inviteSuccess,
            });
          }
        }}
      >
        <MemberStatusTag variant={isExpired ? 'expired' : 'pending'} />
      </Box>
    </Tooltip>
  );
};

export const MemberCell = ({
  props,
}: {
  props: PropsWithChildren<CellContext<OrganizationMemberColumn, Member>>;
}) => {
  return (
    <Flex>
      <OverflowTooltip>
        <Caption>{props.getValue().email}</Caption>
      </OverflowTooltip>
    </Flex>
  );
};

export const MemberRoleCell = ({
  props,
}: {
  props: PropsWithChildren<CellContext<OrganizationMemberColumn, string[]>>;
}) => {
  return <Caption>{props.getValue()[0] || '-'}</Caption>;
};

export const MemberStatusCell = ({
  props,
}: {
  props: PropsWithChildren<
    CellContext<OrganizationMemberColumn, OrgMember['invitation']>
  >;
}) => {
  const { user }: Auth0ContextInterface<User> = useAuth0();
  if (user?.email === props.row.original.member.email) {
    return <MemberStatusTag variant="you" />;
  }

  const { has_accepted, expired_at } = props.getValue();

  const isExpired = expired_at ? new Date() > new Date(expired_at) : false;

  return (
    <Box>
      {has_accepted ? (
        <MemberStatusTag variant="active" />
      ) : (
        <PendingInvitationButton
          isExpired={isExpired}
          email={props.row.original.member.email}
        />
      )}
    </Box>
  );
};

export const MemberMfaCell = ({
  props,
}: {
  props: PropsWithChildren<CellContext<OrganizationMemberColumn, boolean>>;
}) => {
  return (
    <Box>
      {props.getValue() ? (
        <StatusTag variant="success" fontWeight="normal" minH="28px">
          Enabled
        </StatusTag>
      ) : (
        <StatusTag variant="danger" fontWeight="normal" minH="28px">
          Disabled
        </StatusTag>
      )}
    </Box>
  );
};
