import { createContext, useContext, useEffect, useState } from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { Application } from '@finch-api/developer-dashboard-common';

import { getApplications } from '../applications/api';
import { sortApplications } from '../credentials/utils';

type OrganizationApplicationsContextValue = {
  applications: Application[];
  // TODO: @aren55555 extend this to support re-fetching applications (say after
  //       creation)
};

const OrganizationApplicationsContext =
  createContext<OrganizationApplicationsContextValue>({
    applications: [],
  });

export const OrganizationApplications = (props: { children: JSX.Element }) => {
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const [applications, setApplications] = useState<Application[]>([]);

  useEffect(() => {
    const run = async () => {
      const token = await getAccessTokenSilently();
      if (token) {
        const _applications = await getApplications(token);
        const sortedApplications = sortApplications(_applications);
        setApplications(sortedApplications);
      }
    };
    run();
  }, [getAccessTokenSilently]);

  return (
    <OrganizationApplicationsContext.Provider value={{ applications }}>
      {props.children}
    </OrganizationApplicationsContext.Provider>
  );
};

export const useOrganizationApplications = () => {
  const context = useContext(OrganizationApplicationsContext);
  if (context === undefined) {
    throw new Error(
      'useApplications must be used within a OrganizationApplicationsContext',
    );
  }
  return context.applications;
};
