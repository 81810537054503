export const PinnedIcon = ({
  color,
  unpinned,
  opacity = '1',
}: {
  color?: string;
  unpinned?: boolean;
  opacity?: string;
}) =>
  !unpinned ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 10L15 12V13.5H10.75V18.25L10 19L9.25 18.25V13.5H5V12L7 10V4.5H6V3H14V4.5H13V10Z"
        fill={color || 'currentColor'}
        opacity={opacity}
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="push_pin_FILL0_wght400_GRAD0_opsz20 1">
        <path
          id="Vector"
          d="M13 10L15 12V13.5H10.75V18.25L10 19L9.25 18.25V13.5H5V12L7 10V4.5H6V3H14V4.5H13V10ZM7.125 12H12.875L11.5 10.625V4.5H8.5V10.625L7.125 12Z"
          fill={color || 'currentColor'}
          opacity={opacity}
        />
      </g>
    </svg>
  );
