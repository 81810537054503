import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { UseMutationResult } from '@tanstack/react-query';
import { useState } from 'react';
import { COLORS } from '../../constant';
import { AxiosError } from 'axios';

export const ModalPassword = ({
  email,
  isOpen,
  onClose,
  onSuccess,
  getMfaToken,
}: {
  email: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  getMfaToken: UseMutationResult<string, AxiosError, string>;
}) => {
  const [password, setPassword] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW="500px">
        <ModalHeader fontSize="18px" fontWeight="600" pb="1" textAlign="center">
          Password Required
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl
            minH="70px"
            isInvalid={!!getMfaToken.error?.response?.data.error}
          >
            <FormLabel fontSize="sm" fontWeight="normal" pt="4">
              Please enter the password for <strong>{email}</strong> to proceed.
            </FormLabel>
            <Input
              placeholder="Enter password"
              value={password}
              fontSize="14px"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormErrorMessage fontSize="xs" color={COLORS.FINCH.RED}>
              {getMfaToken.error?.response?.data.error === 'invalid_grant' &&
                'Invalid password'}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter pt="32px">
          <HStack spacing="4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primaryPurple"
              onClick={() => getMfaToken.mutate(password, { onSuccess })}
              isLoading={getMfaToken.isPending}
              isDisabled={!password}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
