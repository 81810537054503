import { Box, HStack, Tooltip } from '@chakra-ui/react';
import { InvertedInfoIcon } from '../../shared/InvertedInfoIcon';
import { COLORS } from '../../constant';

export const ConfigurationNeededToolTip = ({
  text,
}: {
  text: React.ReactNode;
}) => {
  return (
    <HStack>
      <Tooltip label={text} placement="top">
        <Box>
          <InvertedInfoIcon bgColor={COLORS.YELLOW_DARK} />
        </Box>
      </Tooltip>
    </HStack>
  );
};
