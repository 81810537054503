import { Flex, HStack, Skeleton, Tag, Tooltip } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { RiQuestionLine } from 'react-icons/ri';

type LimitType = 'connections' | 'accounts';

type Props = {
  used: number;
  limit: number | null;
  loading?: boolean;
  limitType: LimitType;
};

const LIMIT_MESSAGES: Record<LimitType, string> = {
  connections: 'Please disconnect an existing connection to add a new one.',
  accounts: 'Please disconnect an existing account to add a new one.',
};

const LimitTooltip = ({ message }: { message: string }) => {
  return (
    <Tooltip placement="top" label={message} maxW="210px">
      <HStack align="center">
        <RiQuestionLine color={COLORS.GRAY.GRAY_600} size="20px" />
      </HStack>
    </Tooltip>
  );
};

export const SandboxCount = ({ used, limit, loading, limitType }: Props) => {
  const hasLimit = typeof limit === 'number';
  const skeletonWidth = hasLimit ? 92 : 72;

  if (!hasLimit) {
    return null;
  }

  if (loading) {
    return <Skeleton borderRadius="6px" width={`${skeletonWidth}px`} />;
  }

  const reached = used >= limit;
  const label = `Using ${used} of ${limit}`;

  const variant: 'danger' | 'success' = reached ? 'danger' : 'success';

  return (
    <Flex gap="4px">
      {reached && <LimitTooltip message={LIMIT_MESSAGES[limitType]} />}
      <Tag variant={variant} size="md">
        <Flex alignItems="center" fontWeight="400">
          <span>{label}</span>
        </Flex>
      </Tag>
    </Flex>
  );
};
