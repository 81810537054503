import {
  HStack,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { ConnectionsTable } from './ConnectionsTable';
import { StagedConnectionsTable } from './StagedConnectionsTable';
import { Connection } from '../model';
import {
  ConnectionAttentionBanner,
  connectionsNeedAttention,
} from './ConnectionAttentionBanner';
import { useRef, useState } from 'react';
import { NumberCount } from 'components/NumberCount';
import { StagedConnection } from '../types';
import {
  ConnectionStage,
  useConnectionStageQuerySync,
} from '../hooks/useConnectionStageSync';
import startCase from 'lodash/startCase';
import { AppTab, AppTabList } from 'components/Tabs';
import { useApplication } from '../../applications/hooks';

const ConnectionsTabs = [
  ConnectionStage.Live,
  ConnectionStage.Staged,
  ConnectionStage.Disconnected,
] as const;

export const AllConnectionsTable = ({
  liveConnections,
  disconnectedConnections,
  stagedConnections,
  loading,
  isSandbox,
  error,
  stagedError,
  stagedLoading,
}: {
  liveConnections: Connection[];
  disconnectedConnections: Connection[];
  stagedConnections: StagedConnection[];
  isSandbox?: boolean;
  loading: boolean;
  error: Error | null;
  stagedError: Error | null;
  stagedLoading: boolean;
}) => {
  const connectionCount = connectionsNeedAttention(liveConnections);
  const connectionsTableRef = useRef<HTMLDivElement>(null);
  const [filterToNeedsAttention, setFilterToNeedsAttention] =
    useState<boolean>(false);

  const scrollToConnectionsTable = () => {
    connectionsTableRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const [connectionStage, setConnectionStage] = useConnectionStageQuerySync();

  const filterAndScroll = () => {
    setFilterToNeedsAttention(true);
    scrollToConnectionsTable();
  };

  const { application } = useApplication();

  return (
    <Tabs
      flexGrow={1}
      index={ConnectionsTabs.indexOf(connectionStage)}
      isLazy
      onChange={(index) =>
        setConnectionStage(ConnectionsTabs[index] as ConnectionStage)
      }
    >
      <AppTabList gap={6}>
        {ConnectionsTabs.map((title) => (
          <AppTab key={title}>
            <HStack>
              <Text>{startCase(title)}</Text>
              <NumberCount>
                {(() => {
                  switch (title) {
                    case ConnectionStage.Live:
                      return liveConnections.length;
                    case ConnectionStage.Staged:
                      return stagedConnections.length;
                    case ConnectionStage.Disconnected:
                      return disconnectedConnections.length;
                    default:
                      return 0;
                  }
                })()}
              </NumberCount>
            </HStack>
          </AppTab>
        ))}
      </AppTabList>
      <TabPanels>
        <TabPanel paddingLeft="0" paddingRight="0">
          <Stack spacing={4}>
            {!application?.is_blocked && (
              <ConnectionAttentionBanner
                connectionCount={connectionCount}
                onClick={filterAndScroll}
                fullWidth={true}
              />
            )}
            <div ref={connectionsTableRef}>
              <ConnectionsTable
                connections={liveConnections}
                loading={loading}
                isSandbox={isSandbox}
                error={error}
                disabled={application?.is_blocked}
                filterToNeedsAttention={filterToNeedsAttention}
                setFilterToNeedsAttention={setFilterToNeedsAttention}
                connectionStage={ConnectionStage.Live}
              />
            </div>
          </Stack>
        </TabPanel>
        <TabPanel paddingLeft="0" paddingRight="0">
          <StagedConnectionsTable
            connections={stagedConnections}
            loading={stagedLoading}
            isSandbox={isSandbox}
            error={stagedError}
            disabled={application?.is_blocked}
          />
        </TabPanel>
        <TabPanel paddingLeft="0" paddingRight="0">
          <Stack spacing={4}>
            <ConnectionsTable
              connections={disconnectedConnections}
              loading={loading}
              isSandbox={isSandbox}
              error={error}
              disabled={application?.is_blocked}
              filterToNeedsAttention={filterToNeedsAttention}
              setFilterToNeedsAttention={setFilterToNeedsAttention}
              connectionStage={ConnectionStage.Disconnected}
            />
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
