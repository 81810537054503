import { Box, Button, Center, Image, Stack, Text } from '@chakra-ui/react';
import { ExternalLink } from '../../shared/ExternalLink';
import { Caption } from '../../shared/Typography';
import { FINCH_DOCS_BASE_URL } from '../../shared/links';
import { SearchIcon } from '../../shared/icons/SearchIcon';
import connections from '../../assets/images/icons/connections.svg';

export const EmptyStateMessage = ({
  omitExtendedCaption,
}: {
  omitExtendedCaption?: boolean;
}) => {
  return (
    <Center height="300px" border="1px" borderColor="border.gray">
      <Stack align="center" spacing="4">
        <Box p="20px" bg="primary.50" borderRadius="50%">
          <Image src={connections} />
        </Box>
        <Caption>
          You have no connected companies.{' '}
          {!omitExtendedCaption && (
            <>
              Read our{' '}
              <ExternalLink to={FINCH_DOCS_BASE_URL}>
                Documentation
              </ExternalLink>{' '}
              to get started with Finch.
            </>
          )}
        </Caption>
      </Stack>
    </Center>
  );
};

export const EmptyTableRowStateMessage = ({
  text = 'No results found.',
  height = '400px',
}: {
  text?: string;
  height?: string;
}) => {
  return (
    <Center height={height} border="1px" borderColor="border.gray">
      <SearchIcon />
      <Text>No jobs with the selected filters</Text>
      <Text>Try changing the filters or search terms</Text>
      <Button>Clear all filters</Button>
      <Caption>{text}</Caption>
    </Center>
  );
};
