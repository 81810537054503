import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
} from '@chakra-ui/react';

import { COLORS } from '../../constant';
import { useUnsavedSettingsModalStore } from '../state/unsavedSettingsModal';
import { useSelectedTabStore } from '../state/selectedTab';
import { useSaveProviderSettings } from '../hooks/useSaveProviderSettings';

export const UnsavedSettingsModal = () => {
  const { isOpen, setIsOpen, nextTabToNavigate } =
    useUnsavedSettingsModalStore();
  const { setSelectedTab } = useSelectedTabStore();
  const {
    mutateAsync: mutateSettings,
    isPending: isPendingSettings,
    clearStagedProviderSettings,
  } = useSaveProviderSettings();

  const onSave = async () => {
    await mutateSettings();
    if (nextTabToNavigate) {
      setSelectedTab(nextTabToNavigate);
    }
    setIsOpen(false);
  };

  const onDiscard = () => {
    clearStagedProviderSettings();
    if (nextTabToNavigate) {
      setSelectedTab(nextTabToNavigate);
    }
    setIsOpen(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="18px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            alignSelf="center"
          >
            Unsaved Changes
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="14px" color={COLORS.FINCH.BLACK}>
              Discard or publish your unsaved changes before leaving this page
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Button
              onClick={onDiscard}
              mr="3"
              variant="outline"
              alignItems="center"
            >
              Discard
            </Button>
            <Button
              w="36"
              onClick={onSave}
              isLoading={isPendingSettings}
              variant="primaryPurple"
              alignItems="center"
            >
              Publish Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
