import { Button, useDisclosure } from '@chakra-ui/react';
import { PlusIcon } from '../../../shared/icons/PlusIcon';
import { ModalInviteMember } from './Modal';

export const InviteMemberButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Button variant="secondary" leftIcon={<PlusIcon />} onClick={onOpen}>
        Invite Member
      </Button>
      {isOpen && <ModalInviteMember isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
