export const EnableAllIcon = () => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10C3.61111 10 2.43056 9.51389 1.45833 8.54167C0.486111 7.56944 0 6.38889 0 5C0 3.61111 0.486111 2.43056 1.45833 1.45833C2.43056 0.486111 3.61111 0 5 0H13C14.3889 0 15.5694 0.486111 16.5417 1.45833C17.5139 2.43056 18 3.61111 18 5C18 6.38889 17.5139 7.56944 16.5417 8.54167C15.5694 9.51389 14.3889 10 13 10H5ZM13 7.5C13.6944 7.5 14.2847 7.25694 14.7708 6.77083C15.2569 6.28472 15.5 5.69444 15.5 5C15.5 4.30556 15.2569 3.71528 14.7708 3.22917C14.2847 2.74306 13.6944 2.5 13 2.5C12.3056 2.5 11.7153 2.74306 11.2292 3.22917C10.7431 3.71528 10.5 4.30556 10.5 5C10.5 5.69444 10.7431 6.28472 11.2292 6.77083C11.7153 7.25694 12.3056 7.5 13 7.5Z"
      fill="#039855"
    />
  </svg>
);
