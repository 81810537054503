import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  CreateWebhookEndpointRequest,
  CreateWebhookEndpointResponse,
  ListWebhookEndpointRequest,
  ListWebhookEndpointResponse,
  ListWebhookEventResponse,
  UpdateWebhookEndpointRequest,
  UpdateWebhookEndpointResponse,
  WebhookEndpointSecret,
  GetWebhookEndpointResponse,
  ListWebhookMessageRequest,
  ListWebhookMessageResponse,
} from '@finch-api/developer-dashboard-common/dist/api/webhook';

export class WebhookApi {
  constructor(
    private readonly baseURL: string,
    private readonly token: string,
  ) {}

  async listWebhookEndpoints(
    req: ListWebhookEndpointRequest,
  ): Promise<ListWebhookEndpointResponse> {
    const params = new URLSearchParams();

    if (req.limit) {
      params.set('limit', req.limit.toString());
    }

    if (req.cursor) {
      params.set('cursor', req.cursor);
    }

    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${
        req.applicationId
      }/webhook-endpoints?${params.toString()}`,
    );

    try {
      const response = await axios.get(requestURL.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async messageAttempts(
    applicationId: string,
    endpointId: string,
    messageId: string,
  ): Promise<number> {
    const requestURL = new URL(
      `/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}/${messageId}/attempts`,
      this.baseURL,
    );

    try {
      const { data } = await axios.get(requestURL.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      return data;
    } catch (caught) {
      Sentry.captureException(caught);

      throw caught;
    }
  }

  async getWebhookEndpoint(
    applicationId: string,
    endpointId: string,
  ): Promise<GetWebhookEndpointResponse> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}`,
    );

    try {
      const response = await axios.get(requestURL.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async listWebhookMessages(
    applicationId: string,
    endpointId: string,
    req: ListWebhookMessageRequest,
  ): Promise<ListWebhookMessageResponse> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}/messages`,
    );

    try {
      const response = await axios.get(requestURL.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params: req,
      });

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async listWebhookEvents({
    includeBeta,
  }: {
    includeBeta?: boolean;
  }): Promise<ListWebhookEventResponse> {
    const requestURL = new URL(`${this.baseURL}/api/v1/webhook-events`);
    try {
      const response = await axios.get(requestURL.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          includeBeta: includeBeta ? includeBeta : undefined,
        },
      });

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async createWebhookEndpoint(
    applicationId: string,
    req: CreateWebhookEndpointRequest,
  ): Promise<CreateWebhookEndpointResponse> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints`,
    );

    try {
      const response = await axios.post(
        requestURL.toString(),
        {
          ...req,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async updateWebhookEndpoint(
    applicationId: string,
    endpointId: string,
    req: UpdateWebhookEndpointRequest,
  ): Promise<UpdateWebhookEndpointResponse> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}`,
    );
    try {
      const response = await axios.put(
        requestURL.toString(),
        {
          ...req,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async rotateWebhookEndpointSecret(
    applicationId: string,
    endpointId: string,
  ): Promise<WebhookEndpointSecret> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}/rotate-secret`,
    );
    try {
      const response = await axios.post(
        requestURL.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async deleteWebhookEndpoint(
    applicationId: string,
    endpointId: string,
  ): Promise<void> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}`,
    );

    try {
      const response = await axios.delete(requestURL.toString(), {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async sendTestEvent(
    applicationId: string,
    endpointId: string,
  ): Promise<void> {
    const requestURL = new URL(
      `${this.baseURL}/api/v1/applications/${applicationId}/webhook-endpoints/${endpointId}/test`,
    );

    try {
      await axios.post(
        requestURL.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );

      return;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}
