import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { baseURL } from '../../utils/baseUrls';
import { useParams } from 'react-router-dom';
import { useStagedProviderConfigurationsStore } from '../state/stagedConfigurations';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ProviderConfigToSave,
  StagedConfiguration,
  StagedOAuthCredentials,
} from '../types';
import { useToast } from '@chakra-ui/react';

const prepareProviderConfigs = ({
  stagedConfig,
  stagedOAuthCredentials,
  pinnedProviderIds,
}: {
  stagedConfig: StagedConfiguration[];
  stagedOAuthCredentials: Map<string, StagedOAuthCredentials>;
  pinnedProviderIds: string[];
}): ProviderConfigToSave[] => {
  const stagedConfigs = stagedConfig.map((config) => {
    const pinnedIndex = pinnedProviderIds.indexOf(config.id);
    const oauthCredentials = stagedOAuthCredentials.get(config.id);
    return {
      defaultImplementationId: config.defaultImplementationId,
      enabled: config.enabled,
      implementations: config?.implementations?.map((impl) => ({
        enabled: impl.enabled,
        implementationId: impl.id,
      })),
      oauthCredentials,
      pinnedIndex: pinnedIndex === -1 ? null : pinnedIndex,
      providerId: config.id,
    };
  });

  const oauthCredentialsConfigs = Array.from(stagedOAuthCredentials.entries())
    .filter(([id]) => !stagedConfigs.some((config) => config.providerId === id))
    .map(([id, oauthCredentials]) => ({
      oauthCredentials,
      providerId: id,
    }));

  const pinnedProviderConfigs = pinnedProviderIds
    .filter((id) => !stagedConfigs.some((config) => config.providerId === id))
    .map((id) => ({
      pinnedIndex: pinnedProviderIds.indexOf(id),
      providerId: id,
    }));

  const configsToSave = [
    ...stagedConfigs,
    ...oauthCredentialsConfigs,
    ...pinnedProviderConfigs,
  ];

  return configsToSave;
};

export const useSaveProviderConfigurations = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { applicationId } = useParams<{ applicationId: string }>();

  const {
    stagedOAuthCredentials,
    stagedConfigurations,
    resetAllStagedConfigurations,
    pinnedProviderIds,
  } = useStagedProviderConfigurationsStore();

  const clearStagedConfigurations = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['providerConfigurations', applicationId],
    });
    await resetAllStagedConfigurations();
  };

  const mutation = useMutation({
    mutationFn: async () => {
      return axios
        .patch(
          `${baseURL}/api/v1/applications/${applicationId}/provider-configurations`,
          {
            configs: prepareProviderConfigs({
              stagedConfig: Array.from(stagedConfigurations.values()),
              stagedOAuthCredentials,
              pinnedProviderIds,
            }),
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await clearStagedConfigurations();
      toast({
        description: 'Configuration changes successfully published.',
        status: 'success',
        duration: 3000,
        isClosable: false,
      });
    },
    onError: (error) => {
      toast({
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: false,
      });
    },
  });

  return {
    clearStagedConfigurations,
    ...mutation,
  };
};
