import { Button, Flex, HStack, Spacer, useDisclosure } from '@chakra-ui/react';
import { PageHeading } from '../../shared/Typography';
import { useSaveProviderConfigurations } from '../hooks/useSaveProviderConfigurations';
import { PreviewConnect } from './PreviewConnect';
import { COLORS } from '../../constant';
import { tabDetails } from '../constants';
import { useSelectedTabStore } from '../state/selectedTab';
import { useProviderSettings } from '../hooks/useProviderSettings';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';
import { useSaveProviderSettings } from '../hooks/useSaveProviderSettings';
import { SaveConfigConfirmationModal } from './SaveConfigConfirmationModal';
import { SaveSettingsConfirmationModal } from './SaveSettingsConfirmationModal';
import { ProviderConfigActionMenu } from './ProviderConfigActions';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { usePermissions } from '../../hooks/use-permissions';

export const TopBar = () => {
  const {
    mutate: mutateProviderConfigurations,
    isPending: isPendingProviderConfigurations,
    clearStagedConfigurations,
  } = useSaveProviderConfigurations();

  const {
    mutate: mutateSettings,
    isPending: isPendingSettings,
    clearStagedProviderSettings,
  } = useSaveProviderSettings();

  const {
    isOpen: isProviderConfigModalOpen,
    onOpen: onProviderConfigModalOpen,
    onClose: onProviderConfigModalClose,
  } = useDisclosure();

  const {
    isOpen: isSettingsModalOpen,
    onOpen: onSettingsModalOpen,
    onClose: onSettingsModalClose,
  } = useDisclosure();

  const currentTab = useSelectedTabStore((store) => store.selectedTab);
  const isIntegrationsList = tabDetails[currentTab].isIntegrationsList;
  const { hasStagedSettings } = useProviderSettings();
  const { hasChanges } = useProviderConfigurations();

  const isSettingsPage = currentTab === 'Settings';

  const isPending = isSettingsPage
    ? isPendingSettings
    : isPendingProviderConfigurations;

  const hasPendingChanges = () => {
    if (isSettingsPage) {
      return hasStagedSettings();
    }
    return hasChanges();
  };

  const saveProviderConfigChanges = () => {
    mutateProviderConfigurations();
    onProviderConfigModalClose();
  };

  const saveSettingsChanges = () => {
    mutateSettings();
    onSettingsModalClose();
  };

  const onSave = () => {
    if (isSettingsPage) {
      onSettingsModalOpen();
    } else {
      onProviderConfigModalOpen();
    }
  };

  const onClear = () => {
    if (isSettingsPage) {
      onSettingsModalClose();
      clearStagedProviderSettings();
    } else {
      onProviderConfigModalClose();
      clearStagedConfigurations();
    }
  };

  const match = usePermissions([PERMISSION.IntegrationConfigurations.Update]);

  return (
    <HStack spacing={'24px'} flexWrap="wrap">
      <PageHeading as="h1" w={{ base: 'full', md: '275px' }}>
        Integrations
      </PageHeading>

      <Flex
        w="full"
        flex="1"
        direction="row"
        justify={isIntegrationsList ? 'space-between' : 'flex-end'}
        align="center"
        flexWrap="wrap"
      >
        {isIntegrationsList &&
          (match ? (
            <ProviderConfigActionMenu tabName={currentTab} />
          ) : (
            <Spacer />
          ))}

        <HStack justifyContent="flex-end" flexWrap="wrap">
          {isIntegrationsList && <PreviewConnect />}
          <HStack>
            <Button
              variant="secondary"
              isDisabled={!hasPendingChanges()}
              size="md"
              onClick={onClear}
            >
              Cancel
            </Button>
            <Button
              variant="primaryPurple"
              isDisabled={!hasPendingChanges()}
              size="md"
              _hover={{ bg: COLORS.FINCH.BLACK }}
              isLoading={isPending}
              onClick={onSave}
            >
              Save
            </Button>
          </HStack>
        </HStack>
      </Flex>

      {isProviderConfigModalOpen && (
        <SaveConfigConfirmationModal
          isOpen={isProviderConfigModalOpen}
          onClose={onProviderConfigModalClose}
          onSave={saveProviderConfigChanges}
          onCancel={onClear}
        />
      )}
      {isSettingsModalOpen && (
        <SaveSettingsConfirmationModal
          isOpen={isSettingsModalOpen}
          onClose={onSettingsModalClose}
          onSave={saveSettingsChanges}
          onCancel={onClear}
        />
      )}
    </HStack>
  );
};
