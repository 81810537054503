import { Box, Center, Spacer, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import { PageHeading } from '../../shared/Typography';
import {
  WebhookEndpoint,
  WebhookEndpointWithSecret,
} from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { WebhooksNotEnabled } from '../components/WebhooksNotEnabled';
import { WebhooksLoadingState } from '../components/WebhooksLoadingState';
import { WebhooksTable } from '../components/WebhooksTable';
import { WebhookActions } from '../components/WebhookActions';
import { CreateWebhookDrawer } from '../components/CreateWebhookDrawer';
import { WebhookSecretModal } from '../components/WebhookSecretModal';
import { WebhooksEmpty } from '../components/WebhooksEmpty';
import { useListWebhookEvents, useListWebhooks } from '../hooks';
import { PageContainer } from '../../components/PageContainer';

export const WebhooksPage = () => {
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [allEndpoints, setAllEndpoints] = useState<WebhookEndpoint[]>([]);
  const { applicationId } = useParams<{ applicationId: string }>();
  const history = useHistory();
  const {
    isOpen: isCreateDrawerOpen,
    onOpen: onCreateDrawerOpen,
    onClose: onCreateDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isSecretModalOpen,
    onOpen: onSecretModalOpen,
    onClose: onSecretModalClose,
  } = useDisclosure();

  const {
    loading: isLoading,
    data: webhookResponse,
    triggerRefetch,
  } = useListWebhooks(applicationId, cursor);

  const { data: webhookEvents } = useListWebhookEvents();

  useEffect(() => {
    if (!webhookResponse?.endpoints) {
      return;
    }

    setAllEndpoints((prev) => {
      const endpoints = unionBy([...prev, ...webhookResponse.endpoints], 'id');

      return sortBy(endpoints, ({ createdAt }) => createdAt).reverse();
    });
  }, [webhookResponse?.endpoints]);

  const [secretModalData, setSecretModalData] = useState<{
    secret: string;
    endpointURL: string;
  } | null>(null);

  if (!applicationId) throw new Error('no application id in url param');

  const onWebhookCreated = (endpointWithSecret: WebhookEndpointWithSecret) => {
    setSecretModalData({
      secret: endpointWithSecret.secret,
      endpointURL: endpointWithSecret.url,
    });
    onSecretModalOpen();
    setCursor(undefined);
    triggerRefetch();
  };

  const onWebhookViewRequested = (endpoint: WebhookEndpoint) => {
    history.push(`/app/applications/${applicationId}/webhooks/${endpoint.id}`);
  };

  const fetchMoreWebhookEndpoints = useCallback(() => {
    if (webhookResponse?.nextCursor && !webhookResponse.done) {
      setCursor(webhookResponse?.nextCursor);
    }
  }, [webhookResponse?.done, webhookResponse?.nextCursor]);

  return (
    <Box>
      <PageContainer>
        <Center
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems={'center'}
          gap={{ base: '4', sm: '0' }}
          justifyContent={'space-between'}
        >
          <PageHeading>Webhooks</PageHeading>
          {!isLoading && webhookResponse?.webhookEnabled && (
            <WebhookActions onClick={onCreateDrawerOpen} />
          )}
        </Center>

        <Spacer p="4" />
        {isLoading && <WebhooksLoadingState />}
        {!isLoading && !webhookResponse?.webhookEnabled && (
          <WebhooksNotEnabled />
        )}

        {!isLoading && webhookResponse?.webhookEnabled && (
          <Box>
            <CreateWebhookDrawer
              applicationId={applicationId}
              isOpen={isCreateDrawerOpen}
              onClose={onCreateDrawerClose}
              events={webhookEvents.events}
              onWebhookCreated={onWebhookCreated}
            />

            {secretModalData && (
              <WebhookSecretModal
                isOpen={isSecretModalOpen}
                onClose={onSecretModalClose}
                secret={secretModalData.secret}
                endpointURL={secretModalData.endpointURL}
              />
            )}

            {allEndpoints.length > 0 && (
              <>
                <Spacer p="1" />
                <WebhooksTable
                  webhookEndpoints={allEndpoints}
                  isFetching={isLoading}
                  fetchMore={fetchMoreWebhookEndpoints}
                  onWebhookViewRequested={onWebhookViewRequested}
                />
              </>
            )}

            {allEndpoints.length === 0 && (
              <WebhooksEmpty onClick={onCreateDrawerOpen} />
            )}
          </Box>
        )}
      </PageContainer>
    </Box>
  );
};
