import _ from 'lodash';

export const FINCH_BASE_URL = 'https://tryfinch.com';
export const FINCH_DOCS_BASE_URL = 'https://developer.tryfinch.com';

export const legalLinks = {
  privacy: `${FINCH_BASE_URL}/legal/privacy`,
  tos: `${FINCH_BASE_URL}/legal/terms`,
};

export const docLinks = {
  sandbox: `${FINCH_DOCS_BASE_URL}/docs/documentation/3x7je96vjp2xq-finch-sandbox`,
  quickstart: `${FINCH_DOCS_BASE_URL}/docs/documentation/cmlh3anpgti5n-quickstart`,
  webhooks: `${FINCH_DOCS_BASE_URL}/docs/documentation/col7xfm69ll1t-webhooks`,
  webhooksBestPractices: `${FINCH_DOCS_BASE_URL}/docs/documentation/col7xfm69ll1t-webhooks#best-practices-for-handling-webhooks`,
};

export const finchEmails = {
  billing: 'billing@tryfinch.com',
  developers: 'developers@tryfinch.com',
  sales: 'sales@tryfinch.com',
};

export const finchMailToLinks = _.mapValues(finchEmails, (e) => `mailto:${e}`);
