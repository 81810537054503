import { useState } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { RxHamburgerMenu } from 'react-icons/rx';

import {
  Avatar,
  Button,
  Flex,
  Grid,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  VStack,
  IconButton,
  HStack,
  Text,
} from '@chakra-ui/react';
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';

import FinchIcon from '../assets/images/logos/finch/icon.svg';
import { Caption } from './Typography';
import { FeatureReleaseModal } from '../feature-release-modal';
import { useOrganizationApplications } from './OrganizationApplicationsContext';
import { FINCH_DOCS_BASE_URL } from './links';
import { useOrganization } from './OrganizationContext';
import { MobileNavMenu } from './MobileNavMenu';
import { ApplicationTabs } from './ApplicationTabs';
import { DropdownApplicationsMenu } from './DropdownApplicationsMenu';
import { COLORS } from '../constant';
import { OrgSettingsIcon } from './icons/OrgSettings';
import { ApiDocIcon } from './icons/ApiDoc';
import { LogoutIcon } from './icons/Logout';
import { ExternalLinkIcon } from './icons/ExternalLink';

const DropdownProfileMenu = () => {
  const { user }: Auth0ContextInterface<User> = useAuth0();
  const organization = useOrganization();

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        minW={0}
        rightIcon={<FiChevronDown />}
        color="gray.900"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Avatar
          bg="gray.c200"
          color={COLORS.FINCH.BLACK}
          fontWeight="600"
          fontSize="16px"
          name={user?.name}
          size={'sm'}
          src={user?.profile}
        />
      </MenuButton>
      <MenuList>
        <MenuItem
          _hover={{ backgroundColor: 'transparent', color: COLORS.FINCH.BLACK }}
          cursor="unset"
          _focus={{ boxShadow: 'none' }}
        >
          <VStack alignItems="flex-start">
            {organization.organization?.displayName && (
              <>{organization.organization.displayName}</>
            )}
            <Caption color={COLORS.GRAY.GRAY_600}>{user?.name}</Caption>
          </VStack>
        </MenuItem>
        <MenuDivider borderColor={COLORS.GRAY.GRAY_400} />

        <MenuItem
          icon={<OrgSettingsIcon />}
          as={RouterLink}
          to={`/app/settings`}
          _hover={{ textDecoration: 'none', color: COLORS.FINCH.BLACK }}
          color={COLORS.GRAY.GRAY_600}
        >
          Settings
        </MenuItem>

        <MenuItem
          icon={<ApiDocIcon />}
          onClick={() => window.open(FINCH_DOCS_BASE_URL, '_blank')}
          color={COLORS.GRAY.GRAY_600}
          _hover={{ textDecoration: 'none', color: COLORS.FINCH.BLACK }}
        >
          <HStack justify="space-between" align="center">
            <Text>API Docs</Text> <ExternalLinkIcon />
          </HStack>
        </MenuItem>

        <MenuItem
          icon={<LogoutIcon />}
          as={RouterLink}
          _hover={{ textDecoration: 'none', color: COLORS.FINCH.BLACK }}
          to="/logout"
          color={COLORS.GRAY.GRAY_600}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const FinchHomeButton = () => {
  const applicationsPathMatch = useRouteMatch<{ applicationId: string }>(
    '/app/applications/:applicationId',
  );

  const applicationId = applicationsPathMatch?.params?.applicationId;

  const onClick = () => {
    if (applicationId) {
      window.location.href = `/app/applications/${applicationId}`;
    } else {
      window.location.href = '/app';
    }
  };

  return (
    <Image boxSize="22px" src={FinchIcon} cursor="pointer" onClick={onClick} />
  );
};

const LeftMenuDesktop = () => {
  const applications = useOrganizationApplications();

  return (
    <Flex alignItems="center" gap="4">
      <Flex
        borderRight="1px solid"
        borderColor="gray.c600"
        paddingRight="4"
        height="8"
        alignItems="center"
      >
        <FinchHomeButton />
      </Flex>
      {applications && (
        <Switch>
          <Route path="/app/applications/:applicationId">
            <DropdownApplicationsMenu />
          </Route>
        </Switch>
      )}
    </Flex>
  );
};

export const NavigationBar = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const applications = useOrganizationApplications();

  return (
    <>
      <MobileNavMenu
        show={showMobileNav}
        onClose={() => setShowMobileNav(false)}
      />
      <Grid
        templateColumns={{
          base: 'repeat(3, 1fr)',
          md: '2fr 3fr 1fr',
          lg: '1fr 2fr 1fr',
        }}
        height="100%"
        borderBottom="1px solid"
        borderBottomColor="gray.300"
        padding="14px 32px"
        backgroundColor="white"
        gap="16px"
        title="Desktop navigation bar"
      >
        <Flex alignItems={'center'}>
          <Flex
            display={{ base: 'none', md: 'block' }}
            title="Desktop application dropdown"
          >
            <LeftMenuDesktop />
          </Flex>

          <Flex display={{ base: 'block', md: 'none' }}>
            <IconButton
              aria-label="mobile menu button"
              variant="ghost"
              icon={<RxHamburgerMenu size={24} />}
              onClick={() => setShowMobileNav(!showMobileNav)}
            />
          </Flex>
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Flex
            display={{ base: 'none', md: 'flex' }}
            minH="40px"
            align="center"
            title="Desktop application tabs"
          >
            {applications && <ApplicationTabs />}
          </Flex>
          <Flex display={{ base: 'block', md: 'none' }}>
            <FinchHomeButton />
          </Flex>
        </Flex>

        <Flex alignItems="center" justifyContent="flex-end">
          <FeatureReleaseModal />
          <DropdownProfileMenu />
        </Flex>
      </Grid>
    </>
  );
};
