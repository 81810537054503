/* eslint-disable @typescript-eslint/no-explicit-any */
import { Products } from 'constant/scopes';
import pick from 'lodash/pick';
import { type OpenAPIV3 } from 'openapi-types';
import { parseAuthorization } from './parse-authorization';
import { parseParameters } from './parse-parameters';
import { parseRequestBody } from './parse-request-body';
import {
  OperationsOptions,
  OperationsFilter,
  OperationsBySection,
  RequestBodyWithRef,
  RequestMethod,
  Authorization,
} from './types';

const filterToAuthTypes = {
  connection: ['bearerAuth', 'none'],
  application: ['basicAuth'],
};

const OperationPathToScope = {
  '/employer/company': Products.COMPANY,
  '/employer/directory': Products.DIRECTORY,
  '/employer/individual': Products.INDIVIDUAL,
  '/employer/employment': Products.EMPLOYMENT,
  '/employer/payment': Products.PAYMENT,
  '/employer/pay-statement': Products.PAY_STATEMENT,
  '/employer/pay-groups': Products.PAYMENT,
  '/employer/benefits': Products.BENEFITS,
  '/employer/documents': Products.DOCUMENTS,
};

export const parseOperations = (opts: {
  spec?: OpenAPIV3.Document;
  filter?: OperationsFilter;
  connectionScopes?: Products[];
}): {
  operationsBySection: OperationsBySection;
  operations: OperationsOptions[];
} => {
  const { spec, filter, connectionScopes } = opts;

  if (!spec) {
    return { operationsBySection: {}, operations: [] };
  }

  const { paths, security } = spec;

  const defaultAuth = parseAuthorization(security);

  const operationsBySection: OperationsBySection = {};
  const operations: OperationsOptions[] = [];

  for (const [path, pathObject] of Object.entries(paths || {})) {
    const methodPathObjects = pick(pathObject, [
      'get',
      'put',
      'post',
      'delete',
      'options',
      'head',
      'patch',
      'trace',
    ]) as {
      [method in OpenAPIV3.HttpMethods]?: OpenAPIV3.OperationObject;
    };

    for (const [method, operationObject] of Object.entries(methodPathObjects)) {
      const section = operationObject.tags?.[0] || 'Other';

      const auth = parseAuthorization(operationObject.security) || defaultAuth;

      if (
        filter?.operationType &&
        !filterToAuthTypes[filter.operationType].includes(auth as Authorization)
      ) {
        continue;
      }

      if (
        filter?.omitOperations?.includes(`${method as RequestMethod} ${path}`)
      ) {
        continue;
      }

      if (!filter?.isSandbox && section === 'Sandbox') {
        continue;
      }

      if (!operationsBySection[section]) {
        operationsBySection[section] = [];
      }

      const allParameters = [
        ...(pathObject?.parameters || []),
        ...(operationObject.parameters || []),
      ];

      const requiredScope =
        Object.entries(OperationPathToScope).find(([key]) =>
          path.includes(key),
        )?.[1] ?? null;

      const hasRequiredScope =
        requiredScope === null ||
        (connectionScopes?.includes(requiredScope) ?? false);

      const operationOptions = {
        path,
        requiredScope,
        hasRequiredScope,
        title: operationObject.summary,
        method: method as RequestMethod,
        requestBody: parseRequestBody(
          'untyped',
          operationObject.requestBody as RequestBodyWithRef,
          spec,
        ),
        typedRequestBody: parseRequestBody(
          'typed',
          operationObject.requestBody as RequestBodyWithRef,
          spec,
        ),
        parameters: parseParameters(allParameters, spec),
        auth: parseAuthorization(operationObject.security) || defaultAuth,
      };

      operationsBySection[section]?.push(operationOptions);

      operations.push(operationOptions);
    }
  }

  return { operationsBySection, operations };
};
