import { useCallback, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  SignupResponse,
  ResendVerificationRequest,
} from '@finch-api/developer-dashboard-common';

import MailIcon from '../../../assets/images/mail.png';
import { baseURL } from '../../../utils/baseUrls';

// For google tag manager
declare global {
  interface Window {
    dataLayer: any[];
  }
}

type EmailVerificationProps = {
  signupResponse: SignupResponse;
};

const displayResend = false; // TODO: toggled off for now as this feature needs ironing out

export const EmailVerificationPanel = ({
  signupResponse,
}: EmailVerificationProps) => {
  const [isResending, setIsResending] = useState(false);

  const { resendVerificationToken, organizationId, organizationName, email } =
    signupResponse;

  // TODO: this action handler isn't invoked; the resend feature requires further iteration.
  const resendVerification = useCallback(async () => {
    setIsResending(true);

    const request: ResendVerificationRequest = {
      token: resendVerificationToken,
    };

    try {
      await axios.post(
        `${baseURL}/api/v1/account/verify?synchronous=true`,
        request,
      );
      // TODO: success message
    } catch (error) {
      Sentry.captureException(error);
      // TODO: display this error in some way
    } finally {
      setIsResending(false);
    }
  }, [resendVerificationToken]);

  const sendSignUpEventToDataLayer = () => {
    if (window?.dataLayer) {
      // Push the event to google tag manager data layer
      window.dataLayer.push({
        event: 'sign_up',
        organizationId,
        organizationName,
      });
    }
  };

  // useEffect to trigger the event when the component mounts (adjust as needed)
  useEffect(() => {
    sendSignUpEventToDataLayer();
  }, []);

  return (
    <Flex direction="column" align="center" justify="center" h="100%">
      <Flex direction="row" align="center">
        <Box w="414px">
          <Image w="48px" src={MailIcon} mb="24px" mx="auto" />
          <Heading
            fontSize="24px"
            lineHeight="38px"
            mb="8px"
            textAlign="center"
          >
            Let’s verify your email
          </Heading>
          <Text fontSize="16px" lineHeight="24px" textAlign="center">
            We sent a verification link to{' '}
            <Text as="span" fontWeight="bold" whiteSpace="nowrap">
              {email}
            </Text>
            .<br />
            If you have not received an email in a couple of
            <br />
            minutes, check your spam folder.
          </Text>
          {displayResend && (
            <Text
              mt="32px"
              fontSize="14px"
              lineHeight="20px"
              color="gray.c600"
              textAlign="center"
            >
              Didn’t receive the email?{' '}
              {isResending && (
                <Link
                  as="span"
                  color="primary.c300"
                  style={{
                    cursor: 'not-allowed',
                  }}
                >
                  <Spinner mr={1} size="xs" />
                  Resending...
                </Link>
              )}
              {!isResending && (
                <Link
                  as="span"
                  color="primary.c700"
                  onClick={resendVerification}
                >
                  Click here to resend it.
                </Link>
              )}
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
