import {
  Alert,
  AlertDescription,
  Box,
  Text,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { WarningIcon } from '../../shared/icons/WarningIcon';

export const ConfigurationNeededWarning = ({
  text,
}: {
  text: React.ReactNode;
}) => {
  return (
    <Alert variant="finchWarning">
      <Box p="8px">
        <AlertDescription>
          <HStack align={'top'}>
            <Icon boxSize="6">
              <WarningIcon iconColor={COLORS.ORANGE} />
            </Icon>
            <Text fontSize="14px" lineHeight="21px" fontWeight="400">
              {text}
            </Text>
          </HStack>
        </AlertDescription>
      </Box>
    </Alert>
  );
};
