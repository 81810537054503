import axios from 'axios';
import * as Sentry from '@sentry/react';
import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { StagedConnectionDetail } from './types';

export class StagedConnectionDetailApi {
  constructor(
    private baseUrl: string,
    private auth: Auth0ContextInterface<User>,
  ) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth;
    return getAccessTokenSilently();
  };

  async getConnectionDetail(
    applicationId: string,
    connectionId: string,
  ): Promise<StagedConnectionDetail> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/staged-connections/${connectionId}`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get<StagedConnectionDetail>(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}
