import { BadgeTheme } from '../Badge/Badge';
import pending from '../../assets/images/icons/pending.svg';
import in_progress from '../../assets/images/icons/in_progress.svg';
import check_circle from '../../assets/images/icons/check_circle.svg';
import attention from '../../assets/images/icons/error_warning.svg';
import disconnected from '../../assets/images/icons/disconnected.svg';
import unknown from '../../assets/images/icons/unknown.svg';
import disabled from '../../assets/images/icons/disabled.svg';
import failed from '../../assets/images/icons/failed.svg';
import retrying from '../../assets/images/icons/retrying.svg';
import sending from '../../assets/images/icons/sending.svg';

export type TagVariant =
  | 'connected'
  | 'complete'
  | 'disconnected'
  | 'disconnected_aggregate'
  | 'error'
  | 'healthy'
  | 'enabled'
  | 'disabled'
  | 'improper_permissions'
  | 'in_progress'
  | 'needs_attention'
  | 'no_account_set_up'
  | 'partial_error'
  | 'pending'
  | 'permission'
  | 'processing'
  | 'reauth'
  | 'reauth_required'
  | 'sending'
  | 'success'
  | 'failed'
  | 'retrying'
  | 'unknown';

type Config = {
  icon: string;
  text: string;
  theme: BadgeTheme;
};

/**
 * Convenience type for type safety
 */
export type Status<Status extends TagVariant> = Status;

export type Props<V extends TagVariant> = {
  /** The status variant to be rendered */
  variant: V;
};

/**
 * Configuration for each kind of status tag
 */
export const variants: Record<TagVariant, Config> = {
  unknown: { icon: unknown, theme: BadgeTheme.GRAY, text: 'Unknown' },
  pending: { icon: pending, theme: BadgeTheme.GRAY, text: 'Pending' },
  disconnected: {
    icon: disconnected,
    theme: BadgeTheme.GRAY,
    text: 'Disconnected',
  },
  disconnected_aggregate: {
    icon: disconnected,
    theme: BadgeTheme.LIGHT_GRAY,
    text: 'Disconnected',
  },
  disabled: { icon: disabled, theme: BadgeTheme.GRAY, text: 'Disabled' },

  healthy: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Healthy' },
  success: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Success' },
  complete: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Success' },
  connected: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Connected' },
  enabled: { icon: check_circle, theme: BadgeTheme.GREEN, text: 'Enabled' },

  sending: { icon: sending, theme: BadgeTheme.BLUE, text: 'Sending' },

  processing: { icon: in_progress, theme: BadgeTheme.BLUE, text: 'Processing' },
  in_progress: {
    icon: in_progress,
    theme: BadgeTheme.BLUE,
    text: 'In Progress',
  },

  error: { icon: attention, theme: BadgeTheme.RED, text: 'Error' },
  failed: { icon: failed, theme: BadgeTheme.RED, text: 'Failed' },

  retrying: { icon: retrying, theme: BadgeTheme.YELLOW, text: 'Retrying' },

  reauth: { icon: attention, theme: BadgeTheme.RED, text: 'Reauth' },
  permission: { icon: attention, theme: BadgeTheme.RED, text: 'Permission' },

  needs_attention: {
    icon: attention,
    theme: BadgeTheme.YELLOW,
    text: 'Needs Attention',
  },
  partial_error: {
    icon: attention,
    theme: BadgeTheme.YELLOW,
    text: 'Partial Error',
  },
  reauth_required: {
    icon: attention,
    theme: BadgeTheme.YELLOW,
    text: 'Reauth Required',
  },
  no_account_set_up: {
    icon: attention,
    theme: BadgeTheme.YELLOW,
    text: 'No Account Set Up',
  },
  improper_permissions: {
    icon: attention,
    theme: BadgeTheme.YELLOW,
    text: 'Improper Permissions',
  },
};
