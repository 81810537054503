export const DisableAllIcon = () => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10C3.61111 10 2.43056 9.51389 1.45833 8.54167C0.486111 7.56944 0 6.38889 0 5C0 3.61111 0.486111 2.43056 1.45833 1.45833C2.43056 0.486111 3.61111 0 5 0H13C14.3889 0 15.5694 0.486111 16.5417 1.45833C17.5139 2.43056 18 3.61111 18 5C18 6.38889 17.5139 7.56944 16.5417 8.54167C15.5694 9.51389 14.3889 10 13 10H5ZM5 8.5H13C13.9722 8.5 14.7986 8.15972 15.4792 7.47917C16.1597 6.79861 16.5 5.97222 16.5 5C16.5 4.02778 16.1597 3.20139 15.4792 2.52083C14.7986 1.84028 13.9722 1.5 13 1.5H5C4.02778 1.5 3.20139 1.84028 2.52083 2.52083C1.84028 3.20139 1.5 4.02778 1.5 5C1.5 5.97222 1.84028 6.79861 2.52083 7.47917C3.20139 8.15972 4.02778 8.5 5 8.5ZM5 7.5C5.69444 7.5 6.28472 7.25694 6.77083 6.77083C7.25694 6.28472 7.5 5.69444 7.5 5C7.5 4.30556 7.25694 3.71528 6.77083 3.22917C6.28472 2.74306 5.69444 2.5 5 2.5C4.30556 2.5 3.71528 2.74306 3.22917 3.22917C2.74306 3.71528 2.5 4.30556 2.5 5C2.5 5.69444 2.74306 6.28472 3.22917 6.77083C3.71528 7.25694 4.30556 7.5 5 7.5Z"
      fill="#737373"
    />
  </svg>
);
