import { create } from 'zustand';
import { ConnectionsTableColumn, StagedConnection } from '../types';

export const useTableDataStore = create<{
  tableData: ConnectionsTableColumn[];
  setTableData: (data: ConnectionsTableColumn[]) => void;
}>((set) => ({
  tableData: [],
  setTableData: (data) => set({ tableData: data }),
}));

export const useIncompleteTableDataStore = create<{
  tableData: StagedConnection[];
  setTableData: (data: StagedConnection[]) => void;
}>((set) => ({
  tableData: [],
  setTableData: (data) => set({ tableData: data }),
}));
