import { Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useFlag } from '@unleash/proxy-client-react';
import { useEffect, useState } from 'react';
import { Banner } from '../components/Banner';

/** Update every time the banner text is changed */
const BANNER_LOCAL_STORAGE_KEY = 'crowdstrikeIncidentBanner-jul-19-2024';
const MAINTENANCE_DAY = dayjs('2024-07-22');

const dateToday = dayjs();
const isBeforeMaintenanceDay = dateToday.isBefore(MAINTENANCE_DAY, 'day');

export const ScheduledMaintenanceBanner = () => {
  const featureFlagEnabled = useFlag('scheduledMaintenanceBanner');
  const [show, setShow] = useState(false);

  const onClose = () => {
    setShow(false);

    localStorage.setItem(BANNER_LOCAL_STORAGE_KEY, 'dismissed');
  };

  useEffect(() => {
    const bannerKey = localStorage.getItem(BANNER_LOCAL_STORAGE_KEY);

    const canShow =
      isBeforeMaintenanceDay && featureFlagEnabled && bannerKey !== 'dismissed';

    setShow(canShow);
  }, [featureFlagEnabled]);

  if (!show) return null;

  return (
    <Banner type="info" onClose={onClose}>
      <Text>
        Finch is not directly impacted by the recent global outages rooted in
        Crowdstrike&apos;s software update. The API and Dashboard continue to
        function as expected. There may be some upstream Providers impacted,
        which can result in rate limiting behavior or data sync delays. We are
        closely monitoring performance, and no action is currently needed with
        Finch at this time.
      </Text>
    </Banner>
  );
};
