import { HStack, Text, type StackProps } from '@chakra-ui/react';
import { createElement } from 'react';
import { COLORS } from '../../constant';
import { useSelectedTabStore } from '../state/selectedTab';
import { useSettingsStore } from '../state/settingsState';
import { useUnsavedSettingsModalStore } from '../state/unsavedSettingsModal';
import { Tab } from '../types';

export const ConfigureTab = ({
  label,
  icon,
  entries,
  ...rest
}: StackProps & {
  icon: ({ color }: { color: string }) => JSX.Element;
  label: Tab;
  entries?: number;
}) => {
  const { selectedTab, setSelectedTab } = useSelectedTabStore();
  const { hasStagedSettings } = useSettingsStore();
  const { setIsOpen, setNextTabToNavigate } = useUnsavedSettingsModalStore();
  const selected = selectedTab === label;
  const color = selected ? COLORS.FINCH.PURPLE : COLORS.GRAY.GRAY_600;

  const onClick = () => {
    // Check if there are unsaved settings and show the confirmation modal
    if (selectedTab === 'Settings' && hasStagedSettings()) {
      setNextTabToNavigate(label);
      setIsOpen(true);
      return;
    }
    setSelectedTab(label);
  };

  return (
    <HStack
      as="button"
      justify="space-between"
      onClick={onClick}
      _hover={{
        color: COLORS.FINCH.PURPLE,
      }}
      color={color}
      {...rest}
    >
      <HStack>
        {createElement(icon)}
        <Text fontSize="sm" fontWeight={selected ? 600 : 400} color="inherit">
          {label}
        </Text>
      </HStack>
      <Text fontSize="sm" fontWeight={selected ? 600 : 400} color="inherit">
        {entries}
      </Text>
    </HStack>
  );
};
