export const AutomatedIcon = ({ color }: { color: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 741">
      <rect
        id="Rectangle 238"
        x="2"
        y="2"
        width="16"
        height="16"
        rx="4"
        fill={color || 'currentColor'}
      />
      <path
        id="A"
        d="M8.31836 13.5H6.5L8.86182 6.5H11.1143L13.4761 13.5H11.6577L10.0137 8.26367H9.95898L8.31836 13.5ZM8.07568 10.7451H11.8765V12.0303H8.07568V10.7451Z"
        fill="white"
      />
    </g>
  </svg>
);
