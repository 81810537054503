import { ConnectionInfo } from './types';
import {
  AggregatedConnectionStatus,
  AggregatedConnectionStatusInfo,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';

export class Connection {
  constructor(
    private readonly params: Readonly<
      ConnectionInfo | AggregatedConnectionStatusInfo
    >,
  ) {}

  get clientId() {
    return this.params.clientId;
  }

  get companyId() {
    return this.params.companyId;
  }

  get providerId() {
    return this.params.providerId;
  }

  get clientName() {
    return this.params.clientName;
  }

  get companyName() {
    return this.params.companyName;
  }

  get providerName() {
    return this.params.providerName;
  }

  get status() {
    return this.params.status;
  }

  get implementationKinds() {
    return this.params.implementationKinds;
  }

  get providerLogo() {
    return this.params.providerLogo;
  }

  get providerIcon() {
    return this.params.providerIcon;
  }

  get firstConnectedAt() {
    return new Date(this.params.firstConnectedAt);
  }

  get lastSyncAt() {
    const lastSyncAt = this.params.lastSyncAt;

    if (lastSyncAt) {
      return new Date(lastSyncAt);
    }
  }

  isConnected(): boolean {
    return this.params.status === AggregatedConnectionStatus.CONNECTED;
  }

  isDisconnected(): boolean {
    return this.params.status === AggregatedConnectionStatus.DISCONNECTED;
  }

  isPending(): boolean {
    return this.params.status === AggregatedConnectionStatus.PENDING;
  }

  needsAttention(): boolean {
    return this.params.status === AggregatedConnectionStatus.NEEDS_ATTENTION;
  }

  insufficientPermissions(): boolean {
    return (
      this.params.status === AggregatedConnectionStatus.INSUFFICIENT_PERMISSIONS
    );
  }

  noAccountSetUp(): boolean {
    return this.params.status === AggregatedConnectionStatus.NO_ACCOUNT_SET_UP;
  }

  reauthNeeded(): boolean {
    return (
      this.params.status === AggregatedConnectionStatus.REAUTHORIZATION_NEEDED
    );
  }

  isLegacySandboxProvider(): boolean {
    return this.params.providerId === 'finch';
  }

  hasFinchSandboxKinds(): boolean {
    return this.params.implementationKinds.some((imp) =>
      imp.startsWith('finch_sandbox_'),
    );
  }

  isImplementation(...kinds: `${ImplementationKind}`[]): boolean {
    return Array.from(new Set(kinds)).every((kind) =>
      this.implementationKinds.includes(kind as ImplementationKind),
    );
  }

  isAssisted(): boolean {
    return this.params.implementationKinds.some((kind) =>
      [
        ImplementationKind.ASSISTED,
        ImplementationKind.FINCH_SANDBOX_ASSISTED,
      ].includes(kind),
    );
  }

  isAutomated(): boolean {
    return this.isCredential() || this.isOAuth() || this.isApi();
  }

  isCredential(): boolean {
    return this.params.implementationKinds.some((kind) =>
      [
        ImplementationKind.CREDENTIAL,
        ImplementationKind.FINCH_SANDBOX_CREDENTIAL,
      ].includes(kind),
    );
  }

  isOAuth(): boolean {
    return this.params.implementationKinds.some((kind) =>
      [
        ImplementationKind.OAUTH,
        ImplementationKind.FINCH_SANDBOX_OAUTH,
      ].includes(kind),
    );
  }

  isApi(...kind: ('token' | 'credential')[]): boolean {
    const isApiToken = this.params.implementationKinds.some((kind) =>
      [
        ImplementationKind.API_TOKEN,
        ImplementationKind.FINCH_SANDBOX_API_TOKEN,
      ].includes(kind),
    );

    const kinds = new Set(kind);

    if (kinds.size === 2) {
      return isApiToken;
    }

    if (kinds.size === 1) {
      switch (kind[0]) {
        case 'token':
          return isApiToken;
      }
    }

    return isApiToken;
  }
}
