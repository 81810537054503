import { z } from 'zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../utils/baseUrls';
import { useApplication } from '../../applications/hooks';
import { useSandboxStore } from '../state/sandboxStore';
import { ConnectSession } from 'types/connect';

export type Scope = { key: string; label: string; selected: boolean };

export type CreateConnectSessionResponse = {
  sessionId: string;
  connectUrl: string;
};

export const newSessionSchema = z.object({
  externalCompanyId: z
    .string()
    .min(1, { message: 'The company ID is required' }),
  externalCompanyName: z
    .string()
    .min(1, { message: 'The company name is required' }),
  providerId: z.string().optional(),
  implementationId: z.string().uuid().optional(),
  scopes: z.array(z.string()),
  externalCompanyEmail: z.string().optional(),
  redirectUri: z.string().url().optional(),
  expiresAtInSeconds: z.coerce.number().optional(),
  showAssisted: z.boolean().optional(),
});

export const reauthSessionSchema = z.object({
  providerId: z.string(),
  connectionId: z.string().uuid(),
  scopes: z.array(z.string()),
  externalCompanyId: z.string().nullish(),
  externalCompanyName: z.string().nullish(),
});

export type NewSession = z.infer<typeof newSessionSchema>;
export type ReauthSession = z.infer<typeof reauthSessionSchema>;

export type CreateConnectSessionError = {
  message: string;
  extra?: { context?: { connectionId?: string } };
};

export const useConnectSession = () => {
  const toast = useToast();
  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { application } = useApplication(applicationId);
  const selectedSandbox = useSandboxStore((store) => store.selectedSandbox);

  const list = useQuery({
    queryKey: ['connect-sessions', applicationId],
    queryFn: async () => {
      const uri = new URL(
        `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/connect-sessions`,
        baseURL,
      );
      return axios
        .get<{ sessions: ConnectSession[] }>(uri.toString(), {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        })
        .then((res) => res.data.sessions);
    },
    initialData: [],
    enabled: !!applicationId && !!connectionId,
  });

  const create = useMutation<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession
  >({
    mutationFn: async (session: NewSession | ReauthSession) => {
      const uri = new URL('/api/v1/connect-session', baseURL);

      return axios
        .post(
          uri.toString(),
          {
            ...session,
            expiresAtInSeconds:
              'expiresAtInSeconds' in session && session.expiresAtInSeconds
                ? session.expiresAtInSeconds * 60
                : undefined,
            clientId: applicationId,
            sandbox: application?.isSandbox() ? selectedSandbox : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);
    },
    onSuccess: () => {
      toast({
        title: 'Finch Connect URL created successfully',
        status: 'success',
        isClosable: true,
      });

      queryClient.invalidateQueries({
        queryKey: ['connect-sessions', applicationId],
      });

      queryClient.invalidateQueries({
        queryKey: ['staged-connections', applicationId],
      });
    },
  });

  return { create, list };
};
