import { SftpCredentialsUpdate } from './types';
import { FormInputProps } from '../../../components/FormInput';

export const FORM_ENTRIES: Array<
  Pick<
    FormInputProps<SftpCredentialsUpdate>,
    'id' | 'label' | 'type' | 'placeholder' | 'registerOptions'
  >
> = [
  {
    id: 'host',
    label: 'URL',
  },
  {
    id: 'port',
    label: 'Port',
    type: 'number',
  },
  {
    id: 'username',
    label: 'Username',
  },
  {
    id: 'password',
    label: 'Password',
    type: 'password',
    placeholder: '**************',
  },
  {
    id: 'rootPath',
    label: 'Target Directory',
  },
  {
    id: 'notificationEmail',
    label: 'Email',
  },
];
