import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Box,
  TableContainer,
  Stack,
} from '@chakra-ui/react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import type {
  Row,
  ColumnFiltersState,
  SortingState,
  PaginationState,
  FilterFn,
} from '@tanstack/react-table';

import { Pagination } from '../../components/Pagination';
import {
  CompanyCell,
  ConnectedStatusCell,
  ConnectionTypeCell,
  DateCell,
  ProviderNameCell,
} from './TableCells';
import { EmptyStateMessage } from './EmptyStateConnectionsTable';
import { COLORS, SHADOWS } from '../../constant';
import {
  AggregatedConnectionStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { Loading } from '../../components/Loading/Loading';
import { ConnectionsTableColumn, ConnectionTableFilterId } from '../types';
import {
  TableFilters,
  SelectFilterInput,
  RadioFilterInputForArrayId,
  SearchFilterInput,
  filterFns,
  DateRangeFilterInput,
  EmptyFilterStateMessage,
  dateRangeOptions,
  RelativeDateFilterInput,
  relativeDateRangeOptions,
} from '../../components/TableFilters';
import {
  connectionsFilterList,
  connectionStatusOptions,
  connectionTypeOptions,
  getProviderOptions,
} from '../constants';
import { useFilters } from '../../components/TableFilters/hooks';
import { useSearchParams } from '../../shared/useQuery';
import {
  getConnectionFilterFromQueryParams,
  useConnectionsQuerySync,
} from '../hooks/useConnectionsQuerySync';
import { TableHeaderContent } from '../../components/TableHeaderContent';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { Connection } from '../model';

const Style = styled.div`
  table {
    border-spacing: 0;

    th {
      padding: 16px 24px;
      border: 0px;
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};
    }

    tr {
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};

      :last-child {
        border-bottom: 0px;
      }
    }

    td {
      padding: 16px 24px;
      border: 0px;
    }
  }
`;

const TableCellText = styled.div<{ width: string | undefined }>`
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.width && `width: ${props.width};`}
`;

const TableBodyContent = ({
  applicationId,
  rows,
}: {
  applicationId: string;
  rows: Row<ConnectionsTableColumn>[];
}) => {
  const history = useHistory();

  return (
    <>
      {rows.map((row) => {
        return (
          <Tr
            cursor="pointer"
            _hover={{
              bgColor: COLORS.GRAY.GRAY_100,
            }}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                onClick={() => {
                  history.push(
                    `/app/applications/${applicationId}/connections/${row.original.companyId}`,
                    { navigatedFromConnectionsTable: true },
                  );
                }}
              >
                <TableCellText
                  width={cell.column.columnDef.meta?.style?.minWidth?.toString()}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};

export const implementationKindToCategory: Record<
  ImplementationKind,
  ConnectionCategory
> = {
  api_token: ConnectionCategory.Automated,
  oauth: ConnectionCategory.Automated,
  credential: ConnectionCategory.Automated,
  assisted: ConnectionCategory.Assisted,

  finch_sandbox_api_token: ConnectionCategory.Automated,
  finch_sandbox_oauth: ConnectionCategory.Automated,
  finch_sandbox_credential: ConnectionCategory.Automated,
  finch_sandbox_assisted: ConnectionCategory.Assisted,
};

const getConnectionsData = ({
  relations,
}: {
  relations: Connection[] | undefined;
}) => {
  const toCategory = (kind: ImplementationKind) =>
    implementationKindToCategory[kind];

  return (
    relations?.map((relation) => {
      return {
        companyName: relation.companyName,
        companyId: relation.companyId,
        company: {
          id: relation.companyId,
          name: relation.companyName,
        },
        connectedAt: new Date(relation.firstConnectedAt).getTime(),
        lastSyncAt: relation.lastSyncAt?.getTime(),
        providerName: relation.providerName,
        provider: {
          id: relation.providerId,
          name: relation.providerName,
          icon: relation.providerIcon,
        },
        status: relation.status,
        kinds: Array.from(
          new Set<ConnectionCategory>(
            relation.implementationKinds.map(toCategory),
          ),
        ),
      };
    }) ?? []
  );
};

const statusFilter: FilterFn<ConnectionsTableColumn> = (
  row,
  _columnId,
  value,
) => {
  if (value.length === 0) {
    return true;
  }

  const status: AggregatedConnectionStatus = row.getValue('status');
  if (value.includes(status)) {
    return true;
  }

  const othersNeedAttention = [
    AggregatedConnectionStatus.INSUFFICIENT_PERMISSIONS,
    AggregatedConnectionStatus.NO_ACCOUNT_SET_UP,
    AggregatedConnectionStatus.REAUTHORIZATION_NEEDED,
  ];
  if (
    value.includes(AggregatedConnectionStatus.NEEDS_ATTENTION) &&
    othersNeedAttention.includes(status)
  ) {
    return true;
  }
  return false;
};

export const ConnectionsTable = ({
  connections,
  isSandbox,
  loading,
  filterToNeedsAttention,
  setFilterToNeedsAttention,
}: {
  connections: Connection[];
  isSandbox?: boolean;
  loading: boolean;
  filterToNeedsAttention?: boolean;
  setFilterToNeedsAttention?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const queryParams = useSearchParams();
  const pageParam = queryParams.get('page') || '0';

  if (!applicationId) throw new Error('no application id in url param');

  const tableData = useMemo(() => {
    return getConnectionsData({
      relations: connections,
    });
  }, [connections]);

  const columnHelper = createColumnHelper<ConnectionsTableColumn>();

  const columns = [
    columnHelper.accessor('company', {
      header: 'Company',
      meta: {
        style: { minWidth: '334px' },
      },
      cell: (props) => <CompanyCell props={props} />,
    }),
    columnHelper.accessor('status', {
      header: 'Connection Status',
      filterFn: statusFilter,
      meta: {
        style: { minWidth: '200px' },
        filterInput: SelectFilterInput,
        filterInputOptions: connectionStatusOptions,
      },
      cell: (props) => <ConnectedStatusCell props={props} />,
    }),
    columnHelper.accessor('kinds', {
      header: 'Connection Type',
      filterFn: 'arrayEquals',
      meta: {
        style: { minWidth: '216px' },
        filterInput: RadioFilterInputForArrayId,
        filterInputOptions: connectionTypeOptions,
      },
      cell: (props) => <ConnectionTypeCell props={props} />,
    }),
    columnHelper.accessor('connectedAt', {
      header: 'Connected',
      filterFn: 'dateRange',
      meta: {
        style: { minWidth: '160px' },
        filterInput: DateRangeFilterInput,
        filterInputOptions: dateRangeOptions,
      },
      cell: (props) => <DateCell props={props} />,
    }),
    columnHelper.accessor('lastSyncAt', {
      header: 'Last Synced',
      filterFn: 'relativeDateRange',
      meta: {
        style: { minWidth: '160px' },
        filterInput: RelativeDateFilterInput,
        filterInputOptions: relativeDateRangeOptions,
      },
      cell: (props) => <DateCell props={props} />,
    }),
    columnHelper.accessor('provider', {
      header: 'Provider',
      filterFn: 'arrIncludesSome',
      meta: {
        style: { minWidth: '200px' },
      },
      cell: (props) => <ProviderNameCell props={props} />,
      sortingFn: (rowA, rowB) => {
        return rowA.original.provider.name > rowB.original.provider.name
          ? 1
          : rowA.original.provider.name < rowB.original.provider.name
          ? -1
          : 0;
      },
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      enableHiding: true,
      meta: {
        filterLabel: 'Company Name',
        filterInput: SearchFilterInput,
      },
    }),
    columnHelper.accessor('companyId', {
      id: 'companyId',
      enableHiding: true,
      meta: {
        filterLabel: 'Company ID',
        filterInput: SearchFilterInput,
      },
    }),
    columnHelper.accessor('providerName', {
      id: 'providerName',
      filterFn: 'arrIncludesSome',
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: getProviderOptions(tableData),
        filterLabel: 'Provider',
      },
      enableHiding: true,
    }),
  ];

  const [columnVisibility, setColumnVisibility] = useState<
    Partial<Record<keyof ConnectionsTableColumn, boolean>>
  >({
    companyName: false,
    companyId: false,
    providerName: false,
  });
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'connectedAt',
      desc: true,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: parseInt(pageParam),
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() =>
    getConnectionFilterFromQueryParams(),
  );

  const [selectedFilters, setSelectedFilters] = useState<
    ConnectionTableFilterId[]
  >(
    () =>
      getConnectionFilterFromQueryParams().map(
        ({ id }) => id,
      ) as ConnectionTableFilterId[],
  );

  const {
    getHeaderGroups,
    getState,
    getRowModel,
    getColumn,

    getCanPreviousPage,
    getCanNextPage,
    getPageCount,

    getFilteredRowModel: getCurrentFilteredRowModel,
  } = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnVisibility,
      columnFilters,
      pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    filterFns,
  });

  useEffect(() => {
    if (!loading && connections) {
      const maxPageIndex = getPageCount() - 1;
      const pageIndexParam = parseInt(pageParam);
      const newPageIndex =
        pageIndexParam <= maxPageIndex ? pageIndexParam : maxPageIndex;

      setPagination({
        pageIndex: newPageIndex > 0 ? newPageIndex : 0,
        pageSize,
      });
    }
  }, [connections, getPageCount, loading, pageParam, pageSize]);

  const history = useHistory();

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    newUrlParams.set('page', pageIndex.toString());

    history.replace(`?${newUrlParams}`);
  }, [history, applicationId, pageIndex]);

  const state = getState();
  const rows = getRowModel().rows;

  const shouldShowEmptyState = !loading && 0 === connections?.length;

  const shouldShowEmptyRowsState =
    !loading && 0 === rows.length && 0 < state.columnFilters.length;
  const shouldShowPagination = 0 < rows.length && !shouldShowEmptyState;

  const resetPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  };

  const {
    filterConfigs,
    getFilterValue,
    setFilterValue,
    addFilter,
    deleteFilter,
    toggleOpen,
    activeFilterId,
    removeAllFilters,
    closeFilters,
  } = useFilters<ConnectionsTableColumn, ConnectionTableFilterId>({
    filterList: [...connectionsFilterList],
    getColumn,
    selectedFilters,
    setSelectedFilters,
  });

  useEffect(() => {
    if (!filterToNeedsAttention || !setFilterToNeedsAttention) return;
    setColumnFilters([
      {
        id: 'status',
        value: [AggregatedConnectionStatus.NEEDS_ATTENTION],
      },
    ]);
    setSelectedFilters(['status']);
    closeFilters();
    setFilterToNeedsAttention(false);
  }, [
    filterToNeedsAttention,
    setColumnFilters,
    setFilterToNeedsAttention,
    closeFilters,
  ]);

  useConnectionsQuerySync({ columnFilters });

  return (
    <>
      <Stack
        borderRadius="8px"
        boxShadow={SHADOWS.PAGE}
        spacing="0"
        backgroundColor={COLORS.WHITE}
      >
        <TableFilters
          setFilterValue={(...args) => {
            setFilterValue(...args);

            // Reset back to the first page after changing filters from the menu.
            // This is to prevent 0 search results showing if the new filtered list
            // size is less than the last filtered size.
            resetPagination();
          }}
          getFilterValue={getFilterValue}
          filterConfigs={filterConfigs}
          addFilter={addFilter}
          deleteFilter={deleteFilter}
          selectedFilters={selectedFilters}
          toggleOpen={toggleOpen}
          activeFilterId={activeFilterId}
        />

        <TableContainer>
          <Style>
            <Table
              borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
              variant="simple"
            >
              <TableHeaderContent headerGroups={getHeaderGroups()} />
              <Tbody textColor="#1A202C">
                {!loading && (
                  <TableBodyContent applicationId={applicationId} rows={rows} />
                )}
              </Tbody>
            </Table>
          </Style>
          {shouldShowEmptyState && (
            <EmptyStateMessage omitExtendedCaption={isSandbox} />
          )}
          {shouldShowEmptyRowsState && (
            <EmptyFilterStateMessage
              table="connections"
              onClearFilters={removeAllFilters}
            />
          )}
        </TableContainer>
        {loading && (
          <Loading message="We're fetching your application's employer connections." />
        )}
      </Stack>
      {shouldShowPagination && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1,
              }));
            }}
            canPreviousPage={getCanPreviousPage()}
            nextPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }));
            }}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={getCurrentFilteredRowModel().rows.length}
          />
        </Box>
      )}
    </>
  );
};
