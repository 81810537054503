import { WebhookMessageStatus as WebhookMessageStatusEnum } from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { TagVariant } from '../components/Tag/Tag';

export const webhookStatusToVariant: Record<
  WebhookMessageStatusEnum,
  TagVariant
> = {
  [WebhookMessageStatusEnum.SUCCESS]: 'success',
  [WebhookMessageStatusEnum.FAIL]: 'failed',
  [WebhookMessageStatusEnum.PENDING]: 'pending',
  [WebhookMessageStatusEnum.SENDING]: 'sending',
  [WebhookMessageStatusEnum.UNKNOWN]: 'unknown',
};
