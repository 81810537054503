export const LogoutIcon = ({ color }: { color?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17V3H10V4.5H4.5V15.5H10V17H3ZM13.5 13.5L12.4375 12.4375L14.125 10.75H8V9.25H14.125L12.4375 7.5625L13.5 6.5L17 10L13.5 13.5Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
