import { create } from 'zustand';
import { Category } from '../types';

export const usePayStatementItemStore = create<{
  clear: () => void;

  editing: boolean;
  setEditing: (polarity: boolean) => void;

  eligible: Partial<Record<Category, Record<string, boolean>>>;
  setEligible: (category: Category, item: string, polarity: boolean) => void;

  labels: Partial<Record<Category, Record<string, string>>>;
  setLabel: (category: Category, item: string, label: string) => void;
}>((set) => ({
  clear: () => set({ eligible: {}, labels: {} }),

  editing: false,
  setEditing: (polarity) => set({ editing: polarity }),

  eligible: {},
  setEligible: (category, item, polarity) =>
    set((previous) => {
      return {
        ...previous,
        eligible: {
          ...previous.eligible,
          [category]: {
            ...previous.eligible[category],
            [item]: polarity,
          },
        },
      };
    }),

  labels: {},
  setLabel: (category, item, label) =>
    set((previous) => {
      return {
        ...previous,
        labels: {
          ...previous.labels,
          [category]: {
            ...previous.labels[category],
            [item]: label,
          },
        },
      };
    }),
}));
