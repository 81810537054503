import { Badge } from '@chakra-ui/react';
import { RequestMethod } from '../open-api';

export const COLOR_SCHEME: Record<
  RequestMethod,
  { bg: string; color: string } | { colorScheme: string }
> = {
  get: { bg: 'success.100', color: 'success.800' },
  post: { bg: 'primary.200', color: 'primary.600' },
  put: { colorScheme: 'yellow' },
  delete: { colorScheme: 'red' },
  options: { colorScheme: 'gray' },
  head: { colorScheme: 'gray' },
  patch: { colorScheme: 'purple' },
  trace: { colorScheme: 'gray' },
} as const;

export const METHOD_LABEL: Record<RequestMethod, string> = {
  get: 'Get',
  post: 'Post',
  put: 'Put',
  delete: 'Del',
  options: 'Options',
  head: 'Head',
  patch: 'Patch',
  trace: 'Trace',
} as const;

export const RequestBadge = ({ method }: { method: RequestMethod }) => {
  return (
    <Badge
      fontSize="11px"
      size="xs"
      p="2px 4.5px"
      flexBasis="23%"
      borderRadius="4px"
      maxW="2.5rem"
      textAlign="center"
      {...COLOR_SCHEME[method]}
    >
      {METHOD_LABEL[method] || method}
    </Badge>
  );
};
