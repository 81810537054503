import { isLocal } from './baseUrls';

export const unleashConfig = {
  url: 'https://us.app.unleash-hosted.com/usee0010/api/frontend',
  clientKey: isLocal
    ? '*:development.40b02dcee39dfb9d139bf918e8cb025eccf7b672a32674204669a2d9'
    : '*:production.fd07873d332e56e068e5c588a31f5449ce847b453d630d58f9c268cf',
  refreshInterval: 60,
  appName: 'developer-dashboard-ui',
};
