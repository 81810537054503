import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSeachParamsOnLoad } from '../../shared/useQuery';
import { useActivityStore } from '../state/activityStore';

export const useActivityDetails = () => {
  const history = useHistory();
  const {
    logs,
    setSelectedLog,

    initialFetchComplete,
    selectedLog,
  } = useActivityStore();
  const searchParamsOnLoad = useSeachParamsOnLoad();

  useEffect(() => {
    const selectedLogId = searchParamsOnLoad.get('selectedLogId');
    const selectedLog = logs.find((log) => log.id === selectedLogId);

    if (selectedLog) {
      setSelectedLog(selectedLog);
    }
    // We only care to run this hook after the initial fetch on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetchComplete]);

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    newUrlParams.set('selectedLogId', selectedLog?.id || '');
    history.replace(`?${newUrlParams}`);
  }, [history, selectedLog]);
};
