import { Flex, Stack, Text } from '@chakra-ui/react';

import { COLORS } from '../../constant';

export function Disconnected() {
  return (
    <Flex
      backgroundColor={COLORS.WHITE}
      borderRadius="8px"
      boxShadow="0px 0px 1px rgba(22, 22, 22, 0.12), 0px 4px 8px rgba(22, 22, 22, 0.06)"
      width="100%"
      alignItems={'center'}
      justifyContent="center"
    >
      <Stack marginTop={16} marginBottom={16} gap={8} alignItems="center">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="64" height="64" rx="32" fill="#EFF1FF" />
          <path
            d="M40.332 33.3055L40.332 30.5277L23.4987 30.5277L23.4987 33.3055L40.332 33.3055ZM48.6654 32C48.6654 34.287 48.2279 36.4444 47.3529 38.4722C46.4779 40.4999 45.2857 42.2685 43.7765 43.7778C42.2672 45.287 40.4987 46.4791 38.4709 47.3541C36.4431 48.2291 34.2857 48.6666 31.9987 48.6666C29.6931 48.6666 27.5265 48.2291 25.4987 47.3541C23.4709 46.4791 21.707 45.287 20.207 43.7778C18.707 42.2685 17.5195 40.4999 16.6445 38.4722C15.7695 36.4444 15.332 34.287 15.332 32C15.332 29.6944 15.7695 27.5277 16.6445 25.5C17.5195 23.4722 18.707 21.7083 20.207 20.2083C21.707 18.7083 23.4709 17.5208 25.4987 16.6458C27.5265 15.7708 29.6931 15.3333 31.9987 15.3333C34.2857 15.3333 36.4431 15.7708 38.4709 16.6458C40.4987 17.5208 42.2672 18.7083 43.7765 20.2083C45.2857 21.7083 46.4779 23.4722 47.3529 25.5C48.2279 27.5277 48.6654 29.6944 48.6654 32ZM45.8876 32C45.8876 28.1296 44.5358 24.8472 41.832 22.1527C39.1283 19.4583 35.8506 18.111 31.9987 18.111C28.1283 18.111 24.8459 19.4583 22.1514 22.1527C19.457 24.8472 18.1098 28.1296 18.1098 32C18.1098 35.8518 19.457 39.1296 22.1514 41.8333C24.8459 44.537 28.1283 45.8889 31.9987 45.8889C35.8506 45.8889 39.1283 44.537 41.832 41.8333C44.5358 39.1296 45.8876 35.8518 45.8876 32Z"
            fill="#3E4AE7"
          />
        </svg>

        <Text fontWeight="semibold" textAlign="center" fontSize="16px">
          This account has been disconnected.
        </Text>
      </Stack>
    </Flex>
  );
}
