export const format = (date: Date) => {
  return date.toLocaleString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
    timeZoneName: 'short',
  });
};
