import { ReactNode } from 'react';
import { Collapsible } from './collapsible';
import { COLORS } from 'constant';

export const RequestOption = ({
  children,
  title,
  openByDefault,
  isOpen,
}: {
  title: ReactNode;
  isOpen?: boolean;
  children: ReactNode;
  openByDefault?: boolean;
}) => {
  return (
    <Collapsible
      heading={title}
      isOpen={isOpen}
      openByDefault={openByDefault}
      spaceBetweenIcon
      fontSize="13px"
      border="1px"
      borderColor={COLORS.GRAY.GRAY_400}
      bg={COLORS.GRAY.GRAY_200}
      borderRadius="8px"
      fontWeight="600"
      px="1"
      panelProps={{ bg: 'white', p: '12px', borderBottomRadius: '8px' }}
    >
      {children}
    </Collapsible>
  );
};
