import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../utils/baseUrls';
import { Auth0Client } from './auth0-client';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';

export const usePassword = () => {
  const auth0 = useAuth0();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  const client = new Auth0Client(auth0, {
    clientId: config.auth0ClientId,
    domain: config.auth0Domain,
    scope: config.auth0Scope,
    audience: config.auth0Audience,
  });

  const sendChangePasswordEmail = useMutation({
    mutationFn: (email: string) => client.sendChangePasswordEmail(email),
    onError: () => {
      toast({
        description: 'Failed to send a password reset email',
        status: 'error',
      });
    },

    onSuccess: () => {
      toast({
        title: 'Password reset email sent',
        description:
          'Please check your email inbox for instructions on how to reset your password',
        status: 'success',
      });
    },
  });

  return {
    sendChangePasswordEmail,
  };
};
