import { Container, type ContainerProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const PageContainer = ({
  children,
  ...props
}: ContainerProps & { children: ReactNode }) => {
  return (
    <Container maxW={'100rem'} p={{ base: 4, md: 10 }} {...props}>
      {children}
    </Container>
  );
};
