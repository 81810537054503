import { CSSProperties } from 'react';
import { Td, Tr, useColorModeValue } from '@chakra-ui/react';
import { flexRender, type Row } from '@tanstack/react-table';

import { getCommonPinningStyles } from '../../components/TableHeaderContent';
import { COLORS, Z_INDEX } from '../../constant';
import { FieldSupportCell } from '../types';
import { SubheadingCell } from './TableCells';

export const FieldSupportRow = ({ row }: { row: Row<FieldSupportCell> }) => {
  const isSubheading = row.original.kind === 'subheading';
  const subheadingBackground = useColorModeValue(
    COLORS.GRAY.GRAY_200,
    '#2e2e2e',
  );

  return (
    <Tr key={row.id}>
      {row
        .getVisibleCells()
        .reduce<JSX.Element[]>((cells, cell, index, data) => {
          if (isSubheading && index > 1) {
            return cells;
          }

          let colSpan = 1;
          const style: CSSProperties = {
            ...cell.column.columnDef.meta?.style,
            ...getCommonPinningStyles(cell.column),
          };

          if (isSubheading) {
            colSpan = index === 0 ? 1 : data.length - 1;
            style.zIndex = index === 0 ? Z_INDEX.TABLE_PINNED_ROW : 1;
            style.top = 84; // NOTE: twice the hegiht of one cell
            style.backgroundColor = subheadingBackground;
            style.position = 'sticky';
          }

          cells.push(
            <Td key={cell.id} colSpan={colSpan} style={style}>
              {index === 0 && isSubheading ? (
                <SubheadingCell endpoint={row.original.group} />
              ) : (
                flexRender(cell.column.columnDef.cell, cell.getContext())
              )}
            </Td>,
          );

          return cells;
        }, [])}
    </Tr>
  );
};

export const PinnedRow = ({ row }: { row: Row<any> }) => {
  return (
    <Tr
      zIndex={Z_INDEX.TABLE_PINNED_ROW}
      position="sticky"
      top={
        row.getIsPinned() === 'top'
          ? `${row.getPinnedIndex() * 26 + 42}px`
          : undefined
      }
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <Td
            key={cell.id}
            style={{
              ...cell.column.columnDef.meta?.style,
              ...getCommonPinningStyles(cell.column),
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  );
};
