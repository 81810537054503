export const InProgressIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 15.5L5.5 15.5L5.5 13.9583C6.05556 14.5833 6.71875 15.0799 7.48958 15.4479C8.26042 15.816 9.09722 16 10 16C11.4028 16 12.6354 15.5764 13.6979 14.7292C14.7604 13.8819 15.4583 12.8056 15.7917 11.5L14.2292 11.5C13.9097 12.375 13.3715 13.0937 12.6146 13.6562C11.8576 14.2187 10.9861 14.5 10 14.5C9.33333 14.5 8.71528 14.3646 8.14583 14.0937C7.57639 13.8229 7.09028 13.4583 6.6875 13L8 13L8 11.5L4 11.5L4 15.5ZM4.20833 8.5L5.77083 8.5C6.09028 7.625 6.62847 6.90625 7.38542 6.34375C8.14236 5.78125 9.01389 5.5 10 5.5C10.6667 5.5 11.2847 5.63542 11.8542 5.90625C12.4236 6.17708 12.9097 6.54167 13.3125 7L12 7L12 8.5L16 8.5L16 4.5L14.5 4.5L14.5 6.04167C13.9444 5.41667 13.2813 4.92014 12.5104 4.55208C11.7396 4.18403 10.9028 4 10 4C8.59722 4 7.36458 4.42361 6.30208 5.27083C5.23958 6.11805 4.54167 7.19444 4.20833 8.5Z"
      fill="#3E4AE7"
    />
  </svg>
);
