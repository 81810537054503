import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import uniqBy from 'lodash/uniqBy';
import { Path } from 'react-hook-form';
import {
  ConnectionsCsvHeader,
  ConnectionsTableColumn,
  IncompleteConnectionsCsvHeader,
  StagedConnection,
} from './types';

export const connectionStatusOptions = [
  {
    id: AccountStatus.CONNECTED,
    label: 'Connected',
  },
  {
    id: AccountStatus.REAUTH,
    label: 'Reauthentication Needed',
  },
  {
    id: AccountStatus.PENDING,
    label: 'Pending',
  },
  {
    id: AccountStatus.ERROR_NO_ACCOUNT_SETUP,
    label: 'No Account Set Up',
  },
  {
    id: AccountStatus.ERROR_PERMISSIONS,
    label: 'Insufficient Permissions',
  },
];

export const connectStepOptions = [
  {
    id: 'PREAMBLE',
    label: 'Preamble',
  },
  {
    id: 'SIGN_IN',
    label: 'Sign In',
  },
  {
    id: 'MFA',
    label: 'MFA',
  },
  {
    id: 'CAPTCHA',
    label: 'Captcha',
  },
  {
    id: 'CHOOSE_ACCOUNT',
    label: 'Choose Account',
  },
];

export const connectionTypeOptions = [
  {
    id: [ConnectionCategory.Automated],
    label: 'Automated only',
  },
  {
    id: [ConnectionCategory.Assisted],
    label: 'Assisted only',
  },
  {
    id: [ConnectionCategory.Automated, ConnectionCategory.Assisted],
    label: 'Automated and Assisted',
  },
];

export const getProviderOptions = (
  tableData: (ConnectionsTableColumn | StagedConnection)[],
) =>
  uniqBy(
    tableData.map((data) => ({
      id: 'provider' in data ? data.provider.name : data.providerName,
      label: 'provider' in data ? data.provider.name : data.providerName,
    })),
    'id',
  ).sort((a, b) => a.label?.localeCompare(b?.label ?? '') ?? 0);

export const connectionsFilterList = [
  'companyName',
  'connectionId',
  'companyId',
  'status',
  'kinds',
  'connectedAt',
  'lastSyncAt',
  'providerName',
] as const;

export const stagedConnectionFilterList = [
  'externalCompanyId',
  'externalCompanyName',
  'providerName',
  'firstConnectedAt',
] as const;

export const CsvToConnectionTableMap: Record<
  ConnectionsCsvHeader,
  Path<ConnectionsTableColumn>
> = {
  'Connection ID': 'connectionId',
  'Company ID': 'companyId',
  'Company Name': 'companyName',
  'Customer ID': 'customer.id',
  'Customer Name': 'customer.name',
  'Connection Status': 'status',
  'Connection Type': 'kinds',
  'Provider ID': 'provider.id',
  'Provider Name': 'providerName',
  'First Connected At': 'connectedAtIso',
  'Last Synced At': 'lastSyncAtIso',
};

export const CsvToIncompleteConnectionTableMap: Record<
  IncompleteConnectionsCsvHeader,
  Path<StagedConnection>
> = {
  'Customer ID': 'externalCompanyId',
  'Customer Name': 'externalCompanyName',
  'Created At': 'firstConnectedAt',
  'Provider ID': 'providerId',
  'Provider Name': 'providerName',
  'Stage Completed': 'latestStepInfo.step',
};

export const DisabledTableStyles = {
  opacity: 0.5,
  pointerEvents: 'none',
};
