import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Link,
} from '@chakra-ui/react';
import { docLinks, finchMailToLinks } from '../../shared/links';

export const SandboxAlert = () => {
  return (
    <Alert variant="finch">
      <Box>
        <Flex mb={2}>
          <AlertIcon />
          <AlertTitle>Note</AlertTitle>
        </Flex>
        <AlertDescription>
          <Flex flexDirection="column" gap={2}>
            <p>
              This is a sandbox application. To start testing Finch using our
              sandbox environment, follow our{' '}
              <Link
                href={docLinks.sandbox}
                textDecoration="underline"
                target="_blank"
              >
                <strong>Sandbox Instructions</strong>
              </Link>
              .
            </p>
            <p>
              Ready to test your integration with real employment systems?{' '}
              <Link
                textDecoration="underline"
                target="_blank"
                href={finchMailToLinks.sales}
              >
                <strong>Contact</strong>
              </Link>{' '}
              the Finch team!
            </p>
          </Flex>
        </AlertDescription>
      </Box>
    </Alert>
  );
};
