import { useEffect, useState } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { AuthApi } from './api';
import { baseURL } from '../utils/baseUrls';

export const useAuthApi = () => {
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const [api, setApi] = useState<AuthApi | null>(null);

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();

      setApi(new AuthApi(baseURL, token));
    })();
  }, [getAccessTokenSilently]);

  return api;
};
