import {
  ImplementationKind,
  PlainImplementationKind,
} from '@finch-api/common/dist/internal/connect/authorize';
import { Tab } from './types';

export const tabDetails: Record<
  Tab,
  { title: string; info?: string; isIntegrationsList?: boolean }
> = {
  All: {
    title: 'All Integrations',
    isIntegrationsList: true,
  },
  Automated: {
    title: 'Automated Integrations',
    isIntegrationsList: true,
  },
  Assisted: {
    title: 'Assisted Integrations',
    isIntegrationsList: true,
  },
  New: {
    title: 'New Integrations',
    info: 'These are integrations new to Finch Connect in the last 30 days',
    isIntegrationsList: true,
  },
  Pinned: {
    title: 'Pinned Integrations',
    info: 'Pinning integrations makes them show up first in Finch Connect',
    isIntegrationsList: true,
  },
  Settings: { title: 'Settings' },
};

export const kindToTitle: Record<PlainImplementationKind, string> = {
  [ImplementationKind.API_TOKEN]: 'API',
  [ImplementationKind.OAUTH]: 'OAuth',
  [ImplementationKind.CREDENTIAL]: 'Credentials',
  [ImplementationKind.ASSISTED]: 'Assisted',
};
