export const EditIcon = ({
  color,
  hollow,
}: {
  color?: string;
  hollow?: boolean;
}) =>
  hollow ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="edit_FILL0_wght400_GRAD0_opsz20 1">
        <path
          id="Vector"
          d="M4.5 15.5H5.5625L13.375 7.6875L12.3125 6.625L4.5 14.4375V15.5ZM3 17V13.8125L14.4375 2.375L17.625 5.5625L6.1875 17H3ZM12.8344 7.16556L12.3125 6.625L13.375 7.6875L12.8344 7.16556Z"
          fill={color || 'currentColor'}
        />
      </g>
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1875 7.54175L12.4583 3.81258L14.7292 1.54175L18.4583 5.27091L16.1875 7.54175ZM2.5 17.5001V13.7709L11.2292 5.04175L14.9583 8.77092L6.22917 17.5001H2.5Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
