import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import axios from 'axios';
import { baseURL } from '../utils/baseUrls';
import * as Sentry from '@sentry/react';
import {
  Organization as APIOrganization,
  OrganizationMember,
} from '@finch-api/developer-dashboard-common';
import { Role } from '../constant/roles-and-permissions';

const organizationMembersUrl = new URL(
  `${baseURL}/api/v1/organization/members`,
);

const organizationMembersV2Url = new URL(
  `${baseURL}/api/v2/organization/members`,
);

export type OrganizationMfaSettings = {
  required: boolean;
};

export type OrgMember = OrganizationMember & {
  invitation: OrganizationMember['invitation'] & { expired_at?: string };
};

export type InviteMemberBody = {
  emails: string[];
  role: Role;
};

export class OrganizationClient {
  constructor(private readonly auth0: Auth0ContextInterface<User>) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth0;
    return getAccessTokenSilently();
  };

  getOrganization = async () => {
    try {
      const response = await axios.get<APIOrganization>(
        `${baseURL}/api/v1/organization`,
        { headers: { Authorization: `Bearer ${await this.getToken()}` } },
      );
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  };

  updateMfaSettings = async (settings: OrganizationMfaSettings) => {
    try {
      await await axios.patch<OrganizationMfaSettings>(
        `${baseURL}/api/v1/organization/mfa-settings`,
        settings,
        { headers: { Authorization: `Bearer ${await this.getToken()}` } },
      );
      return;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  };

  acceptCurrentTermsOfService = async () => {
    try {
      await axios.post(
        `${baseURL}/api/v1/organization/tos`,
        {},
        { headers: { Authorization: `Bearer ${await this.getToken()}` } },
      );
      return;
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  };

  getOrganizationMembers = async (): Promise<OrgMember[]> => {
    try {
      return (
        await axios.get<OrgMember[]>(organizationMembersUrl.toString(), {
          headers: { Authorization: `Bearer ${await this.getToken()}` },
        })
      ).data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  inviteOrganizationMember = async (
    body: InviteMemberBody,
  ): Promise<boolean> => {
    try {
      await axios.patch(organizationMembersV2Url.toString(), body, {
        headers: { Authorization: `Bearer ${await this.getToken()}` },
      });
      return true;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  removeOrganizationMember = async (id: string): Promise<boolean> => {
    const requestURL = new URL(organizationMembersUrl.toString());

    try {
      await axios.delete(requestURL.toString(), {
        headers: { Authorization: `Bearer ${await this.getToken()}` },
        data: { id_to_remove: id },
      });
      return true;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  updateUserRole = async ({
    memberId: id,
    role,
  }: {
    memberId: string;
    email: string;
    role: Role;
  }): Promise<boolean> => {
    try {
      await axios.patch(
        `${organizationMembersUrl.toString()}/${id}/role`,
        { role },
        { headers: { Authorization: `Bearer ${await this.getToken()}` } },
      );
      return true;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  getUserRole = async (id: string): Promise<{ role: Role }> => {
    try {
      const response = await axios.get<{ role: Role }>(
        `${organizationMembersUrl.toString()}/${id}/role`,
        { headers: { Authorization: `Bearer ${await this.getToken()}` } },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };
}
