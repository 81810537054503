import { JobRole, ProductType } from '@finch-api/developer-dashboard-common';

export const panelHeadingProps = {
  lineHeight: 'clamp(32px, 5vw, 40px)',
  fontSize: 'clamp(22px, 5vw, 32px)',
  color: 'gray.c900',
  fontWeight: 500,
};

export const productTypesWithNames: {
  value: ProductType;
  name: string;
}[] = [
  {
    value: ProductType.BENEFITS,
    name: 'Benefits',
  },
  {
    value: ProductType.EMPLOYEE_ENGAGEMENT_AND_DEVELOPMENT,
    name: 'Employee Engagement & Development',
  },
  {
    value: ProductType.FINANCIAL_PLANNING,
    name: 'Financial Planning',
  },
  {
    value: ProductType.EXPENSE_MANAGEMENT,
    name: 'Expense Management',
  },
  {
    value: ProductType.TAX_OPTIMIZATION,
    name: 'Tax Optimization',
  },
  {
    value: ProductType.COMPLIANCE,
    name: 'Compliance',
  },
  {
    value: ProductType.OTHER,
    name: 'Other',
  },
];

export const jobRolesWithNames: {
  value: JobRole;
  name: string;
}[] = [
  {
    value: JobRole.DEVELOPER,
    name: 'Developer',
  },
  {
    value: JobRole.PRODUCT_MANAGER,
    name: 'Product Manager',
  },
  {
    value: JobRole.OTHER,
    name: 'Other',
  },
];
