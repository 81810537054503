import {
  AggregatedConnectionStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import uniqBy from 'lodash/uniqBy';
import { ConnectionsTableColumn } from './types';

export const connectionStatusOptions = [
  {
    id: AggregatedConnectionStatus.CONNECTED,
    label: 'Connected',
  },
  {
    id: AggregatedConnectionStatus.NEEDS_ATTENTION,
    label: 'Needs Attention',
  },
  {
    id: AggregatedConnectionStatus.REAUTHORIZATION_NEEDED,
    label: 'Reauthentication Needed',
  },
  {
    id: AggregatedConnectionStatus.PENDING,
    label: 'Pending',
  },
  {
    id: AggregatedConnectionStatus.NO_ACCOUNT_SET_UP,
    label: 'No Account Set Up',
  },
  {
    id: AggregatedConnectionStatus.INSUFFICIENT_PERMISSIONS,
    label: 'Insufficient Permissions',
  },
  {
    id: AggregatedConnectionStatus.DISCONNECTED,
    label: 'Disconnected',
  },
];

export const connectionTypeOptions = [
  {
    id: [ConnectionCategory.Automated],
    label: 'Automated only',
  },
  {
    id: [ConnectionCategory.Assisted],
    label: 'Assisted only',
  },
  {
    id: [ConnectionCategory.Automated, ConnectionCategory.Assisted],
    label: 'Automated and Assisted',
  },
];

export const getProviderOptions = (tableData: ConnectionsTableColumn[]) =>
  uniqBy(
    tableData.map((data) => ({
      id: data.provider.name,
      label: data.provider.name,
    })),
    'id',
  ).sort((a, b) => a.label.localeCompare(b.label));

export const connectionsFilterList = [
  'companyName',
  'companyId',
  'status',
  'kinds',
  'connectedAt',
  'lastSyncAt',
  'providerName',
] as const;
