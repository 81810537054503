import { ConnectionCard } from 'components/ConnectionCard';
import {
  HStack,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { truncateUuid } from 'utils/truncateUuid';
import { ClipboardButton } from 'components/ClipboardButton';
import { InfoIcon } from 'shared/InfoIcon';

const DetailItem = ({
  label,
  value,
  isLoading,
  textToCopy,
  tooltipText,
}: {
  label: string;
  value?: string | null;
  isLoading?: boolean;
  tooltipText?: string;
  textToCopy?: string | null;
}) => {
  return (
    <Stack
      flexBasis="25%"
      alignSelf="flex-start"
      p={{ base: '2', lg: '6' }}
      px={{ base: '4', lg: '6' }}
    >
      <HStack>
        <Text fontSize={{ base: 'xs', lg: 'sm' }} fontWeight={500}>
          {label}
        </Text>
        {tooltipText && (
          <Tooltip label={tooltipText} w="280px">
            <div>
              <InfoIcon />
            </div>
          </Tooltip>
        )}
      </HStack>
      <HStack justify="space-between">
        <Text fontSize={{ base: 'md', lg: '2xl' }} fontWeight={600}>
          {isLoading ? (
            <Skeleton
              height="40px"
              width={{ base: '256px', lg: '100px', xl: '256px' }}
              rounded="lg"
            />
          ) : (
            value ?? '—'
          )}
        </Text>
        {textToCopy && <ClipboardButton iconSize="20px" value={textToCopy} />}
      </HStack>
    </Stack>
  );
};

export const ConnectionDetailHeader = ({
  companyName,
  companyId,
  customerName,
  customerId,
  isLoading,
  isStaged,
}: {
  companyName?: string | null;
  companyId?: string | null;
  customerName?: string | null;
  customerId?: string | null;
  isLoading?: boolean;
  isStaged?: boolean;
}) => {
  return (
    <ConnectionCard>
      <Stack
        divider={<StackDivider borderColor="gray.200" />}
        align="flex-start"
        direction={{ base: 'column', lg: 'row' }}
        spacing="0"
      >
        <DetailItem
          label="Company Name"
          value={companyName}
          isLoading={isLoading}
          tooltipText={
            isStaged
              ? 'A Company Name will be pulled from the provider when an access token is created'
              : undefined
          }
        />
        <DetailItem
          label="Company ID"
          value={companyId ? truncateUuid(companyId) : undefined}
          isLoading={isLoading}
          textToCopy={companyId}
          tooltipText={
            isStaged
              ? 'A Company ID will be generated when an access token is created'
              : undefined
          }
        />
        <DetailItem
          label="Customer Name"
          value={customerName}
          isLoading={isLoading}
        />
        <DetailItem
          label="Customer ID"
          value={customerId}
          isLoading={isLoading}
        />
      </Stack>
    </ConnectionCard>
  );
};
