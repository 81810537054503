import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { COLORS } from '../../constant';
import all_jobs from '../../assets/images/icons/all_jobs.svg';
import all_jobs_gray from '../../assets/images/icons/all_jobs_gray.svg';
import { AccountDetails } from '../../connections/types';
import { getAccountStatusVariant } from './AccountInfo';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { SandboxCount } from '../../connections/components/SandboxCount';
import { FINCH_SANDBOX_ACCOUNT_LIMIT } from '@finch-api/common/dist/internal/sandbox';
import { Flex, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useApplication } from '../../applications/hooks';
import { useSandboxConnections } from '../../connections/hooks/useConnections';

const AccountSelectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(22, 22, 22, 0.12),
    0px 4px 8px rgba(22, 22, 22, 0.06);
  padding: 12px;
  gap: 24px;
  color: ${COLORS.GRAY.GRAY_600};
`;

const JobsByAccountSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const JobsByAccountLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  padding-left: 11px;
`;

interface ButtonProps {
  selected: boolean;
}

const AccountButton = styled.button<ButtonProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 9px 11px;
  border-radius: 4px;

  ${(props) =>
    props.selected &&
    css`
      background-color: #eff1ff;
      color: ${COLORS.FINCH.PURPLE};
      font-weight: 600;
    `};
`;

const TextBlock = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AccountStatusIcon = styled.img`
  height: 16px;
  width: 16px;
`;

export const AccountSelectionPanel = ({
  connectionAccounts,
  selectedAccount,
  setAccount,
}: {
  connectionAccounts: AccountDetails[];
  selectedAccount: string | undefined;
  setAccount: React.Dispatch<React.SetStateAction<AccountDetails | undefined>>;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { application } = useApplication(applicationId);
  const { selectedSandbox } = useSandboxConnections();

  return (
    <Stack w={{ base: 'full', lg: '310px' }}>
      <AccountSelectionContent>
        {connectionAccounts.length > 1 && (
          <AccountButton
            selected={selectedAccount === undefined}
            onClick={() => setAccount(undefined)}
          >
            <AccountStatusIcon
              src={selectedAccount === undefined ? all_jobs : all_jobs_gray}
            />
            All Jobs
          </AccountButton>
        )}
        <JobsByAccountSection>
          <Flex align={'center'} justify={'space-between'}>
            <JobsByAccountLabel>Account</JobsByAccountLabel>

            {selectedSandbox === 'finch' && application?.isSandbox() && (
              <SandboxCount
                used={connectionAccounts.length}
                limit={FINCH_SANDBOX_ACCOUNT_LIMIT}
                limitType="accounts"
              />
            )}
          </Flex>

          {connectionAccounts.map((account) => {
            const key = `${account.accountId}-${account.implementationKind}`;
            return (
              <AccountButton
                key={key}
                selected={selectedAccount === key}
                onClick={() => setAccount(account)}
              >
                <AccountStatusIcon
                  src={getAccountStatusVariant(account.accountStatus).icon}
                />
                <TextBlock title={account.username || undefined}>
                  {(() => {
                    switch (account.implementationKind) {
                      case ImplementationKind.FINCH_SANDBOX_API_TOKEN:
                      case ImplementationKind.FINCH_SANDBOX_OAUTH:
                      case ImplementationKind.API_TOKEN:
                      case ImplementationKind.OAUTH:
                        return 'API Token';
                      case ImplementationKind.FINCH_SANDBOX_ASSISTED:
                      case ImplementationKind.ASSISTED:
                        return 'Assisted';
                      case ImplementationKind.FINCH_SANDBOX_CREDENTIAL:
                      case ImplementationKind.CREDENTIAL:
                        return `Credential (${account.username})`;
                      default:
                        return 'Unknown';
                    }
                  })()}
                </TextBlock>
              </AccountButton>
            );
          })}
        </JobsByAccountSection>
      </AccountSelectionContent>
    </Stack>
  );
};
