export const IncompleteCircle = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5613 15.8477C9.05633 15.9476 8.53428 16 8 16C7.46572 16 6.94367 15.9476 6.4387 15.8477L6.72981 14.3762C7.13941 14.4573 7.56397 14.5 8 14.5C8.43602 14.5 8.86059 14.4573 9.27019 14.3762L9.5613 15.8477ZM3.55507 14.6525L4.38955 13.4061C3.68007 12.9311 3.06891 12.3199 2.59393 11.6104L1.34748 12.4449C1.93154 13.3173 2.68266 14.0685 3.55507 14.6525ZM0.152272 9.5613L1.62375 9.27019C1.54272 8.86059 1.5 8.43602 1.5 8C1.5 7.56397 1.54272 7.13941 1.62375 6.72981L0.152272 6.4387C0.0523756 6.94367 0 7.46572 0 8C0 8.53428 0.0523755 9.05633 0.152272 9.5613ZM1.34748 3.55507L2.59393 4.38955C3.06892 3.68007 3.68007 3.06891 4.38955 2.59393L3.55508 1.34748C2.68266 1.93154 1.93154 2.68266 1.34748 3.55507ZM6.4387 0.152272C6.94367 0.0523755 7.46572 0 8 0C8.53428 0 9.05633 0.0523756 9.5613 0.152272L9.27019 1.62375C8.86059 1.54272 8.43602 1.5 8 1.5C7.56397 1.5 7.13941 1.54272 6.72981 1.62375L6.4387 0.152272ZM12.4449 1.34748L11.6104 2.59393C12.3199 3.06892 12.9311 3.68007 13.4061 4.38955L14.6525 3.55508C14.0685 2.68266 13.3173 1.93154 12.4449 1.34748ZM15.8477 6.4387L14.3762 6.72981C14.4573 7.13941 14.5 7.56397 14.5 8C14.5 8.43602 14.4573 8.86059 14.3762 9.27019L15.8477 9.5613C15.9476 9.05633 16 8.53428 16 8C16 7.46572 15.9476 6.94367 15.8477 6.4387ZM14.6525 12.4449L13.4061 11.6104C12.9311 12.3199 12.3199 12.9311 11.6104 13.4061L12.4449 14.6525C13.3173 14.0685 14.0685 13.3173 14.6525 12.4449Z"
      fill="#999999"
    />
  </svg>
);
