import { PlainImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import type { FilterFn } from '@tanstack/react-table';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import first from 'lodash/first';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';

import { endpoints, groups, subgroups } from './constants';
import {
  Endpoint,
  FieldSupportCell,
  Group,
  ProviderSupport,
  Subgroup,
} from './types';

/**
 * Given a POJO (Plain Old JavaScript Object), this function will return a
 * "flat" representation of the object, where it's keys are the paths to their
 * values.
 *
 * ### Example
 *
 * ```ts
 * { foo: { bar: 1 } } // => { 'foo.bar': 1 }
 * ```
 */
export const flattenObject = (
  object: Record<string, unknown>,
  path = '',
  flattened: Record<string, unknown> = Object.create(null),
): Record<string, unknown> => {
  Object.keys(object)
    .filter((x) => null !== object[x]) // HACK: `null` data coming back from `api-server`
    .forEach((key) => {
      const newPath = `${path ? path + '.' : ''}${key}`;

      if (
        typeof object[key] === 'object' &&
        object[key] !== null &&
        !(object[key] instanceof Array)
      ) {
        flattenObject(
          object[key] as Record<string, unknown>,
          newPath,
          flattened,
        );
      } else {
        flattened[newPath] = object[key];
      }
    });

  return flattened;
};

/**
 * Utility function for expanding a list of providers into a list of provider
 * implementations that is suitable for table data.
 *
 *                 [bamboo_hr_credential]
 * [alphastaff]   /
 * [bamboo_hr]  --
 * [bob]          \
 *                 [bamboo_hr_oauth]
 * [deel]
 */
export function toRowData(
  providerSupport: ProviderSupport[],
  includeCoverage: boolean,
  includeAvailability: boolean,
  includeBeta: boolean,
): () => FieldSupportCell[] {
  return (): FieldSupportCell[] => {
    let total = 0;
    const [
      providers,
      paths,
      support,
      implementations,
      availability,
      coverage,
      groups,
      status,
    ] = providerSupport.reduce(
      (partitions, ps) => {
        const [
          providers,
          paths,
          support,
          implementations,
          availability,
          coverage,
          groups,
          status,
        ] = partitions;
        const key = [ps.id, ps.type].join('_');
        const fieldPaths = Object.keys(ps.supportedFields || {});
        const supported = Object.values(ps.supportedFields || {}).filter(
          Boolean,
        ).length;
        const fieldEntries = fieldPaths.length;

        if (fieldEntries > total) {
          total = fieldEntries;
        }

        providers.push(key);
        fieldPaths.forEach((path) => {
          const [endpoint] = path.split('.');
          paths.add(path);

          if (path.endsWith('description')) {
            groups.set(
              endpoint as string,
              upperFirst(ps.supportedFields![path]),
            );
          } else if (!groups.has(endpoint as string)) {
            groups.set(endpoint as string, startCase(endpoint));
          }
        });
        support.set(key, ps.supportedFields || {});
        implementations.set(key, ps.type);
        availability.set(key, [supported, total]);
        coverage.set(key, supported > 0 ? supported / total : 0);
        status.set(key, ps.beta);

        return partitions;
      },
      [
        new Array<string>(),
        new Set<string>(),
        new Map<string, Record<string, boolean>>(),
        new Map<string, `${PlainImplementationKind}`>(),
        new Map<string, [number, number]>(),
        new Map<string, number>(),
        new Map<string, string>(),
        new Map<string, boolean>(),
      ],
    );

    const authorizationType: FieldSupportCell = {
      field: 'Authentication Type',
      kind: 'authorization_type',
      endpoint: '',
      group: '',
    };
    const fieldAvailability: FieldSupportCell = {
      field: 'Field Availability',
      kind: 'field_availability',
      endpoint: '',
      group: '',
    };
    const totalCoverage: FieldSupportCell = {
      field: 'Coverage',
      kind: 'coverage',
      endpoint: '',
      group: '',
    };
    const beta: FieldSupportCell = {
      field: 'Status',
      kind: 'status',
      endpoint: '',
      group: '',
    };
    const fieldSupport: FieldSupportCell[] = [];

    for (const provider of providers) {
      authorizationType[provider] = implementations.get(provider);
      fieldAvailability[provider] = availability.get(provider);
      totalCoverage[provider] = coverage.get(provider);
      beta[provider] = status.get(provider);
    }

    for (const path of Array.from(paths)) {
      const [endpoint, ...field] = path.split('.');

      const row: FieldSupportCell = {
        field: field.join('.'),
        kind: 'support',
        endpoint: endpoint!,
        group: groups.get(endpoint!) ?? '',
      };

      for (const provider of providers) {
        row[provider] = support.get(provider)![path];
        row.endpoint = endpoint!;
      }

      fieldSupport.push(row);
    }

    return [
      authorizationType,
      ...(includeBeta ? [beta] : []),
      ...(includeAvailability ? [fieldAvailability] : []),
      ...(includeCoverage ? [totalCoverage] : []),
      ...sortBy(fieldSupport, (x) => x.endpoint).reduce(
        (rows: FieldSupportCell[], current, index, items) => {
          const previous = items[index - 1];

          if (
            index === 0 ||
            (previous && previous.endpoint !== current.endpoint)
          ) {
            rows.push({
              endpoint: current.endpoint,
              field: current.endpoint,
              kind: 'subheading',
              group: groups.get(current.endpoint) ?? '',
            });
          }

          if (current.field !== 'description') {
            rows.push(current);
          }

          return rows;
        },
        [],
      ),
    ];
  };
}

export function filterOptionCategory(id: string): string {
  return first(id.split('.')) ?? id;
}

/**
 * A higher-order function that creates a dynamic table filter function for the
 * given data in the table.
 */
export function createContextualFilter(
  subheadings: string[],
): FilterFn<FieldSupportCell> {
  return (row, columnId, filterValue) => {
    const value: string = row.getValue(columnId);
    const valueInParentFilter = (filterValue as string[])?.some((filterVal) =>
      value.includes(filterVal),
    );
    return (
      valueInParentFilter ||
      (row.original.kind === 'subheading' &&
        subheadings?.includes(row.original.endpoint!)) ||
      ['field_availability', 'coverage'].includes(row.original.kind)
    );
  };
}

/**
 * Is the given value one of the top-level "endpoints" of the Finch API? I.e.,
 * is it a string that equals 'company', 'directory', 'payment', etc.
 */
export function isEndpoint(value?: unknown): value is Endpoint {
  return (
    isString(value) &&
    (Object.values(endpoints).flat() as string[]).includes(value)
  );
}

/**
 * Is the given value one of the endpoint ("product") groups of the Finch API?
 * I.e., is it a string that equals 'organization', 'payroll', etc.
 */
export function isGroup(value?: unknown): value is Group {
  return isString(value) && (groups as string[]).includes(value);
}

/**
 * Is the given value one of the sub-product groups of the Finch API?
 *
 * Currently this is only relavent for the deductions/benefits group/product.
 */
export function isSubgroup(value?: unknown): value is Subgroup {
  return (
    isString(value) &&
    (Object.values(subgroups).flat() as string[]).includes(value)
  );
}
