import { useQuery } from '@tanstack/react-query';

import { useConnectionDetailApi } from './useConnectionDetailApi';
import { AxiosError } from 'axios';

export const useConnectionDetails = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const api = useConnectionDetailApi();

  const {
    isLoading,
    data = null,
    error,
  } = useQuery({
    queryKey: ['connections-detail', applicationId, connectionId],
    queryFn: () => api.getConnectionDetail(applicationId, connectionId),
    refetchOnMount: false,
    retry: 1,
  });

  return { connectionDetail: data, isLoading, error: error as AxiosError };
};
