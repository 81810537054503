import { setUserId, track } from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';
import { SignUpEvent } from '@finch-api/common/dist/analytics/developer-dashboard-events';
import { useEffect } from 'react';

export const useEvent = (event: SignUpEvent, shouldBeAuthenticated = true) => {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (shouldBeAuthenticated) {
      if (isAuthenticated) {
        setUserId(user?.email);

        track(event, {
          email: user?.email,
        });
      }
    } else {
      track(event, {
        email: user?.email,
      });
    }
  }, [user?.email, isAuthenticated, shouldBeAuthenticated, event]);
};
