export const AssistedIcon = ({ color }: { color: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H8V3.5H6C4.61929 3.5 3.5 4.61929 3.5 6V8H2V6C2 3.79086 3.79086 2 6 2ZM2 14V12H3.5V14C3.5 15.3807 4.61929 16.5 6 16.5H8V18H6C3.79086 18 2 16.2091 2 14ZM12 3.5V2H14C16.2091 2 18 3.79086 18 6V8H16.5V6C16.5 4.61929 15.3807 3.5 14 3.5H12ZM16.5 12H18V14C18 16.2091 16.2091 18 14 18H12V16.5H14C15.3807 16.5 16.5 15.3807 16.5 14V12ZM6.5 13.5H8.3184L8.7788 12.0303H11.1963L11.6577 13.5H13.4761L11.1143 6.5H8.8618L6.5 13.5ZM10.7928 10.7451L10.0137 8.2637H9.959L9.1815 10.7451H10.7928Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
