import type { ReadonlyDeep } from 'type-fest';

type Layer =
  | 'menu'
  | 'table_sticky_header'
  | 'table_pinned_row'
  | 'background'
  | 'foreground';

export const Z_INDEX: ReadonlyDeep<Record<Uppercase<Layer>, number>> = {
  MENU: 10,
  TABLE_STICKY_HEADER: 4,
  TABLE_PINNED_ROW: 3,

  FOREGROUND: 1, // NOTE: Anything above 1 (in front) should be named
  BACKGROUND: 0, // NOTE: Anything below 0 (behind) should be named
};
