import { FeatureFlag } from '../../constant/feature-flags';
import { useQuery } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';

import { useConnectionDetailApi } from './useConnectionDetailApi';

export const useConnectionPayStatementItemLabels = ({
  applicationId,
  connectionId,
}: {
  applicationId: string;
  connectionId: string;
}) => {
  const api = useConnectionDetailApi();
  const enabled = useFlag(FeatureFlag.PayStatementMapping);

  const { isLoading, data } = useQuery({
    queryKey: [
      'connection-pay-statement-item-labels',
      applicationId,
      connectionId,
    ],
    queryFn: () => api.payStatementItemLabels(applicationId, connectionId),
    initialData: [],
    enabled,
  });

  return { payStatementItemLabels: data || [], isLoading };
};
