import { Stack, Text, Divider, Box } from '@chakra-ui/react';
import { SettingsIcon } from '../../shared/icons/Settings';
import { TabOption } from '../types';
import { ConfigureTab } from './ConfigureTab';
import { AssistedIcon } from '../../shared/icons/Assisted';
import { AutomatedIcon } from '../../shared/icons/Automated';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';
import { NewIcon } from '../../shared/icons/New';
import { PinnedIcon } from '../../shared/icons/Pinned';
import { ListIcon } from '../../shared/icons/List';

export const SideNavigation = () => {
  const {
    allProviders,
    assistedProviders,
    automatedProviders,
    pinnedProviders,
    newProviders,
  } = useProviderConfigurations();

  const integrationTypes: TabOption[] = [
    {
      label: 'All',
      icon: ListIcon,
      entries: allProviders.length,
      section: 'Integrations',
    },
    {
      label: 'Automated',
      icon: AutomatedIcon,
      entries: automatedProviders.length,
      section: 'Integrations',
    },
    {
      label: 'Assisted',
      icon: AssistedIcon,
      entries: assistedProviders.length,
      section: 'Integrations',
    },
  ];

  const newAndPinned: TabOption[] = [
    {
      label: 'New',
      icon: NewIcon,
      entries: newProviders.length,
      section: 'Integrations',
    },
    {
      label: 'Pinned',
      icon: PinnedIcon,
      entries: pinnedProviders.length,
      section: 'Integrations',
    },
  ];

  return (
    <Box role="group" title="Integrations navigation">
      <Stack p="16px" spacing="16px">
        <Text fontWeight="500" fontSize="sm">
          Integrations
        </Text>
        <Stack spacing="32px">
          <Stack>
            {integrationTypes.map(({ label, icon, entries }) => (
              <ConfigureTab
                key={label}
                label={label}
                icon={icon}
                entries={entries}
              />
            ))}
          </Stack>
          <Stack>
            {newAndPinned.map(({ label, icon, entries }) => (
              <ConfigureTab
                key={label}
                label={label}
                icon={icon}
                entries={entries}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack>
        <ConfigureTab label="Settings" icon={SettingsIcon} p="16px" />
      </Stack>
    </Box>
  );
};
