export const AccordionCollapse = () => (
  <svg
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 0L0 5H10L5 0Z" fill="#737373" />
  </svg>
);
