import { Box, Code, HStack, Text } from '@chakra-ui/react';
import { RequestBadge } from './request-badge';
import { useSelectedOperation } from '../store/selected-operation';
import { COLORS } from 'constant';

export const OperationTitle = () => {
  const { selectedOperation } = useSelectedOperation();

  return (
    selectedOperation && (
      <HStack px="1" spacing="1">
        <RequestBadge method={selectedOperation.method} />
        <HStack
          fontFamily="Roboto Mono"
          fontSize="xs"
          fontWeight="500"
          color="gray.700"
          flexWrap="wrap"
          spacing="1"
          divider={<Box border="0">/</Box>}
        >
          {selectedOperation.path.split('/').map((pathPiece) => {
            return (
              <Box as="code" key={pathPiece}>
                {/^\{.*\}$/.test(pathPiece) ? (
                  <Code
                    borderRadius="5"
                    border="2px solid"
                    bg="primary.200"
                    color="primary.600"
                    borderColor={COLORS.FINCH.PURPLE}
                    fontSize="xs"
                    whiteSpace="nowrap"
                  >
                    {pathPiece}
                  </Code>
                ) : (
                  <Text key={pathPiece}>{pathPiece}</Text>
                )}
              </Box>
            );
          })}
        </HStack>
      </HStack>
    )
  );
};
