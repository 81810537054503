import { Tooltip } from '@chakra-ui/react';
import { PinnedIcon } from '../../shared/icons/Pinned';
import { COLORS } from '../../constant';
import { ProviderWithImplementation } from '../types';
import { useStagedProviderConfigurationsStore } from '../state/stagedConfigurations';

export const PinProvider = ({
  provider,
  updateIsPinned,
  disableTooltip = false,
  enabled = true,
}: {
  provider: ProviderWithImplementation;
  updateIsPinned: (id: string, isPinned: boolean) => void;
  disableTooltip?: boolean;
  enabled?: boolean;
}) => {
  const pinnedProviderIds = useStagedProviderConfigurationsStore(
    (state) => state.pinnedProviderIds,
  );

  const isPinned = pinnedProviderIds.includes(provider.id);

  const onClick = () => {
    if (enabled) {
      updateIsPinned(provider.id, !isPinned);
    }
  };

  return (
    <Tooltip
      label="Pin to the top of Finch Connect"
      placement="top"
      isDisabled={disableTooltip}
    >
      <button
        role="checkbox"
        aria-label={`Pin provider: ${provider.displayName}`}
        aria-checked={isPinned}
        style={{ padding: '4px' }}
        disabled={!enabled}
        onClick={onClick}
      >
        <PinnedIcon color={COLORS.GRAY.GRAY_600} unpinned={!isPinned} />
      </button>
    </Tooltip>
  );
};
