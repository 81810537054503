import {
  DrawerCloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Tabs,
  TabPanels,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { ConnectSession, ConnectSessionEvent } from 'types/connect';
import { SessionDetailsTabPanel } from './SessionDetailsTabPanel';
import { SessionEventsTabPanel } from './SessionEventsTabPanel';
import { SessionHistoryTab } from '../constants';
import { AppTab, AppTabList } from 'components/Tabs';

export function SessionDetailsDrawer({
  connectSession,
  sessionEvents,
  onClose,
  isLoading,
  activeSessionEventsTab,
  setActiveSessionEventsTab,
}: {
  connectSession: ConnectSession | null;
  sessionEvents: ConnectSessionEvent[];
  onClose: () => void;
  isLoading?: boolean;
  activeSessionEventsTab: SessionHistoryTab;
  setActiveSessionEventsTab: (tab: SessionHistoryTab) => void;
}) {
  return (
    <Drawer
      isOpen={!!connectSession}
      placement="right"
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent bg={COLORS.WHITE} h="full" w="600px">
        <HStack p="4" justify="space-between" bg={COLORS.WHITE} align="center">
          <Heading fontWeight="600" fontSize="16px">
            Session History
          </Heading>
          <DrawerCloseButton pos="relative" top="0" right="0" />
        </HStack>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Stack h="full">
            {connectSession && (
              <Tabs
                p="4"
                size="sm"
                h="full"
                index={activeSessionEventsTab}
                onChange={setActiveSessionEventsTab}
              >
                <AppTabList gap={4}>
                  <AppTab>Session Details</AppTab>
                  <AppTab>Session Events</AppTab>
                </AppTabList>
                <TabPanels h="full">
                  <SessionDetailsTabPanel connectSession={connectSession} />
                  <SessionEventsTabPanel sessionEvents={sessionEvents} />
                </TabPanels>
              </Tabs>
            )}
          </Stack>
        )}
      </DrawerContent>
    </Drawer>
  );
}
