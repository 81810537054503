import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSettingsStore } from '../state/settingsState';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../utils/baseUrls';
import { ProviderSettings } from '../types';

export const useProviderSettings = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { applicationId } = useParams<{ applicationId: string }>();
  const {
    stagedProviderSettings,
    originalProviderSettings,
    initProviderSettings,
    setStagedProviderSettings,
    hasStagedSettings,
  } = useSettingsStore();

  const { isLoading, error } = useQuery({
    queryKey: ['providerSettings', applicationId],
    refetchOnMount: false,
    queryFn: async () => {
      return axios
        .get<ProviderSettings>(
          `${baseURL}/api/v1/applications/${applicationId}/provider-settings`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => {
          initProviderSettings(res.data);
          return res.data;
        });
    },
  });

  return {
    isLoading,
    error,
    stagedProviderSettings,
    providerSettings: originalProviderSettings,
    setStagedProviderSettings,
    hasStagedSettings,
  };
};
