import { useEffect } from 'react';

import { useSelectedSubgroupStore } from '../state/selectedSubgroup';
import { isSubgroup } from '../utils';
import { useControlsFromURI } from './useControlsFromURI';

/**
 * Use the selected subgroup for products (groups) that have multiple
 * sub-tables.
 */
export function useSelectedSubgroup() {
  const { subgroup } = useControlsFromURI();
  const { selectedSubgroup, setSelectedSubgroup } = useSelectedSubgroupStore();

  useEffect(() => {
    if (isSubgroup(subgroup)) {
      setSelectedSubgroup(subgroup);
    }
  }, []);

  return { selectedSubgroup, setSelectedSubgroup };
}
