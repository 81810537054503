import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Center, Spacer, Spinner } from '@chakra-ui/react';
import { Application } from '@finch-api/developer-dashboard-common';

import { getApplications } from '../../applications/api';
import { EmptyApplicationPage } from './EmptyApplicationPage';
import {
  findHighestPriorityApplication,
  sortApplications,
} from '../utils/utils';

export const ApplicationsRedirectPage = () => {
  const [applications, setApplications] = useState<Application[]>();
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const run = async () => {
      const token = await getAccessTokenSilently();
      if (token) {
        const _applications = await getApplications(token);
        const sortedApplications = sortApplications(_applications);
        setApplications(sortedApplications);
        setIsLoading(false);
      }
    };
    run();
  }, [getAccessTokenSilently]);

  const redirectToApplication = findHighestPriorityApplication(applications);

  if (isLoading) {
    // No applications results yet, show a spinner while we wait.
    return (
      <>
        <Spacer py="10" />
        <Center>
          <Spinner />
        </Center>
      </>
    );
  }

  if (redirectToApplication) {
    return (
      <Redirect
        to={{ pathname: `/app/applications/${redirectToApplication.id}` }}
      />
    );
  }

  return <EmptyApplicationPage />;
};
