import styled from '@emotion/styled';
import { COLORS } from '../../constant';
import React, { ForwardedRef, DetailedHTMLProps } from 'react';
import { Icon } from '@chakra-ui/react';

/** @deprecated */
export enum BadgeTheme {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  BLUE = 'BLUE',
  GRAY = 'GRAY',
  LIGHT_GRAY = 'LIGHT_GRAY',
}

const badgeTheme: {
  [k in BadgeTheme]: { color: string; backgroundColor: string };
} = {
  [BadgeTheme.GREEN]: {
    color: '#027A48',
    backgroundColor: '#ECFDF3',
  },
  [BadgeTheme.RED]: {
    color: '#B42318',
    backgroundColor: '#FEF3F2',
  },
  [BadgeTheme.YELLOW]: {
    color: '#B54708',
    backgroundColor: '#FFFAEB',
  },
  [BadgeTheme.BLUE]: {
    color: COLORS.FINCH.PURPLE,
    backgroundColor: '#EFF1FF',
  },
  [BadgeTheme.GRAY]: {
    color: '#585858',
    backgroundColor: COLORS.GRAY.GRAY_200,
  },
  [BadgeTheme.LIGHT_GRAY]: {
    color: COLORS.GRAY.GRAY_600,
    backgroundColor: COLORS.GRAY.GRAY_100,
  },
};

const Text = styled.div<{ hasIcon: boolean }>`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: inherit;
  ${(props) => props.color && `color: ${props.color}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.hasIcon && `padding-right: 2px`};
`;

interface StyledBadgeProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  color: string;
  tooltip?: boolean;
}

const StyledBadge = styled.div<StyledBadgeProps>`
  height: 28px;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 99px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  ${(props) => props.tooltip && 'cursor: pointer;'}
  gap: 6px;
`;

/** @deprecated */
export const Badge = React.forwardRef(
  (
    {
      theme,
      icon,
      text,
      tooltip,
    }: {
      theme: BadgeTheme;
      icon?: string | typeof Icon;
      text: string;
      tooltip?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledBadge
      ref={ref}
      color={badgeTheme[theme]?.backgroundColor}
      tooltip={tooltip}
    >
      {!!icon && typeof icon === 'string' && (
        <img color={badgeTheme[theme]?.color} src={icon} alt="" />
      )}
      {!!icon &&
        typeof icon !== 'string' &&
        icon({ color: badgeTheme[theme]?.color })}
      <Text color={badgeTheme[theme]?.color} hasIcon={!!icon}>
        {text}
      </Text>
    </StyledBadge>
  ),
);

Badge.displayName = 'Badge';
