import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Text,
} from '@chakra-ui/react';

import { COLORS } from '../../constant';

export const SaveConfigConfirmationModal = ({
  isOpen,
  onClose,
  onCancel,
  onSave,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSave: () => void;
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="18px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            alignSelf="center"
          >
            Confirm Configuration Changes
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="14px" color={COLORS.FINCH.BLACK}>
              Custom lists and ordering may result in lower conversion. Are you
              sure you want to publish these changes?
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Button
              onClick={onCancel}
              mr="3"
              variant="outline"
              alignItems="center"
            >
              Cancel
            </Button>
            <Button
              w="36"
              onClick={onSave}
              variant="primaryPurple"
              alignItems="center"
            >
              Publish Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
