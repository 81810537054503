import * as amplitude from '@amplitude/analytics-browser';
import { posthog } from 'posthog-js';
import type { AnalyticsEventProperties, FrontendSignUpEvent } from './types';

export const analyticsClient = {
  setUserId: (userId: string) => {
    amplitude.setUserId(userId);
    posthog.identify(userId);
  },

  track: <EventName extends FrontendSignUpEvent>(
    eventName: EventName,
    eventProperties?: AnalyticsEventProperties[EventName],
  ) => {
    amplitude.logEvent(eventName, eventProperties);
    posthog.capture(eventName, eventProperties);
  },
};
