import {
  Box,
  HStack,
  Stack,
  StackDivider,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { COLORS } from '../../constant';
import { format } from '../../shared/utils/dateFormatter';
import {
  ConnectSessionEventType,
  ConnectStep,
  ConnectSessionEvent,
} from 'types/connect';
import { CancelCircleIcon } from 'shared/icons/CancelCircle';
import { CheckCircle } from 'shared/icons/CheckCircle';
import { ConnectStepMap } from '../constants';

export const SessionEventsTabPanel = ({
  sessionEvents,
}: {
  sessionEvents: ConnectSessionEvent[];
}) => {
  return (
    <TabPanel p="0" h="full" overflowY="auto">
      {!sessionEvents.length && (
        <Text fontSize="sm" color={COLORS.GRAY.GRAY_600} py="4">
          The employer has not started the Finch Connect flow
        </Text>
      )}
      <Stack
        spacing="0"
        divider={<StackDivider borderColor={COLORS.GRAY.GRAY_400} />}
      >
        {sessionEvents.map((event) => {
          const currentStep =
            event.eventType === ConnectSessionEventType.OnStepReached &&
            event.eventData?.session?.step &&
            event.eventData.step
              ? event.eventData?.session?.step
              : event.eventData.step;

          const nextStep =
            event.eventType === ConnectSessionEventType.OnStepReached &&
            event.eventData?.session?.step &&
            event.eventData.step
              ? event.eventData.step
              : null;

          return (
            <HStack key={event.id} align="flex-start" py="16px">
              {[
                ConnectSessionEventType.OnSessionCompleted,
                ConnectSessionEventType.OnStepReached,
                ConnectSessionEventType.OnTokenExchanged,
              ].includes(event.eventType) && (
                <Box py="1">
                  <CheckCircle />
                </Box>
              )}

              {event.eventType === ConnectSessionEventType.OnError && (
                <Box py="1">
                  <CancelCircleIcon />
                </Box>
              )}

              <Stack flex="1">
                <HStack justify="space-between" align="center">
                  {event.eventType ===
                    ConnectSessionEventType.OnStepReached && (
                    <Text fontSize="sm" fontWeight={600}>
                      {ConnectStepMap[currentStep as ConnectStep] ||
                        startCase(currentStep)}
                    </Text>
                  )}
                  {event.eventType ===
                    ConnectSessionEventType.OnSessionCompleted && (
                    <Text fontSize="sm" fontWeight={600}>
                      Session Successfully Completed
                    </Text>
                  )}
                  {event.eventType === ConnectSessionEventType.OnError && (
                    <Text fontSize="sm" fontWeight={600}>
                      Error
                    </Text>
                  )}
                  {event.eventType ===
                    ConnectSessionEventType.OnTokenExchanged && (
                    <Text fontSize="sm" fontWeight={600}>
                      Access Token Generated
                    </Text>
                  )}
                  <Text alignSelf="flex-start" fontSize="xs">
                    {format(new Date(event.createdAt))}
                  </Text>
                </HStack>

                {event.eventType === ConnectSessionEventType.OnError && (
                  <Text fontSize="sm">{event.eventData?.errorMessage}</Text>
                )}

                {event.eventData?.step === ConnectStep.SELECT_PROVIDER &&
                event.providerName ? (
                  <Text fontSize="sm">{event.providerName}</Text>
                ) : null}

                {nextStep ? (
                  <Text fontSize="sm">
                    Navigated to {ConnectStepMap[nextStep as ConnectStep]}
                  </Text>
                ) : null}
              </Stack>
            </HStack>
          );
        })}
      </Stack>
    </TabPanel>
  );
};
