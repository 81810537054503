import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useConnectionDetailApi } from '../../../hooks/useConnectionDetailApi';
import { useStatusToast } from '../../../../shared/StatusToast';
import { usePayStatementItemStore } from '../store/pay-statement-store';
import { useParams } from 'react-router-dom';
import { Category, Input } from '../types';

export function useFormControls() {
  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();
  const api = useConnectionDetailApi();
  const toast = useStatusToast('bottom');
  const { editing, setEditing, labels, eligible, clear } =
    usePayStatementItemStore();
  const queryClient = useQueryClient();

  const save = useMutation({
    mutationFn: async (labels: Input[]) =>
      api.createPayStatementLabels(applicationId, connectionId, labels),
    onSuccess: (data) => {
      setEditing(false);
      toast({
        wasSuccessful: true,
        message: 'Pay Statement Labels have been successfully updated',
      });
      queryClient.invalidateQueries({
        queryKey: [
          'connection-pay-statement-item',
          applicationId,
          connectionId,
        ],
      });
      clear();
    },
    onError: (err) => {
      setEditing(false);
      toast({
        wasSuccessful: false,
        message: 'Failed to update one or more Pay Statement Labels',
      });
    },
  });

  return {
    loading: save.isPending,
    editing,
    onEdit: () => setEditing(true),
    onCancel: () => setEditing(false),
    onSave: () => {
      const payload: Input[] = [];

      for (const [category, items] of Object.entries(labels)) {
        for (const [name, label] of Object.entries(items)) {
          payload.push({
            category: category as Category,
            name,
            label,
          });
        }
      }

      save.mutate(payload);
    },
  };
}
