import { toString } from 'qrcode';
import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import axios, { AxiosError } from 'axios';
import { baseURL } from '../utils/baseUrls';

export type Authenticator = {
  id: string;
  active: boolean;
  authenticator_type: 'otp' | 'recovery-code';
};

type GetMfaTokenBody = {
  username: string;
  password: string;
};

export type GetMfaTokenResponse = {
  mfaToken: string;
};

type EnrollAuthenticatorBody = {
  authenticator_types: Array<'otp'>;
};

export type EnrollAuthenticatorResponse = {
  authenticator_type: string;
  barcode_uri: string;
  recovery_codes?: Array<string>;
  secret: string;
};

type VerifyOtpBody = {
  otp: string;
  mfaToken: string;
};

export type VerifyOtpResponse = {
  access_token: string;
  scope: string;
  expires_in: number;
  token_type: 'Bearer';
};

export type VerifyOtpError = AxiosError<{
  error: 'expired_token' | 'invalid_grant';
}>;

export type GetMfaTokenError = AxiosError<{
  error: 'expired_token' | 'invalid_grant';
}>;

type ChangePasswordBody = {
  email: string;
};

export class Auth0Client {
  constructor(
    private readonly auth0Context: Auth0ContextInterface<User>,
    private readonly authConfig: {
      clientId: string;
      domain: string;
      scope: string;
      audience: string;
    },
  ) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth0Context;
    return getAccessTokenSilently();
  };

  getMfaToken = async (password: string) => {
    const resp = await axios.post<GetMfaTokenResponse>(
      `${baseURL}/api/v1/account/mfa/token`,
      {
        username: this.auth0Context.user?.email,
        password,
      } as GetMfaTokenBody,
      { headers: { Authorization: `Bearer ${await this.getToken()}` } },
    );

    return resp.data.mfaToken;
  };

  getAuthenticators = async () => {
    const resp = await axios.get<Array<Authenticator>>(
      `https://${this.authConfig.domain}/mfa/authenticators`,
      {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      },
    );

    return resp.data;
  };

  async sendChangePasswordEmail(email: string) {
    const resp = await axios.post(
      `${baseURL}/api/v1/account/reset-password`,
      { email } as ChangePasswordBody,
      { headers: { Authorization: `Bearer ${await this.getToken()}` } },
    );

    return resp.data;
  }

  enrollOtpAuthenticator = async () => {
    const resp = await axios.post<EnrollAuthenticatorResponse>(
      `https://${this.authConfig.domain}/mfa/associate`,
      { authenticator_types: ['otp'] } as EnrollAuthenticatorBody,
      { headers: { Authorization: `Bearer ${await this.getToken()}` } },
    );

    const qrCodeSvg = await toString(resp.data.barcode_uri, { type: 'svg' });

    return { ...resp.data, qrCodeSvg };
  };

  async verifyOtpAuthenticator(opts: { otp: string; mfaToken: string }) {
    const { otp, mfaToken } = opts;
    const resp = await axios.post<VerifyOtpResponse>(
      `${baseURL}/api/v1/account/mfa/otp/verify`,
      { otp, mfaToken } as VerifyOtpBody,
      { headers: { Authorization: `Bearer ${await this.getToken()}` } },
    );

    return resp.data;
  }

  async deleteAuthenticator(id: string) {
    return axios.delete(
      `https://${this.authConfig.domain}/mfa/authenticators/${id}`,
      { headers: { Authorization: `Bearer ${await this.getToken()}` } },
    );
  }

  async deleteOtpAuthenticator(authenticators: Array<Authenticator>) {
    for (const authenticator of authenticators) {
      if (
        ['otp', 'recovery-code'].includes(authenticator.authenticator_type) &&
        authenticator.active
      ) {
        await this.deleteAuthenticator(authenticator.id);
      }
    }
  }

  async deleteUnverifiedAuthenticators(authenticators: Array<Authenticator>) {
    for (const authenticator of authenticators) {
      if (!authenticator.active) {
        await this.deleteAuthenticator(authenticator.id);
      }
    }
  }
}
