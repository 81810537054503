export const SecurityIcon = ({ color }: { color?: string }) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.125 10.9375L10.7083 6.33333L9.64583 5.27083L6.125 8.8125L4.35417 7.04167L3.29167 8.10417L6.125 10.9375ZM7 16C5.125 15.5417 3.57292 14.4792 2.34375 12.8125C1.11458 11.1458 0.5 9.29861 0.5 7.27083V2.5L7 0L13.5 2.5V7.27083C13.5 9.29861 12.8854 11.1458 11.6562 12.8125C10.4271 14.4792 8.875 15.5417 7 16Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
