export enum Provider {
  ADPRun = 'adp_run',
  ADPTotalSource = 'adp_totalsource',
  ADPWFN = 'adp_workforce_now',
  AlphaStaff = 'alphastaff',
  AmplifyHR = 'amplify_hr',
  AspenHr = 'aspen_hr',
  Bambee = 'bambee',
  BambooHR = 'bamboo_hr',
  Bob = 'bob',
  CeridianDayforce = 'ceridian_dayforce',
  CognosHR = 'cognos_hr',
  ConnectPay = 'connectpay',
  Deel = 'deel',
  EmPowerHR = 'empower_hr',
  FactorialHR = 'factorial_hr',
  Finch = 'finch',
  Gusto = 'gusto',
  HorizonPayroll = 'horizonpayroll',
  Humaans = 'humaans',
  Hybrid = 'hybrid',
  ISolved = 'isolved',
  Insperity = 'insperity',
  Justworks = 'justworks',
  Namely = 'namely',
  Netchex = 'netchex',
  OnPay = 'onpay',
  OysterHR = 'oyster_hr',
  Patriot = 'patriot',
  PaychexFlex = 'paychex_flex',
  Paycom = 'paycom',
  Paycor = 'paycor',
  Paylocity = 'paylocity',
  PayrollSolutions = 'payroll_solutions',
  Paytime = 'paytime',
  Personio = 'personio',
  PrestigePEO = 'prestige_peo',
  PrismHR = 'prism_hr',
  Quickbooks = 'quickbooks',
  Rippling = 'rippling',
  SageHR = 'sage_hr',
  Sapling = 'sapling',
  SequoiaOne = 'sequoia_one',
  SquarePayroll = 'square_payroll',
  ToastPayroll = 'toast_payroll',
  Trinet = 'trinet',
  UKGReady = 'ukg_ready',
  UltiPro = 'ulti_pro',
  Wave = 'wave',
  Workday = 'workday',
  Zenefits = 'zenefits',
}
