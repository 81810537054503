import * as Yup from 'yup';
import _ from 'lodash';
import {
  Application,
  ApplicationStatus,
} from '@finch-api/developer-dashboard-common';

export const redirectURIsFormValidationSchema = (redirectURIs: Set<string>) =>
  Yup.object().shape({
    // TODO: @aren55555 move this logic to common (currently it returns just a bool)
    url: Yup.string()
      .test('isURL', 'error msg here', (value, { createError }) => {
        if (!value) return true;

        let url;
        try {
          // eslint-disable-next-line prefer-const
          url = new URL(value);
        } catch (err) {
          return createError({ message: 'This URI is not valid.' });
        }

        if (url.protocol !== 'https:' && url.protocol !== 'http:') {
          return createError({
            message:
              'This URI uses an invalid protocol, must be either http or https.',
          });
        }

        if (
          url.protocol === 'http:' &&
          url.hostname !== 'localhost' &&
          url.hostname !== '127.0.0.1'
        ) {
          return createError({
            message:
              'The http protocol may only be used with localhost (e.g. http://localhost:3000).',
          });
        }

        // URL Ok!
        return true;
      })
      .required('This field is required.')
      .test('isUnique', 'This Redirect URI already exists.', (value) => {
        if (!value) return false;
        if (redirectURIs.has(value.toLocaleLowerCase())) {
          return false;
        }
        return true;
      }),
  });

export const findHighestPriorityApplication = (
  applications: Application[] | undefined,
) => {
  if (!applications) return;

  let foundApplication: Application | undefined;

  // Try to find the first LIVE application
  foundApplication = _.find(
    applications,
    (application) => ApplicationStatus.LIVE === application.status,
  );
  if (foundApplication) return foundApplication;

  // Couldn't find a LIVE application, trying to find a (dev) TEST application.
  foundApplication = _.find(
    applications,
    (application) => ApplicationStatus.TEST === application.status,
  );
  if (foundApplication) return foundApplication;

  // Couldn't find a LIVE or TEST application; last ditch to find a SANDBOX!
  foundApplication = _.find(
    applications,
    (application) => ApplicationStatus.SANDBOX === application.status,
  );
  return foundApplication;
};

export const sortApplications = (applications: Application[]) =>
  _.sortBy(applications, [
    (application) => application.name.toLowerCase(),
    'id',
  ]);
