// TODO: Move this to a more appropriate folder than `utils`.
// TODO: @aren55555 convert this into a global AppConfigProvider

type Environment = typeof process.env.NODE_ENV | 'staging';

export const isLocal = new URL(window.location.href).hostname === 'localhost';

export const config = {
  env: process.env.REACT_APP_ENV as Environment,

  publicAPIUrl: process.env.REACT_APP_PUBLIC_API_URI as string,
  dashboardAPIUrl: process.env.REACT_APP_API_URI as string,

  connectBaseUrl: process.env.REACT_APP_CONNECT_URI as string,
  connectUrl: `${process.env.REACT_APP_CONNECT_URI}/authorize`,

  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  auth0CustomDomain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN as string,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  auth0Scope: process.env.REACT_APP_AUTH0_SCOPE as string,
} as const;

export const baseURL: string = config.dashboardAPIUrl;
