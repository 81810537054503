import styles from './EditButton.module.css';

/**
 * This is a stripped down version of the Button component to improve its performance
 * in a big list. Chakra has a runtime trade-off documented here
 * @see {@link https://chakra-ui.com/getting-started/comparison#the-runtime-trade-off-%EF%B8%8F}
 */
export const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button type="button" className={styles.button} onClick={onClick}>
      <span className={styles.icon}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="edit_FILL0_wght400_GRAD0_opsz20 1">
            <path
              id="Vector"
              d="M4.5 15.5H5.5625L13.375 7.6875L12.3125 6.625L4.5 14.4375V15.5ZM3 17V13.8125L14.4375 2.375L17.625 5.5625L6.1875 17H3ZM12.8344 7.16556L12.3125 6.625L13.375 7.6875L12.8344 7.16556Z"
              fill="currentColor"
            ></path>
          </g>
        </svg>
      </span>
      Edit
    </button>
  );
};
