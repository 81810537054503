import { Box, Stack, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { COLORS } from '../../constant';
import { Link, Redirect, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import {
  useGetWebhookEndpoint,
  useListWebhookEvents,
  useSendTestEvent,
} from '../hooks';
import { WebhooksLoadingState } from '../components/WebhooksLoadingState';
import { useStatusToast } from '../../shared/StatusToast';
import { WebhookMessagesTable } from '../components/WebhookMessagesTable';
import { WebhookEndpointActionMenu } from '../components/WebhookEndpointActionMenu';
import { useState } from 'react';
import { EditWebhookDrawer } from '../components/EditWebhookDrawer';
import { RotateSecretConfirmationModal } from '../components/RotateSecretConfirmationModal';
import { DeleteEndpointConfirmationModal } from '../components/DeleteEndpointConfirmationModal';
import { WebhookSecretModal } from '../components/WebhookSecretModal';
import { WebhookDetail } from '../components/WebhookDetail';
import { PageContainer } from '../../components/PageContainer';

const GoBackButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.FINCH.PURPLE};
  margin-bottom: 40px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WebhookEndpointPage = () => {
  const { applicationId, endpointId } = useParams<{
    applicationId: string;
    endpointId: string;
  }>();
  const [secretModalData, setSecretModalData] = useState<{
    secret: string;
    endpointURL: string;
  } | null>(null);
  const { loading, data, error, triggerRefetch } = useGetWebhookEndpoint({
    applicationId,
    endpointId,
  });

  const { data: webhookEvents } = useListWebhookEvents();

  const {
    isOpen: isEditDrawerOpen,
    onOpen: onEditDrawerOpen,
    onClose: onEditDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isSecretModalOpen,
    onOpen: onSecretModalOpen,
    onClose: onSecretModalClose,
  } = useDisclosure();
  const {
    isOpen: isRotateSecretModalOpen,
    onOpen: onRotateSecretModalOpen,
    onClose: onRotateSecretModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteEndpointModalOpen,
    onOpen: onDeleteEndpointModalOpen,
    onClose: onDeleteEndpointModalClose,
  } = useDisclosure();

  const { sendEvent } = useSendTestEvent();
  const toast = useStatusToast('bottom');

  const onSendTestEvent = async () => {
    if (!data) {
      return;
    }

    if (!data.endpoint.enabled) {
      toast({
        message: 'Cannot send test event to disabled endpoint',
        wasSuccessful: false,
      });
      return;
    }

    const { error } = await sendEvent(applicationId, endpointId);
    if (error) {
      toast({
        message: 'Failed to send test event',
        wasSuccessful: false,
      });
      return;
    }
    toast({
      message: 'Successfully sent test event',
      wasSuccessful: true,
    });
    triggerRefetch();
  };

  const onWebhookUpdated = () => {
    // trigger refetch
    triggerRefetch();
  };

  const onWebhookEditRequested = () => {
    onEditDrawerOpen();
  };

  const onWebhookSecretRotateRequested = () => {
    //setSelectedEndpoint(endpoint);
    onRotateSecretModalOpen();
  };

  const onWebhookDeleteRequested = () => {
    //setSelectedEndpoint(endpoint);
    onDeleteEndpointModalOpen();
  };

  const onWebhookSecretRotated = (secret: string) => {
    setSecretModalData({
      secret,
      endpointURL: '',
    });
    //setSelectedEndpoint(null);
    onSecretModalOpen();
  };
  const onWebhookDeleted = () => {
    //setSelectedEndpoint(null);
    // trigger refetch
    triggerRefetch();
  };

  if (error) {
    return <Redirect to={`/app/applications/${applicationId}/webhooks`} />;
  }

  return (
    <PageContainer>
      {loading && <WebhooksLoadingState />}
      {!loading && !error && data && (
        <>
          <Box>
            <GoBackButton>
              <Link to={`/app/applications/${applicationId}/webhooks`}>
                <Stack direction="row">
                  <IoArrowBack />
                  <Text>Back to Webhooks</Text>
                </Stack>
              </Link>
            </GoBackButton>
          </Box>

          <Stack>
            <Stack
              justifyContent={{ base: 'space-between', md: 'row' }}
              flexDirection={{ base: 'column', md: 'row' }}
              marginBottom="24px"
            >
              <Stack>
                <Text fontSize="14px" fontWeight="500">
                  Webhook
                </Text>
                <Text
                  color={COLORS.FINCH.BLACK}
                  fontSize="24px"
                  fontWeight="600"
                >
                  {data.endpoint.url}
                </Text>
              </Stack>

              <WebhookEndpointActionMenu
                endpoint={data.endpoint}
                onSendTestEventRequested={onSendTestEvent}
                onWebhookEditRequested={onWebhookEditRequested}
                onWebhookSecretRotateRequested={onWebhookSecretRotateRequested}
                onWebhookDeleteRequested={onWebhookDeleteRequested}
              />
            </Stack>
            <WebhookDetail
              endpoint={data.endpoint}
              onWebhookEditRequested={onWebhookEditRequested}
            />
            <Spacer />
            <WebhookMessagesTable
              applicationId={applicationId}
              endpointId={endpointId}
            />
          </Stack>
          {secretModalData && (
            <WebhookSecretModal
              isOpen={isSecretModalOpen}
              onClose={onSecretModalClose}
              secret={secretModalData.secret}
              endpointURL={secretModalData.endpointURL}
            />
          )}
          <EditWebhookDrawer
            applicationId={applicationId}
            isOpen={isEditDrawerOpen}
            onClose={onEditDrawerClose}
            events={webhookEvents.events}
            onWebhookUpdated={onWebhookUpdated}
            endpoint={data.endpoint}
          />
          <RotateSecretConfirmationModal
            applicationId={applicationId}
            endpointId={data.endpoint.id}
            endpointURL={data.endpoint.url}
            isOpen={isRotateSecretModalOpen}
            onClose={onRotateSecretModalClose}
            onWebhookSecretRotated={onWebhookSecretRotated}
          />
          <DeleteEndpointConfirmationModal
            applicationId={applicationId}
            endpointId={data.endpoint.id}
            endpointURL={data.endpoint.url}
            isOpen={isDeleteEndpointModalOpen}
            onClose={onDeleteEndpointModalClose}
            onWebhookDeleted={onWebhookDeleted}
          />
        </>
      )}
    </PageContainer>
  );
};
