import { Center } from '@chakra-ui/react';
import { COLORS } from 'constant';

export const NumberCount = ({ children }: { children: number }) => {
  return (
    <Center
      bg={COLORS.GRAY.GRAY_300}
      color={COLORS.GRAY.GRAY_800}
      p="1"
      minW="20px"
      rounded="md"
      fontWeight="500"
      fontSize="xs"
      lineHeight="12px"
    >
      {children}
    </Center>
  );
};
