import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';

import { EmailInputForm } from './InviteForm';
import { useOrganization } from '../../../shared/hooks/use-organization';
import { Role } from '../../../constant/roles-and-permissions';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';

const inviteFormSchema = z.object({
  emails: z.array(z.string().email()),
  role: z.nativeEnum(Role),
});

export type InviteFormValues = z.infer<typeof inviteFormSchema>;

export const ModalInviteMember = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [inputValue, setInputValue] = useState('');
  const { members, inviteMember } = useOrganization();
  const toast = useToast();

  const inviteFormSchemaWithEmailCheck = inviteFormSchema.extend({
    emails: z
      .array(
        z
          .string()
          .email()
          .refine(
            // Check if the email already exists in the members list
            (email) =>
              !members.data?.find(
                ({ member }) => email === member.email.toLowerCase().trim(),
              ),
            { message: 'Email already exists' },
          ),
      )
      .min(1, 'An email is required')
      .max(5, 'You can only invite up to 5 members at a time'),
  });

  const { control, handleSubmit, formState } = useForm<InviteFormValues>({
    resolver: zodResolver(inviteFormSchemaWithEmailCheck),
  });

  const onSubmit = async ({ emails, role }: InviteFormValues) => {
    const emailString = emails.join(', ');
    inviteMember.mutate(
      { emails, role },
      {
        onSuccess: () => {
          toast({
            description: `We've successfully sent an invite to ${emailString}`,
            status: 'success',
          });
          onClose();
        },
        onError: () => {
          toast({
            description: `Failed to send an invite to ${emailString}`,
            status: 'error',
          });
          onClose();
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="550px">
        <ModalHeader fontSize="18px" fontWeight="600" textAlign="center">
          Invite Member
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EmailInputForm
            control={control}
            formState={formState}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </ModalBody>
        <ModalFooter pt="32px">
          <HStack spacing="4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primaryPurple"
              onClick={handleSubmit(onSubmit)}
              isLoading={inviteMember.isPending}
            >
              Send invite
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
