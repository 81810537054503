import { Status, TagVariant, variants } from './Tag';
import { Badge } from '../Badge/Badge';

export type WebhookEndpointStatusVariant = Status<
  'success' | 'failed' | 'pending' | 'sending' | 'unknown'
>;

/**
 * Tag used for displaying the status of an webhook event message
 *
 * @see https://www.figma.com/file/gldI3ap3tphioNftkEHThx/Employers?type=design&node-id=2235-1475&mode=design&t=Np9Bs86KCQ1W7jGc-4
 */
export const WebhookMessageStatusTag = ({
  variant = 'unknown',
}: {
  variant: TagVariant;
}) => {
  const { icon, theme, text } = variants[variant];

  return <Badge theme={theme} icon={icon} text={text} />;
};
