import { Stack, StackDivider, type StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { COLORS } from '../../constant';

export type StackWithDividerProps = StackProps & {
  children: ReactNode;
};

export const StackWithDivider = ({
  children,
  ...props
}: StackWithDividerProps) => {
  return (
    <Stack
      divider={<StackDivider borderColor={COLORS.GRAY.GRAY_400} />}
      spacing={0}
      {...props}
    >
      {children}
    </Stack>
  );
};
