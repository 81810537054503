import React from 'react';
import { posthog } from 'posthog-js';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { FlagProvider } from '@unleash/proxy-client-react';
import * as amplitude from '@amplitude/analytics-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import type { AppState, Auth0ProviderOptions } from '@auth0/auth0-react';
import { getConfig } from './Auth0Config';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import buildInfo from './build-info.json';

import { history } from './utils/history';
import { isLocal } from './utils/baseUrls';
import {
  DEVELOPMENT_AMPLITUDE_KEY,
  PRODUCTION_AMPLITUDE_KEY,
} from './constant/amplitude';
import { unleashConfig } from './utils/featureFlags';

const onRedirectCallback = (appState: AppState | undefined) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname,
  );
};

const config = getConfig();
const providerConfig: Auth0ProviderOptions = {
  domain: config.customDomain,
  clientId: config.clientId,
  audience: config.audience,
  redirectUri: window.location.origin + '/auth/callback',
  onRedirectCallback,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  /**
   * These scopes are necessary to manage MFA factors. They also needed to be enabled for the "http://test-developer-dashboard.tryfinch.com" API for them to take effect.
   * @see {@link https://auth0.com/docs/secure/multi-factor-authentication/manage-mfa-auth0-apis/manage-authenticator-factors-mfa-api#scopes}
   */
  scope: config.scope,
};

Sentry.init({
  dsn: isLocal
    ? undefined
    : 'https://ee81bbec460d4db7a0b6c9d1485a1869@o412759.ingest.sentry.io/5931224',
  environment: process.env.NODE_ENV,
  release: buildInfo?.gitSha,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dashboardapi\.tryfinch\.com/,
    /^https:\/\/api\.tryfinch\.com/,
    /^https:\/\/api\.prod\.tryfinch\.com/,
  ],

  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

if (!isLocal) {
  datadogRum.init({
    applicationId: 'bc6db808-d1ad-4e99-906d-8e6b58cf3822',
    clientToken: 'pub470d914cba0f49f29e4906b3ac1b568c',
    site: 'datadoghq.com',
    service: 'developer-dashboard-ui',
    env: process.env.NODE_ENV || 'development',
    version: buildInfo?.gitSha,
  });
}

amplitude.init(isLocal ? DEVELOPMENT_AMPLITUDE_KEY : PRODUCTION_AMPLITUDE_KEY, {
  defaultTracking: true,
});

if (
  process.env.REACT_APP_ENV === 'production' &&
  process.env.REACT_APP_POST_HOG_KEY
) {
  posthog.init(process.env.REACT_APP_POST_HOG_KEY, {
    api_host: `${window.location.origin}/ingest`,
  });
}

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Auth0Provider {...providerConfig}>
          <FlagProvider config={unleashConfig}>
            <App />
          </FlagProvider>
        </Auth0Provider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
);
