export const ListIcon = ({ color }: { color: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 15V13.5H17V15H7.5ZM7.5 10.75V9.25H17V10.75H7.5ZM7.5 6.5V5H17V6.5H7.5ZM4.49558 15.75C4.08186 15.75 3.72917 15.6027 3.4375 15.3081C3.14583 15.0135 3 14.6593 3 14.2456C3 13.8319 3.14731 13.4792 3.44192 13.1875C3.73654 12.8958 4.09071 12.75 4.50442 12.75C4.91814 12.75 5.27083 12.8973 5.5625 13.1919C5.85417 13.4865 6 13.8407 6 14.2544C6 14.6681 5.85269 15.0208 5.55808 15.3125C5.26346 15.6042 4.90929 15.75 4.49558 15.75ZM4.49558 11.5C4.08186 11.5 3.72917 11.3527 3.4375 11.0581C3.14583 10.7635 3 10.4093 3 9.99558C3 9.58186 3.14731 9.22917 3.44192 8.9375C3.73654 8.64583 4.09071 8.5 4.50442 8.5C4.91814 8.5 5.27083 8.64731 5.5625 8.94192C5.85417 9.23654 6 9.59071 6 10.0044C6 10.4181 5.85269 10.7708 5.55808 11.0625C5.26346 11.3542 4.90929 11.5 4.49558 11.5ZM4.49558 7.25C4.08186 7.25 3.72917 7.10269 3.4375 6.80808C3.14583 6.51346 3 6.15929 3 5.74558C3 5.33186 3.14731 4.97917 3.44192 4.6875C3.73654 4.39583 4.09071 4.25 4.50442 4.25C4.91814 4.25 5.27083 4.39731 5.5625 4.69192C5.85417 4.98654 6 5.34071 6 5.75442C6 6.16814 5.85269 6.52083 5.55808 6.8125C5.26346 7.10417 4.90929 7.25 4.49558 7.25Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
