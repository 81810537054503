import { Box, Image } from '@chakra-ui/react';
import { COLORS } from '../../constant';

type Props = {
  provider: string;
  src: string;
  size?: 'sm' | 'md' | 'lg';
};

const sizeToDimensions = {
  sm: '24px',
  md: '40px',
  lg: '48px',
};

export const ProviderIcon = ({ src, provider, size }: Props) => {
  const dimension = sizeToDimensions[size || 'sm'];

  return (
    <Box
      width={dimension}
      minWidth={dimension}
      height={dimension}
      backgroundColor={COLORS.GRAY.GRAY_100}
      borderRadius="6px"
    >
      <Image
        src={src}
        alt={`${provider} logo`}
        width={dimension}
        height={dimension}
        style={{ borderRadius: '6px' }}
      />
    </Box>
  );
};
