import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../utils/baseUrls';
import {
  Auth0Client,
  Authenticator,
  GetMfaTokenError,
  VerifyOtpError,
  VerifyOtpResponse,
} from './auth0-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { useState } from 'react';

export const useMfa = () => {
  const auth0 = useAuth0();
  const queryClient = useQueryClient();

  // Save the mfaToken in the query cache for 5 minutes
  const { data: mfaToken, isStale: isMfaTokenStale } = useQuery({
    queryKey: ['mfaToken'],
    initialData: '',
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const [postMfaTokenAction, setPostMfaTokenAction] = useState<
    (() => void) | null
  >(null);

  const {
    isOpen: isPasswordModalOpen,
    onOpen: onOpenPasswordModal,
    onClose,
  } = useDisclosure();

  const toast = useToast({
    duration: 3000,
    isClosable: true,
  });

  const client = new Auth0Client(auth0, {
    clientId: config.auth0ClientId,
    domain: config.auth0Domain,
    scope: config.auth0Scope,
    audience: config.auth0Audience,
  });

  const getAuthenticators = useQuery({
    queryKey: ['authenticators'],
    queryFn: client.getAuthenticators,
  });

  const getMfaToken = useMutation<string, GetMfaTokenError, string>({
    mutationFn: client.getMfaToken,
    onSuccess: (data) => queryClient.setQueryData(['mfaToken'], data),
  });

  const handlerWithMfaToken = (action: () => void) => () => {
    if (!mfaToken || isMfaTokenStale) {
      setPostMfaTokenAction(() => action);
      onOpenPasswordModal();
    } else {
      action();
    }
  };

  const onClosePasswordModal = () => {
    getMfaToken.reset();
    onClose();
  };

  const onSuccessfulMfaToken = () => {
    onClosePasswordModal();
    setPostMfaTokenAction(null);
    postMfaTokenAction?.();
  };

  const enrollOtpAuthenticator = useMutation({
    mutationFn: () => client.enrollOtpAuthenticator(),
    onError: () => {
      toast({
        description: 'Error enrolling authenticator',
        status: 'error',
      });
    },
  });

  const verifyOtp = useMutation<VerifyOtpResponse, VerifyOtpError, string>({
    mutationFn: (otp: string) =>
      client.verifyOtpAuthenticator({ otp, mfaToken }),
    onSuccess: async () => {
      const newAuthenticators = await getAuthenticators.refetch();
      enrollOtpAuthenticator.reset();
      await client.deleteUnverifiedAuthenticators(newAuthenticators.data || []);
      toast({
        description:
          'Multi-Factor Authentication for your account has been successfully enabled',
        status: 'success',
      });
    },
    onError: (error) => {
      if (error?.response?.data.error === 'expired_token') {
        queryClient.setQueryData(['mfaToken'], '');
      }

      toast({
        description: 'Error verifying OTP code',
        status: 'error',
      });
    },
  });

  const deleteOtpAuthenticator = useMutation({
    mutationFn: (authenticators: Array<Authenticator>) =>
      client.deleteOtpAuthenticator(authenticators),
    onSuccess: () => {
      getAuthenticators.refetch();
    },
    onError: () => {
      toast({
        description: 'Error deleting authenticator',
        status: 'error',
      });
    },
  });

  const otpAuthenticator = getAuthenticators.data?.find(
    (authenticator) =>
      authenticator.authenticator_type === 'otp' && authenticator.active,
  );

  return {
    authenticators: { ...getAuthenticators, otpAuthenticator },
    hasMfaToken: !!mfaToken,
    enrollOtpAuthenticator,
    deleteOtpAuthenticator,
    handlerWithMfaToken,
    isPasswordModalOpen,
    onClosePasswordModal,
    onSuccessfulMfaToken,
    getMfaToken,
    verifyOtp,
    user: auth0.user,
  };
};
