import { z } from 'zod';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

const optionalArrayOfStrings = z.preprocess((x: unknown) => {
  if (isNil(x)) {
    return x;
  }

  return isString(x) && x.length ? x.split(',').filter(Boolean) : [];
}, z.array(z.string()).optional());

export const filterSchema = z.object({
  field: optionalArrayOfStrings,
  endpoint: optionalArrayOfStrings,
  provider: optionalArrayOfStrings,
  authorization_type: optionalArrayOfStrings,
});

export const authorizationTypeSchema = z.enum([
  'assisted',
  'oauth',
  'api_token',
  'credential',
]);

export const groupSchema = z.enum(['organization', 'payroll', 'deductions']);

export const deductionSupportSchema = z.enum(['operations', 'features']);

export const subgroupSchema = z.enum([...deductionSupportSchema.options]);

export const organizationScopeSchema = z.enum([
  'company',
  'directory',
  'individual',
  'employment',
]);

export const payrollScopeSchema = z.enum([
  'payment',
  'pay_statement',
  'pay_group',
  'all_pay_groups',
]);

export const controlsSchema = z.object({
  group: groupSchema.default('organization'),
  subgroup: subgroupSchema.default('operations'),
  mode: z.enum(['light', 'dark']).default('light'),
  iframe: z.literal('docs').optional(),
});

export const globalFilterSchema = z.object({
  provider: z.array(z.string()).optional(),
  authorization_type: z.array(authorizationTypeSchema).optional(),
});

export const organizationFilterSchema = globalFilterSchema.extend({
  field: z.array(z.string()).optional(),
  endpoint: z.array(z.enum(organizationScopeSchema.options)).optional(),
});

export const payrollFilterSchema = globalFilterSchema.extend({
  field: z.array(z.string()).optional(),
  endpoint: z.array(z.enum(payrollScopeSchema.options)).optional(),
});

export const deductionOperationsFilterSchema = globalFilterSchema.extend({
  field: z.array(z.string()).optional(),
  endpoint: z.array(z.string().min(1)).optional(),
});

export const deductionFeaturesFilterSchema = globalFilterSchema.extend({
  field: z.array(z.string()).optional(),
  endpoint: z.array(z.string().min(1)).optional(),
});
