import React from 'react';

export const CopyIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13V0H16V13H3ZM4.5 11.5H14.5V1.5H4.5V11.5ZM0 16V3H1.5V14.5H13V16H0Z"
        fill={color || '#161616'}
      />
    </svg>
  );
};
