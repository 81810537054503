import { Box, HStack, Spacer, Text, TextProps } from '@chakra-ui/react';
import { OverflowTooltip } from 'shared/OverflowTooltip';
import { Caption } from 'shared/Typography';
import { format } from 'shared/utils/dateFormatter';
import { AssistedIcon } from 'shared/AssistedIcon';
import { AutomatedIcon } from 'shared/AutomatedIcon';
import {
  AccountStatus,
  ConnectionCategory,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { COLORS } from 'constant';
import type { CellContext } from '@tanstack/react-table';
import {
  ConnectionsTableColumn,
  ConnectStep,
  StagedConnection,
} from '../types';
import { ProviderIcon } from 'components/Iconagraphy/ProviderIcon';
import { ConnectionStatusTag } from 'components/Tag/ConnectionStatusTag';
import { ConnectStepReachedTag } from 'components/Tag/ConnectStepReachedTag';
import { truncateUuid } from 'utils/truncateUuid';

export const isDisconnected = (status: AccountStatus) =>
  AccountStatus.DISCONNECTED === status;

const CompanyId = ({
  children,
  disconnected,
  ...textProps
}: TextProps & {
  children: string;
  disconnected?: boolean;
}) => {
  return (
    <Text
      as="span"
      fontFamily="Roboto Mono, monospace"
      fontWeight={400}
      fontSize="14px"
      color={disconnected ? COLORS.GRAY.GRAY_500 : COLORS.GRAY.GRAY_600}
      {...textProps}
    >
      {truncateUuid(children)}
    </Text>
  );
};

export const IdCell = ({
  disconnected,
  id,
}: {
  disconnected?: boolean;
  id: string;
}) => {
  return (
    <Box isTruncated color={disconnected ? COLORS.GRAY.GRAY_500 : 'initial'}>
      <Caption fontFamily="Roboto Mono, monospace">{truncateUuid(id)}</Caption>
    </Box>
  );
};

export const CompanyCell = ({
  disconnected,
  name,
  id,
  idProps,
}: {
  disconnected?: boolean;
  name: string;
  id?: string;
  idProps?: TextProps;
}) => {
  return (
    <Box color={disconnected ? COLORS.GRAY.GRAY_500 : 'initial'}>
      <OverflowTooltip>
        <Caption>{name}</Caption>
      </OverflowTooltip>
      {id && (
        <>
          <Spacer mt="7px" />
          <HStack minH="20px">
            <CompanyId disconnected={disconnected} {...idProps}>
              {id}
            </CompanyId>
          </HStack>
        </>
      )}
    </Box>
  );
};

export const ConnectedStatusCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, AccountStatus>;
}) => {
  return <ConnectionStatusTag status={props.getValue()} />;
};

export const ConnectStepCell = ({
  props,
}: {
  props: CellContext<
    StagedConnection,
    { step: ConnectStep | null; hasError: boolean } | null
  >;
}) => {
  return (
    <ConnectStepReachedTag
      variant={props.getValue()?.step ?? 'NOT_STARTED'}
      isError={props.getValue()?.hasError}
    />
  );
};

const ConnectionType = ({
  kind,
  disconnected,
}: {
  kind: ConnectionCategory;
  disconnected: boolean;
}) => {
  const opacity = disconnected ? 0.33 : 1.0;

  switch (kind) {
    case ConnectionCategory.Assisted:
      return (
        <HStack opacity={opacity}>
          <AssistedIcon />
          <Caption>Assisted</Caption>
        </HStack>
      );
    case ConnectionCategory.Automated:
      return (
        <HStack opacity={opacity}>
          <AutomatedIcon />
          <Caption>Automated</Caption>
        </HStack>
      );
  }
};

export const ConnectionTypeCell = ({
  props,
}: {
  props: CellContext<ConnectionsTableColumn, ConnectionCategory[]>;
}) => {
  const disconnected = isDisconnected(props.row.original.status);

  return (
    <Box>
      <HStack spacing="16px">
        {props
          .getValue()
          .sort()
          .reverse()
          .map((value, index) => (
            <ConnectionType
              key={index}
              kind={value}
              disconnected={disconnected}
            />
          ))}
      </HStack>
    </Box>
  );
};

export const DateCell = ({
  disconnected,
  value,
}: {
  disconnected?: boolean;
  value?: number | string;
}) => {
  let content = '';

  if (!value) {
    content = '—';
  } else {
    content = format(new Date(value));
  }

  return (
    <Box isTruncated color={disconnected ? COLORS.GRAY.GRAY_500 : 'initial'}>
      <Caption>{content}</Caption>
    </Box>
  );
};

export const ProviderNameCell = ({
  name,
  icon,
  disconnected,
}: {
  name: string;
  icon: string;
  disconnected?: boolean;
}) => {
  return (
    <Box opacity={disconnected ? 0.5 : 1.0}>
      <HStack>
        <ProviderIcon src={icon} provider={name} />
        <OverflowTooltip>
          <Caption>{name}</Caption>
        </OverflowTooltip>
      </HStack>
    </Box>
  );
};
