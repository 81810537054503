import { useAuth0, User } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { CompletedVerificationResponse } from '@finch-api/developer-dashboard-common';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { useStatusToast } from '../../../shared/StatusToast';
import { useSearchParams } from '../../../shared/useQuery';
import { baseURL } from '../../../utils/baseUrls';

const _keyVerificationToken = 'verificationToken';

export const Verification = () => {
  const verifyingRef = useRef(false);

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  }: Auth0ContextInterface<User> = useAuth0();

  const toast = useStatusToast();
  const [isVerifying, setIsVerifying] = useState(false);
  const [redirectTo, setRedirectTo] = useState<string | undefined>();
  const queryParams = useSearchParams();

  useEffect(() => {
    if (verifyingRef.current) return;

    const verify = async () => {
      if (isLoading) return; // Auth0 was still loading...

      if (isAuthenticated) {
        toast({
          wasSuccessful: false,
          message:
            'Could not verify your account while already signed in. Please sign out and try again.',
        });
        setRedirectTo('/app');
        setIsVerifying(false);
        return;
      }

      const verificationToken = queryParams.get(_keyVerificationToken);
      if (!verificationToken) return; // TODO: redirect!
      setIsVerifying(true);

      try {
        const response = await axios.post<CompletedVerificationResponse>(
          `${baseURL}/api/v1/account/verify?synchronous=true`,
          {
            token: verificationToken,
          },
        );

        toast({
          wasSuccessful: true,
          message: 'Email was successfully verified.',
        });

        await loginWithRedirect({
          organization: response.data.organizationId,
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          wasSuccessful: false,
          message: 'An error occurred, we could not verify your email address.',
        });

        setRedirectTo('/signup');
      } finally {
        setIsVerifying(false);
        verifyingRef.current = true;
      }
    };
    verify();
  }, [isAuthenticated, isLoading, loginWithRedirect, queryParams, toast]);

  return (
    <>
      {redirectTo && <Redirect to={redirectTo} />}
      <Flex justifyContent="center" alignItems="center" h="100vh">
        {
          <>
            {isVerifying && (
              <VStack>
                <Spinner />
                <Text fontSize="14px" lineHeight="20px" color="gray.c600">
                  Verifying...
                </Text>
              </VStack>
            )}
          </>
        }
      </Flex>
    </>
  );
};
