export const TeamIcon = ({ color }: { color?: string }) => (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.5V8.3125C0 7.77083 0.270833 7.33333 0.8125 7C1.35417 6.66667 2.08333 6.5 3 6.5C3.19444 6.5 3.375 6.50694 3.54167 6.52083C3.70833 6.53472 3.86806 6.55556 4.02083 6.58333C3.85417 6.83333 3.72569 7.10495 3.63542 7.39819C3.54514 7.69141 3.5 7.98924 3.5 8.29167V9.5H0ZM4.5 9.5V8.29167C4.5 7.90278 4.60069 7.55556 4.80208 7.25C5.00347 6.94444 5.30556 6.67361 5.70833 6.4375C6.11111 6.20139 6.59028 6.02778 7.14583 5.91667C7.70139 5.80556 8.32288 5.75 9.01031 5.75C9.69788 5.75 10.316 5.80556 10.8646 5.91667C11.4132 6.02778 11.8889 6.20139 12.2917 6.4375C12.6944 6.65972 12.9965 6.92839 13.1979 7.2435C13.3993 7.55861 13.5 7.908 13.5 8.29167V9.5H4.5ZM14.5 9.5V8.29167C14.5 7.98611 14.4549 7.69097 14.3646 7.40625C14.2743 7.12153 14.1458 6.84722 13.9792 6.58333C14.1042 6.55556 14.2465 6.53472 14.4062 6.52083C14.566 6.50694 14.7639 6.5 15 6.5C15.9167 6.5 16.6458 6.66667 17.1875 7C17.7292 7.33333 18 7.77083 18 8.3125V9.5H14.5ZM3 5.5C2.58333 5.5 2.22917 5.35417 1.9375 5.0625C1.64583 4.77083 1.5 4.41667 1.5 4C1.5 3.58333 1.64583 3.22917 1.9375 2.9375C2.22917 2.64583 2.58333 2.5 3 2.5C3.41667 2.5 3.77083 2.64583 4.0625 2.9375C4.35417 3.22917 4.5 3.58333 4.5 4C4.5 4.41667 4.35417 4.77083 4.0625 5.0625C3.77083 5.35417 3.41667 5.5 3 5.5ZM15 5.5C14.5833 5.5 14.2292 5.35417 13.9375 5.0625C13.6458 4.77083 13.5 4.41667 13.5 4C13.5 3.58333 13.6458 3.22917 13.9375 2.9375C14.2292 2.64583 14.5833 2.5 15 2.5C15.4167 2.5 15.7708 2.64583 16.0625 2.9375C16.3542 3.22917 16.5 3.58333 16.5 4C16.5 4.41667 16.3542 4.77083 16.0625 5.0625C15.7708 5.35417 15.4167 5.5 15 5.5ZM9 4.75C8.375 4.75 7.84375 4.5311 7.40625 4.09331C6.96875 3.65553 6.75 3.12394 6.75 2.49854C6.75 1.87451 6.96889 1.34375 7.40667 0.90625C7.84446 0.46875 8.37606 0.25 9.00146 0.25C9.62549 0.25 10.1562 0.469097 10.5938 0.907291C11.0312 1.34549 11.25 1.87639 11.25 2.5C11.25 3.125 11.0309 3.65625 10.5927 4.09375C10.1545 4.53125 9.62361 4.75 9 4.75Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
