import { Banner } from '../components/Banner';
import { useApplication } from '../applications/hooks';
import { Box, Link } from '@chakra-ui/react';
import { finchMailToLinks } from '../shared/links';

export const ClientSuspendedBanner = () => {
  const { application } = useApplication();

  if (!application?.is_blocked) return null;

  return (
    <Banner type="error" closeable={false}>
      <Box>
        Your account has been suspended due to unpaid invoices. You will not be
        able to create new connections or access data for existing connections
        until the invoices are settled. Please reach out to{' '}
        <Link href={finchMailToLinks.billing} textDecoration="underline">
          billing@tryfinch.com
        </Link>{' '}
        to settle payment.
      </Box>
    </Banner>
  );
};
