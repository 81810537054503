import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProfileSettingsPage } from './ProfileSettingsPage';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { PageHeading } from '../../shared/Typography';
import {
  NavList,
  NavLinkOption,
  SideNavigationWithContent,
} from '../../shared/SideNavigationWithContent';
import { ProfileIcon } from '../../shared/icons/Profile';
import { SecurityIcon } from '../../shared/icons/Security';
import { TeamIcon } from '../../shared/icons/Team';
import { SecuritySettingsPage } from './SecuritySettingsPage';
import { PageContainer } from '../../components/PageContainer';
import { InviteMemberButton } from '../components/InviteMemberButton';
import { MembersTable } from '../components/MembersTable';
import { usePermissions } from '../../hooks/use-permissions';
import { PERMISSION } from '../../constant/roles-and-permissions';

export const SettingsRouter = () => {
  const navigationOptions: NavLinkOption[] = [
    {
      label: 'Team',
      href: '/app/settings/team',
      icon: TeamIcon,
    },
    {
      label: 'Profile',
      href: '/app/settings/profile',
      icon: ProfileIcon,
    },
    {
      label: 'Security',
      href: '/app/settings/security',
      icon: SecurityIcon,
    },
  ];

  const { path } = useRouteMatch();
  const roleCanInviteMember = usePermissions([PERMISSION.Members.Create]);

  return (
    <Box>
      <PageContainer>
        <Stack spacing={6} maxW={{ base: 'full', lg: '85%' }}>
          <HStack minH="40px" justify="space-between">
            <PageHeading>Settings</PageHeading>
            {roleCanInviteMember && (
              <Route path={`${path}/team`}>
                <InviteMemberButton />
              </Route>
            )}
          </HStack>

          <SideNavigationWithContent
            nav={<NavList options={navigationOptions} />}
          >
            <Switch>
              <Route path={`${path}/profile`}>
                <ProfileSettingsPage />
              </Route>
              <Route path={`${path}/team`}>
                <MembersTable />
              </Route>
              <Route path={`${path}/security`}>
                <SecuritySettingsPage />
              </Route>
              <Route path="*">
                <Redirect to={`${path}/team`} />
              </Route>
            </Switch>
          </SideNavigationWithContent>
        </Stack>
      </PageContainer>
    </Box>
  );
};
