import styled from '@emotion/styled';
import React, { ForwardedRef, DetailedHTMLProps, ReactNode } from 'react';
import { COLORS } from '../../constant';

const colorTheme = {
  warning: {
    textColor: COLORS.ORANGE,
    borderColor: COLORS.YELLOW_DARK,
    backgroundColor: COLORS.YELLOW,
  },
} as const;

interface StyledTextProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type: 'warning';
}

const Text = styled.div<StyledTextProps>`
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  cursor: inherit;
  color: ${(props) => colorTheme[props.type].textColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0em;
  text-align: left;
`;

interface StyledBannerProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type: 'warning';
  tooltip?: boolean;
  fullWidth?: boolean;
}

const StyledBadge = styled.div<StyledBannerProps>`
  height: 40px;
  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
  padding: 8px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${(props) => colorTheme[props.type].borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  background-color: ${(props) => colorTheme[props.type].backgroundColor};
  color: ${(props) => colorTheme[props.type].textColor}
    ${(props) => props.tooltip && 'cursor: pointer;'};
`;

const CustomizedIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 6px;
`;

export const Banner = React.forwardRef(
  (
    {
      type = 'warning',
      icon,
      content,
      tooltip,
      fullWidth = false,
    }: {
      type?: 'warning';
      icon: string;
      content: ReactNode;
      tooltip?: boolean;
      fullWidth?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <StyledBadge ref={ref} type={type} tooltip={tooltip} fullWidth={fullWidth}>
      <CustomizedIcon src={icon} />
      <Text type={type}>{content}</Text>
    </StyledBadge>
  ),
);

Banner.displayName = 'Banner';
