export const SearchIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="search_FILL0_wght300_GRAD0_opsz48 1">
      <path
        id="Vector"
        d="M39.5277 41.1228L26.5508 28.1459C25.5559 29.0263 24.3957 29.7048 23.0703 30.1812C21.7449 30.6577 20.373 30.8959 18.9546 30.8959C15.5589 30.8959 12.6849 29.7206 10.3328 27.3702C7.98074 25.0197 6.80469 22.1697 6.80469 18.8202C6.80469 15.4707 7.97992 12.6197 10.3304 10.2671C12.6809 7.91456 15.5345 6.73828 18.8913 6.73828C22.2482 6.73828 25.1025 7.91383 27.4541 10.2649C29.8057 12.616 30.9815 15.4669 30.9815 18.8176C30.9815 20.2186 30.7418 21.5825 30.2623 22.9094C29.7828 24.2363 29.0892 25.4382 28.1815 26.5151L41.1892 39.4421L39.5277 41.1228ZM18.9239 28.6267C21.6557 28.6267 23.9701 27.6748 25.867 25.7709C27.7639 23.8671 28.7123 21.5491 28.7123 18.8171C28.7123 16.085 27.7639 13.7671 25.867 11.8632C23.9701 9.9594 21.6557 9.00748 18.9239 9.00748C16.175 9.00748 13.8461 9.9594 11.9372 11.8632C10.0283 13.7671 9.07389 16.085 9.07389 18.8171C9.07389 21.5491 10.0283 23.8671 11.9372 25.7709C13.8461 27.6748 16.175 28.6267 18.9239 28.6267Z"
        fill="#3E4AE7"
      />
    </g>
  </svg>
);
