export const AtoZIcon = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12L3.00583 4H4.72917L7.72917 12H6.0625L5.38808 10.0833H2.33333L1.66667 12H0ZM2.8125 8.70833H4.89583L3.89583 5.72917H3.8125L2.8125 8.70833ZM9.14583 12V10.4792L13.2083 5.4375H9.33333V4H14.9583V5.52083L10.9375 10.5625H15V12H9.14583ZM5.5 2.5L8 0L10.5 2.5H5.5ZM8 16L5.5 13.5H10.5L8 16Z"
      fill="#161616"
    />
  </svg>
);
