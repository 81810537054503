import { useState } from 'react';
import styled from '@emotion/styled';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Box,
  useDisclosure,
  Stack,
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, Row, ColumnFiltersState } from '@tanstack/react-table';
import { COLORS } from '../../constant';
import { NoJobsScreen } from './NoJobsScreen';
import { JobDetailsDrawer } from './JobDetailsDrawer';
import { JobEntry, JobsTableFilterId } from '../types';
import {
  EmptyFilterStateMessage,
  filterFns,
  TableFilters,
  useFilters,
} from '../../components/TableFilters';
import {
  getConnectionDetailsFilterFromQueryParams,
  useConnectionDetailsQuerySync,
} from '../../connections/hooks/useConnectionDetailsQuerySync';
import { TableHeaderContent } from '../../components/TableHeaderContent';

interface JobsTableProps {
  data: JobEntry[];
  columns: ColumnDef<JobEntry, any>[];
  isLoading: boolean;
  textColor: string;
}

const Style = styled.div`
  overflow-x: auto;

  table {
    border-spacing: 0;

    th {
      padding: 16px;
      border: 0px;
    }

    tr {
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};

      :last-child {
        border-bottom: 0px;
      }
    }

    td {
      padding: 22px 16px;
      border: 0px;
    }
  }
`;

const TableCellText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableBodyContent = ({ rows }: { rows: Row<JobEntry>[] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<JobEntry>();

  return (
    <>
      {rows.map((row) => {
        return (
          <Box
            as={Tr}
            _hover={{ bg: COLORS.GRAY.GRAY_100 }}
            key={row.id}
            onClick={() => {
              setSelectedRow(row.original);
              onOpen();
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                <TableCellText>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Box>
        );
      })}
      {selectedRow && (
        <JobDetailsDrawer isOpen={isOpen} onClose={onClose} job={selectedRow} />
      )}
    </>
  );
};

export const JobsTable = ({ data, columns, textColor }: JobsTableProps) => {
  const [columnVisibility, setColumnVisibility] = useState<
    Partial<Record<keyof JobEntry, boolean>>
  >({
    connectionCategory: false,
  });

  /** This tracks the filter state */
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() =>
    getConnectionDetailsFilterFromQueryParams(),
  );

  /** This tracks the filters selected even if it's state has not been populated */
  const [selectedFilters, setSelectedFilters] = useState<JobsTableFilterId[]>(
    () =>
      getConnectionDetailsFilterFromQueryParams().map(
        ({ id }) => id,
      ) as JobsTableFilterId[],
  );

  const { getRowModel, getHeaderGroups, getColumn, getState } = useReactTable({
    data,
    columns,
    filterFns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
  });

  const {
    filterConfigs,
    getFilterValue,
    setFilterValue,
    addFilter,
    deleteFilter,
    toggleOpen,
    activeFilterId,
    removeAllFilters,
  } = useFilters<JobEntry, JobsTableFilterId>({
    filterList: ['connectionCategory', 'jobId', 'type', 'date', 'status'],
    getColumn,
    selectedFilters,
    setSelectedFilters,
  });

  const rows = getRowModel().rows;
  const headerGroups = getHeaderGroups();

  const shouldShowEmptyRowsState =
    0 === rows.length && 0 < getState().columnFilters.length;

  useConnectionDetailsQuerySync({ columnFilters });

  return (
    <Stack spacing={0} w="100%">
      <TableFilters
        setFilterValue={setFilterValue}
        getFilterValue={getFilterValue}
        filterConfigs={filterConfigs}
        addFilter={addFilter}
        deleteFilter={deleteFilter}
        selectedFilters={selectedFilters}
        toggleOpen={toggleOpen}
        activeFilterId={activeFilterId}
      />

      {data.length ? (
        <TableContainer height="fit-content" style={{ overflow: 'visible' }}>
          <Style>
            <Table
              variant="simple"
              borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
            >
              <TableHeaderContent headerGroups={headerGroups} />
              <Tbody
                textColor={textColor}
                borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
              >
                <TableBodyContent rows={rows} />
              </Tbody>
            </Table>
          </Style>
          {shouldShowEmptyRowsState && (
            <EmptyFilterStateMessage
              table="jobs"
              onClearFilters={removeAllFilters}
            />
          )}
        </TableContainer>
      ) : (
        <NoJobsScreen />
      )}
    </Stack>
  );
};
