import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../../utils/baseUrls';
import { SftpCredentialsUpdate, SftpCredentials } from './types';

export type Scope = { key: string; label: string; selected: boolean };

export const useSftpCredentials = (opts?: { disabled?: boolean }) => {
  const { disabled } = opts || {};
  const toast = useToast();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { getAccessTokenSilently } = useAuth0();

  const get = useQuery({
    queryKey: ['sftpCredentials', applicationId],
    queryFn: async () => {
      const uri = new URL(
        `/api/v1/applications/${applicationId}/sftp`,
        baseURL,
      );

      return axios
        .get<SftpCredentials[]>(uri.toString(), {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        })
        .then((res) => (res.data[0] ? { ...res.data[0], password: '' } : null));
    },
    enabled: !disabled,
  });

  const save = useMutation({
    mutationFn: async (opts: {
      credentialId?: string;
      payload: Partial<SftpCredentialsUpdate>;
    }) => {
      const { credentialId, payload } = opts;

      const uri = new URL(
        `/api/v1/applications/${applicationId}/sftp`,
        baseURL,
      );

      if (credentialId) {
        return axios
          .patch(
            uri.toString() + `/${credentialId}`,
            { ...payload, clientId: applicationId, credentialId },
            {
              headers: {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
              },
            },
          )
          .then((res) => res.data);
      } else {
        return axios
          .post(uri.toString(), payload, {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          })
          .then((res) => res.data);
      }
    },
    onSuccess: async () => {
      await get.refetch();

      toast({
        title: 'SFTP Credentials have been successfully updated',
        status: 'success',
        isClosable: true,
      });
    },
    onError: (error: AxiosError) => {
      toast({
        title: 'An error occurred when saving your SFTP credentials',
        description: error.response?.data.message,
        status: 'error',
        isClosable: true,
      });
    },
  });

  return { save, get };
};
