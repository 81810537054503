import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { COLORS } from '../../constant';

import { Tab } from '../types';
import { EnableAllIcon } from '../../shared/icons/EnableAllIcon';
import { DisableAllIcon } from '../../shared/icons/DisableAllIcon';
import { useProviderConfigurations } from '../hooks/useProviderConfigurations';

export const ProviderConfigActionMenu = ({ tabName }: { tabName: Tab }) => {
  const { updateEnabled, currentProviders } = useProviderConfigurations();

  const toggleProviders = (enabled: boolean) => {
    currentProviders.forEach((provider) => {
      updateEnabled(provider.id, enabled);
    });
  };

  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={Button}
        aria-label="Actions"
        rightIcon={<ChevronDownIcon />}
        variant="secondary"
        size="md"
      >
        Actions
      </MenuButton>
      <MenuList borderRadius={'8px'} padding="1px 1px 1px 1px">
        <MenuItem
          _hover={{
            backgroundColor: COLORS.LIGHT_GREEN,
            borderRadius: '4px',
          }}
          onClick={() => toggleProviders(true)}
        >
          <Flex gap={2} align="center">
            <EnableAllIcon />
            <Text fontSize={'14px'} color={COLORS.GREEN}>
              Enable All {tabName !== 'All' ? tabName : ''} Integrations
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem
          _hover={{
            backgroundColor: COLORS.GRAY.GRAY_200,
          }}
          onClick={() => toggleProviders(false)}
        >
          <Flex gap={2} align="center">
            <DisableAllIcon />
            <Text fontSize={'14px'} color={COLORS.GRAY.GRAY_600}>
              Disable All {tabName !== 'All' ? tabName : ''} Integrations
            </Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
