import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { ConnectSession } from 'types/connect';

import { baseURL } from '../../utils/baseUrls';

export type Scope = { key: string; label: string; selected: boolean };



export type CreateConnectSessionResponse = {
  sessionId: string;
  connectUrl: string;
};

export type CreateConnectSessionError = {
  message: string;
  extra?: { context?: { connectionId?: string } };
};

export const useStagedConnectSession = () => {
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();

  const create = useMutation<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    ConnectSession
  >({
    mutationFn: async (session: ConnectSession) => {
      const uri = new URL('/api/v1/connect-session', baseURL);

      return axios
        .post(uri.toString(), session, {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        })
        .then((res) => res.data);
    },
    onSuccess: () => {
      toast({
        title: 'Finch Connect URL created successfully',
        status: 'success',
        isClosable: true,
      });
    },
  });

  return { create };
};
