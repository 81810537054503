import { useEffect, useState } from 'react';
import type { ColumnFiltersState, OnChangeFn } from '@tanstack/react-table';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import unionBy from 'lodash/unionBy';
import isEmpty from 'lodash/isEmpty';

import { useSelectedGroup } from './useSelectedGroup';
import { useSelectedSubgroup } from './useSelectedSubgroup';
import { useGlobalFilters } from './useGlobalFilters';
import { useLocalFilters } from './useLocalFilters';
import { FieldSupportTableFilterId } from '../types';
import { useFieldSupportFiltersFromURI } from './useFieldSupportFiltersFromURI';

const isNotEmpty = ({ value }: ColumnFiltersState[number]) => !isEmpty(value);

/**
 * Use the current set of table filters that apply to the URL query parameters
 * (only once, during page load & mounting) & the selected product
 * (organization, payroll, deduction features/operations, etc.)
 */
export function useColumnFilters(): {
  columnFilters: ColumnFiltersState;
  setColumnFilters: OnChangeFn<ColumnFiltersState>;
  selectedFilters: FieldSupportTableFilterId[];
  setSelectedFilters: OnChangeFn<FieldSupportTableFilterId[]>;
} {
  const initialFilters = useFieldSupportFiltersFromURI();
  const { selectedGroup } = useSelectedGroup();
  const { selectedSubgroup } = useSelectedSubgroup();
  const { globalFilters, setGlobalFilters } = useGlobalFilters(initialFilters);
  const { localFilters, setLocalFilters } = useLocalFilters(
    initialFilters,
    selectedGroup,
    selectedSubgroup,
  );
  const [columnFilters, _setColumnFilters] = useState<ColumnFiltersState>([]);
  const [selectedFilters, setSelectedFilters] = useState<
    FieldSupportTableFilterId[]
  >(map(columnFilters, 'id') as FieldSupportTableFilterId[]);
  const setColumnFilters: OnChangeFn<ColumnFiltersState> = (updater) => {
    const updated = isFunction(updater)
      ? updater(unionBy(globalFilters, localFilters, 'id'))
      : updater;

    _setColumnFilters(updated);
    setLocalFilters(updated);
    setGlobalFilters(updated);
  };

  useEffect(() => {
    const view = unionBy(globalFilters, localFilters, 'id');

    setColumnFilters(view);
    setSelectedFilters(map(view, 'id') as FieldSupportTableFilterId[]);
  }, [selectedGroup, selectedSubgroup]);

  useEffect(() => {
    const view = unionBy(
      initialFilters,
      globalFilters,
      localFilters,
      'id',
    ).filter(isNotEmpty);

    setColumnFilters(view);
    setSelectedFilters(map(view, 'id') as FieldSupportTableFilterId[]);
  }, []);

  return {
    columnFilters,
    setColumnFilters,
    selectedFilters,
    setSelectedFilters,
  };
}
