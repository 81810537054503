export const LinkIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 16.25V14H12V12.5H14.25V10.25H15.75V12.5H18V14H15.75V16.25H14.25ZM9 14H6C4.89333 14 3.95 13.6095 3.17 12.8286C2.39 12.0477 2 11.1033 2 9.99529C2 8.88732 2.39 7.94444 3.17 7.16667C3.95 6.38889 4.89333 6 6 6H9V7.5H6C5.30556 7.5 4.71528 7.74306 4.22917 8.22917C3.74306 8.71528 3.5 9.30556 3.5 10C3.5 10.6944 3.74306 11.2847 4.22917 11.7708C4.71528 12.2569 5.30556 12.5 6 12.5H9V14ZM7 10.75V9.25H13V10.75H7ZM18 10H16.5C16.5 9.30556 16.2569 8.71528 15.7708 8.22917C15.2847 7.74306 14.6944 7.5 14 7.5H11V6H14C15.1067 6 16.05 6.39 16.83 7.17C17.61 7.95 18 8.89333 18 10Z"
      fill="currentColor"
    />
  </svg>
);
