import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Flex,
  Badge,
  Divider,
  Box,
  Code,
} from '@chakra-ui/react';
import { Log } from '@finch-api/developer-dashboard-common';
import { useRef } from 'react';
import { FiCodesandbox as CodeSandBox } from 'react-icons/fi';
import { Spacer } from '../shared/Spacer';
import { SectionHeading, Caption, BoldCaption } from '../shared/Typography';
import { format } from '../shared/utils/dateFormatter';
import { useActivityStore } from './state/activityStore';
import { getColorScheme, getStatusLabelByValue } from './tableProperties';

const queryParametersToSentenceCase = {
  limit: 'Limit',
  offset: 'Offset',
  start_date: 'Start date',
  end_date: 'End date',
} as { [key: string]: string };

const DrawerHeading = ({ log }: { log: Log }) => {
  return (
    <>
      <Flex alignItems="center">
        <Caption>{format(new Date(log.timestamp))}</Caption>
        <Spacer p="2" />
        <Badge
          colorScheme={getColorScheme(log.status.code)}
          px={3}
          py={1}
          borderRadius={8}
          fontSize={12}
          fontWeight="normal"
          textTransform="unset"
        >
          <Caption>{`${log.status.code} ${getStatusLabelByValue(
            log.status,
          )}`}</Caption>
        </Badge>
      </Flex>
      <Spacer p="2" />
      <Caption>{log.company.name}</Caption>
      <Spacer p="2" />
      <Flex alignItems="center">
        <CodeSandBox />
        <Spacer p="1" />
        <Caption>{log.payrollProvider.name}</Caption>
      </Flex>
    </>
  );
};

const QueryParameters = ({ log }: { log: Log }) => {
  const searchParams = new URL(log.fullUrl).searchParams;
  const paramsToValue = Object.fromEntries(searchParams);
  const availableQueryParameters = Object.keys(queryParametersToSentenceCase);

  return 0 < Object.keys(paramsToValue).length ? (
    <>
      {availableQueryParameters.map((parameter) => {
        const value = paramsToValue[parameter];
        return value === undefined ? null : (
          <Box key={parameter}>
            <Spacer p="1" />
            <Caption>{`${[
              queryParametersToSentenceCase[parameter],
            ]}: ${value}`}</Caption>
          </Box>
        );
      })}
    </>
  ) : (
    <>
      <Spacer p="1" />
      <Caption>None</Caption>
    </>
  );
};

const RequestBody = ({ log }: { log: Log }) => {
  return Object.keys(log.body).length > 0 ? (
    <Code
      p="4"
      display="block"
      whiteSpace="pre-wrap"
      borderRadius="6"
      background="#F2F4F8"
    >
      {JSON.stringify(log.body, null, 2)}
    </Code>
  ) : (
    <Caption>None</Caption>
  );
};

const DrawerBodyDetails = ({ log }: { log: Log }) => {
  const url = new URL(log.fullUrl);
  const endpoint = url.protocol + '//' + url.host + url.pathname;

  return (
    <Box>
      <BoldCaption>Company ID</BoldCaption>
      <Spacer p="1" />
      <Caption>{log.company.id}</Caption>
      <Spacer p="2" />
      <BoldCaption>Request ID</BoldCaption>
      <Spacer p="1" />
      <Caption>{log.id}</Caption>
      <Spacer p="2" />
      <BoldCaption>Endpoint</BoldCaption>
      <Spacer p="1" />
      <Caption>{endpoint}</Caption>
      <Spacer p="2" />
      <BoldCaption>Method</BoldCaption>
      <Spacer p="1" />
      <Caption>{log.method}</Caption>
      <Spacer p="2" />
      <BoldCaption>Query Parameters</BoldCaption>
      <QueryParameters log={log} />
      <Spacer p="2" />
      <BoldCaption>Request Body</BoldCaption>
      <Spacer p="1" />
      <RequestBody log={log} />
      <Spacer p="2" />
      <BoldCaption>IP Address</BoldCaption>
      <Spacer p="1" />
      <Caption>{log.ipAddress}</Caption>
    </Box>
  );
};

export const RequestDetailsDrawer = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const { selectedLog, setSelectedLog } = useActivityStore();

  return (
    <Drawer
      isOpen={!!selectedLog}
      placement="right"
      onClose={() => setSelectedLog(null)}
      size="lg"
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <SectionHeading>Request Details</SectionHeading>
        </DrawerHeader>
        <DrawerBody>
          {!!selectedLog && (
            <>
              <DrawerHeading log={selectedLog} />
              <Spacer p="2" />
              <Divider />
              <Spacer p="2" />
              <DrawerBodyDetails log={selectedLog} />
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
