import { ReactNode } from 'react';
import {
  Box,
  Button,
  Code,
  Container,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Tag,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from '@chakra-ui/react';

import { COLORS } from '../../constant';
import { AuthenticationMethod, FieldSupportCell } from '../types';
import { authorizationType } from '../constants';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';
import FinchIcon from '../../assets/images/logos/finch/icon.svg';
import FinchIconWhite from '../../assets/images/logos/finch/icon-white.svg';
import FinchLogo from '../../assets/images/logos/finch/logo.svg';
import FinchLogoWhite from '../../assets/images/logos/finch/logo-white.svg';
import { CheckCircle } from '../../shared/icons/CheckCircle';
import { ExternalLink } from '../../shared/ExternalLink';

export const FinchHeaderCell = ({ fromDocs }: { fromDocs: boolean }) => {
  const logos: [string, string] = fromDocs
    ? [FinchIcon, FinchIconWhite]
    : [FinchLogo, FinchLogoWhite];
  const width = fromDocs ? '20px' : '65px';
  return (
    <Container paddingLeft="32px">
      <Image src={useColorModeValue(...logos)} width={width} height="20px" />
    </Container>
  );
};

export const ProviderHeaderCell = ({
  name,
  src,
}: {
  name: string;
  src: string;
}) => (
  <Flex align="center" justifyContent="center" gap={1}>
    <ProviderIcon src={src} provider={name} />
    <Text
      isTruncated
      title={name}
      letterSpacing="normal"
      maxWidth="124px"
      fontWeight={500}
      fontSize="md"
      color={useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f')}
      style={{ textTransform: 'none' }}
    >
      {name}
    </Text>
  </Flex>
);

export const RowLabelCell = ({
  kind,
  children,
}: {
  kind: FieldSupportCell['kind'];
  children: ReactNode;
}) => {
  const textColor = useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f');
  const codeColor = useColorModeValue(COLORS.FINCH.BLACK, '#eeeeee');
  const codeBorderColor = useColorModeValue(COLORS.GRAY.GRAY_400, '#262626');
  const codeBackground = useColorModeValue(COLORS.GRAY.GRAY_200, '#191919');

  if (
    ['coverage', 'field_availability', 'authorization_type', 'status'].includes(
      kind,
    )
  ) {
    return (
      <Text fontWeight={500} fontSize="14px" color={textColor}>
        {children}
      </Text>
    );
  }

  if (kind === 'support') {
    return (
      <div>
        <Code
          borderWidth="1px"
          borderColor={codeBorderColor}
          background={codeBackground}
          borderRadius="4px"
          fontSize={'13px'}
          color={codeColor}
        >
          {children}
        </Code>
      </div>
    );
  }

  return <div>{children}</div>;
};

export const AuthorizationTypeCell = ({
  implementation,
}: {
  implementation: AuthenticationMethod;
}) => (
  <Flex justifyContent="center">
    <Tag
      padding="2px 6px"
      backgroundColor={useColorModeValue(COLORS.GRAY.GRAY_200, '#212121')}
      color={useColorModeValue(COLORS.GRAY.GRAY_600, '#dfdfdf')}
      fontSize="12px"
    >
      {`${authorizationType[implementation]}`}
    </Tag>
  </Flex>
);

export const StatusCell = ({ beta }: { beta: boolean }) => (
  <Flex justifyContent="center">
    <Tag
      padding="2px 6px"
      backgroundColor={useColorModeValue(
        beta ? '#EFF1FF' : COLORS.LIGHT_GREEN,
        beta ? '#233349' : '#1e412a',
      )}
      color={useColorModeValue(
        beta ? COLORS.FINCH.PURPLE : COLORS.GREEN,
        beta ? COLORS.FINCH.INDIGO_DARK : '#22c55e',
      )}
      fontSize="12px"
    >
      {beta ? 'Beta' : 'Available'}
    </Tag>
  </Flex>
);

export const FieldAvailabilityCell = ({
  supported,
}: {
  supported: [number, number];
}) => (
  <Text
    fontSize="14px"
    color={useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f')}
    fontWeight={400}
    align="center"
  >
    {supported.join('/')}
  </Text>
);

export const CoverageCell = ({ percentage }: { percentage: number }) => (
  <Text
    fontSize="14px"
    color={useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f')}
    fontWeight={400}
    align="center"
  >
    {`${Math.ceil(100 * percentage)}%`}
  </Text>
);

export const SupportCell = () => (
  <Flex justifyContent="center">
    <CheckCircle fill={useColorModeValue(undefined, '#2ab673')} />
    <VisuallyHidden>✅</VisuallyHidden>
  </Flex>
);

const TooltipSupportCell = ({
  children,
  learnMore,
}: {
  children?: ReactNode;
  learnMore?: string;
}) => {
  const fontColor = useColorModeValue(COLORS.FINCH.BLACK, COLORS.WHITE);

  return (
    <Flex justifyContent="center" gap="6px" alignItems="center">
      <Spacer maxWidth="16px" />
      <CheckCircle fill={useColorModeValue(undefined, '#2ab673')} />
      <Popover trigger="hover" placement="top-start">
        <PopoverTrigger>
          <Button
            fontSize="13px"
            fontWeight="semibold"
            variant="link"
            minWidth={0}
          >
            (?)
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent fontSize="14px">
            <PopoverHeader
              color={fontColor}
              borderBottom={0}
              paddingTop={3}
              paddingInline={4}
              fontWeight="semibold"
            >
              Note
            </PopoverHeader>
            <PopoverBody paddingTop={0} paddingInline={4} paddingBottom={4}>
              <Stack gap={3}>
                <Box color={fontColor}>{children}</Box>
                {learnMore && (
                  <Box>
                    <ExternalLink to={learnMore}>Learn More</ExternalLink>
                  </Box>
                )}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
};

export const NoSupportCell = () => (
  <Text
    fontSize="14px"
    color={useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f')}
    fontWeight={400}
    align="center"
  >
    —
  </Text>
);

export const NotAvailableCell = () => (
  <Text
    fontSize="14px"
    color={useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f')}
    fontWeight={400}
    align="center"
  >
    N/A
  </Text>
);

export const SubheadingCell = ({ endpoint }: { endpoint: string }) => (
  <Text
    fontSize="14px"
    fontWeight={500}
    color={useColorModeValue(COLORS.FINCH.BLACK, '#9f9f9f')}
  >
    {endpoint}
  </Text>
);

// NOTE: all cells below here are provider-specific support notes

export const GustoIndividualEncryptedSSNFootnote = () => (
  <TooltipSupportCell>
    <Text>
      Gusto’s API returns SSNs that are encrypted and require special decryption
      instructions.
    </Text>
  </TooltipSupportCell>
);

export const WorkdayDepartmentNameFootnote = () => (
  <TooltipSupportCell>
    <Text>
      The department name is populated with the names of the Workday supervisory
      organizations for the company.
    </Text>
  </TooltipSupportCell>
);

export const WorkdayDepartmentSourceIDFootnote = () => (
  <TooltipSupportCell>
    <Text>
      The source ID is populated with the IDs of the Workday supervisory
      organizations for the company and is available upon request.
    </Text>
  </TooltipSupportCell>
);

export const TrinetCreateBenefitsFootnote = () => (
  <TooltipSupportCell learnMore="https://developer.tryfinch.com/integrations/provider-information/TriNet#deductions">
    <Text>Employers must contact TriNet directly to create savings plans.</Text>
  </TooltipSupportCell>
);
