import { Box } from '@chakra-ui/react';
import { ScheduledMaintenanceBanner } from '../banners/ScheduledMaintenanceBanner';

import { NavigationBar } from './NavigationBar';
import { OrganizationApplications } from './OrganizationApplicationsContext';
import { ClientSuspendedBanner } from '../banners/ClientSuspendedBanner';
import { Route, Switch } from 'react-router-dom';

export const PageLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <OrganizationApplications>
        <Box>
          <ScheduledMaintenanceBanner />
          <Switch>
            <Route path="/app/applications/:applicationId">
              <ClientSuspendedBanner />
            </Route>
          </Switch>
          <NavigationBar />
          {children}
        </Box>
      </OrganizationApplications>
    </>
  );
};
