import axios from 'axios';
import * as Sentry from '@sentry/react';

export class AuthApi {
  constructor(
    private readonly baseURL: string,
    private readonly token: string,
  ) {}

  async resendVerificationEmail(userId: string): Promise<boolean> {
    const url = new URL('/api/v1/account/verification', this.baseURL);

    try {
      const { data } = await axios.post(url.toString(), { userId });

      return data.sent;
    } catch (error) {
      Sentry.captureException(error);

      throw error;
    }
  }

  async tokenExchange(
    clientId: string,
    code: string,
    redirectUri?: string,
  ): Promise<string> {
    const url = new URL('/api/v1/auth/token', this.baseURL);

    try {
      const { data } = await axios.post(
        url.toString(),
        {
          clientId,
          code,
          redirectUri,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      );

      return data.token;
    } catch (caught) {
      Sentry.captureException(caught);

      throw caught;
    }
  }
}
