export const ProfileIcon = ({ color }: { color?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.9375 12.0625C3.6875 11.5347 4.48958 11.1424 5.34375 10.8854C6.19792 10.6285 7.08333 10.5 8 10.5C8.91667 10.5 9.80208 10.6285 10.6562 10.8854C11.5104 11.1424 12.3125 11.5347 13.0625 12.0625C13.5486 11.4931 13.9097 10.8611 14.1458 10.1667C14.3819 9.47222 14.5 8.75 14.5 8C14.5 6.19896 13.8665 4.66536 12.5994 3.39921C11.3323 2.13307 9.79758 1.5 7.99521 1.5C6.19285 1.5 4.65972 2.13307 3.39583 3.39921C2.13194 4.66536 1.5 6.19896 1.5 8C1.5 8.75 1.61806 9.47222 1.85417 10.1667C2.09028 10.8611 2.45139 11.4931 2.9375 12.0625ZM8 9.5C7.16667 9.5 6.45833 9.20833 5.875 8.625C5.29167 8.04167 5 7.33333 5 6.5C5 5.66667 5.29167 4.95833 5.875 4.375C6.45833 3.79167 7.16667 3.5 8 3.5C8.83333 3.5 9.54167 3.79167 10.125 4.375C10.7083 4.95833 11 5.66667 11 6.5C11 7.33333 10.7083 8.04167 10.125 8.625C9.54167 9.20833 8.83333 9.5 8 9.5ZM8.00575 16C6.90469 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0767 0.625 11.105C0.208333 10.1334 0 9.09519 0 7.99046C0 6.88571 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92332 1.04167 4.89496 0.625C5.86661 0.208333 6.90481 0 8.00954 0C9.11429 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92169 15.375 4.89008C15.7917 5.85849 16 6.89321 16 7.99425C16 9.09531 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0783 14.9583 11.1099 15.375C10.1415 15.7917 9.10679 16 8.00575 16Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
