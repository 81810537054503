import { Box, Stack, Text, StackProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const EmptyState = ({
  height,
  text,
  subText,
  icon,
  ...props
}: StackProps & {
  text: string;
  subText: string;
  height?: string;
  icon: ReactNode;
}) => {
  return (
    <Stack
      height={height}
      spacing="24px"
      align="center"
      textAlign="center"
      {...props}
    >
      <Box borderRadius="50%" bg="primary.50" p="2">
        {icon}
      </Box>
      <Stack spacing="4px">
        <Text fontWeight="semibold">{text}</Text>
        <Text fontSize="sm">{subText}</Text>
      </Stack>
    </Stack>
  );
};
