import { config } from './utils/baseUrls';

export function getConfig() {
  return {
    auth0Domain: config.auth0Domain,
    customDomain: config.auth0CustomDomain,
    clientId: config.auth0ClientId,
    audience: config.auth0Audience,
    scope: config.auth0Scope,
  };
}
