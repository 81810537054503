import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import isString from 'lodash/isString';
import { COLORS, SHADOWS, Z_INDEX } from '../../constant';
import { CloseIcon } from '../../shared/icons/CloseIcon';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { FilterComposerOptions, FilterInputOption } from './types';

export function isArrayId(id: string | string[]): id is string[] {
  return Array.isArray(id);
}

export function isStringId(id: string | string[]): id is string {
  return typeof id === 'string';
}

const equalArrayTo =
  (list: string[]) =>
  ({ id }: FilterInputOption) =>
    isArrayId(id) &&
    id.every((value) => list.includes(value)) &&
    id?.length === list.length;

const getLabelWhenClosed = <FilterValue,>(
  inputLabel?: string,
  filterValue?: FilterValue,
  options?: FilterInputOption[],
) => {
  const inputHasOptions = options?.length;
  const isListFilterValue = Array.isArray(filterValue);
  const isObjectFilterValue = typeof filterValue === 'object';
  const isStringFilterValue = typeof filterValue === 'string';

  // Return the input label if there is no filter value
  if (!filterValue) {
    return inputLabel || '';
  }

  // input has no options, so return the filter value as is
  if (!inputHasOptions) {
    return filterValue;
  }

  const optionsHaveArrayId = options.every(({ id }) => isArrayId(id));
  const optionsHaveId = options.every(({ id }) => !!id);

  // The filter value is meant to be compared to the array ID
  if (isListFilterValue && optionsHaveArrayId) {
    return (
      options
        .filter(({ id }) => Array.isArray(id))
        .find(equalArrayTo(filterValue))?.label || ''
    );
  }

  // The filter value is a list of multiple IDs
  if (isListFilterValue && optionsHaveId) {
    return filterValue
      .map((value) => options.find(({ id }) => value === id)?.label)
      .join(', ');
  }

  if (isObjectFilterValue && 'id' in filterValue) {
    return options.find(({ id }) => {
      if (isStringId(id)) {
        return filterValue.id === id
      }

      return isString(filterValue.id) && id.includes(filterValue.id)
    })?.label;
  }

  if (isStringFilterValue && optionsHaveId) {
    return options.find(({ id }) => filterValue === id)?.label;
  }

  // Otherwise return the filter value as is
  return filterValue;
};

export const FilterComposer = <FilterValue, FilterId>({
  filterConfig,
  isOpen,
  toggleOpen,
  label,
  filterValue,
  setFilterValue,
  deleteFilter,
  children,
  options,
  hover,
  color,
  borderColor,
  menu,
  shadows,
  scrollbar,
}: FilterComposerOptions<FilterValue, FilterId>) => {
  const labelWhenOpen = label || '';
  const labelWhenClosed = getLabelWhenClosed(
    label,
    filterValue,
    options,
  ) as string;

  return (
    <Menu isOpen={isOpen} onClose={() => toggleOpen(filterConfig.id)}>
      <MenuButton
        as={Button}
        borderColor={borderColor}
        _hover={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
        _active={hover ?? { bg: COLORS.GRAY.GRAY_200 }}
        size="sm"
        px="4px"
        pl="8px"
        pr="0"
        maxW="200px"
        onClick={() => toggleOpen(filterConfig.id)}
        fontWeight="normal"
        variant="outline"
        sx={{
          '.chakra-button__icon': {
            margin: 0,
          },
        }}
        rightIcon={
          isOpen ? (
            <Box p="4px 8px">
              <ChevronDownIcon />
            </Box>
          ) : (
            <Box
              aria-label="Close button"
              p="4px 8px"
              onClick={() => deleteFilter(filterConfig.id)}
            >
              <CloseIcon />
            </Box>
          )
        }
      >
        <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {isOpen ? labelWhenOpen : labelWhenClosed}
        </Text>
      </MenuButton>
      <MenuList
        p="4px"
        color={color}
        boxShadow={shadows ?? SHADOWS.PAGE}
        zIndex={Z_INDEX.MENU}
        backgroundColor={menu?.backgroundColor}
        style={{ scrollbarColor: scrollbar }}
      >
        <Stack
          align="start"
          p="12px"
          spacing="16px"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggleOpen(filterConfig.id);
            }
          }}
        >
          {children({
            placeholder: `Search by ${label}`,
            value: filterValue,
            options,
            setValue: (value?: FilterValue) => {
              setFilterValue(filterConfig.id, value);
            },
          })}
          <Button
            size="sm"
            variant="link"
            fontWeight="normal"
            onClick={() => deleteFilter(filterConfig.id)}
          >
            Clear
          </Button>
        </Stack>
      </MenuList>
    </Menu>
  );
};
