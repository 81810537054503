import {
  Alert,
  AlertDescription,
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';

import { COLORS } from '../../constant';
import check_circle from '../../assets/images/icons/check_circle.svg';
import error_warning from '../../assets/images/icons/error_warning.svg';
import dotted_circle from '../../assets/images/icons/dotted_circle.svg';
import {
  ACCOUNTANT_INVITATION_ACCEPTED,
  assistedStatusProgress,
  NO_PROGRESS,
  PERMISSIONS_VERIFIED,
  TOKEN_CREATED,
  type Progress,
} from '../constants';
import type { AssistedSetupStatusSteps } from '../types';

function progress(from?: AccountStatus): Progress {
  return from ? assistedStatusProgress[from] : NO_PROGRESS;
}

function isError(status?: AccountStatus): boolean {
  return status
    ? [
        AccountStatus.ERROR_NO_ACCOUNT_SETUP,
        AccountStatus.ERROR_PERMISSIONS,
      ].includes(status)
    : false;
}

export function AssistedSetupStatus({
  status,
  message,
  lastUpdatedAt,
}: {
  status?: AccountStatus;
  message?: string;
  lastUpdatedAt?: Date;
}) {
  const steps: AssistedSetupStatusSteps = [
    {
      title: 'Permission Verified',
      description:
        'Employer has granted Finch access with sufficient permissions',
      completed: progress(status) >= PERMISSIONS_VERIFIED,
    },
    {
      title: 'Account Invitation Accepted',
      description:
        'Employer has invited Finch to access the payroll provider system',
      completed: progress(status) >= ACCOUNTANT_INVITATION_ACCEPTED,
    },
    {
      title: 'Token Created',
      description: 'Employer has completed the Connect flow',
      completed: progress(status) >= TOKEN_CREATED,
    },
  ];

  return (
    <>
      {isError(status) && (
        <Box marginBottom="24px" marginTop="12px">
          <Alert
            status="warning"
            borderRadius="lg"
            border="1px solid"
            borderColor={COLORS.YELLOW_DARK}
            backgroundColor={COLORS.YELLOW}
            color={COLORS.ORANGE}
            fontSize="14px"
            padding="14px 12px"
          >
            <Flex alignItems="start" gap={2}>
              <Image
                src={error_warning}
                width="16px"
                height="16px"
                margin="4px"
              />
              <AlertDescription>{message}</AlertDescription>
            </Flex>
          </Alert>
        </Box>
      )}
      <Flex
        backgroundColor={COLORS.WHITE}
        borderRadius="8px"
        boxShadow="0px 0px 1px rgba(22, 22, 22, 0.12), 0px 4px 8px rgba(22, 22, 22, 0.06)"
        width="100%"
        direction="column"
      >
        <HStack
          width="100%"
          color={COLORS.GRAY.GRAY_600}
          fontSize="xs"
          padding="16px"
        >
          <Text
            fontStyle="normal"
            fontWeight="500"
            fontSize="14px"
            color={COLORS.FINCH.BLACK}
          >
            Assisted Setup
          </Text>
          <Spacer />
          <Text>
            <Text as="strong" fontWeight="semibold">
              Last updated:
            </Text>{' '}
            {lastUpdatedAt
              ? `${lastUpdatedAt.toDateString()} at ${lastUpdatedAt.toLocaleTimeString()}`
              : '—'}
          </Text>
        </HStack>
        <Stack>
          {steps.map(({ title, description, completed }, index) => (
            <Box key={index}>
              <Divider />
              <Box padding="24px 12px">
                <HStack gap={2} alignItems="start">
                  <Image
                    src={completed ? check_circle : dotted_circle}
                    width="16px"
                    height="16px"
                    margin="4px"
                  />
                  <Stack gap={1}>
                    <Text
                      fontSize="16px"
                      color={
                        completed ? COLORS.FINCH.BLACK : COLORS.GRAY.GRAY_600
                      }
                      fontWeight="semibold"
                    >
                      {title}
                    </Text>
                    <Text
                      fontSize="16px"
                      color={
                        completed ? COLORS.FINCH.BLACK : COLORS.GRAY.GRAY_600
                      }
                    >
                      {description}
                    </Text>
                  </Stack>
                </HStack>
              </Box>
            </Box>
          ))}
        </Stack>
      </Flex>
    </>
  );
}
