import { COLORS } from '../../constant';
import { Box, HStack, Text } from '@chakra-ui/react';
import { ConnectionAccountDetail } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';

import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
  ConnectionCardRow,
} from 'components/ConnectionCard';
import { kindToTitle } from '../utils';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';

// TODO: add the provider logo to connectionDetail
export const ProviderInformationCard = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail;
}) => {
  const opacity =
    connectionDetail.connectionStatus === AccountStatus.DISCONNECTED ? 0.5 : 1;

  const implementationKinds = Array.from(
    new Set(
      connectionDetail.products.map(
        (product) => kindToTitle[product.implementationKind],
      ),
    ),
  );

  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          color={COLORS.FINCH.BLACK}
        >
          Provider Information
        </Text>
      </ConnectionCardHeader>
      <ConnectionCardBody spacing="16px">
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Provider Name
            </Text>
          </Box>
          <Box opacity={opacity}>
            <HStack>
              <ProviderIcon
                src={connectionDetail.providerIcon}
                provider={connectionDetail.providerName}
              />
              <Text fontWeight={400} fontSize="14px" color={COLORS.FINCH.BLACK}>
                {connectionDetail.providerName}
              </Text>
            </HStack>
          </Box>
        </ConnectionCardRow>
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Integration Type
            </Text>
          </Box>
          <Box opacity={opacity}>
            <Text fontWeight={400} fontSize="14px" color={COLORS.FINCH.BLACK}>
              {implementationKinds.join(' and ')}
            </Text>
          </Box>
        </ConnectionCardRow>
      </ConnectionCardBody>
    </ConnectionCard>
  );
};
