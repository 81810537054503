import { HStack, Text } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { NavItemProps } from './types';
import { createElement } from 'react';
import { Link } from 'react-router-dom';

export const NavItem = ({
  label,
  icon,
  selected,
  href,
  rightInfo,
  onClick,
}: NavItemProps) => {
  const color = selected ? COLORS.FINCH.PURPLE : COLORS.GRAY.GRAY_600;

  return (
    <HStack
      as={href ? Link : 'button'}
      justify="space-between"
      _hover={{ color: COLORS.FINCH.PURPLE }}
      color={color}
      onClick={onClick}
      fontSize="sm"
      fontWeight={selected ? 600 : 400}
      {...(href ? { to: href } : {})}
    >
      <HStack>
        {createElement(icon)}
        <Text>{label}</Text>
      </HStack>
      <Text>{rightInfo}</Text>
    </HStack>
  );
};
