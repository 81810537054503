import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { SearchIcon } from '../../shared/icons/SearchIcon';

export const ConnectionsFetchErrorMessage = ({
  onRetry,
  height = '400px',
  table,
}: {
  table: 'jobs' | 'connections';
  height?: string;
  onRetry: () => void;
}) => {
  return (
    <Stack
      height={height}
      spacing="24px"
      align="center"
      p="64px 100px"
      textAlign="center"
    >
      <Box borderRadius="50%" bg="primary.50" p="2">
        <SearchIcon />
      </Box>
      <Stack spacing="4px">
        <Text fontWeight="semibold">Error fetching {table}</Text>
      </Stack>
      <Button
        variant="outline"
        fontWeight="500"
        fontSize="sm"
        _hover={{ bg: COLORS.GRAY.GRAY_200 }}
        _active={{ bg: COLORS.GRAY.GRAY_200 }}
        borderColor={COLORS.GRAY.GRAY_400}
        onClick={onRetry}
      >
        Retry
      </Button>
    </Stack>
  );
};
