import { Stack, HStack, Text, StackDivider } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { Switch } from '../../components/Switch';
import { ContentBox } from '../../components/ContentBox';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { useProviderSettings } from '../hooks/useProviderSettings';
import { Loading } from '../../components/Loading/Loading';
import { usePermissions } from '../../hooks/use-permissions';
import { PERMISSION } from '../../constant/roles-and-permissions';

const implementationKinds = [
  { id: ImplementationKind.CREDENTIAL, label: 'Credentials' },
  { id: ImplementationKind.API_TOKEN, label: 'API Token' },
  { id: ImplementationKind.OAUTH, label: 'OAuth' },
  { id: ImplementationKind.ASSISTED, label: 'Assisted' },
];

export const SettingsPage = () => {
  const { isLoading, stagedProviderSettings, setStagedProviderSettings } =
    useProviderSettings();

  const handleAutoEnableNewImplementations = (enabled: boolean) => {
    if (stagedProviderSettings) {
      setStagedProviderSettings({
        ...stagedProviderSettings,
        autoEnableNewImplementations: enabled,
      });
    }
  };

  const handleToggleImplementation = (
    id: ImplementationKind,
    enabled: boolean,
  ) => {
    if (stagedProviderSettings) {
      const allowedImplementationKinds = enabled
        ? [...stagedProviderSettings.allowedImplementationKinds, id]
        : stagedProviderSettings.allowedImplementationKinds.filter(
            (kind) => kind !== id,
          );
      setStagedProviderSettings({
        ...stagedProviderSettings,
        allowedImplementationKinds,
      });
    }
  };

  const roleCanUpdateConfigurations = usePermissions([
    PERMISSION.IntegrationConfigurations.Update,
  ]);

  return (
    <ContentBox borderTopRadius="0">
      {isLoading ? (
        <Loading message="Your configurations are being loaded" />
      ) : (
        <Stack divider={<StackDivider />}>
          <Stack p="24px">
            <Text fontSize="sm" fontWeight="500">
              Add New Integrations Automatically
            </Text>
            <HStack justify="space-between" align="start">
              <Text color={COLORS.GRAY.GRAY_600} fontSize="sm" flexBasis="50%">
                Any new integrations that Finch adds will be automatically shown
                to your customers in Finch Connect. If turned off, any new
                integrations will be disabled by default.
              </Text>

              <Switch
                id="add-new-integrations"
                enabled={
                  stagedProviderSettings?.autoEnableNewImplementations ?? false
                }
                isDisabled={!roleCanUpdateConfigurations}
                onChange={handleAutoEnableNewImplementations}
                labels={{ enabled: 'On', disabled: 'Off' }}
              />
            </HStack>
          </Stack>
          <Stack p="24px">
            <Text fontSize="sm" fontWeight="500">
              Available Authentication Methods
            </Text>
            <HStack justify="space-between" align="start">
              <Text color={COLORS.GRAY.GRAY_600} fontSize="sm" flexBasis="50%">
                Enable or disable entire authentication methods in Finch
                Connect.
              </Text>

              <Stack divider={<StackDivider />} flex="1">
                {implementationKinds.map(({ id, label }) => (
                  <HStack key={id} justify="space-between">
                    <Text fontSize="sm">{label}</Text>
                    <Switch
                      id="add-new-integrations"
                      enabled={
                        stagedProviderSettings?.allowedImplementationKinds?.includes(
                          id,
                        ) ?? false
                      }
                      isDisabled={!roleCanUpdateConfigurations}
                      onChange={(enabled) => {
                        handleToggleImplementation(id, enabled);
                      }}
                      labels={{ enabled: 'On', disabled: 'Off' }}
                    />
                  </HStack>
                ))}
              </Stack>
            </HStack>
          </Stack>
        </Stack>
      )}
    </ContentBox>
  );
};
