import {
  DrawerCloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Text,
  StackDivider,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { format } from '../../shared/utils/dateFormatter';
import { ConnectSessionErrorEvent, ConnectStep } from 'types/connect';
import { ConnectStepMap } from 'connection-detail-v2/constants';
import { CancelCircleIcon } from 'shared/icons/CancelCircle';
import { TableDetailItem } from 'connection-detail-v2/components/TableDetailItem';
import { ProviderIcon } from 'components/Iconagraphy';
import startCase from 'lodash/startCase';

export function TimelineErrorDrawer({
  errorEvents,
  isOpen,
  onClose,
  isLoading,
}: {
  errorEvents: ConnectSessionErrorEvent[];
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
}) {
  const latestError = errorEvents[errorEvents.length - 1];
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent bg={COLORS.WHITE}>
        <HStack
          p="4"
          justify="space-between"
          bg={COLORS.WHITE}
          align="center"
          borderBottom={`1px solid ${COLORS.GRAY.GRAY_400}`}
        >
          <Heading fontWeight="500" fontSize="16px">
            Stage 4 — Authentication
          </Heading>
          <DrawerCloseButton pos="relative" top="0" right="0" />
        </HStack>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Stack
            spacing="0"
            px="4"
            divider={<StackDivider borderColor={COLORS.GRAY.GRAY_300} />}
          >
            <TableDetailItem
              title="Status"
              data={
                <HStack>
                  <CancelCircleIcon />
                  <Text fontSize="sm" color={COLORS.FINCH.ERROR_RED}>
                    Error
                  </Text>
                </HStack>
              }
            />

            <TableDetailItem
              title="Authentication Progress"
              data={
                <HStack>
                  <CancelCircleIcon />
                  <Text fontSize="sm" color={COLORS.FINCH.ERROR_RED}>
                    {ConnectStepMap[latestError?.eventData.step as ConnectStep]}
                  </Text>
                </HStack>
              }
            />

            <TableDetailItem
              title="Error Message"
              data={latestError?.eventData.errorMessage}
            />

            {latestError?.providerName && latestError?.providerIcon && (
              <TableDetailItem
                title="Provider"
                data={
                  <HStack>
                    <ProviderIcon
                      src={latestError.providerIcon}
                      provider={latestError.providerName}
                    />
                    <Text
                      fontWeight={400}
                      fontSize="14px"
                      color={COLORS.FINCH.BLACK}
                    >
                      {latestError.providerName}
                    </Text>
                  </HStack>
                }
              />
            )}

            {latestError?.implementationKind && (
              <TableDetailItem
                title="Integration Type"
                data={startCase(latestError.implementationKind)}
              />
            )}

            <TableDetailItem
              title="Failed Attempts"
              data={errorEvents.length.toString()}
            />

            {latestError?.createdAt && (
              <TableDetailItem
                title="Latest Activity"
                data={format(new Date(latestError?.createdAt))}
              />
            )}
          </Stack>
        )}
      </DrawerContent>
    </Drawer>
  );
}
