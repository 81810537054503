import styled from '@emotion/styled';
import { COLORS, SIZES } from '../../constant';

const Container = styled.div<{ omitBorder?: boolean }>`
  display: flex;
  flex-grow: 1;
  max-height: 512px;
  min-height: 200px;
  padding: 48px;

  box-shadow: ${({ omitBorder }) =>
    omitBorder
      ? 'none'
      : `0px 0px 1px rgba(22, 22, 22, 0.12),
    0px 4px 8px rgba(22, 22, 22, 0.06)`};
  border-radius: ${({ omitBorder }) => (omitBorder ? '0' : '8px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ omitBorder }) =>
    omitBorder ? 'transparent' : COLORS.WHITE};

  @media (max-width: ${SIZES.mobile}) {
    max-height: max-content;
  }
`;

const TextWrapper = styled.div`
  width: 60%;
  margin-top: 32px;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

export const InfoBox = ({
  header,
  message,
  children,
  omitBorder,
}: {
  header: string;
  message: string;
  children: JSX.Element;
  omitBorder?: boolean;
}) => {
  return (
    <Container omitBorder={omitBorder}>
      {children}
      <TextWrapper>
        <Header>{header}</Header>
        <Message>{message}</Message>
      </TextWrapper>
    </Container>
  );
};
