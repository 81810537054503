export const PaperAirplaneIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 16V12L9 10L3 8V4L18 10L3 16Z" fill="currentColor" />
  </svg>
);
