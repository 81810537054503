import { Box, type BoxProps, Grid, GridProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ContentBox } from 'components/ContentBox';

import { SideNavigation } from './SideNavigation';

type SideNavigationWithContentProps = {
  children: ReactNode;
  nav: ReactNode;
  navProps?: BoxProps;
  containerProps?: GridProps;
  mainContentProps?: BoxProps;
  wrapMainContentInContentBox?: boolean;
};

export const SideNavigationWithContent = ({
  children,
  nav,
  navProps,
  containerProps,
  mainContentProps,
  wrapMainContentInContentBox,
}: SideNavigationWithContentProps) => {
  return (
    <Grid
      templateColumns={{ base: '1fr', md: '275px 1fr' }}
      gap="24px"
      h="full"
      overflow="hidden"
      {...containerProps}
    >
      <SideNavigation alignSelf="start" {...navProps}>
        {nav}
      </SideNavigation>
      {wrapMainContentInContentBox ? (
        <ContentBox flex="1" {...mainContentProps}>
          {children}
        </ContentBox>
      ) : (
        <Box {...mainContentProps}>{children}</Box>
      )}
    </Grid>
  );
};
