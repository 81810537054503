const GRAY = {
  GRAY_100: '#fafafa',
  GRAY_200: '#f4f4f4',
  GRAY_300: '#EDEDED',
  GRAY_400: '#d9d9d9',
  GRAY_500: '#999999',
  GRAY_600: '#737373',
  GRAY_800: '#454545',
};

const FINCH = {
  GRAY: '#454545',
  RED: '#D92D20',
  ERROR_RED: '#B42318',
  BLACK: '#161616',
  PURPLE: '#3e4ae7',
  LIGHT_PURPLE: '#eff1ff',
  LAVENDER: '#cad0eb',
  INDIGO_DARK: '#7a84ff',
};

export const COLORS = {
  LIGHT: '#e8f1f5',
  WHITE: '#ffffff',
  RED: '#ff0000',
  LIGHT_RED: '#FEF3F2',
  YELLOW: '#fef0c7',
  YELLOW_DARK: '#fec84b',
  ORANGE: '#b54708',
  GREEN: '#027A48',
  LIGHT_GREEN: '#ECFDF3',
  GRAY,
  FINCH,
};
