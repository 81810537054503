import { Box, Text } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import startCase from 'lodash/startCase';

export const ScopeTag = ({ scope }: { scope: string }) => {
  return (
    <Box
      p="2px 6px"
      borderRadius="6px"
      bg={COLORS.GRAY.GRAY_100}
      color={COLORS.GRAY.GRAY_600}
      border={`1px solid ${COLORS.GRAY.GRAY_400}`}
    >
      <Text fontSize="xs" fontWeight="500">
        {startCase(scope.replace('employer:', ''))}
      </Text>
    </Box>
  );
};
