import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  return searchParams;
};

const searchParamOnLoad = window.location.search;

export const useSeachParamsOnLoad = () => {
  const searchParamsOnLoad = useMemo(
    () => new URLSearchParams(searchParamOnLoad),
    [],
  );
  return searchParamsOnLoad;
};
