import {
  AggregatedConnectionStatus,
  AccountStatus,
} from '@finch-api/common/dist/external/dashboard/connection-status';
import { Badge } from '../Badge/Badge';
import { CircleIcon } from '../../components/Iconagraphy/CircleIcon';
import { TagVariant, variants } from './Tag';
import startCase from 'lodash/startCase';

const snakeToTitleCase = (str: string): string => {
  return startCase(str.toLowerCase());
};

const displayName = (status: AggregatedConnectionStatus | AccountStatus) => {
  if (status === AggregatedConnectionStatus.NEEDS_ATTENTION)
    return 'Multiple Errors';
  return snakeToTitleCase(status.toString());
};

const statusVariant = (
  status: AggregatedConnectionStatus | AccountStatus,
): TagVariant => {
  switch (status) {
    case AggregatedConnectionStatus.CONNECTED:
    case AccountStatus.CONNECTED:
      return 'connected';
    case AggregatedConnectionStatus.DISCONNECTED:
    case AccountStatus.DISCONNECTED:
      return 'disconnected_aggregate';
    case AggregatedConnectionStatus.PENDING:
    case AccountStatus.PENDING:
      return 'processing';
    default:
      return 'needs_attention';
  }
};

export const ConnectionStatusTag = ({
  status,
}: {
  status: AggregatedConnectionStatus | AccountStatus;
}): JSX.Element => {
  const { theme } = variants[statusVariant(status)];
  return <Badge icon={CircleIcon} theme={theme} text={displayName(status)} />;
};
