import { LinkIcon } from 'shared/icons/Link';
import { DownloadIcon } from 'shared/icons/Download';
import { useDisclosure } from '@chakra-ui/react';
import { ModalNewConnectSession } from './ConnectSession/ModalNewConnectSession';
import { exportCSV, mapTableDataToExportData } from 'utils/exportCsv';
import {
  useIncompleteTableDataStore,
  useTableDataStore,
} from '../state/tableDataStore';
import {
  CsvToConnectionTableMap,
  CsvToIncompleteConnectionTableMap,
} from '../constants';
import {
  ConnectionStage,
  getConnectionStageFromQueryParams,
} from '../hooks/useConnectionStageSync';
import { ActionMenu } from 'components/ActionMenu';
import { useApplication } from '../../applications/hooks';

export const ConnectionsActionMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tableData = useTableDataStore((state) => state.tableData);

  const incompleteTableData = useIncompleteTableDataStore(
    (state) => state.tableData,
  );

  const { application } = useApplication();

  const connectionStage = getConnectionStageFromQueryParams();

  const completeExportData = mapTableDataToExportData(
    tableData,
    CsvToConnectionTableMap,
  );

  const stagedExportData = mapTableDataToExportData(
    incompleteTableData,
    CsvToIncompleteConnectionTableMap,
  );

  const handleDownloadCSV = () => {
    switch (connectionStage) {
      case ConnectionStage.Staged:
        exportCSV({
          data: stagedExportData,
          headers: Object.keys(CsvToIncompleteConnectionTableMap),
          fileName: 'Staged-Connections-' + new Date().toISOString(),
        });
        break;
      case ConnectionStage.Live:
        exportCSV({
          data: completeExportData,
          headers: Object.keys(CsvToConnectionTableMap),
          fileName: 'Connections-' + new Date().toISOString(),
        });
        break;
      case ConnectionStage.Disconnected:
        exportCSV({
          data: completeExportData,
          headers: Object.keys(CsvToConnectionTableMap),
          fileName: 'Disconnected-Connections-' + new Date().toISOString(),
        });
        break;
    }
  };

  return (
    <>
      <ActionMenu
        isDisabled={application?.is_blocked}
        menuItems={[
          {
            icon: <LinkIcon />,
            label: 'Create a New Connect Link',
            onClick: onOpen,
          },
          {
            icon: <DownloadIcon />,
            label: 'Download CSV',
            onClick: handleDownloadCSV,
          },
        ]}
      />

      {isOpen && <ModalNewConnectSession isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
