import { create } from 'zustand';
import { Group } from '../types';

export const useSelectedGroupStore = create<{
  selectedGroup: Group;
  setSelectedGroup: (group: Group) => void;
}>((set) => ({
  selectedGroup: 'organization',
  setSelectedGroup: (selectedTab) => set({ selectedGroup: selectedTab }),
}));
