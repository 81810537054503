import { Stack } from '@chakra-ui/react';

import { Fragment } from 'react';
import { ProviderEntry } from './ProviderEntry';
import { ProviderListProps } from '../types';

export const ProviderList = ({
  providers,
  providerSettings,
  updateIsPinned,
  updateEnabled,
  setSelectedProviderId,
  hasStagedConfiguration,
  shouldAllowUpdatesToProvider,
}: ProviderListProps) => {
  return (
    <Stack
      as="ul"
      title="Integration list"
      overflow="auto"
      h="full"
      spacing="0"
      width="100%"
    >
      {providers.map((provider, index) => {
        const isLastItem = index === providers.length - 1;

        return (
          <Fragment key={provider.id}>
            <ProviderEntry
              provider={provider}
              providerSettings={providerSettings}
              updateIsPinned={updateIsPinned}
              updateEnabled={updateEnabled}
              isLastItem={isLastItem}
              canUpdate={shouldAllowUpdatesToProvider(provider.id)}
              setSelectedProviderId={setSelectedProviderId}
              hasStagedConfiguration={hasStagedConfiguration}
            />
          </Fragment>
        );
      })}
    </Stack>
  );
};
