import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { AlertBodyText, AlertHeadingText } from './Typography';

export type ConfirmationProps = {
  heading?: React.ReactNode;
  body: React.ReactNode;
  confirm: {
    text?: string;
    action: () => Promise<void> | void;
  };
  cancel: {
    text?: string;
    action: () => void;
  };
  isOpen: boolean;
  isLoading?: boolean;
};

export const Confirmation = (props: ConfirmationProps) => {
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={leastDestructiveRef}
      onClose={props.cancel.action}
      size="md"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            <Flex alignItems="center">
              <AlertHeadingText>
                {props.heading ?? 'Are you sure you want to continue?'}
              </AlertHeadingText>
            </Flex>
          </AlertDialogHeader>
          <AlertDialogBody py={0}>
            <AlertBodyText>{props.body}</AlertBodyText>
          </AlertDialogBody>
          <AlertDialogFooter p="24px">
            <VStack align="stretch" w="full">
              <Button
                variant="danger"
                onClick={props.confirm.action}
                isLoading={props.isLoading}
              >
                {props.confirm.text ?? "I'm Sure"}
              </Button>
              <Button
                ref={leastDestructiveRef}
                onClick={props.cancel.action}
                variant="secondary"
              >
                {props.cancel.text ?? 'Cancel'}
              </Button>
            </VStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
