import { useTheme } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';

/*
 * The content here is to overwrite the react-datepicker CSS using emotion.
 */

export const DatePickerTheme = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        .react-datepicker {
          font-family: unset;
        }
        .react-datepicker__day--selected:hover,
        .react-datepicker__day--in-selecting-range:hover,
        .react-datepicker__day--in-range:hover {
          background-color: ${theme.colors.brand.purple};
        }
        .react-datepicker__day--keyboard-selected:hover {
          background-color: ${theme.colors.brand.purple};
        }
        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range {
          background-color: ${theme.colors.brand.purple};
        }
        .react-datepicker__day--keyboard-selected {
          background-color: ${theme.colors.brand.purple};
        }
        .react-datepicker__navigation {
          top: 9px;
        }
      `}
    />
  );
};

export const CalendarContainer = styled.div({
  fontFamily: 'Inter',
});
