export const CheckIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23177 9.0625L0.710938 5.52083L1.7526 4.47917L4.23177 6.9375L10.2526 0.9375L11.2943 2L4.23177 9.0625Z"
        fill={color || '#737373'}
      />
    </svg>
  );
};
