import * as Sentry from '@sentry/react';
import axios from 'axios';

import { baseURL } from '../utils/baseUrls';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProviders = async (token: string, id: string): Promise<any> => {
  const requestURL = new URL(`${baseURL}/api/v1/applications/${id}/providers`);

  try {
    const response = await axios.get(requestURL.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};
