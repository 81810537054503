import { useDisclosure } from '@chakra-ui/react';
import { type Row } from '@tanstack/react-table';
import { EditIcon } from '../../../shared/icons/EditIcon';
import { MenuOption } from './MenuOption';
import { ModalEditRole } from './ModalEditRole';
import { Role } from '../../../constant/roles-and-permissions';
import { OrganizationMemberColumn } from './MemberTableCell';

export const EditRoleOption = ({
  row,
}: {
  row: Row<OrganizationMemberColumn>;
}) => {
  const [role] = row.original.roles as Role[];
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <MenuOption icon={<EditIcon hollow />} onClick={onOpen}>
        Edit
      </MenuOption>
      {isOpen && (
        <ModalEditRole
          isOpen={isOpen}
          onClose={onClose}
          email={row.original.member.email}
          currentRole={role || Role.Member}
          memberId={row.original.member.id}
        />
      )}
    </>
  );
};
