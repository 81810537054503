import { useQuery } from '@tanstack/react-query';

import { useSandboxStore } from '../state/sandboxStore';
import { useConnectionApi } from './useConnectionApi';
import { Connection } from '../model';
import { useParams } from 'react-router-dom';
import {
  FINCH_SANDBOX_CONNECTION_LIMIT,
  PROVIDER_SANDBOX_CONNECTION_LIMIT,
} from '@finch-api/common/dist/internal/sandbox';

export const useConnections = () => {
  const { applicationId } = useParams<{ applicationId: string }>();

  const api = useConnectionApi();

  const {
    isLoading,
    data = [],
    error,
  } = useQuery({
    queryKey: ['connections-v2', applicationId],
    queryFn: () => api.getConnections(applicationId),
    refetchOnMount: false,
  });

  const {
    isLoading: isStagedLoading,
    data: stagedData = [],
    error: stagedError,
  } = useQuery({
    queryKey: ['staged-connections', applicationId],
    queryFn: () => api.getStagedConnections(applicationId),
    refetchOnMount: false,
  });

  const connections = data.map((connection) => new Connection(connection));

  const disconnectedConnections = connections.filter((connection) =>
    connection.isDisconnected(),
  );

  const liveConnections = connections.filter(
    (connection) => !connection.isDisconnected(),
  );

  return {
    connections: liveConnections,
    disconnectedConnections,
    isLoading,
    stagedConnections: stagedData,
    isStagedLoading,
    error,
    stagedError,
  };
};

const isFinchSandbox = (connection: Connection) =>
  connection.hasFinchSandboxKinds() && !connection.isLegacySandboxProvider();

const isProviderSandbox = (connection: Connection) =>
  !connection.hasFinchSandboxKinds() && !connection.isLegacySandboxProvider();

const notDisconnected = (connection: Connection) =>
  !connection.isDisconnected();

export const useSandboxConnections = () => {
  const {
    connections,
    disconnectedConnections,
    stagedConnections,
    isLoading,
    isStagedLoading,
  } = useConnections();

  const { selectedSandbox, setSelectedSandbox } = useSandboxStore();

  const defaultInfo = {
    connections,
    disconnectedConnections,
    stagedConnections,
    isLoading,
    isStagedLoading,
    used: 0,
    selectedSandbox,
    setSelectedSandbox,
    limit: null,
  };

  if (selectedSandbox === 'finch') {
    const sandboxConnections = connections.filter(isFinchSandbox);
    const disconnectedSandboxConnections =
      disconnectedConnections.filter(isFinchSandbox);
    const stagedSandboxConnections = stagedConnections.filter(
      (connection) => connection.sandbox === 'finch',
    );

    return {
      ...defaultInfo,
      connections: sandboxConnections,
      disconnectedConnections: disconnectedSandboxConnections,
      stagedConnections: stagedSandboxConnections,
      used: sandboxConnections.filter(notDisconnected).length,
      limit: FINCH_SANDBOX_CONNECTION_LIMIT,
    };
  }

  if (selectedSandbox === 'provider') {
    const sandboxConnections = connections.filter(isProviderSandbox);
    const disconnectedSandboxConnections =
      disconnectedConnections.filter(isProviderSandbox);
    const stagedSandboxConnections = stagedConnections.filter(
      (connection) => connection.sandbox === 'provider',
    );

    return {
      ...defaultInfo,
      connections: sandboxConnections,
      disconnectedConnections: disconnectedSandboxConnections,
      stagedConnections: stagedSandboxConnections,
      used: sandboxConnections.filter(notDisconnected).length,
      limit: PROVIDER_SANDBOX_CONNECTION_LIMIT,
    };
  }

  return defaultInfo;
};
