import { useEffect } from 'react';
import { User, useAuth0 } from '@auth0/auth0-react';
import type {
  Auth0ContextInterface,
  RedirectLoginOptions,
} from '@auth0/auth0-react';

import { useSearchParams } from '../shared/useQuery';

const queryParamKeyOrgId = 'orgId';
const queryParamInvitation = 'invitation';
const queryParamOrganization = 'organization';

export const Login = () => {
  const searchParams = useSearchParams();
  const { isAuthenticated, loginWithRedirect }: Auth0ContextInterface<User> =
    useAuth0();

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) {
        // Accept URL SearchParams to set the organization right before redirection.
        const orgId = searchParams.get(queryParamKeyOrgId) || undefined;
        const invitation = searchParams.get(queryParamInvitation) || undefined;
        const organization =
          searchParams.get(queryParamOrganization) || undefined;

        const redirectLoginOptions: RedirectLoginOptions = {
          organization: orgId || organization,
          invitation,
          // So users can use the back button to go to the previous page
          redirectMethod: 'replace',
        };

        await loginWithRedirect(redirectLoginOptions);
        return;
      }
    })();
  }, [isAuthenticated, loginWithRedirect, searchParams]);

  return <></>;
};
