import { Center, Spacer } from '@chakra-ui/react';
import { Body } from './shared/Typography';

export const NotFoundPage = () => {
  return (
    <>
      <Spacer p="100" />
      <Center>
        <Body>Page Not Found.</Body>
      </Center>
    </>
  );
};
