import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { COLORS } from '../../constant';
import startCase from 'lodash/startCase';
import { InfoIcon } from '../../shared/InfoIcon';

export const AssistedBenefitScopeTag = () => {
  return (
    <Box
      p="2px 6px"
      borderRadius="6px"
      bg={COLORS.GRAY.GRAY_100}
      color={COLORS.GRAY.GRAY_600}
      border={`1px dashed ${COLORS.GRAY.GRAY_400}`}
    >
      <HStack>
        <Text fontSize="xs" fontWeight="500">
          {startCase('benefits')}
        </Text>
        <Tooltip
          label={`The Benefits scope is covered by our assisted integration.`}
          placement="top"
        >
          <Box>
            <InfoIcon width="15" height="15" />
          </Box>
        </Tooltip>
      </HStack>
    </Box>
  );
};
