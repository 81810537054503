export const getColorScheme = (statusCode: number) => {
  if (statusCode >= 200 && statusCode < 300) {
    return 'green';
  } else {
    return 'red';
  }
};

const statusLabelByValue: Record<number, string> = {
  200: 'success',
  201: 'created',
  202: 'accepted',
};

export const getStatusLabelByValue = (status: {
  code: number;
  description: string;
}) => {
  if (status.code >= 300) {
    return status.description;
  } else {
    return statusLabelByValue[status.code];
  }
};

export const statusDisplayFilters = [
  { code: undefined, description: 'All' },
  {
    code: 200,
    description: 'success',
  },
  {
    code: 201,
    description: 'created',
  },
  {
    code: 202,
    description: 'accepted',
  },
  {
    code: 400,
    description: 'invalid_grant_error',
  },
  {
    code: 401,
    description: 'invalid_token_error',
  },
  {
    code: 401,
    description: 'invalid_request_error',
  },
  {
    code: 401,
    description: 'authentication_error',
  },
  {
    code: 401,
    description: 'reauthenticate_user',
  },
  {
    code: 401,
    description: 'invalid_client_error',
  },
  {
    code: 401,
    description: 'unauthorized_request_error',
  },
  {
    code: 403,
    description: 'insufficient_scope_error',
  },
  {
    code: 404,
    description: 'not_found_error',
  },
  {
    code: 404,
    description: 'item_not_found',
  },
  {
    code: 404,
    description: 'benefit_not_found',
  },
  {
    code: 404,
    description: 'individual_not_found',
  },
  {
    code: 422,
    description: 'unprocessable_request_error',
  },
  {
    code: 422,
    description: 'unsupported_parameters',
  },
  {
    code: 422,
    description: 'invalid_employee_enrollment',
  },
  {
    code: 500,
    description: 'server_error',
  },
];

export type PayrollProvider = { id: string | undefined; name: string };
