import { Button, Center, Spinner, Text } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { ContentBox } from '../../components/ContentBox';
import { SubSectionHeading } from '../../shared/Typography';
import { SettingEntry } from '../components/SettingEntry';
import { StackWithDivider } from '../components/StackWithDivider';
import { usePassword } from '../../auth/use-password';

export const ProfileSettingsPage = () => {
  const { isLoading, user } = useAuth0();

  const { sendChangePasswordEmail } = usePassword();

  return (
    <ContentBox>
      <StackWithDivider>
        <SubSectionHeading px="6" py="4">
          Profile
        </SubSectionHeading>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : user?.email ? (
          <StackWithDivider>
            <SettingEntry title="Email" details={user.email} />
            <SettingEntry
              title="Password"
              details={`Click the button to receive an email at ${user.email} with instructions on how to reset your password`}
              action={
                <Button
                  variant="secondary"
                  onClick={() => {
                    if (user.email) {
                      sendChangePasswordEmail.mutate(user.email);
                    }
                  }}
                  isLoading={sendChangePasswordEmail.isPending}
                >
                  Reset password
                </Button>
              }
            />
          </StackWithDivider>
        ) : (
          <Text p="2">Unable to load user profile</Text>
        )}
      </StackWithDivider>
    </ContentBox>
  );
};
