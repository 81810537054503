export const MinusIcon = () => (
  <svg
    width="8"
    height="2"
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="8" height="1.5" fill="white" />
  </svg>
);
