import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useMfa } from '../../auth/use-mfa';
import { ModalEnrollMfa } from './ModalEnrollMfa';
import { SettingEntry } from './SettingEntry';
import { StackWithDivider } from './StackWithDivider';
import { useOrganization } from '../../shared/hooks/use-organization';
import { StatusTag } from '../../shared/StatusTag';
import { ModalPassword } from './ModalPassword';

export const TwoFactorAuthentication = () => {
  const {
    authenticators,
    enrollOtpAuthenticator,
    verifyOtp,
    getMfaToken,
    handlerWithMfaToken,
    isPasswordModalOpen,
    onClosePasswordModal,
    onSuccessfulMfaToken,
    deleteOtpAuthenticator,
    user,
  } = useMfa();

  const { organization } = useOrganization();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onEnrollMfa = handlerWithMfaToken(async () => {
    await enrollOtpAuthenticator.mutateAsync();
    onOpen();
  });

  const closeEnrollMfa = () => {
    enrollOtpAuthenticator.reset();
    verifyOtp.reset();
    onClose();
  };

  return (
    <StackWithDivider>
      <SettingEntry
        title={
          <HStack flexWrap="wrap" align="center">
            <Text>Account Multi-Factor Authentication</Text>
            {authenticators.otpAuthenticator ? (
              <StatusTag variant="success">Enabled</StatusTag>
            ) : (
              <StatusTag variant="danger">Disabled</StatusTag>
            )}
          </HStack>
        }
        details="Protect your Finch account with an extra security step."
        action={
          authenticators.otpAuthenticator && organization.data?.mfa.required ? (
            <Button
              variant="secondary"
              onClick={onEnrollMfa}
              isLoading={enrollOtpAuthenticator.isPending}
            >
              Edit MFA
            </Button>
          ) : authenticators.otpAuthenticator ? (
            <Button
              variant="danger"
              onClick={handlerWithMfaToken(() =>
                deleteOtpAuthenticator.mutate(authenticators.data || []),
              )}
              isLoading={deleteOtpAuthenticator.isPending}
            >
              Remove MFA
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={onEnrollMfa}
              isLoading={enrollOtpAuthenticator.isPending}
            >
              Set up MFA
            </Button>
          )
        }
      />

      {isPasswordModalOpen && (
        <ModalPassword
          isOpen={isPasswordModalOpen}
          onClose={onClosePasswordModal}
          onSuccess={onSuccessfulMfaToken}
          getMfaToken={getMfaToken}
          email={user?.email || ''}
        />
      )}

      {enrollOtpAuthenticator.data && (
        <ModalEnrollMfa
          isOpen={isOpen}
          onClose={closeEnrollMfa}
          qrCodeSvg={enrollOtpAuthenticator.data.qrCodeSvg}
          secret={enrollOtpAuthenticator.data.secret}
          verifyOtp={verifyOtp}
        />
      )}
    </StackWithDivider>
  );
};
