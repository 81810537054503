import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  Application,
  ApplicationWithFeatures,
} from '@finch-api/developer-dashboard-common';

import { baseURL } from '../utils/baseUrls';

/**
 * Fetches all applications belonging to the Developer's Auth0 Organization (
 * discerned from the bearer token).
 * @param token the Developer's Auth0 token
 * @returns a list of the Developer's application(s)
 */
export const getApplications = async (
  token: string,
): Promise<Application[]> => {
  try {
    const response = await axios.get<Application[]>(
      `${baseURL}/api/v1/applications`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

/**
 * Fetches a Developer's Application by it's unique UUID.
 * @param token the Developer's Auth0 Token
 * @param applicationId the application for which the details should be obtained.
 * @returns an Application, or a 404 exception!
 */
export const getApplication = async (
  token: string,
  applicationId?: string,
): Promise<ApplicationWithFeatures | undefined> => {
  if (!applicationId) {
    return undefined;
  }
  try {
    const response = await axios.get<ApplicationWithFeatures>(
      `${baseURL}/api/v1/applications/${applicationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
