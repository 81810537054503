import axios from 'axios';
import * as Sentry from '@sentry/react';
import { baseURL } from '../../utils/baseUrls';

const requestURL = (applicationId: string) => {
  const requestUrl = new URL(baseURL);
  requestUrl.pathname = `/api/v1/applications/${applicationId}/redirects`;
  return requestUrl.toString();
};

/**
 * Adds Redirect URL(s) to a Developer's Application by it's unique UUID.
 * @param token the Developer's Auth0 Token.
 * @param applicationId the Application to add to.
 * @param redirects the list of Redirect URLs to be added to the Application.
 * @returns a list of the resulting Application's Redirect URLs.
 */
export const addApplicationRedirectURIs = async (
  token: string,
  applicationId: string,
  redirects: string[],
): Promise<string[]> => {
  try {
    const response = await axios.patch<string[]>(
      requestURL(applicationId),
      redirects,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Removes Redirect URL(s) to a Developer's Application by it's unique UUID.
 * @param token the Developer's Auth0 Token.
 * @param applicationId which Application to remove from.
 * @param redirects the list of Redirect URLs to be removed from the Application.
 * @returns a list of the Application's remaining Redirect URLs.
 */
export const removeApplicationRedirectURIs = async (
  token: string,
  applicationId: string,
  redirects: string[],
): Promise<string[]> => {
  try {
    const response = await axios.delete<string[]>(requestURL(applicationId), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: redirects,
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
