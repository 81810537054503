import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { type Row } from '@tanstack/react-table';
import { useAuth0 } from '@auth0/auth0-react';
import { OptionsIcon } from '../../../shared/OptionsIcons';
import { CopyIcon } from '../../../shared/icons/CopyIcon';
import { COLORS } from '../../../constant';
import { MenuOption } from './MenuOption';
import { OrganizationMemberColumn, DeleteOption } from './MemberTableCell';
import { EditRoleOption } from './EditRoleOption';
import { PERMISSION } from '../../../constant/roles-and-permissions';
import { usePermissions } from '../../../hooks/use-permissions';

export const OptionsButton = ({
  row,
}: {
  row: Row<OrganizationMemberColumn>;
}) => {
  const toast = useToast({ duration: 2000, isClosable: true });
  const { user } = useAuth0();
  const { onCopy } = useClipboard(user?.email || '');
  const roleCanChangeRoles = usePermissions([PERMISSION.MemberRoles.Update]);
  const roleCanDeleteMembers = usePermissions([PERMISSION.Members.Delete]);
  const isMemberCurrentUser = user?.email === row.original.member.email;

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<OptionsIcon />}
        variant="outline"
        boxSize={6}
        minW={6}
        border="none"
        _hover={{ bg: COLORS.GRAY.GRAY_200 }}
        _active={{ bg: COLORS.GRAY.GRAY_200 }}
      />
      <MenuList minW="150px" borderColor={COLORS.GRAY.GRAY_200} p="4px">
        {!isMemberCurrentUser &&
          roleCanChangeRoles &&
          row.original.hasAccepted && <EditRoleOption row={row} />}
        <MenuOption
          icon={<CopyIcon />}
          onClick={() => {
            onCopy();
            toast({ title: 'Email copied', status: 'success' });
          }}
        >
          Copy Email
        </MenuOption>
        {!isMemberCurrentUser && roleCanDeleteMembers && (
          <DeleteOption row={row} />
        )}
      </MenuList>
    </Menu>
  );
};
