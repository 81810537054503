import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSeachParamsOnLoad } from '../../shared/useQuery';

import { useActivityStore } from '../state/activityStore';
import { useActivityLogs } from './useActivityLogs';

export const useActivityUrlParamSync = () => {
  const history = useHistory();
  const searchParamsOnLoad = useSeachParamsOnLoad();
  const { fetchLogs } = useActivityLogs();
  const {
    activeFilters: {
      fuzzyIdAndNameSearchQuery,
      endpointSearchQuery,
      payrollProviderId,
      statusCode,
      statusDescription,
      startDate,
      endDate,
      pageIndex,
    },
    updateDraftFilters,
    commitFilters,
    setInitialFetchComplete,
  } = useActivityStore();
  const didInitialFetch = useRef(false);

  // Only on load, set the search to the values from URL query params
  useEffect(() => {
    if (didInitialFetch.current) return;

    didInitialFetch.current = true;

    const [statusCode, statusDescription] =
      searchParamsOnLoad.get('status')?.split('-') || [];

    updateDraftFilters({
      fuzzyIdAndNameSearchQuery: searchParamsOnLoad.get('q') || '',
      endpointSearchQuery: searchParamsOnLoad.get('endpoint') || '',
      payrollProviderId: searchParamsOnLoad.get('provider') || '',
      statusCode: statusCode ? Number(statusCode) : undefined,
      statusDescription: statusDescription || '',
      startDate: Number(searchParamsOnLoad.get('startDate')) || undefined,
      endDate: Number(searchParamsOnLoad.get('endDate')) || undefined,
      pageIndex: Number(searchParamsOnLoad.get('skipCount')) || 0,
    });

    commitFilters();

    fetchLogs().then(() => {
      setInitialFetchComplete(true);
    });
  }, [
    commitFilters,
    fetchLogs,
    searchParamsOnLoad,
    setInitialFetchComplete,
    updateDraftFilters,
  ]);

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    const statusParam =
      statusCode && statusDescription
        ? `${statusCode}-${statusDescription}`
        : '';

    newUrlParams.set('q', fuzzyIdAndNameSearchQuery || '');
    newUrlParams.set('endpoint', endpointSearchQuery || '');
    newUrlParams.set('provider', payrollProviderId || '');
    newUrlParams.set('status', statusParam);
    newUrlParams.set('startDate', startDate ? String(startDate) : '');
    newUrlParams.set('endDate', endDate ? String(endDate) : '');
    newUrlParams.set('skipCount', String(pageIndex) || '0');

    history.replace(`?${newUrlParams}`);
  }, [
    endDate,
    endpointSearchQuery,
    fuzzyIdAndNameSearchQuery,
    history,
    pageIndex,
    payrollProviderId,
    startDate,
    statusCode,
    statusDescription,
  ]);
};
