import { useState } from 'react';
import { Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { FiEye, FiEyeOff, FiRefreshCcw } from 'react-icons/fi';
import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import * as Sentry from '@sentry/react';

import { refreshSecret } from '../utils/applicationSecret';
import { useStatusToast } from '../../shared/StatusToast';
import { InputConfirmationDialog } from './InputConfirmationDialog';
import { ClipboardButton } from '../../components/ClipboardButton';
import { TransparentIconButton } from '../../shared/TransparentIconButton';
import { COLORS } from '../../constant';
import { PermissionRequired } from '../../components/PermissionRequired';
import { PERMISSION } from '../../constant/roles-and-permissions';

type ClientSecretFieldProps = {
  applicationName: string;
};

export const ClientSecretField = ({
  applicationName,
}: ClientSecretFieldProps) => {
  const toast = useStatusToast();
  const { applicationId } = useParams<{ applicationId: string }>();
  if (!applicationId) throw new Error('no application id in url param');

  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();

  const [isConfirming, setIsConfirming] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [secret, setSecret] = useState<string>();
  const [isSecretVisible, setIsSecretVisible] = useState<boolean>(false);

  const confirmRefresh = async () => {
    setIsConfirming(true);
  };

  const refresh = async () => {
    setIsConfirming(false);
    setIsRefreshing(true);

    try {
      const token = await getAccessTokenSilently();
      const secret = await refreshSecret(token, applicationId);
      setIsSecretVisible(true);
      setSecret(secret);
      toast({
        wasSuccessful: true,
        message: 'Your client secret was successfully refreshed.',
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      toast({
        wasSuccessful: false,
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  const onClose = async () => {
    setIsConfirming(false);
  };

  const inputValue = secret ? secret : 'PLACEHOLDERVALUE';
  const inputType = !!secret && isSecretVisible ? 'text' : 'password';

  const toggleVisibility = (v: boolean) => () => setIsSecretVisible(v);

  const iconsWidth = !isRefreshing && !!secret ? '96px' : '';

  const bodyText = (
    <>
      This will permanently change the client secret of the application{' '}
      <Text as="span" fontWeight="bold">
        {applicationName}
      </Text>
      . Any API calls using the previous client secret will fail.
    </>
  );

  return (
    <InputGroup>
      <Input
        fontSize="24px"
        color={COLORS.GRAY.GRAY_600}
        isReadOnly
        value={inputValue}
        type={inputType}
        pr={iconsWidth}
      />
      {isConfirming && (
        <InputConfirmationDialog
          header={{
            text: 'Important',
            showIcon: true,
          }}
          bodyText={bodyText}
          destructiveButton={{
            action: refresh,
            text: 'I understand the consequences, refresh client secret',
          }}
          isOpen={true}
          onClose={onClose}
          inputConfirmation={{
            instructions: 'Type the application name below to confirm',
            text: applicationName,
          }}
        />
      )}
      <InputRightElement width={iconsWidth} gap={2}>
        {/* Show (plaintext) Button */}
        {!isRefreshing && !!secret && !isSecretVisible && (
          <TransparentIconButton
            aria-label="show secret"
            icon={<FiEye />}
            onClick={toggleVisibility(true)}
            variant="icon"
          />
        )}
        {/* Hide (•••) Button*/}
        {!isRefreshing && !!secret && !!isSecretVisible && (
          <TransparentIconButton
            aria-label="hide secret"
            icon={<FiEyeOff />}
            onClick={toggleVisibility(false)}
            variant="icon"
          />
        )}
        {/* Refresh Button */}
        {!secret && (
          <PermissionRequired match={[PERMISSION.ClientSecret.Update]}>
            <TransparentIconButton
              aria-label="refresh secret"
              icon={<FiRefreshCcw />}
              isLoading={isRefreshing}
              onClick={confirmRefresh}
              variant="icon"
            />
          </PermissionRequired>
        )}
        {/* Copy Button */}
        {!isRefreshing && secret && <ClipboardButton value={secret} />}
      </InputRightElement>
    </InputGroup>
  );
};
