import { Button, useDisclosure } from '@chakra-ui/react';
import { COLORS } from '../../../constant';
import { ModalNewConnectSession } from './ModalNewConnectSession';
import { LinkIcon } from '../../../shared/icons/Link';

export const NewConnectSessionButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        size="sm"
        color={COLORS.FINCH.BLACK}
        fontWeight="normal"
        border={`1px solid ${COLORS.GRAY.GRAY_400}`}
        background={COLORS.WHITE}
        _hover={{ background: COLORS.GRAY.GRAY_200 }}
        p="8px 10px"
        minW="fit-content"
        onClick={onOpen}
        leftIcon={<LinkIcon />}
      >
        Create a new Connect Link
      </Button>
      {isOpen && <ModalNewConnectSession isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
