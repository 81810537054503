import {
  Box,
  Flex,
  FormLabel,
  Input,
  Text,
  Stack,
  Divider,
  Switch,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { WebhookEvent } from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { COLORS } from '../../constant';
import { Caption } from '../../shared/Typography';
import { useEffect, useState } from 'react';
import { WebhookFormData, WebhookFormError } from '../types';
import { ContentBox } from '../../components/ContentBox';
import { WebhookEventSelection } from './WebhookEventSelection';

export const WebhookForm = ({
  initFormData,
  events,
  error,
  onFormUpdated,
}: {
  initFormData: WebhookFormData;
  events: WebhookEvent[];
  error?: WebhookFormError;
  onFormUpdated: (data: WebhookFormData) => void;
}) => {
  const [formData, setFormData] = useState<WebhookFormData>(initFormData);

  useEffect(() => {
    onFormUpdated(formData);
  }, [formData, onFormUpdated]);

  const onEndpointURLUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, endpointURL: event.target.value });
  };

  const onEndpointEnabledUpdated = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({ ...formData, endpointEnabled: event.target.checked });
  };

  const onEventSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setFormData({ ...formData, events: [...formData.events, value] });
    } else {
      setFormData({
        ...formData,
        events: formData.events.filter((e) => e !== value),
      });
    }
  };

  const onBulkUpdateEvents = (eventNames: string[]) => {
    setFormData({ ...formData, events: [...eventNames] });
  };

  return (
    <ContentBox>
      <Stack spacing="24px" direction="column" gap={0}>
        <Box padding="24px">
          <FormControl isInvalid={Boolean(error?.endpointURL)}>
            <FormLabel htmlFor="endpointURL" fontSize="14px">
              Endpoint URL
            </FormLabel>
            <Input
              size={'sm'}
              id="endpointURL"
              placeholder="https://acmecompany.com/webhooks/finch/company"
              value={formData.endpointURL}
              onChange={onEndpointURLUpdated}
            />
            <FormErrorMessage>{error?.endpointURL}</FormErrorMessage>
          </FormControl>
        </Box>
        <Divider />
        <Box padding="24px">
          <Flex gap="8px" flexDirection={'column'}>
            <FormLabel htmlFor="endpointEnabled" fontSize="14px" m={0}>
              <Text>Endpoint Status</Text>
            </FormLabel>
            <Box
              display={'flex'}
              justifyContent="space-between"
              flexDirection={'row'}
              gap="8px"
            >
              <Caption>
                <Text
                  maxWidth="350px"
                  fontWeight={'normal'}
                  color={COLORS.GRAY.GRAY_600}
                >
                  When enabled, this webhook will receive HTTPS notifications.
                </Text>
              </Caption>
              <Box>
                <FormControl
                  display="flex"
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <FormLabel
                    htmlFor="email-alerts"
                    mb="0"
                    fontSize={'14px'}
                    color={
                      formData.endpointEnabled ? 'success.600' : 'gray.400'
                    }
                  >
                    {formData.endpointEnabled ? 'Enabled' : 'Disabled'}
                  </FormLabel>
                  <Switch
                    id="endpointEnabled"
                    alignSelf="end"
                    isChecked={formData.endpointEnabled}
                    onChange={onEndpointEnabledUpdated}
                  />
                </FormControl>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Divider />
        <WebhookEventSelection
          events={events}
          formEvents={formData.events}
          onEventSelected={onEventSelected}
          onBulkUpdateEvents={onBulkUpdateEvents}
          error={error}
        />
      </Stack>
    </ContentBox>
  );
};
