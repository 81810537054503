import { create } from 'zustand';
import { ProviderSettings } from '../types';

export const useSettingsStore = create<{
  stagedProviderSettings: ProviderSettings | null;
  originalProviderSettings: ProviderSettings | null;
  hasStagedSettings: () => boolean;
  initProviderSettings: (settings: ProviderSettings | null) => void;
  setStagedProviderSettings: (settings: ProviderSettings | null) => void;
  clearStagedSettings: () => void;
}>((set, get) => ({
  stagedProviderSettings: null,
  originalProviderSettings: null,
  initProviderSettings: (settings) => {
    set({
      originalProviderSettings: settings,
      stagedProviderSettings: settings,
    });
  },
  setStagedProviderSettings: (settings) => {
    set({ stagedProviderSettings: settings });
  },
  clearStagedSettings: () => {
    set((state) => ({
      stagedProviderSettings: state.originalProviderSettings,
    }));
  },

  hasStagedSettings: () => {
    const { stagedProviderSettings, originalProviderSettings } = get();
    if (!stagedProviderSettings || !originalProviderSettings) {
      return false;
    }

    return (
      JSON.stringify(stagedProviderSettings) !==
      JSON.stringify(originalProviderSettings)
    );
  },
}));
