import { useEffect } from 'react';
import { checkApiIsInMaintenance } from '../../status/maintenance';

import { useFlag } from '@unleash/proxy-client-react';

const MAINTENANCE_REDIRECT_URL = 'https://maintenance.tryfinch.com';

export const useMaintenanceCheck = () => {
  const dashboardMaintenanceEnabled = useFlag('dashboardMaintenanceEnabled');

  useEffect(() => {
    if (dashboardMaintenanceEnabled) {
      window.location.href = MAINTENANCE_REDIRECT_URL;
    }
  });

  useEffect(() => {
    checkApiIsInMaintenance().then(({ maintenance }) => {
      if (maintenance) {
        window.location.href = MAINTENANCE_REDIRECT_URL;
      }
    });
  }, []);
};
