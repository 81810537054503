import { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  keyframes,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Text,
} from '@chakra-ui/react';

import SuccessCircle from '../../assets/images/icons/successCircle.svg';
import ErrorCircle from '../../assets/images/icons/errorCircle.svg';
import FinchLogo from '../../assets/images/logos/finch/logo.svg';
import { useAuthApi } from '../../auth/hooks';
import { COLORS } from '../../constant';
import { ClipboardButton } from '../../components/ClipboardButton';
import loadingIcon from '../../assets/images/icons/loading.svg';
import styled from '@emotion/styled';
import noop from 'lodash/noop';

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const LoadingIcon = styled.img`
  width: 64px;
  animation: ${spin} 1s linear infinite;
`;

export const TokenExchangeModal = ({
  isOpen,
  onClose,
  applicationId,
  code,
  redirectUri,
}: {
  isOpen: boolean;
  onClose: () => void;
  applicationId: string;
  code: string | null;
  redirectUri?: string;
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(undefined);
  const api = useAuthApi();

  useEffect(() => {
    if (!api || !code || !isOpen || token) {
      return;
    }

    api
      .tokenExchange(applicationId, code, redirectUri)
      .then((token) => {
        setToken(token);
        setLoading(false);
      })
      .catch((reason) => {
        setLoading(false);
        if (reason) {
          setError(reason);
        }
      });
  }, [api, isOpen, code, applicationId, redirectUri, token]);

  useEffect(() => {
    if (!isOpen) {
      setToken(null);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxWidth="400px" minHeight="700px" borderRadius="20px">
        <ModalHeader padding="20px">
          <Flex>
            <Spacer />
            <Image src={FinchLogo} width="65px" height="20px" />
            <Spacer />
          </Flex>
        </ModalHeader>
        <ModalBody padding="8px 20px">
          <Spacer height="36px" />
          <Flex>
            <Spacer />
            {loading && <LoadingIcon src={loadingIcon} />}
            {!loading && (
              <Image
                src={error ? ErrorCircle : SuccessCircle}
                width="64px"
                height="64px"
              />
            )}
            <Spacer />
          </Flex>
          <Spacer height="24px" />
          <Heading
            fontWeight="semibold"
            fontSize="20px"
            lineHeight="26px"
            textAlign="center"
            marginBottom="8px"
          >
            {loading && 'Please wait ...'}
            {!loading && (error ? 'Error!' : 'Success!')}
          </Heading>
          <Text textAlign="center" marginBottom="24px">
            {loading &&
              "Hold on while we fetch your access token. Please don't close this window or navigate away."}
            {!loading &&
              (error
                ? 'Uh-oh... We ran into an unexpected error while trying to exchange your token. Please try again.'
                : 'You’ve connected a sandbox provider. Copy this access token to use in your application.')}
          </Text>
          {loading && <Skeleton borderRadius="8px" height="40px" />}
          {!loading && !error && (
            <FormControl>
              <InputGroup>
                <Input
                  fontSize="14px"
                  padding="6px 10px"
                  borderColor={COLORS.GRAY.GRAY_400}
                  backgroundColor={COLORS.GRAY.GRAY_200}
                  borderRadius="8px"
                  color={COLORS.GRAY.GRAY_600}
                  isReadOnly
                  value={token ?? ''}
                  type={'text'}
                />
                <InputRightElement gap={2}>
                  <ClipboardButton value={token ?? ''} />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={loading ? noop : onClose}
            variant="primary"
            size="lg"
            fontSize="16px"
            fontWeight="semibold"
            isLoading={loading}
            spinner={<></>}
            loadingText="Close Window"
            width="full"
            borderRadius="8px"
          >
            Close Window
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
