import { useAuth0, User } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';
import {
  CreateWebhookEndpointRequest,
  ListWebhookEndpointResponse,
  ListWebhookEventResponse,
  UpdateWebhookEndpointRequest,
  GetWebhookEndpointResponse,
  ListWebhookMessageResponse,
} from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { useEffect, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { baseURL } from '../utils/baseUrls';
import { WebhookApi } from './api';

export const useWebhookApi = () => {
  const { getAccessTokenSilently }: Auth0ContextInterface<User> = useAuth0();
  const [api, setAPI] = useState<WebhookApi | null>(null);

  useEffect(() => {
    const _setAPI = async () => {
      const token = await getAccessTokenSilently();
      setAPI(new WebhookApi(baseURL, token));
    };
    _setAPI();
  }, [getAccessTokenSilently]);

  return api;
};

const useRefetch = () => {
  const [refetch, setRefetch] = useState<boolean>(false);
  const triggerRefetch = () => setRefetch((prev) => !prev);
  return { refetch, triggerRefetch };
};

export const useListWebhooks = (
  applicationId: string,
  cursor?: string,
  limit = 100,
) => {
  const [data, setData] = useState<ListWebhookEndpointResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { refetch, triggerRefetch } = useRefetch();
  const api = useWebhookApi();

  useEffect(() => {
    if (!api) return;
    const run = async () => {
      try {
        const response = await api.listWebhookEndpoints({
          applicationId,
          cursor,
          limit,
        });
        setData(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    };
    run();
  }, [api, applicationId, cursor, limit, refetch]);

  return { loading, data, error, triggerRefetch };
};

export const useListWebhookEvents = () => {
  const [data, setData] = useState<ListWebhookEventResponse>({ events: [] });
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { refetch, triggerRefetch } = useRefetch();
  const includeBeta = useFlag('showBetaWebhookEvents');
  const api = useWebhookApi();

  useEffect(() => {
    if (!api) return;
    const run = async () => {
      setLoading(true);
      try {
        const response = await api.listWebhookEvents({ includeBeta });

        setData(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    };
    run();
  }, [api, refetch]);

  return { loading, data, error, triggerRefetch };
};

export const useGetWebhookEndpoint = ({
  applicationId,
  endpointId,
}: {
  applicationId: string;
  endpointId: string;
}) => {
  const [data, setData] = useState<GetWebhookEndpointResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { refetch, triggerRefetch } = useRefetch();
  const api = useWebhookApi();

  useEffect(() => {
    if (!api) return;
    const run = async () => {
      setLoading(true);
      try {
        const response = await api.getWebhookEndpoint(
          applicationId,
          endpointId,
        );
        setData(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    };
    run();
  }, [api, applicationId, endpointId, refetch]);

  return { loading, data, error, triggerRefetch };
};

export const useListWebhookMessages = ({
  applicationId,
  endpointId,
  limit,
  cursor,
}: {
  applicationId: string;
  endpointId: string;
  limit?: number;
  cursor?: string;
}) => {
  const [data, setData] = useState<ListWebhookMessageResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { refetch, triggerRefetch } = useRefetch();
  const api = useWebhookApi();

  useEffect(() => {
    if (!api) return;
    const run = async () => {
      setLoading(true);
      try {
        const response = await api.listWebhookMessages(
          applicationId,
          endpointId,
          { limit, cursor },
        );
        setData(response);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    };
    run();
  }, [api, applicationId, endpointId, limit, cursor, refetch]);

  return { loading, data, error, triggerRefetch };
};

export const useMessageAttempts = (
  applicationId: string,
  endpointId: string,
  messageId: string,
) => {
  const [attempts, setAttempts] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useWebhookApi();

  useEffect(() => {
    if (!api) {
      return;
    }

    const run = async () => {
      setLoading(true);

      try {
        const response = await api.messageAttempts(
          applicationId,
          endpointId,
          messageId,
        );

        setAttempts(response);
      } catch (caught) {
        if (caught instanceof Error) {
          setError(caught);
        } else {
          throw caught;
        }
      } finally {
        setLoading(false);
      }
    };

    run();
  }, [api, applicationId, endpointId, messageId]);

  return { loading, attempts, error };
};

export const useCreateWebhookEndpoint = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useWebhookApi();

  const mutate = async (
    applicationId: string,
    req: CreateWebhookEndpointRequest,
  ) => {
    setLoading(true);
    try {
      const response =
        (await api?.createWebhookEndpoint(applicationId, req)) ?? null;
      return { data: response };
    } catch (error) {
      if (error instanceof Error) {
        return { error };
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutate };
};

export const useUpdateWebhookEndpoint = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useWebhookApi();

  const mutate = async (
    applicationId: string,
    endpointId: string,
    req: UpdateWebhookEndpointRequest,
  ) => {
    setLoading(true);
    try {
      const response =
        (await api?.updateWebhookEndpoint(applicationId, endpointId, req)) ??
        null;
      return { data: response };
    } catch (error) {
      if (error instanceof Error) {
        return { error };
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutate };
};

export const useRotateEndpointSecret = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useWebhookApi();

  const mutate = async (applicationId: string, endpointId: string) => {
    setLoading(true);
    try {
      const response =
        (await api?.rotateWebhookEndpointSecret(applicationId, endpointId)) ??
        null;
      return { data: response };
    } catch (error) {
      if (error instanceof Error) {
        return { error };
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutate };
};

export const useDeleteWebhookEndpoint = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useWebhookApi();

  const mutate = async (applicationId: string, endpointId: string) => {
    setLoading(true);
    try {
      await api?.deleteWebhookEndpoint(applicationId, endpointId);
      return {};
    } catch (error) {
      if (error instanceof Error) {
        return { error };
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutate };
};

export const useSendTestEvent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const api = useWebhookApi();

  const sendEvent = async (applicationId: string, endpointId: string) => {
    setLoading(true);
    try {
      await api?.sendTestEvent(applicationId, endpointId);
      return {};
    } catch (error) {
      if (error instanceof Error) {
        return { error };
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, sendEvent };
};
