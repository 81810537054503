import axios from 'axios';
import * as Sentry from '@sentry/react';
import { ApplicationSecret } from '@finch-api/developer-dashboard-common';
import { baseURL } from '../../utils/baseUrls';

/**
 * Fetches all applications for the Developer corresponding to the provided token
 * @param token the Developer's Auth0 token
 * @returns the new plaintext secret
 */
export const refreshSecret = async (
  token: string,
  applicationId: string,
): Promise<string> => {
  try {
    const response = await axios.post<ApplicationSecret>(
      `${baseURL}/api/v1/applications/${applicationId}/secret`,
      null, // no body!
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data.secret;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
