import {
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
  HStack,
} from '@chakra-ui/react';
import { SandboxCount } from './SandboxCount';
import { SandboxSetup } from './SandboxSetup';
import { ConnectSandbox } from './SandboxConnect';
import { ReactNode } from 'react';
import { SandboxMode } from '../types';
import { ContentBox } from '../../components/ContentBox';
import { toWords } from 'number-to-words';

const HeadingText = ({ children }: { children: ReactNode }) => (
  <Heading as="h3" size="xs" fontWeight="500" lineHeight="24px">
    {children}
  </Heading>
);

export const SandboxInfoBar = ({
  used,
  limit,
  loading,
  children,
  mode,
}: {
  used: number;
  limit: number | null;
  loading: boolean;
  children: ReactNode;
  mode: SandboxMode;
}) => {
  let label = '';
  let description = '';

  switch (mode) {
    case 'finch':
      label = 'Finch Sandbox';
      description =
        'Create and configure mock connections to any employment system.';

      break;

    case 'provider':
      label = 'Provider Sandbox';
      description =
        'Set up demo accounts with select employment systems to see how they interact with Finch.';

      break;
  }

  return (
    <Stack spacing="8px" mt="24px">
      <ContentBox>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          divider={<StackDivider borderColor="gray.200" />}
        >
          <Stack p="24px 16px" flexBasis="50%">
            <HeadingText>About {label} Connections</HeadingText>
            <Flex
              justify="space-between"
              direction={{ base: 'column', lg: 'row' }}
              gap="8px"
            >
              <Text fontSize="14px">{description}</Text>
              <ConnectSandbox mode={mode} />
            </Flex>
          </Stack>
          <Stack p="24px 16px" flexBasis="25%">
            <HStack
              justify="space-between"
              align="center"
              flexWrap="wrap"
              gap="1"
            >
              <HeadingText>Connections</HeadingText>
              <SandboxCount
                used={used}
                limit={limit}
                loading={loading}
                limitType="connections"
              />
            </HStack>
            <HStack>
              <Text fontSize="14px">
                {label} connections are{' '}
                {limit ? ` limited to ${toWords(limit)}` : 'unlimited'}.
              </Text>
            </HStack>
          </Stack>
          <Stack p="24px 16px" flexBasis="25%" minW="fit-content">
            <HeadingText>Getting Started</HeadingText>
            <SandboxSetup mode={mode} />
          </Stack>
        </Stack>
      </ContentBox>
      {children}
    </Stack>
  );
};
