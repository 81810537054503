import { IconButton, Flex } from '@chakra-ui/react';
import {
  FiArrowLeft as ArrowLeft,
  FiArrowRight as ArrowRight,
} from 'react-icons/fi';

import { pageSize } from '@finch-api/developer-dashboard-common';
import { useActivityLogs } from './hooks/useActivityLogs';
import { useActivityStore } from './state/activityStore';

export const Pagination = () => {
  const { fetchLogs, totalLogSize } = useActivityLogs();
  const { updateActivePageIndex, activeFilters } = useActivityStore();

  return (
    <Flex justifyContent={'flex-end'} alignItems="center" gap={2}>
      <Flex gap={1}>
        <IconButton
          icon={<ArrowLeft />}
          variant="icon"
          aria-label="Previous page"
          onClick={() => {
            updateActivePageIndex(activeFilters.pageIndex - pageSize);

            fetchLogs();
          }}
          isDisabled={0 === activeFilters.pageIndex}
        />
        <IconButton
          variant="icon"
          icon={<ArrowRight />}
          aria-label="Next page"
          onClick={() => {
            updateActivePageIndex(activeFilters.pageIndex + pageSize);

            fetchLogs();
          }}
          isDisabled={totalLogSize <= activeFilters.pageIndex + pageSize}
        />
      </Flex>
    </Flex>
  );
};
