import {
  Badge,
  Box,
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  ExternalJobStatus,
  JobType,
} from '@finch-api/common/dist/external/dashboard/job-status';
import { useRef } from 'react';
import {
  JobStatusTag,
  JobStatusVariant,
} from '../../components/Tag/JobStatusTag';
import { COLORS } from '../../constant';
import { InfoIcon } from '../../shared/InfoIcon';
import { format } from '../../shared/utils/dateFormatter';
import { getJobTypeLabel } from './JobsTable';
import { StatusCell } from './TableCells';
import { JobEntry } from '../types';

const benefitsEndpointMap: Partial<
  Record<
    JobType,
    {
      url: string;
      method: 'GET' | 'POST' | 'DELETE' | 'PUT';
    }
  >
> = {
  [JobType.BENEFIT_CREATE]: {
    url: 'https://api.finch.com/employer/benefits',
    method: 'POST',
  },
  [JobType.BENEFIT_UPDATE]: {
    url: 'https://api.finch.com/employer/benefits/{benefit_id}',
    method: 'POST',
  },
  [JobType.BENEFIT_ENROLL]: {
    url: 'https://api.tryfinch.com/employer/benefits/{benefit_id}/individuals',
    method: 'POST',
  },
  [JobType.BENEFIT_UNENROLL]: {
    url: 'https://api.tryfinch.com/employer/benefits/{benefit_id}/individuals',
    method: 'DELETE',
  },
};

const TableHeader = ({ children }: { children: string }) => (
  <Th
    textTransform="capitalize"
    backgroundColor={COLORS.WHITE}
    textColor={COLORS.GRAY.GRAY_600}
    letterSpacing="inherit"
  >
    <Flex alignItems="center">{children}</Flex>
  </Th>
);

const TableStyle = styled.div`
  table {
    border-spacing: 0;

    th {
      padding: 16px 0;
      border: 0px;
    }

    tr {
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};

      :last-child {
        border-bottom: 0px;
      }
    }

    td {
      padding: 16px 0;
      border: 0px;
    }
  }
`;

export const MethodColors = {
  POST: 'blue',
  GET: 'green',
  PUT: 'yellow',
  DELETE: 'red',
};

export const NumberTag = ({ children }: { children: number }) => {
  return (
    <Tag
      size="sm"
      fontWeight="600"
      bg={COLORS.GRAY.GRAY_300}
      color={COLORS.FINCH.GRAY}
      minH="24px"
      minW="24px"
      lineHeight="12px"
      p={Number(children) < 100 ? '0' : undefined}
      justifyContent="center"
      borderRadius="full"
    >
      {children}
    </Tag>
  );
};

const InfoHeader = ({ children }: { children: string }) => {
  return (
    <Text fontWeight="600" fontSize="12px">
      {children}
    </Text>
  );
};

export const JobDetailsDrawer = ({
  isOpen,
  onClose,
  job,
}: {
  isOpen: boolean;
  onClose: () => void;
  job: JobEntry;
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const hasBatchedIndividuals =
    job.type !== JobType.ORG_PAYROLL_SYNC
      ? job?.tasks?.some((task) => task.employeeId !== null)
      : undefined;

  const hasEndpoint = benefitsEndpointMap[job.type];

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size="md"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay bg="blackAlpha.400" />
      <DrawerContent maxW="650px">
        <HStack
          p="25px"
          justify="space-between"
          bg={COLORS.GRAY.GRAY_100}
          align="center"
          borderBottom={`1px solid ${COLORS.GRAY.GRAY_400}`}
        >
          <Heading fontWeight="600" fontSize="14px">
            Job ID{' '}
            <Box
              ml="8px"
              as="span"
              fontFamily="Roboto Mono, monospace"
              fontWeight="400"
              color={COLORS.GRAY.GRAY_600}
            >
              {job?.jobId}
            </Box>
          </Heading>
          <DrawerCloseButton pos="relative" top="0" right="0" />
        </HStack>

        <Stack
          spacing="16px"
          divider={<Divider borderColor={COLORS.GRAY.GRAY_400} opacity="1" />}
          p="24px"
        >
          <HStack justify="space-between">
            <Stack spacing="8px">
              <InfoHeader>Job Status</InfoHeader>
              {job.status && (
                <StatusCell status={job.status as ExternalJobStatus} />
              )}
            </Stack>
            <Stack spacing="8px">
              <InfoHeader>Job Type</InfoHeader>
              <Text fontSize="14px">{getJobTypeLabel(job.type)}</Text>
            </Stack>
            {job.creationDate && (
              <Stack spacing="8px">
                <InfoHeader>Creation Date</InfoHeader>
                <Text fontSize="14px">
                  {format(new Date(job.creationDate))}
                </Text>
              </Stack>
            )}
            <Stack spacing="8px">
              <InfoHeader>Completion Date</InfoHeader>
              <Text fontSize="14px">
                {job.date ? format(new Date(job.date)) : '-'}
              </Text>
            </Stack>
          </HStack>

          {hasEndpoint && (
            <Stack spacing="8px">
              <InfoHeader>Endpoint</InfoHeader>
              <HStack>
                <Text fontSize="14px">
                  {benefitsEndpointMap[job.type]?.url}
                </Text>
                <Badge
                  size="sm"
                  colorScheme={
                    MethodColors[benefitsEndpointMap[job.type]?.method || 'GET']
                  }
                >
                  {benefitsEndpointMap[job.type]?.method}
                </Badge>
              </HStack>
            </Stack>
          )}

          {hasBatchedIndividuals && job.type !== JobType.ORG_PAYROLL_SYNC && (
            <TableStyle>
              <Table mt="8px">
                <Thead>
                  <Tr
                    style={{
                      borderBottom: `1px solid ${COLORS.GRAY.GRAY_400}`,
                    }}
                  >
                    <Th p="0"></Th>
                    <TableHeader>Individual ID</TableHeader>
                    <TableHeader>Status</TableHeader>
                  </Tr>
                </Thead>
                <Tbody>
                  {job?.tasks?.map((task, index) => {
                    const taskResponse = task?.response as {
                      message?: string;
                      code?: string;
                    };

                    return (
                      <Tr key={task.id}>
                        <Td
                          textAlign="center"
                          width="auto"
                          p="0"
                          style={{
                            paddingRight: '8px',
                            paddingLeft: '8px',
                          }}
                        >
                          <NumberTag>{index + 1}</NumberTag>
                        </Td>
                        <Td fontSize="14px">{task.employeeId}</Td>
                        <Td>
                          {task.status && (
                            <HStack justify="space-between">
                              <JobStatusTag
                                variant={task.status as JobStatusVariant}
                              />
                              {taskResponse?.message && (
                                <Tooltip
                                  placement="top-end"
                                  hasArrow
                                  label={taskResponse.message}
                                >
                                  <div>
                                    <InfoIcon />
                                  </div>
                                </Tooltip>
                              )}
                            </HStack>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableStyle>
          )}
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};
