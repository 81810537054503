import type { ColumnFiltersState } from '@tanstack/react-table';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';

import { useSeachParamsOnLoad } from '../../shared/useQuery';
import {
  FieldSupportTableFilterId,
  FilterSchema,
  Group,
  Subgroup,
} from '../types';
import {
  deductionFeaturesFilterSchema,
  deductionOperationsFilterSchema,
  filterSchema,
  organizationFilterSchema,
  payrollFilterSchema,
} from '../schema';
import { useControlsFromURI } from './useControlsFromURI';

function validate(filters: FilterSchema, group: Group, subgroup: Subgroup) {
  switch (group) {
    case 'organization':
      return organizationFilterSchema.safeParse(filters);

    case 'payroll':
      return payrollFilterSchema.safeParse(filters);

    case 'deductions':
      switch (subgroup) {
        case 'operations':
          return deductionOperationsFilterSchema.safeParse(filters);

        case 'features':
          return deductionFeaturesFilterSchema.safeParse(filters);
      }
  }
}

/**
 * Safely parse the table filters from the URI parameters at page load to seed
 * the initial state and resulting view.
 */
export function useFieldSupportFiltersFromURI(): ColumnFiltersState {
  const query = useSeachParamsOnLoad();
  const { group, subgroup } = useControlsFromURI();
  const parsed = (
    Object.keys(filterSchema.shape) as FieldSupportTableFilterId[]
  ).reduce<FilterSchema>(
    (parsed, parameter) => set(parsed, parameter, query.get(parameter)),
    Object.create(null),
  );
  const sanitization = filterSchema.safeParse(omitBy(parsed, isNil));

  if (!sanitization.success) {
    return [];
  }

  const validation = validate(sanitization.data, group, subgroup);

  if (!validation.success) {
    return [];
  }

  const filters = omitBy(validation.data, isNil);

  return Object.entries(filters).map(([id, value]) => ({ id, value }));
}
