import {
  Box,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Text,
  useDisclosure,
  Tag,
  Tfoot,
  HStack,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { useListWebhookMessages } from '../hooks';
import { WebhooksLoadingState } from './WebhooksLoadingState';
import { format } from '../../shared/utils/dateFormatter';
import { Caption } from '../../shared/Typography';
import {
  WebhookMessage,
  WebhookMessageStatus as WebhookMessageStatusEnum,
} from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { useState } from 'react';
import { WebhookPagination } from './WebhookPagination';
import { WebhookMessagesEmpty } from './WebhookMessagesEmpty';
import { EventDetailDrawer } from './EventDetailDrawer';
import { RiInformationLine } from 'react-icons/ri';
import { MAX_ATTEMPTS } from '../constants';
import { ContentBox } from '../../components/ContentBox';
import { WebhookMessageStatusTag } from '../../components/Tag/WebhookMessageStatusTag';
import { webhookStatusToVariant } from '../../constant/status';

const WebhookStatus = ({
  message: { status, nextAttempt },
}: {
  message: WebhookMessage;
}) => {
  let label: string | undefined;

  if (nextAttempt) {
    label = `Next attempt at ${format(new Date(nextAttempt))}`;
  }

  if (status === WebhookMessageStatusEnum.FAIL) {
    label = `We tried sending this webhook event ${MAX_ATTEMPTS} times over two hours but failed. Please check your webhook settings and try again.`;
  }

  return (
    <>
      <WebhookMessageStatusTag variant={webhookStatusToVariant[status]} />
      {label && (
        <Tooltip hasArrow label={label}>
          <HStack align="center">
            <RiInformationLine size="16px" />
          </HStack>
        </Tooltip>
      )}
    </>
  );
};

export const WebhookMessagesTable = ({
  applicationId,
  endpointId,
}: {
  applicationId: string;
  endpointId: string;
}) => {
  const [queryParam, setQueryParam] = useState<{
    limit: number;
    cursor?: string;
  }>({
    limit: 5,
    cursor: undefined,
  });

  const { loading, data } = useListWebhookMessages({
    applicationId,
    endpointId,
    ...queryParam,
  });
  const {
    isOpen: isMessageDetailOpen,
    onOpen: onMessageDetailOpen,
    onClose: onMessageDetailClose,
  } = useDisclosure();
  const [selectedMessage, setSelectedMessage] = useState<WebhookMessage | null>(
    null,
  );

  const onViewMessageDetail = (message: WebhookMessage) => {
    setSelectedMessage(message);
    onMessageDetailOpen();
  };

  const onCursorChange = (cursor: string | undefined) => {
    setQueryParam({
      ...queryParam,
      cursor,
    });
  };

  return (
    <>
      {loading && <WebhooksLoadingState />}

      {!loading && data && (
        <ContentBox>
          <TableContainer border={0}>
            <Table
              variant="simple"
              sx={{
                'td:first-of-type': {
                  width: '100%',
                },
              }}
            >
              <Thead
                sx={{
                  Tr: {
                    border: 0,
                  },
                  p: {
                    textTransform: 'capitalize',
                    fontWeight: '600',
                    fontSize: '12px',
                    color: 'gray.600',
                  },
                }}
              >
                <Tr>
                  <Th>
                    <Text casing={'capitalize'}>Event ID</Text>
                  </Th>
                  <Th>
                    <Text casing={'capitalize'}>Event Type</Text>
                  </Th>
                  <Th>
                    <Text casing={'capitalize'}>Status</Text>
                  </Th>
                  <Th>
                    <Text casing={'capitalize'}>Timestamp</Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.messages.length === 0 && (
                  <Tr>
                    <Td colSpan={12} textAlign="center" padding="0px">
                      <WebhookMessagesEmpty />
                    </Td>
                  </Tr>
                )}

                {data.messages.length > 0 &&
                  data.messages.map((msg) => (
                    <Tr
                      key={msg.id}
                      onClick={() => onViewMessageDetail(msg)}
                      style={{ cursor: 'pointer' }}
                      _hover={{
                        backgroundColor: COLORS.GRAY.GRAY_100,
                      }}
                    >
                      <Td>
                        <Text
                          fontFamily="Roboto Mono"
                          fontSize="14px"
                          fontWeight="400"
                        >
                          {msg.id}
                        </Text>
                      </Td>
                      <Td>
                        <Tag
                          backgroundColor="#EFF1FF"
                          color={COLORS.FINCH.PURPLE}
                        >
                          {msg.eventType}
                        </Tag>
                      </Td>
                      <Td minWidth={'200px'}>
                        <Stack direction="row">
                          <WebhookStatus message={msg} />
                        </Stack>
                      </Td>
                      <Td>
                        <Box isTruncated>
                          <Caption>{format(new Date(msg.timestamp))}</Caption>
                        </Box>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
              {data.messages.length > 0 &&
                (data.nextCursor !== null || data.prevCursor !== null) && (
                  <Tfoot>
                    <Tr alignContent="right">
                      <Th></Th>
                      <Th></Th>
                      <Th></Th>
                      <Th>
                        <WebhookPagination
                          prevCursor={data.prevCursor}
                          nextCursor={data.nextCursor}
                          onCursorChange={onCursorChange}
                        />
                      </Th>
                    </Tr>
                  </Tfoot>
                )}
            </Table>
          </TableContainer>

          {selectedMessage && (
            <EventDetailDrawer
              message={selectedMessage}
              applicationId={applicationId}
              endpointId={endpointId}
              isOpen={isMessageDetailOpen}
              onClose={onMessageDetailClose}
            />
          )}
        </ContentBox>
      )}
    </>
  );
};
