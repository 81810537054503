import { useDisclosure } from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { DisconnectedIcon } from '../../shared/icons/DisconnectedIcon';
import { ConnectionAccountDetail } from '../types';
import { DisconnectConfirmationModal } from './DisconnectConfirmationModal';
import { LinkIcon } from 'shared/icons/Link';
import { useConnectSession } from 'connections-v2/hooks/useConnectSession';
import { ModalNewConnectSession } from 'connections-v2/components/ConnectSession/ModalNewConnectSession';
import { useApplication } from 'applications/hooks';
import { FeatureFlag } from 'constant/feature-flags';
import { useFlag } from '@unleash/proxy-client-react';
import { ActionMenu } from 'components/ActionMenu';

export const ConnectionDetailActionMenu = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail | null;
}) => {
  const {
    isOpen: isDisconnectModalOpen,
    onOpen: onDisconnectModalOpen,
    onClose: onDisconnectModalClose,
  } = useDisclosure();

  const { application } = useApplication();
  const isFlatfileEnabled = application?.features.flatFileEnabled;
  const isConnectionDisconnectEnabled = useFlag(
    FeatureFlag.DashboardConnectionDisconnect,
  );

  const {
    isOpen: isNewConnectSessionOpen,
    onOpen: onNewConnectSessionOpen,
    onClose: onNewConnectSessionClose,
  } = useDisclosure();

  const {
    create: { mutate, data, isPending },
    list: { data: connectSessions },
  } = useConnectSession();

  const latestSession = connectSessions?.[0];

  const onConnectionDisconnectRequested = () => {
    onDisconnectModalOpen();
  };

  const onCreateNewConnectSession = () => {
    if (connectionDetail) {
      mutate(
        {
          connectionId: connectionDetail.connectionAccountId,
          providerId: connectionDetail.providerId,
          scopes: connectionDetail.products.flatMap(
            (product) => product.scopes,
          ),
          externalCompanyId: null,
          externalCompanyName: null,
        },
        { onSuccess: onNewConnectSessionOpen },
      );
    }
  };

  return (
    <>
      {connectionDetail && (
        <DisconnectConfirmationModal
          isOpen={isDisconnectModalOpen}
          onClose={onDisconnectModalClose}
          connectionId={connectionDetail.connectionAccountId}
          companyName={connectionDetail.companyName}
          clientId={connectionDetail.clientId}
          onConnectionDisconnected={() => {}}
        />
      )}
      {/* One of features have to be enabled to show the menu */}
      {(isConnectionDisconnectEnabled || isFlatfileEnabled) && (
        <ActionMenu
          isLoading={isPending}
          menuItems={[
            ...(isFlatfileEnabled
              ? [
                  {
                    icon: <LinkIcon />,
                    label:
                      latestSession && !latestSession.tokenId
                        ? 'Refresh Latest Connect Link'
                        : 'Create a Re-authentication Connect Link',
                    onClick: onCreateNewConnectSession,
                  },
                ]
              : []),
            ...(isConnectionDisconnectEnabled
              ? [
                  {
                    icon: <DisconnectedIcon />,
                    label: 'Disconnect',
                    onClick: onConnectionDisconnectRequested,
                    color: COLORS.FINCH.RED,
                    bgHoverColor: COLORS.LIGHT_RED,
                    textHoverColor: COLORS.FINCH.RED,
                  },
                ]
              : []),
          ]}
        />
      )}
      {data?.connectUrl && (
        <ModalNewConnectSession
          isOpen={isNewConnectSessionOpen}
          onClose={onNewConnectSessionClose}
          connectUrl={data.connectUrl}
        />
      )}
    </>
  );
};
